import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot, getDoc, Timestamp } from "firebase/firestore";
import { db } from "../../config/firebase";
import { simplifyConnections } from "../../utils/constants";
import { addUserToDb } from "../../utils/firebaseOperations";

let unsubscribeListener = null;

const initialState = {
	data: null,
	loading: true,
	error: null,
	userType: null, // New field to track user type
};

// Helper function to check user existence in collections
const checkUserInCollections = async (userId) => {
	if (!userId) throw new Error("No user ID provided");

	const octoMarketRef = doc(db, "octoMarketUsers", userId);
	const transporterRef = doc(db, "transporters", userId);

	const [octoMarketDoc, transporterDoc] = await Promise.all([getDoc(octoMarketRef), getDoc(transporterRef)]);

	if (!octoMarketDoc.exists() && !transporterDoc.exists()) {
		throw new Error("Not a valid OctoMarket user. Please logout and sign up for OctoMarket specifically.");
	}

	// Return the document that exists along with the user type
	if (octoMarketDoc.exists()) {
		return {
			doc: octoMarketDoc,
			userType: "octoMarket",
		};
	}

	if (transporterDoc.exists()) {
		// Create octoUser Profile
		let timestamp = Timestamp.now();
		let dataToBeSent = {
			...mapTransporterData({ ...transporterDoc.data(), id: transporterDoc.id }),
			profileCompleted: false,
			rejected: false,
			approved: true,
			contacts: [],
			category: [],
			blocked: false,
			deleted: false,
			createdAt: timestamp,
			userType: "Transporter",
		};
		// console.log({ dataToBeSent });
		await addUserToDb("octoMarketUsers", userId, dataToBeSent);
		return { userType: "Transporter", doc: dataToBeSent };
	}
};

// Updated fetch user data function
export const fetchUserData = createAsyncThunk("userData/fetch", async (userId) => {
	const { doc, userType } = await checkUserInCollections(userId);
	if (userType == "Transporter") {
		let { createdAt, ...data } = doc;
		return {
			userType,
			userData: data,
		};
	}
	return {
		userData: mapUserData({ id: doc.id, ...doc.data() }),
		userType,
	};
});

// Updated listen to user data function
export const listenToUserData = createAsyncThunk("userData/listen", async (userId, { dispatch, getState }) => {
	if (!userId) throw new Error("No user ID provided");

	const collectionName = "octoMarketUsers";

	const userDocRef = doc(db, collectionName, userId);

	if (unsubscribeListener) {
		unsubscribeListener();
		unsubscribeListener = null;
	}

	unsubscribeListener = onSnapshot(
		userDocRef,
		(doc) => {
			if (doc.exists()) {
				dispatch(updateUserData(mapUserData({ id: doc.id, ...doc.data() })));
			}
		},
		(error) => {
			console.error("Real-time listener error:", error);
			dispatch(setUserDataError(error.message));
		}
	);
});

export const userDataSlice = createSlice({
	name: "userData",
	initialState,
	reducers: {
		setUserData: (state, action) => {
			state.data = action.payload.userData;
			state.userType = action.payload.userType;
			state.loading = false;
			state.error = null;
		},
		updateUserData: (state, action) => {
			if (state.data) {
				state.data = { ...state.data, ...action.payload };
			}
		},
		setUserDataError: (state, action) => {
			state.error = action.payload;
		},
		clearUserData: (state) => {
			state.data = null;
			state.loading = false;
			state.error = null;
			state.userType = null;
		},
		clearNotificationListener: () => {
			if (unsubscribeListener) {
				unsubscribeListener();
				unsubscribeListener = null;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserData.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchUserData.fulfilled, (state, action) => {
				state.data = action.payload.userData;
				state.userType = action.payload.userType;
				state.loading = false;
				state.error = null;
			})
			.addCase(fetchUserData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message || "Error fetching user data";
			});
	},
});

export const { setUserData, updateUserData, setUserDataError, clearUserData, clearNotificationListener } =
	userDataSlice.actions;

export default userDataSlice.reducer;

// Helper function to map user data (unchanged)
const mapUserData = (data) => ({
	id: data.id,
	address: formatAddVendor(data?.address) ?? "",
	suite: data?.suite ?? "",
	street: data?.street ?? "",
	city: data?.city ?? "",
	state: data?.state ?? "",
	zip: data?.zip ?? "",
	region: data?.region ?? "",
	specialty: data?.specialty ?? "",
	category: data?.category ?? [],
	keyVerticals: data?.keyVerticals ?? "",
	timeZone: data?.timeZone ?? "",
	websiteUrl: data?.websiteUrl ?? "",
	bio: data?.bio ?? "",
	profileImage: data?.profileImage ?? "",
	contacts: data?.contacts ?? [],
	email: data?.email ?? "",
	companyName: data?.companyName ?? "",
	name: data?.name ?? "",
	shortDesc: data?.shortDesc ?? "",
	serviceArea: data?.serviceArea ?? "",
	serviceAreaArr: data?.serviceAreaArr ?? "",
	approved: Boolean(data.approved),
	profileCompleted: Boolean(data.profileCompleted),
	phone: data?.phone ?? "",
	localAreas: data?.localAreas ?? [],
	rejected: data?.rejected,
	hidden: Boolean(data?.hidden),
	userType: data?.userType ? data?.userType : "Vendor",
	connections: simplifyConnections(data?.connections) ?? {},
});

// Helper function to map transporter data
const mapTransporterData = (data) => ({
	id: data.id,
	address: formatAddTransporter(data) ?? "",
	suite: data?.billingSuite ?? "",
	street: data?.billingStreet ?? "",
	city: data?.billingCity ?? "",
	state: data?.billingState ?? "",
	zip: data?.billingZip ?? "",
	region: data?.region ?? "",
	specialty: data?.specialty ?? "",
	category: data?.category ?? [],
	keyVerticals: data?.keyVerticals ?? "",
	timeZone: data?.timeZone ?? "",
	websiteUrl: data?.website ?? "",
	bio: data?.bio ?? "",
	profileImage: data?.companyLogo?.link ?? "",
	contacts: data?.contacts ?? [],
	email: data?.generalEmail ?? "",
	companyName: data?.companyDisplayName ?? "",
	shortDesc: data?.shortDesc ?? "",
	serviceArea: data?.serviceArea ?? "",
	serviceAreaArr: data?.serviceAreaArr ?? "",
	approved: Boolean(data.approved),
	profileCompleted: Boolean(data.profileCompleted),
	phone: data?.phoneNumber ?? "",
	localAreas: data?.localAreas ?? [],
	rejected: data?.rejected,
	hidden: !Boolean(data?.optedForOctoConnect),
	userType: "Transporter",
	connections: simplifyConnections(data?.connections) ?? {},
});

export let formatAddTransporter = (transporter) => {
	let formattedAdd = "";
	transporter.billingStreet?.trim()?.length ? (formattedAdd += transporter.billingStreet) : (formattedAdd = "");
	transporter.billingSuite?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingSuite}`)
		: (formattedAdd += transporter.billingSuite ?? "");
	transporter.billingCity?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingCity}`)
		: (formattedAdd += transporter.billingCity ?? "");
	transporter.billingState?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingState}`)
		: (formattedAdd += transporter.billingState ?? "");
	transporter.billingZip?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingZip}`)
		: (formattedAdd += transporter.billingZip ?? "");

	return formattedAdd.length ? formattedAdd : "--";
};

export let formatAddVendor = (vendor) => {
	console.log({ vendor });

	if (!vendor) return "--";

	let formattedAdd = "";
	vendor?.street?.trim()?.length ? (formattedAdd += vendor.street) : (formattedAdd = "");
	// console.log({ formattedAdd, suite: vendor.suite });

	vendor?.suite?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${vendor.suite}`)
		: (formattedAdd += vendor.suite ?? "");
	// console.log({ formattedAdd });
	vendor?.city?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${vendor.city}`)
		: (formattedAdd += vendor.city ?? "");
	// console.log({ formattedAdd });
	vendor?.state?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${vendor.state}`)
		: (formattedAdd += vendor.state ?? "");
	// console.log({ formattedAdd });
	vendor?.zip?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${vendor.zip}`)
		: (formattedAdd += vendor.zip ?? "");

	// console.log({ formattedAdd });
	return formattedAdd.length ? formattedAdd : "--";
};

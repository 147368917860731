export const vehicleOptions = [
	{ label: "Box Truck", value: "BOX_TRUCK" },
	{ label: "Cargo Van", value: "CARGO_VAN" },
	{ label: "Mobile Shred Truck", value: "MOBILE_SHRED_TRUCK" },
	{ label: "Flatbed Truck", value: "FLATBED_TRUCK" },
	{ label: "Semi-Truck", value: "SEMI_TRUCK" },
	{ label: "Step Van", value: "STEP_VAN" },
];

export const fuelOptions = [
	{ label: "Diesel", value: "DIESEL" },
	{ label: "Electric", value: "ELECTRIC" },
	{ label: "Gasoline", value: "GASOLINE" },
];
export const statusOptions = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
	{ label: "Maintenance", value: "MAINTENANCE" },
];

const stateOptions = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' }
];

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

const notificationTypeOption = [
  { value: "Full Manifest", label: "Full Manifest" },
  { value: "Combined Manifest", label: "Combined Manifest" },
];

const defaultTreatment = {
  name: "",
  octoNumber: "",
  street: "",
  suite: "",
  city: "",
  phone: "",
  state: "",
  treatmentStatus: "Active",
  zip: "",
  email1: "",
  email2: "",
  email3: "",
  notificationTypeOption: "Combined Manifest",
  stateRegistrationNumber1: "",
  stateRegistrationNumber2: "",
  stateRegistrationNumber3: "",
  medicalwaste: {
    transferStation: false,
    treatmentFacilities: true,
    paperShredding: false,
  },
  treatmentCoordinates: {
    lat: "", lng: ""
  },
  wasteProcessingFees: {
    biohazard: {
      checked: false,
      fee: {
        id: "",
        feeType: "Biohazard/Sharps",
        unit: "pound",
        price: 0.00,
        isActive: false,
      },
    },
    pharmaceutical: {
      checked: false,
      fee: {
        id: "",
        feeType: "Pharmaceutical",
        unit: "Pound",
        price: 0.00,
        isActive: false,
      },
    },
    chemotherapy: {
      checked: false,
      fee: {
        id: "",
        feeType: "Chemotherapy",
        unit: "Pound",
        price: 0.00,
        isActive: false,
      },
    },
    containerWashing: {
      checked: false,
      fee: {
        id: "",
        feeType: "Container Washing",
        unit: "Each",
        price: 0.00,
        isActive: false,
      },
    },
    documentFee: {
      checked: false,
      fee: {
        id: "",
        feeType: "Document Fee",
        unit: "Per Transfer",
        price: 0.00,
        isActive: false,
      },
    },
    energyFee: {
      checked: false,
      fee: {
        id: "",
        feeType: "Energy Fee",
        unit: "Per Transfer",
        price: 0.00,
        isActive: false,
      },
    },

    gateFee: {
      checked: false,
      fee: {
        id: "",
        feeType: "Gate Fee",
        unit: "Per Transfer",
        price: 0.00,
        isActive: false,
      },
    },
  },
};

import React, { useEffect, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import { useNavigate, useParams } from "react-router-dom";
import { createNewTreatment, GetTreatmentData, editTreatmentDetails, listenToWasteProcessingFees } from "../../../../../utils/firebaseOperations";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Button from "../../../../../components/UI/Button";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import Checkbox from "../../../../../components/UI/Checkbox";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import TreatmentMap from "../../../../../components/maps/TreatmentMap";
import { debounce } from "lodash";
import InputTreatment from "./InputTreatment";
import MapWithSingleMarker from "../../../../../components/maps/MapWithSingleMarker";


const AddTreatment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [treatmentCoordinates, setTreatmentCoordinates] = useState({ lat: null, lng: null });
  const [markers, setMarkers] = useState([]);
  const [isAddressesLoaded, setIsAddressesLoaded] = useState(true);
  const [fees, setFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    trigger,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      treatment: defaultTreatment
    }
  });

  const { batchGeocodeTreatmentAddresses, calculateCenterPosition, geocodeAddress } = useGeocoding();

  useEffect(() => {
    const fetchWasteProcessingFees = () => {
      setLoading(true);
      try {
        const unsubscribe = listenToWasteProcessingFees((newFees) => {
          if (newFees.length === 0) {
            // setError("No data available.");
          } else {
            setFees(newFees);
          }
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (err) {
        console.error("Error fetching waste processing fees:", err);
        setError("Failed to load waste processing fees.");
        setLoading(false);
      }
    };

    const cleanup = fetchWasteProcessingFees();
    return cleanup;
  }, []);

  const initialCenter = useMemo(() => ({
    lat: treatmentCoordinates.lat || 32.7157,
    lng: treatmentCoordinates.lng || -117.1611
  }), [treatmentCoordinates]);

  const debouncedGeocoding = useMemo(() =>
    debounce(async (serviceAddress) => {
      if (!serviceAddress) return;

      const currentAddressString = `${serviceAddress.street?.trim() || ""} ${serviceAddress.city.trim()} ${serviceAddress.state.trim()} ${serviceAddress.zip.trim()}`.toLowerCase();
      const oldAddressString = markers[0]
        ? `${markers[0].serviceAddress.street?.trim() || ""} ${markers[0].serviceAddress.city.trim()} ${markers[0].serviceAddress.state.trim()} ${markers[0].serviceAddress.zipCode.trim()}`.toLowerCase()
        : "";

      if (currentAddressString !== oldAddressString) {
        setIsAddressesLoaded(false);
        const geocodedMarkers = await batchGeocodeTreatmentAddresses([{
          name: "New Treatment",
          serviceAddress,
        }]);

        if (geocodedMarkers?.[0]) {
          const newMarker = {
            ...geocodedMarkers[0],
            key: Math.random().toString(36).substr(2, 9),
            formattedAdd: `${serviceAddress.street}, ${serviceAddress.city}, ${serviceAddress.state} ${serviceAddress.zip}`,
          };
          setMarkers([newMarker]);
          setTreatmentCoordinates({
            lat: newMarker.lat,
            lng: newMarker.lng,
          });
        }
        setIsAddressesLoaded(true);
      }
    }, 1000),
    [batchGeocodeTreatmentAddresses, markers]
  );

  useEffect(() => {
    const fetchTreatmentData = async () => {
      if (id) {
        try {
          const treatmentData = await GetTreatmentData(id);
          if (treatmentData) {
            // Process waste processing fees
            if (treatmentData.wasteProcessingFees) {
              Object.keys(treatmentData.wasteProcessingFees).forEach(key => {
                if (treatmentData.wasteProcessingFees[key]?.fee?.price) {
                  let priceInDollars = treatmentData.wasteProcessingFees[key].fee.price / 100;
                  priceInDollars = `$${priceInDollars.toFixed(2)}`;
                  treatmentData.wasteProcessingFees[key].fee.price = priceInDollars;
                }
              });
            }

            reset({ treatment: treatmentData });

            // Geocode address and set coordinates
            if (treatmentData.street && treatmentData.city && treatmentData.state && treatmentData.zip) {
              const address = {
                street: treatmentData.street,
                city: treatmentData.city,
                state: treatmentData.state,
                zip: treatmentData.zip,
              };

              try {
                const geocodedData = await geocodeAddress(address);
                if (geocodedData) {
                  setMarkers([
                    {
                      ...geocodedData,
                      key: Math.random().toString(36).substr(2, 9),
                      formattedAdd: `${address.street}, ${address.city}, ${address.state} ${address.zip}`,
                    }
                  ]);
                  setTreatmentCoordinates({
                    lat: geocodedData.lat,
                    lng: geocodedData.lng,
                  });
                }
              } catch (error) {
                console.error("Live geocoding failed:", error);
                toast.error("Unable to geocode the provided address.");
              }
            }
          }
        } catch (error) {
          console.error("Error fetching treatment data:", error);
          toast.error("Failed to fetch treatment details.");
        }
      }
    };

    fetchTreatmentData();
  }, [id, reset, geocodeAddress]);

  useEffect(() => {
    const watchedValues = watch(['treatment.street', 'treatment.city', 'treatment.state', 'treatment.zip']);
    const address = {
      street: watchedValues[0] || '',
      city: watchedValues[1] || '',
      state: watchedValues[2] || '',
      zip: watchedValues[3] || '',
    };

    const updateMapWithNewAddress = async () => {
      if (address.street && address.city && address.state && address.zip) {
        try {
          const geocodedData = await geocodeAddress(address);
          if (geocodedData) {
            setMarkers([
              {
                ...geocodedData,
                key: Math.random().toString(36).substr(2, 9),
                formattedAdd: `${address.street}, ${address.city}, ${address.state} ${address.zip}`,
              }
            ]);
            setTreatmentCoordinates({
              lat: geocodedData.lat,
              lng: geocodedData.lng,
            });
          }
        } catch (error) {
          console.error("Error updating map with new address:", error);
        }
      }
    };

    updateMapWithNewAddress();
  }, [watch, geocodeAddress]);

  const handleFeeCheckboxChange = (feeKey, isChecked) => {
    const currentValues = getValues();
    const currentPrice = currentValues.treatment.wasteProcessingFees[feeKey]?.fee?.price || 0.00;

    setValue(`treatment.wasteProcessingFees.${feeKey}.checked`, isChecked);
    setValue(`treatment.wasteProcessingFees.${feeKey}.fee.isActive`, isChecked);

    if (!isChecked) {
      setValue(`treatment.wasteProcessingFees.${feeKey}.fee.price`, 0);
    }
  };

  const onSubmit = async (data) => {
    try {
      const isValid = await trigger();
      if (!isValid) {
        toast.error("Please fill all required fields.");
        return;
      }

      const addressCoordinates = (treatmentCoordinates.lat && treatmentCoordinates.lng)
        ? treatmentCoordinates
        : await geocodeAddress({
          street: data.treatment.street,
          city: data.treatment.city,
          state: data.treatment.state,
          zip: data.treatment.zip
        });

      const finalCoordinates = addressCoordinates || {
        lat: 32.7157,
        lng: -117.1611
      };

      const processedFees = {};
      Object.entries(data.treatment.wasteProcessingFees).forEach(([key, value]) => {
        if (value.checked) {
          const cleanedPrice = (typeof value.fee.price === 'string')
            ? value.fee.price.replace(/[^\d.-]/g, '')
            : value.fee.price.toString().replace(/[^\d.-]/g, '');

          const priceInDollars = parseFloat(cleanedPrice);
          const priceWithTwoDecimals = Math.round(priceInDollars * 100) / 100;
          const priceInCents = Math.round(priceWithTwoDecimals * 100);

          processedFees[key] = {
            checked: true,
            fee: {
              ...value.fee,
              price: priceInCents,
              isActive: true
            }
          };
        }
      });

      const treatmentData = {
        ...data.treatment,
        treatmentCoordinates: finalCoordinates,
        wasteProcessingFees: processedFees
      };

      if (id) {
        await editTreatmentDetails(treatmentData, id);
        toast.success("Treatment updated successfully!");
      } else {
        await createNewTreatment(treatmentData);
        toast.success("Treatment added successfully!");
      }

      navigate("/admin/assets/treatments-facilities");
    } catch (error) {
      console.error("Error saving treatment:", error);
      toast.error("Failed to save treatment.");
    }
  };

  const handlePreviousBtn = () => {
    navigate("/admin/assets/treatments-facilities");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="p-6">
        <div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
          <div className="py-5 pl-8 font-medium">Treatment Facility (TF) / Treatment Station (TS) Profile</div>
          <div className="h-[400px]">

            {isAddressesLoaded && markers.length > 0 && (
              <TreatmentMap
                geocodedMarker={markers[0]}
                isAddressesLoaded={isAddressesLoaded}
                initialCenter={initialCenter}
                zoomVal={16}
              />
            )}
            {isAddressesLoaded && markers.length === 0 && (
              <TreatmentMap zoomVal={16}
                isAddressesLoaded={isAddressesLoaded}
                initialCenter={initialCenter}
              />
            )}
          </div>
          <ToastContainer />
          <div className="border-b mb-2 border-[#CCCCCC]"></div>
          <div className="ml-6 mr-6">
            <div className="flex gap-8">
              <div className="w-1/2">
                {/* Name Field */}
                <Controller
                  name={`treatment.name`}
                  control={control}
                  rules={{ required: "Name is required" }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Name*" value={value} onChange={onChange} />
                      {errors.treatment?.name && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.name.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Street Field */}
                <Controller
                  name={`treatment.street`}
                  control={control}
                  rules={{ required: "Street is required" }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Street*" value={value} onChange={onChange} />
                      {errors.treatment?.street && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.street.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Suite Field */}
                <Controller
                  name={`treatment.suite`}
                  control={control}
                  //rules={{ required: "Suite is required" }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Suite" value={value} onChange={onChange} />
                      {errors.treatment?.suite && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.suite.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* City Field */}
                <Controller
                  name={`treatment.city`}
                  control={control}
                  rules={{ required: "City is required" }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="City*" value={value} onChange={onChange} />
                      {errors.treatment?.city && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.city.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* State Field */}
                <Controller
                  name={`treatment.state`}
                  control={control}
                  rules={{ required: "State is required" }}
                  render={({ field: { value, onChange } }) => (
                    <div>
                      <Dropdown
                        value={value}
                        onChange={onChange}
                        options={stateOptions}
                        label="State*"
                      />
                      {errors.treatment?.state && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.state.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Zip Field */}
                <Controller
                  name={`treatment.zip`}
                  control={control}
                  rules={{
                    required: "Zip is required",
                    pattern: { value: /^[0-9]+$/, message: "Zip must be numeric" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Zip*" value={value} onChange={onChange} />
                      {errors.treatment?.zip && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.zip.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Phone Field */}
                <Controller
                  name={`treatment.phone`}
                  control={control}
                  rules={{
                    required: "Phone is required",
                  }}
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        id={`treatment.phone`}
                        label="Phone*"
                        value={field.value || ""}
                        onChange={(formattedNumber) => {
                          field.onChange(formattedNumber);
                        }}
                        isDisabled={false}
                      />
                      {errors.treatment?.phone && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.phone.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Treatment Status Field */}
                <Controller
                  name={`treatment.treatmentStatus`}
                  control={control}
                  rules={{ required: "Treatment Status is required" }}
                  render={({ field: { value, onChange } }) => (
                    <div>
                      <Dropdown
                        value={value}
                        onChange={onChange}
                        options={statusOptions}
                        label="TF & TS Status*"
                      />
                      {errors.treatment?.treatmentStatus && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.treatmentStatus.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="w-1/2">
                {/* Email 1 Field */}
                <Controller
                  name={`treatment.email1`}
                  control={control}
                  rules={{
                    required: "Email 1 is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Email 1*" value={value} onChange={onChange} />
                      {errors.treatment?.email1 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.email1.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Email 2 Field */}
                <Controller
                  name={`treatment.email2`}
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Email 2" value={value} onChange={onChange} />
                      {errors.treatment?.email2 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.email2.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Email 3 Field */}
                <Controller
                  name={`treatment.email3`}
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input label="Email 3" value={value} onChange={onChange} />
                      {errors.treatment?.email3 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.email3.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* Notification Type Field */}
                <Controller
                  name={`treatment.notificationTypeOption`}
                  control={control}
                  rules={{ required: "Notification Type is required" }}
                  render={({ field: { value, onChange } }) => (
                    <div>
                      <Dropdown
                        value={value}
                        onChange={onChange}
                        options={notificationTypeOption}
                        label="Notification Type*"
                      />
                      {errors.treatment?.notificationTypeOption && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.notificationTypeOption.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* State Registration Number 1 Field */}
                <Controller
                  name={`treatment.stateRegistrationNumber1`}
                  control={control}
                  rules={{ required: "State Registration Number 1 is required" }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input
                        label="State Registration Number 1*"
                        value={value}
                        onChange={onChange}
                      />
                      {errors.treatment?.stateRegistrationNumber1 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.stateRegistrationNumber1.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                {/* State Registration Number 2 Field */}
                <Controller
                  name={`treatment.stateRegistrationNumber2`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input
                        label="State Registration Number 2"
                        value={value}
                        onChange={onChange}
                      />
                      {errors.treatment?.stateRegistrationNumber2 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.stateRegistrationNumber2.message}
                        </p>
                      )}
                    </div>
                  )}
                />
                {/* State Registration Number  Field */}
                <Controller
                  name={`treatment.stateRegistrationNumber3`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <Input
                        label="State Registration Number 3"
                        value={value}
                        onChange={onChange}
                      />
                      {errors.treatment?.stateRegistrationNumber3 && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.treatment.stateRegistrationNumber3.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div>
              <div className="mt-12 mb-4 font-medium">Services Offered</div>
              <div className="border-t"></div>
              <div className="grid gap-5 py-4 mt-2">
                {/* Medical Waste Treatment Facility (TF) */}
                <Controller
                  name="treatment.medicalwaste.treatmentFacilities"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Medical Waste Treatment Facility (TF)"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />

                {/* Medical Waste Transfer Station (TS) */}
                <Controller
                  name="treatment.medicalwaste.transferStation"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Medical Waste Transfer Station (TS)"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />

                {/* Onsite Paper Shredding */}
                <Controller
                  name="treatment.medicalwaste.paperShredding"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Onsite Paper Shredding"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <div className="mt-12 mb-4 font-medium">Waste Processing Fees</div>
              <div className="border-t"></div>
              <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-[#E5F2FF]">
                <div className="w-1/4">Sub-waste Type (Fee)</div>
                <div className="w-1/4">Unit</div>
                <div className="w-1/4">Cost</div>
                <div className="w-1/4">Active</div>
              </div>

              <div className="flex justify-around gap-4 pt-4 ">
                {/* Fee Type Column */}
                <div className="w-1/4 flex flex-col gap-2 pl-8 ">
                  {fees &&
                    Object.keys(fees).map((key) => (
                      <div key={key} >
                        {fees[key].feeType}
                      </div>
                    ))}
                </div>

                {/* Unit Column */}
                <div className="w-1/4 flex flex-col gap-2 pl-5">
                  {fees &&
                    Object.keys(fees).map((key) => (
                      <div key={key}>
                        {fees[key].unit}
                      </div>
                    ))}
                </div>

                {/* Price Column */}
                <div className="w-1/4 flex flex-col gap-1">
                  {fees && Object.keys(fees).map((key) => (
                    <Controller
                      key={key}
                      name={`treatment.wasteProcessingFees.${key}.fee.price`}
                      control={control}
                      render={({ field }) => (
                        <InputTreatment
                          value={field.value}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (getValues(`treatment.wasteProcessingFees.${key}.checked`)) {
                              field.onChange(value);
                            }
                          }}
                          disabled={!getValues(`treatment.wasteProcessingFees.${key}.checked`)}
                        />
                      )}
                    />
                  ))}
                </div>

                {/* Active Checkbox Column */}
                <div className="w-1/4 flex flex-col gap-1.5">
                  {fees && Object.keys(fees).map((key) => (
                    <Controller
                      key={key}
                      name={`treatment.wasteProcessingFees.${key}.checked`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          isChecked={value}
                          setIsChecked={(checked) => {
                            onChange(checked);
                            handleFeeCheckboxChange(key, checked);
                          }}
                        />
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-end gap-8 py-10">
                <Button
                  onClick={handlePreviousBtn}
                  btnStyle="form_nav_secondary"
                  text={"View TF/TS List"}
                />
                <Button
                  type="submit"
                  btnStyle="form_nav_primary"
                  onClick={handleSubmit(onSubmit)}

                  text={"Save"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTreatment;

import { closestCorners, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import Col from "./Col";
import { useState } from "react";
import { db } from "./config/firebase";
import { collection, getDocs } from "firebase/firestore";

function Test() {
	const [columns, setColumns] = useState([
		{
			id: "a",
			name: "Col 1",
			items: [
				{ id: "1", name: "Item 1" },
				{ id: "2", name: "Item 2" },
				{ id: "3", name: "Item 3" },
			],
		},
		{
			id: "b",
			name: "Col 2",
			items: [
				{ id: "4", name: "item 4" },
				{ id: "5", name: "item 5" },
				{ id: "6", name: "item 6" },
			],
		},
		{
			id: "c",
			name: "Col 3",
			items: [
				{ id: "7", name: "item 7" },
				{ id: "8", name: "item 8" },
				{ id: "9", name: "item 9" },
			],
		},
		{
			id: "d",
			name: "Col 4",
			items: [
				{ id: "10", name: "item 10" },
				{ id: "11", name: "item 11" },
				{ id: "12", name: "item 12" },
			],
		},
		{
			id: "e",
			name: "Col 5",
			items: [
				{ id: "13", name: "item 13" },
				{ id: "14", name: "item 14" },
				{ id: "15", name: "item 15" },
			],
		},
	]);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);

	const handleClick = async () => {
		// try {
		// 	const usersCollectionRef = collection(db, "generators");
		// 	const snapshots = await getDocs(usersCollectionRef);
		// 	if (snapshots.empty) {
		// 		console.log("No data", { snapshots: snapshots.docs.length });
		// 	} else {
		// 		console.log("Data found", snapshots.docs);
		// 	}
		// } catch (error) {
		// 	console.log({ error });
		// }
	};

	return (
		<div className="p-4  mx-auto flex items-start w-screen h-screen border m-5">
			{/* <DndContext collisionDetection={closestCorners} sensors={sensors}>
				<SortableContext items={columns.map((col) => col.id)}>
					{columns.map((column) => (
						<Col key={column.id} id={column.id} items={column.items} name={column.name} />
					))}
				</SortableContext>
			</DndContext> */}
			<button className="px-4 py-2 bg-primary text-white" onClick={handleClick}>
				Click to fetch data
			</button>
		</div>
	);
}

export default Test;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { collection, doc, getDoc, updateDoc, arrayUnion, Timestamp, getDocs } from "firebase/firestore";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import { auth, db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";

const dropdownOptions = ["Listed", "Unlisted"];

const Match_Request = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [generators, setGenerators] = useState([]);
	const [selectedGenerators, setSelectedGenerators] = useState([]);
	const [transporters, setTransporters] = useState([]);
	const [transporter, setTransporter] = useState("");
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();

	const {
		control,
		formState: { errors },
		watch,
		handleSubmit,
	} = useForm({
		defaultValues: {
			subContractor: "",
		},
	});

	const selectedSubcontractor = watch("subContractor");

	// Fetch current transporter's generators
	useEffect(() => {
		const fetchGenerators = async (transporterId) => {
			setIsLoading(true);
			try {
				const transporterRef = doc(collection(db, "transporters"), transporterId);
				const transporterDoc = await getDoc(transporterRef);
				console.log({ transporterId });

				if (transporterDoc.exists()) {
					const generatorIds = transporterDoc.data().generators || [];
					setTransporter(transporterDoc.data()?.name);
					// Fetch generator details
					const generatorData = await Promise.all(
						generatorIds.map(async (genId) => {
							const genDoc = await getDoc(doc(collection(db, "generators"), genId));
							return { id: genDoc.id, ...genDoc.data(), name: genDoc.data()?.generatorName };
						})
					);

					setGenerators(generatorData);
				}
			} catch (error) {
				console.error("Error fetching generators:", error);
			}
			setIsLoading(false);
		};

		if (user) fetchGenerators(user?.uid);
	}, [user]);

	// Fetch available transporters for subcontracting
	useEffect(() => {
		const fetchTransporters = async (transporterId) => {
			try {
				const transportersSnapshot = await getDocs(collection(db, "transporters"));
				const transporterOptions = transportersSnapshot.docs
					.map((doc) => ({
						label: doc.data().name,
						value: doc.id,
					}))
					.filter((t) => t.value !== transporterId); // Exclude current transporter

				setTransporters(transporterOptions);
			} catch (error) {
				console.error("Error fetching transporters:", error);
			}
		};

		if (user) fetchTransporters(user?.uid);
	}, [user]);

	const handleGeneratorSelection = (generatorId) => {
		setSelectedGenerators((prev) =>
			prev.includes(generatorId) ? prev.filter((id) => id !== generatorId) : [...prev, generatorId]
		);
	};

	const sendRequest = async (data) => {
		if (selectedGenerators.length === 0) {
			toast.warning("Please select at least one generator");
			return;
		}

		try {
			const currentTransporterRef = doc(collection(db, "transporters"), user.uid);

			// Update contractorTo field for current transporter
			await updateDoc(currentTransporterRef, {
				[`contractorTo.${data.subContractor}`]: {
					startDate: Timestamp.now(),
					status: "pending",
					sharedGenerators: arrayUnion(...selectedGenerators),
				},
			});

			// Update subcontractorTo field for selected transporter
			const subcontractorRef = doc(collection(db, "transporters"), data.subContractor);
			await updateDoc(subcontractorRef, {
				[`subcontractorTo.${user.uid}`]: {
					startDate: Timestamp.now(),
					status: "pending",
					managedGenerators: arrayUnion(...selectedGenerators),
				},
			});

			toast.success("Subcontract request sent successfully!");
			navigate("/admin/octo-connect/linked-generators");
		} catch (error) {
			console.error("Error sending subcontract request:", error);
			toast.error("Error sending request. Please try again.");
		}
	};

	const filteredGenerators = generators.filter(
		(gen) =>
			gen.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
			gen.octoNumber?.toString()?.includes(searchQuery.toLowerCase())
	);

	console.log({ filteredGenerators });

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="pt-6 font-medium text-lg pl-8">Search Generator</div>
				<div className="p-4 pl-8">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-2/5">
						<input
							type="text"
							placeholder="Search by Generator Name or OCTO number"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="ml-2 bg-transparent focus:outline-none text-gray-500 flex-1 w-full"
						/>
					</div>
				</div>

				<div className="px-8 my-2 font-medium text-lg text-black">Available Generators</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="w-16"></div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Contractor Name</div>
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-32">Status</div>
							{/* <div className="truncate w-60 text-center">Options</div> */}
							<div className="truncate w-60">Pricebook</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<div className="flex justify-center items-center h-40">Loading...</div>
						) : filteredGenerators.length > 0 ? (
							filteredGenerators.map((generator, index) => (
								<div
									key={generator.id}
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < filteredGenerators.length - 1 ? "border-b border-gray-300" : ""
									}`}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="w-16">
											<input
												type="checkbox"
												checked={selectedGenerators.includes(generator.id)}
												onChange={() => handleGeneratorSelection(generator.id)}
												className="w-4 h-4"
											/>
										</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.name ?? "--"}</div>
										<div className="truncate w-80 h-full">{transporter ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.subContractorName ?? "--"}</div>
										<div className="truncate h-full w-32">{generator.generatorStatus ?? "--"}</div>
										{/* <div className="flex justify-center w-60 text-xs font-medium">
											<ServiceDropdown buttonText="Options" options={dropdownOptions} />
										</div> */}
										<div className="truncate h-full w-60">{generator.pricebook ?? "--"}</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No generators found</p>
							</div>
						)}
					</div>
				</div>

				<div className="mt-12 px-8">
					<div className="text-lg py-2 font-medium">Partner Selection</div>
					<div className="border"></div>
					<div className="w-1/2">
						<Controller
							name="subContractor"
							control={control}
							rules={{ required: "Please select a subcontractor" }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										styles="flex justify-start"
										value={value}
										onChange={onChange}
										options={transporters}
										label="Select A Sub-Contractor"
									/>
									{errors.subContractor && <p className="text-red-500 text-sm mt-1">{errors.subContractor.message}</p>}
								</div>
							)}
						/>
					</div>
					<div className="w-1/2 flex py-2 justify-end">
						<button
							onClick={handleSubmit(sendRequest)}
							disabled={!selectedSubcontractor || selectedGenerators.length === 0}
							style={{ width: "184px", height: "44px" }}
							className={`px-4 py-2 rounded-full transition-opacity duration-300 ${
								!selectedSubcontractor || selectedGenerators.length === 0
									? "bg-gray-300 cursor-not-allowed"
									: "bg-whiteBlue hover:bg-blue-300"
							}`}
						>
							Send Request
						</button>
					</div>
				</div>

				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={() => navigate("/admin/octo-connect/contractor-management")}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={() => navigate("/admin/octo-connect/linked-generators")}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default Match_Request;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GeneratorBreadcrumb from "./GeneratorBreadcrumb";

const GeneratorHeader = () => {
	const [breadCrumbOptions, setBreadCrumbOptions] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		setBreadCrumbOptions(getBreadcrumbItems(location));
	}, [location]);

	const activeIndex = breadCrumbOptions.findIndex((item) => location.pathname.endsWith(item.path));

	return (
		<div className="top-0 flex items-center px-8 text-sm xl:text-base justify-between bg-white border h-20">
			<GeneratorBreadcrumb items={breadCrumbOptions} activeIndex={activeIndex} navigate={navigate} />
		</div>
	);
};

const getBreadcrumbItems = (location) => {
	const pathMap = {
		"/generator-dashboard": "Dashboard",
		"/generator-dashboard/service-reports": "Service Reports",
		"/generator-dashboard/invoices-and-payments": "Invoices and Payments",
		"/generator-dashboard/saved-payment-methods": "Saved Payment Method",
		"/generator-dashboard/make-payment": "Make Payment",
	};
	const currentPath = location.pathname;
	if (currentPath === "/generator-dashboard") {
		return [{ label: "Dashboard", path: location.pathname }];
	}
	if (currentPath === "/generator-dashboard/service-reports") {
		return [
			{ label: "Dashboard", path: "/generator-dashboard" },
			{ label: "Service Reports", path: "/generator-dashboard/service-reports" },
		];
	}
	if (currentPath === "/generator-dashboard/invoices-and-payments") {
		return [
			{ label: "Dashboard", path: "/generator-dashboard" },
			{ label: "Invoices and Payments", path: "/generator-dashboard/invoices-and-payments" },
		];
	}
	if (currentPath === "/generator-dashboard/saved-payment-methods") {
		return [
			{ label: "Dashboard", path: "/generator-dashboard" },
			{ label: "Saved Payment Method", path: "/generator-dashboard/saved-payment-methods" },
		];
	}
	if (currentPath === "/generator-dashboard/make-payment") {
		return [
			{ label: "Dashboard", path: "/generator-dashboard" },
			{ label: "Make Payment", path: "/generator-dashboard/make-payment" },
		];
	}
	const label = pathMap[currentPath] || "Dashboard";

	return [{ label, path: location.pathname }];
};

export default GeneratorHeader;

const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../../components/UI/Button";
import {
	createNewSatellite,
	GetSatelliteData,
	editSatelliteDetails,
	getDrivers,
	deleteSatelliteLogo,
} from "../../../../../utils/firebaseOperations";
import Checkbox from "../../../../../components/UI/Checkbox";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../../../../../config/firebase";
import MultiSelectRounded from "../../../../../components/UI/dropdowns/MultiSelectRounded";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import FileUpload from "../../../../transporters/UploadFiles";
import CustomDatePicker from "../../../../transporters/TransporterDatePicker";
import AddressForm from "../../../../transporters/Form";
import UrlInput from "../../../../../components/UI/UrlInput";
import { getAllTreatment } from "../../../../../utils/firebaseOperations";
import { useAuthState } from "react-firebase-hooks/auth";

const AddSatellite = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { batchGeocodeGeneratorsAddresses } = useGeocoding();
	const [serviceAddCoordinates, setServiceAddCoordinates] = useState(null);
	const [billingAddCoordinates, setBillingAddCoordinates] = useState(null);
	const [satelliteData, setSatelliteData] = useState(null);
	const [user] = useAuthState(auth);
	// Default form values
	const defaultValues = {
		name: "",
		phoneNumber: "",
		registration1: "",
		website: "",
		dot: "",
		businessHours: "",
		satelliteStatus: "Active",
		reviewLink: "",
		entityId: "",
		sic: "",
		cageCode: "",
		companyDisplayName: "",
		generalEmail: "",
		registration2: "",
		companyLogo: null,
		startOfFiscalYear: "",
		ein: "",
		epaId: "",
		sos: "",
		naicsCode: "",
		companySlogan: "",
		companyMissionStatement: "",
		bio: "",
		aboutEmail: "",
		assignedUser: [],
		existingUser: "",
		serviceOffered: {
			medicalWasteTransporter: false,
			medicalWasteTransferStation: false,
			medicalWasteTreatmentFacilities: false,
			rollOffContainer: false,
			offsitePaperShredding: false,
			onsitePaperShredding: false,
			solidWaste: false,
			recycling: false,
		},
		dispatchEmail: "",
		dispatchPhone: "",
		billingAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
		serviceAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
	};

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		trigger,
		setValue,
	} = useForm({ defaultValues });

	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);

	// Watch form values for billing and service address
	const watchBillingAddress = watch("billingAddress") || {};
	const watchServiceAddress = watch("serviceAddress") || {};

	const handleCopyAddress = (isChecked, copyFrom) => {
		if (copyFrom === "service" && isChecked) {
			Object.keys(watchBillingAddress).forEach((key) => {
				setValue(`serviceAddress.${key}`, watchBillingAddress[key]);
			});
		} else if (copyFrom === "billing" && isChecked) {
			Object.keys(watchServiceAddress).forEach((key) => {
				setValue(`billingAddress.${key}`, watchServiceAddress[key]);
			});
		}
	};

	const sanitizeData = (data) => {
		// Sanitize data to replace undefined with null
		Object.keys(data).forEach((key) => {
			if (data[key] === undefined) {
				data[key] = null;
			} else if (typeof data[key] === "object" && data[key] !== null) {
				data[key] = sanitizeData(data[key]); // Recursively sanitize nested objects
			}
		});
		return data;
	};

	useEffect(() => {
		const geocodeAddresses = async () => {
			try {
				// Geocode service address
				if (watchServiceAddress.street && watchServiceAddress.city && watchServiceAddress.state) {
					const serviceMarkers = await batchGeocodeGeneratorsAddresses([
						{
							name: watchServiceAddress.name || "Service Address",
							serviceAddress: watchServiceAddress,
						},
					]);

					if (serviceMarkers && serviceMarkers[0]) {
						setServiceAddCoordinates({
							lat: serviceMarkers[0].lat,
							lng: serviceMarkers[0].lng,
						});
					}
				}

				// Geocode billing address
				if (watchBillingAddress.street && watchBillingAddress.city && watchBillingAddress.state) {
					const billingMarkers = await batchGeocodeGeneratorsAddresses([
						{
							name: watchBillingAddress.name || "Billing Address",
							serviceAddress: watchBillingAddress,
						},
					]);

					if (billingMarkers && billingMarkers[0]) {
						setBillingAddCoordinates({
							lat: billingMarkers[0].lat,
							lng: billingMarkers[0].lng,
						});
					}
				}
			} catch (error) {
				//console.error("Geocoding error:", error);
				//toast.error("Error getting location coordinates");
			}
		};

		geocodeAddresses();
	}, [
		watchServiceAddress.street,
		watchServiceAddress.city,
		watchServiceAddress.state,
		watchBillingAddress.street,
		watchBillingAddress.city,
		watchBillingAddress.state,
	]);

	const onSubmit = async (data) => {
		try {
			// Validate address fields
			const validateAddressFields = async (addressType) => {
				const isAddressFilled = Object.values(data[addressType]).some((value) => value.trim() !== "");

				if (isAddressFilled) {
					const isValid = await trigger(addressType);
					if (!isValid) {
						toast.error(`Please fill all required fields in the ${addressType}.`);
						return false;
					}
				}
				return true;
			};

			// Validate billing and service addresses
			const isBillingValid = await validateAddressFields("billingAddress");
			const isServiceValid = await validateAddressFields("serviceAddress");

			if (!isBillingValid || !isServiceValid) {
				return;
			}

			// Destructure and prepare data
			const { serviceOffered = {}, companyLogo, ...restData } = data;

			const satelliteDataToSave = {
				...restData,
				// Save full coordinate objects with lat and lng
				satelliteServiceCoordinates: {
					lat: serviceAddCoordinates?.lat || 32.7122671,
					lng: serviceAddCoordinates?.lng || -117.0542354,
				},
				satelliteBillingCoordinates: {
					lat: billingAddCoordinates?.lat || 32.7122671,
					lng: billingAddCoordinates?.lng || -117.0542354,
				},

				// Set default service offerings with user-provided overrides
				serviceOffered: {
					medicalWasteTransporter: false,
					medicalWasteTransferStation: false,
					medicalWasteTreatmentFacilities: false,
					rollOffContainer: false,
					offsitePaperShredding: false,
					onsitePaperShredding: false,
					solidWaste: false,
					recycling: false,
					...serviceOffered,
				},

				// Set default status and connection preferences
				satelliteStatus: restData.satelliteStatus || "Active",
				assignedUser: restData.assignedUser || [],
				existingUser: restData.existingUser || "",
			};

			// Copy address details based on checkbox selections
			const copyAddressDetails = (source, target) => {
				const addressFields = ["name", "street", "suite", "city", "state", "zip", "email", "phone"];

				addressFields.forEach((field) => {
					const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
					satelliteDataToSave[`${target}${capitalizedField}`] = restData[`${source}${capitalizedField}`];
				});
			};

			if (billingCopyChecked) copyAddressDetails("service", "billing");
			if (serviceCopyChecked) copyAddressDetails("billing", "service");

			// Only update logo if a new file is uploaded
			if (companyLogo && companyLogo instanceof File) {
				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `/satellite/companyLogo/${companyLogo.name}`);
				const snapshot = await uploadBytes(storageRef, companyLogo);
				const downloadURL = await getDownloadURL(snapshot.ref);

				satelliteDataToSave.companyLogo = {
					name: companyLogo.name,
					link: downloadURL,
					mime: companyLogo.type || "",
					path: storageRef.fullPath,
					ref: "",
					token: token,
				};
			}

			// Save or update satellite location
			if (id) {
				await editSatelliteDetails(satelliteDataToSave, id);
				toast.success("Satellite Location details updated successfully!");
			} else {
				await createNewSatellite(satelliteDataToSave);
				toast.success("Satellite Location added successfully!");
				reset(defaultValues);
			}

			navigate("/admin/assets/satellite-location");
		} catch (error) {
			console.error(error);
			toast.error(error.message || "Failed to save Satellite");
		}
	};

	useEffect(() => {
		const fetchSatellite = async () => {
			if (id) {
				try {
					const data = await GetSatelliteData(id);
					if (data) {
						setSatelliteData(data);
						reset(data);
					}
				} catch (error) {
					toast.error("Failed to fetch Satellite data");
				}
			}
		};

		fetchSatellite();
	}, [id, reset]);

	const [treatmentOptions, setTreatmentOptions] = useState([]);
	useEffect(() => {
		let unsubscribe;
		const getTreatmentdata = async () => {
			unsubscribe = getAllTreatment((treatmentsData) => {
				const optionsForDropdown = treatmentsData.map(({ name }) => ({
					label: `${name}`,
					value: `${name}`,
				}));

				// Update the state with new options
				setTreatmentOptions(optionsForDropdown);
			}, user?.uid);
		};

		getTreatmentdata();
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const [DriverOptions, setDriverOptions] = useState([]);
	useEffect(() => {
		const getDriversdata = async () => {
			const dataWeGot = await getDrivers(user?.uid);
			const OptionsForDropdown = dataWeGot.map(({ firstName, lastName, middleInitial }) => ({
				label: `${firstName} ${middleInitial} ${lastName}`,
				value: `${firstName} ${middleInitial} ${lastName}`,
			}));
			setDriverOptions(OptionsForDropdown);
		};

		getDriversdata();
	}, []);

	// const handleDelete = async (id) => {
	// 	const loadingToast = toast.loading("Deleting...");
	// 	try {
	// 		await deleteSatelliteLogo(id);

	// 		toast.update(loadingToast, {
	// 			render: "Satellite Location deleted successfully!",
	// 			type: "success",
	// 			isLoading: false,
	// 			autoClose: 3000,
	// 		});

	// 	} catch (error) {
	// 		toast.update(loadingToast, {
	// 			render: "Error deleting satellite location!",
	// 			type: "error",
	// 			isLoading: false,
	// 			autoClose: 3000,
	// 		});
	// 		console.error("Error deleting satellite location: ", error);
	// 	}
	// };

	return (
		<>
			<div className="p-6">
				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<ToastContainer />
					<div className="my-4 ml-6 text-xl font-semibold">Satellite Profile</div>
					<div className="ml-6">General Information</div>
					<div className="mt-4 ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="mx-6">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-8">
								<div className="w-1/2">
									<Controller
										name="name"
										control={control}
										rules={{ required: "Company Name is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="companyDisplayName"
										control={control}
										rules={{ required: "Company Display Name is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Display Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.companyDisplayName && (
													<p className="text-red-500 text-sm mt-1">{errors.companyDisplayName?.message}</p>
												)}
											</div>
										)}
									/>

									<Controller
										name="phoneNumber"
										control={control}
										rules={{ required: "Phone is required" }}
										render={({ field }) => (
											<div>
												<PhoneInput
													id="phoneNumber"
													label="General Phone*"
													value={field.value}
													onChange={field.onChange}
												/>
												{errors.phoneNumber && (
													<p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>
												)}
											</div>
										)}
									/>

									<Controller
										name="generalEmail"
										control={control}
										rules={{
											required: "Email is required",
											pattern: {
												value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
												message: "Please enter a valid email address",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="General Email*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.generalEmail && (
													<p className="text-red-500 text-sm mt-1">{errors.generalEmail?.message}</p>
												)}
											</div>
										)}
									/>

									<Controller
										name="website"
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/[^\s]*)?$/,
												message: "Please enter a valid URL",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<UrlInput
													value={value}
													onChange={onChange}
													//type="url"
													label="Website URL"
													className="w-full px-2 py-1 text-sm"
													//placeholder="https://example.com"
												/>
												{errors.website && <p className="text-red-500 text-sm mt-4">{errors.website?.message}</p>}
											</div>
										)}
									/>

									<div className="flex items-center justify-between mt-8">
										<label className="truncate text-inputLabel font-normal">Start of Fiscal Year</label>
										<div className="w-2/3">
											<Controller
												name="startOfFiscalYear"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CustomDatePicker
														selectedDate={value}
														setSelectedDate={onChange}
														label="Start of Fiscal Year"
													/>
												)}
											/>
										</div>
									</div>

									<Controller
										name="businessHours"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Business Hours" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="reviewLink"
										control={control}
										rules={
											{
												// pattern: {
												// 	value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												// 	message: "Please enter a valid URL",
												// },
											}
										}
										render={({ field: { value, onChange } }) => (
											<div className="mb-8">
												<UrlInput
													value={value}
													onChange={onChange}
													//type="url"
													label="Google Review Link"
													className="w-full px-2 py-1 text-sm"
													//placeholder="https://example.com"
												/>
												{errors.reviewLink && <p className="text-red-500 text-sm mt-1">{errors.reviewLink?.message}</p>}
											</div>
										)}
									/>

									<FileUpload
										control={control}
										errors={errors}
										existingFile={satelliteData?.companyLogo}
										name={"companyLogo"}
										onDeleteFile={() => console.log("Clicked to delete file")}
									/>

									<div className="flex justify-center mt-2">
										{satelliteData?.companyLogo?.link && satelliteData?.companyLogo?.link.length > 0 ? (
											<div className="existing-file">
												<img
													src={satelliteData.companyLogo.link}
													alt="Company Logo"
													style={{ maxWidth: "100px", maxHeight: "100px", marginBottom: "10px" }}
												/>
											</div>
										) : null}
									</div>

									<div className="text-xs mt-1 text-gray-600">
										<span className="">Note:</span>{" "}
										<span className="font-light">
											(File should be a maximum of 5 MB and one of the following formats: PNG, JPG, JPEG.)
										</span>
									</div>
									<Controller
										name="satelliteStatus"
										control={control}
										rules={{ required: "Satellite Status is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={statusOptions}
													label="Satellite Location Status*"
													className="text-sm"
												/>
												{errors.satelliteStatus && (
													<p className="text-red-500 text-sm mt-1">{errors.satelliteStatus?.message}</p>
												)}
											</div>
										)}
									/>
								</div>

								<div className="w-1/2">
									<Controller
										name="registration1"
										control={control}
										rules={{ required: "Registration Number 1 is required" }}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<div>
												<Input
													label="Registration Number 1*"
													value={value}
													onChange={onChange}
													error={error ? error.message : null}
												/>
												{errors.registration1 && (
													<p className="text-red-500 text-sm mt-1">{errors.registration1?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="registration2"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="epaId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="dot"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="ein"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="entityId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sos"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sic"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="naicsCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="cageCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
								</div>
							</div>
							<AddressForm control={control} handleCopyAddress={handleCopyAddress} errors={errors} trigger={trigger} />

							<div className="">
								<div className="mt-12 mb-4">Services Offered</div>
								<div className="border-t"></div>
								<div className="grid gap-5 grid-cols-3 py-4 mt-2">
									<Controller
										name="serviceOffered.medicalWasteTransporter"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Medical Waste Satellite" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTransferStation"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Medical Waste Transfer Station" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTreatmentFacilities"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Medical Waste Treatment Facilities" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.rollOffContainer"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Roll Off Container" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.offsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Offsite Paper Shredding" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.onsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Onsite Paper Shredding" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.solidWaste"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Solid Waste" isChecked={value} setIsChecked={onChange} />
										)}
									/>
									<Controller
										name="serviceOffered.recycling"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox label="Recycling" isChecked={value} setIsChecked={onChange} />
										)}
									/>
								</div>
								<div className="border-t"></div>
								<div className="justify-between gap-6 py-4 mt-2">
									<div className="flex justify-between">
										<div className="w-1/2">
											<Controller
												name="existingUser"
												control={control}
												render={({ field }) => (
													<div>
														<Dropdown
															options={treatmentOptions}
															value={field.value}
															onChange={field.onChange}
															label="Link Existing TF/TS "
															className="text-sm"
														/>
													</div>
												)}
											/>
										</div>
									</div>
								</div>
								<div className="">Dispatch Department</div>
								<div className="border-t mt-4"></div>
								<div className="flex gap-6 w-full">
									<div className="w-1/2">
										<Controller
											name="dispatchEmail"
											control={control}
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
													message: "Please enter a valid email address",
												},
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div>
													<Input label="Email*" type="text" value={value} onChange={onChange} />
													{error?.message && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
												</div>
											)}
										/>
									</div>

									<div className="w-1/2">
										<Controller
											name="dispatchPhone"
											control={control}
											rules={{ required: "Phone is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
													{errors.dispatchPhone && (
														<p className="text-red-500 text-sm mt-1">{errors.dispatchPhone.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
							</div>

							{/* <div className="pb-4 mt-6">User Information</div>
							<div className="border-t"></div> */}
							{/* <div>
								<div className="flex justify-between">
									<div className="w-1/2 ">
										<Controller
											name="assignedUser"
											control={control}
											rules={{ required: "Assigned User is required" }}
											render={({ field }) => (
												<div>
													<MultiSelectRounded
														options={DriverOptions}
														value={field.value}
														onChange={field.onChange}
														label="Assigned User *"
														className="text-sm"
													/>
													{errors.assignedUser && (
														<p className="text-red-500 text-sm mt-1">{errors.assignedUser.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
							</div> */}

							<div className="flex justify-end gap-8 py-10">
								<Button
									onClick={() => navigate("/admin/assets/satellite-location")}
									btnStyle="form_nav_secondary"
									text="View Satellite Location List"
								/>
								<Button type="submit" btnStyle="form_nav_primary" text={"Submit"} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddSatellite;

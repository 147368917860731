const serviceFreqValues = ["all", "WC", "MTW", "E1W", "E2W", "E4W", "E8W", "E12W", "Unscheduled"];
const serviceFreqOpts = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "WC",
		value: "WC",
	},
	{
		label: "MTW",
		value: "MTW",
	},
	{
		label: "E1W",
		value: "E1W",
	},
	{
		label: "E2W",
		value: "E2W",
	},
	{
		label: "E4W",
		value: "E4W",
	},
	{
		label: "E8W",
		value: "E8W",
	},
	{
		label: "E12W",
		value: "E12W",
	},
	{
		label: "Unscheduled",
		value: "Unscheduled",
	},
];

import React, { useEffect, useState, useMemo, useCallback, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import Loader from "../../../../../components/UI/loaders/Loader";
import { useBreadcrumb } from "../../../../../context/BreadcrumbContext";
import { PiListBold } from "react-icons/pi";
import { FiMapPin } from "react-icons/fi";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";
import { generatorStatus, industryTypes, serviceTypes } from "../../../../../utils/constants";
import { useGeocoding } from "../../../../../hooks/useGeocoding";
import GenreratorsList from "./components/GenreratorsList";
import { useDispatch, useSelector } from "react-redux";
import {
	startGeneratorListener,
	stopGeneratorListener,
	allGenerators,
	isLoading,
	hasError,
} from "../../../../../store/sliceReducers/generatorSlice";
import { auth } from "../../../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Generators = ({ viewOption }) => {
	const navigate = useNavigate();
	const [isAddressesLoaded, setIsAddressesLoaded] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const { handleSetBreadcrumb } = useBreadcrumb();
	const [selectedServiceTypes, setSelectedServiceTypes] = useState(["all", ...serviceTypes.map((item) => item.value)]);
	const [selectedContractorTypes, setSelectedContractorTypes] = useState(["all", "contractors", "subContractors"]);
	const [selectedGeneratorStatus, setSelectedGeneratorStatus] = useState(["ACTIVE"]);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [selectedIndustryTypes, setSelectedIndustryTypes] = useState([]);
	const [selectedServiceFrequencies, setSelectedServiceFrequencies] = useState(serviceFreqValues);
	const [selectedGenerators, setSelectedGenerators] = useState([]);
	const { batchGeocodeAddresses, batchGeocodeGeneratorsAddresses, calculateCenterPosition } = useGeocoding();
	const [geocodedMarkers, setGeocodedMarkers] = useState([]);
	const generators = useSelector(allGenerators);
	const loading = useSelector(isLoading);
	const error = useSelector(hasError);
	const [isPending, startTransition] = useTransition();
	const [filteredData, setFilteredData] = useState([]);
	const [user] = useAuthState(auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!viewOption) {
			return navigate("/admin/generators/list");
		}
	}, [navigate]);

	useEffect(() => {
		dispatch(startGeneratorListener(user?.uid));
		return () => dispatch(stopGeneratorListener());
	}, [dispatch]);

	const normalizedSearchQuery = searchQuery.replace(/\s+/g, "").toLowerCase();
	const hasIntersection = (arr1, arr2) => arr1.some((item) => arr2.includes(item));

	useEffect(() => {
		startTransition(() => {
			let finalDataFiltered = generators;
			finalDataFiltered = finalDataFiltered.filter((data) =>
				[data.name, data.phone, data.email, data.address, data.octoNumber, data.internalAccountNumber].some((field) =>
					field?.toString()?.includes(normalizedSearchQuery)
				)
			);
			finalDataFiltered = finalDataFiltered.filter(
				(item) =>
					hasIntersection(selectedServiceTypes, item.serviceType) ||
					selectedServiceTypes.includes(item.serviceType) ||
					(Array.isArray(item.serviceType) && item.serviceType.length == 0)
			);
			finalDataFiltered = finalDataFiltered.filter(
				(item) =>
					selectedGeneratorStatus.includes(item.generatorStatus) || item.generatorStatus == "" || !item.generatorStatus
			);

			setFilteredData(finalDataFiltered);
		});
	}, [generators, normalizedSearchQuery, selectedServiceTypes, selectedGeneratorStatus]);

	const handleCheckboxChange = (id) => {
		if (id === "select_all") {
			// Select all generators using their IDs
			setSelectedGenerators(filteredData.map((item) => item.id));
		} else if (id === "deselect_all") {
			setSelectedGenerators([]);
		} else {
			// Toggle individual selection using ID
			setSelectedGenerators((prev) =>
				prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
			);
		}
	};

	useEffect(() => {
		if (selectedGenerators.length > 0) {
			setSelectedGenerators([]);
		}
	}, [filteredData]);

	const handleRowClick = (data) => {
		handleSetBreadcrumb("generators", data.octoNumber);
		navigate(`/admin/generators/${data.id}`);
	};

	console.log({ generators });

	const geocodeAddresses = useCallback(
		async (generatorsData) => {
			if (generatorsData.length > 0) {
				const markers = await batchGeocodeGeneratorsAddresses(generatorsData);
				setGeocodedMarkers(markers);
				setIsAddressesLoaded(true);
			} else {
				setGeocodedMarkers([]);
				setIsAddressesLoaded(true);
			}
		},
		[filteredData]
	);

	useEffect(() => {
		if (viewOption === "map" && generators.length > 0) {
			geocodeAddresses(filteredData);
		}
	}, [navigate, filteredData]);

	const initialCenter = useMemo(() => {
		return geocodedMarkers.length > 0 ? calculateCenterPosition(geocodedMarkers) : { lat: 0, lng: 0 };
	}, [geocodedMarkers]);

	const handleSelectedOptionsChange = (selected, type) => {
		if (type == "serviceType") setSelectedServiceTypes(selected);
		if (type == "generatorStatus") setSelectedGeneratorStatus(selected);
		if (type == "contractorType") setSelectedContractorTypes(selected);
		if (type == "serviceFrequency") setSelectedServiceFrequencies(selected);
		if (type == "route") setSelectedRoutes(selected);
		if (type == "industry") setSelectedIndustryTypes(selected);
	};

	const clearAllFilters = () => {
		setSearchQuery("");
		setSelectedGeneratorStatus(["ACTIVE"]);
		setSelectedContractorTypes(["all", "contractors", "subContractors"]);
		setSelectedServiceTypes(["all", ...serviceTypes.map((item) => item.value)]);
	};

	if (!viewOption) return <Loader />;

	return (
		<div
			style={{ height: selectedGenerators.length > 0 ? `calc(100% - 40px)` : `100%` }}
			className={`main-container flex flex-col border text-xs xl:text-sm border-cardBorderCol bg-white rounded-cardRadii`}
		>
			<div className="flex px-4 py-4 gap-2 items-center justify-between ">
				<div className="flex items-center gap-4 w-full max-w-xl">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-full max-w-lg">
						<input
							type="text"
							placeholder="Search by Name, Phone Number, Email, or Address"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="ml-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
						/>
					</div>
					<h6 className="text-nowrap text-cardTextGray">{filteredData?.length} Generators</h6>

					<button
						className="px-4 py-2 rounded-lg min-w-fit disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed border border-cardTextBlue hover:bg-creamWhite transition-colors duration-200 ease-in-out"
						onClick={clearAllFilters}
					>
						Remove Filters
					</button>
				</div>
				<div className="flex items-center gap-2 border-l border-borderCol pl-2 relative overflow-x-auto py-2">
					<div className="flex items-center gap-2">
						<MultiselectDropdown
							width="w-44"
							buttonText="Service Frequency"
							options={serviceFreqOpts}
							selectedOptions={selectedServiceFrequencies}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "serviceFrequency")}
						/>
						<MultiselectDropdown
							width="w-40 ml-2"
							buttonText="Route"
							options={[]}
							selectedOptions={selectedRoutes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "route")}
						/>
						<MultiselectDropdown
							width="w-40 ml-2"
							buttonText="Stage"
							options={[]}
							selectedOptions={[]}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "stage")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Contractor Type"
							options={[
								{ label: "All", value: "all" },
								{ label: "Contractors", value: "contractors" },
								{ label: "Sub-contractors", value: "subContractors" },
							]}
							selectedOptions={selectedContractorTypes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "contractorType")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Generator Status"
							options={[{ label: "All", value: "all" }, ...generatorStatus]}
							selectedOptions={selectedGeneratorStatus}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "generatorStatus")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Service Type"
							options={[{ label: "All", value: "all" }, ...serviceTypes]}
							selectedOptions={selectedServiceTypes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "serviceType")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Industry Type"
							options={[{ label: "All", value: "all" }, ...industryTypes]}
							selectedOptions={selectedIndustryTypes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "industry")}
						/>
					</div>
					<div className="flex items-center gap-2">
						<button
							className={`${
								viewOption == "list" ? "border-cardTextBlue text-cardTextBlue" : "text-cardTextGray border-borderCol"
							} flex items-center justify-between gap-3 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out whitespace-nowrap`}
							onClick={() => navigate("/admin/generators/list")}
						>
							<span>List</span>
							<PiListBold size={16} />
						</button>
						<button
							className={`${
								viewOption == "map" ? "border-cardTextBlue text-cardTextBlue" : "text-cardTextGray border-borderCol"
							} flex items-center justify-between gap-3 px-4 py-2 rounded-lg bg-creamWhite border transition-colors hover:bg-cardTextGray hover:bg-opacity-10 duration-200 ease-in-out whitespace-nowrap`}
							onClick={() => navigate("/admin/generators/map")}
						>
							<span>Map</span>
							<FiMapPin size={16} />
						</button>
					</div>
				</div>
			</div>
			<div style={{ height: `calc(100% - 80px)` }} className="w-full text-[#11142D] px-4 pb-4">
				{viewOption == "list" ? (
					<>
						{isPending ? (
							<Loader />
						) : (
							<GenreratorsList
								isLoading={loading}
								generators={filteredData}
								handleRowClick={handleRowClick}
								selectedGenerators={selectedGenerators}
								handleCheckboxChange={handleCheckboxChange}
							/>
						)}
					</>
				) : (
					<>
						{isAddressesLoaded ? (
							<MapWithMarkers
								geocodedMarkers={geocodedMarkers}
								initialCenter={initialCenter}
								isAddressesLoaded={isAddressesLoaded}
								mapUniqueId="MultiOptions"
							/>
						) : (
							<Loader />
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Generators;

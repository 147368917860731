import React, { useEffect, useState, useTransition } from "react";
import Sidebar from "./Sidebar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { db } from "../../config/firebase";
import {
	collection,
	endBefore,
	getCountFromServer,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
} from "firebase/firestore";
import { BiChevronDown, BiPencil, BiTrash, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import DashboardPanel from "./DashboardPanel";
import MultiselectDropdown from "../../components/UI/dropdowns/MultiselectDropdown";
import { useDispatch } from "react-redux";
import { initialFilterValue } from "../../utils/constants";
import { IoIosSearch } from "react-icons/io";
import Loader from "../../components/UI/loaders/Loader";
import { formattedDate } from "../../utils/helpers";

const serviceOptions = [
	{ label: "Consulting Services", value: "CONSULTING_SERVICES" },
	{ label: "Container Supplier (Medical Waste)", value: "CONTAINER_SUPPLIER_MEDICAL_WASTE" },
	{ label: "Container Supplier (Paper Shredding)", value: "CONTAINER_SUPPLIER_PAPER_SHREDDING" },
	{ label: "E-Waste Recycling Services", value: "E_WASTE_RECYCLING_SERVICES" },
	{ label: "Financial Services", value: "FINANCIAL_SERVICES" },
	{ label: "Hazardous Waste Services", value: "HAZARDOUS_WASTE_SERVICES" },
	{ label: "Legal Services", value: "LEGAL_SERVICES" },
	{ label: "Linen & Uniform Services", value: "LINEN_AND_UNIFORM_SERVICES" },
	{ label: "Medical Waste Processing Equipment", value: "MEDICAL_WASTE_PROCESSING_EQUIPMENT" },
	{ label: "Medical Waste Transfer Station (TS)", value: "MEDICAL_WASTE_TRANSFER_STATION" },
	{ label: "Medical Waste Transporter", value: "MEDICAL_WASTE_TRANSPORTER" },
	{ label: "Medical Waste Treatment Facility (TF)", value: "MEDICAL_WASTE_TREATMENT_FACILITY" },
	{ label: "Online OSHA Training", value: "ONLINE_OSHA_TRAINING" },
	{ label: "Paper Shredding Services (Offsite)", value: "PAPER_SHREDDING_SERVICES_OFFSITE" },
	{ label: "Paper Shredding Services (Onsite)", value: "PAPER_SHREDDING_SERVICES_ONSITE" },
	{ label: "Product Supplier Reverse Distributions", value: "PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS" },
	{ label: "Training & Certification Services", value: "TRAINING_AND_CERTIFICATION_SERVICES" },
	{ label: "Transportation Insurance Providers", value: "TRANSPORTATION_INSURANCE_PROVIDERS" },
];

const Dashboard = () => {
	const [activeTab, setActiveTab] = useState("dashboard");

	return (
		<div className="flex">
			<DashboardPanel />
		</div>
	);
};

export default Dashboard;

export const UsersList = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState(["all", ...serviceOptions.map((item) => item.value)]);
	const [selectedStatus, setSelectedStatus] = useState(["all", "approved", "pending", "rejected"]);
	const [isPending, startTransition] = useTransition();
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();
	const { id } = useParams();

	console.log({ id });

	const normalizedSearchQuery = searchQuery.replace(/\s+/g, "").toLowerCase();
	const hasIntersection = (arr1, arr2) => arr1.some((item) => arr2.includes(item));

	useEffect(() => {
		startTransition(() => {
			let finalDataFiltered = users;
			finalDataFiltered = finalDataFiltered.filter((data) =>
				[data.name, data.phone, data.email, data.companyName, data.address].some((field) =>
					field?.toString()?.toLowerCase()?.includes(normalizedSearchQuery)
				)
			);
			console.log({ finalDataFiltered });

			if (selectedCategories.length)
				finalDataFiltered = finalDataFiltered.filter((item) => hasIntersection(selectedCategories, item.category));

			if (selectedStatus.length) {
				let resp = (item) => (item.approved == true ? "approved" : item.rejected == true ? "rejected" : "pending");
				finalDataFiltered = finalDataFiltered
					.filter((item) => selectedStatus.includes(resp(item)))
					?.sort((a, b) => a.rejected < b.rejected);
			}

			setFilteredData(finalDataFiltered);
		});
	}, [users, normalizedSearchQuery, selectedStatus, selectedCategories]);

	// Fetch all users
	const fetchAllUsers = async () => {
		setLoading(true);
		try {
			const userQuery = query(collection(db, "octoMarketUsers"), orderBy("approved"));
			const querySnapshot = await getDocs(userQuery);
			const allUsers = querySnapshot.docs
				.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))
				.filter((item) => item.profileCompleted && item.userType != "Transporter");
			setUsers(allUsers);
			setFilteredData(allUsers);
		} catch (error) {
			console.error("Error fetching users:", error);
		} finally {
			setLoading(false);
		}
	};

	console.log({ users });

	const handleCheckboxChange = (index) => {
		if (index == "select_all") {
			setSelectedUsers(filteredData.map((_, i) => i));
		} else if (index == "deselect_all") {
			setSelectedUsers([]);
		} else {
			setSelectedUsers((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
		}
	};

	useEffect(() => {
		if (selectedUsers.length > 0) {
			setSelectedUsers([]);
		}
	}, [filteredData]);

	// Initial data load
	useEffect(() => {
		fetchAllUsers();
	}, []);

	const handleRowClick = (data) => {
		console.log(data);
		navigate(`/market-admin/users/${data.id}`);
	};

	const handleSelectedOptionsChange = (selected, type) => {
		if (type == "status") setSelectedStatus(selected);
		if (type == "category") setSelectedCategories(selected);
	};

	return (
		<div className="w-full">
			<div className="m-4 p-2 bg-[#EEEEEE] rounded-md text-primary font-medium h-auto w-fit">
				All {`(${filteredData.length})`}
			</div>
			<div className="flex justify-between items-center m-4">
				<div className="flex gap-8 items-center w-1/2">
					<h2 className="text-xl text-nowrap font-semibold">Users Listing</h2>
					<div className="flex items-center bg-white rounded-full p-3 px-4 border border-gray-200 w-full">
						<IoIosSearch size={20} color="#c2c1c1" />
						<input
							type="text"
							placeholder="Search All"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="ml-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
						/>
					</div>
				</div>
				<div className="flex gap-4">
					<MultiselectDropdown
						width="w-44"
						buttonText="Category"
						options={[{ label: "All", value: "all" }, ...serviceOptions]}
						selectedOptions={selectedCategories}
						onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "category")}
					/>
					<MultiselectDropdown
						width="w-44"
						buttonText="Status"
						options={[
							{ label: "All", value: "all" },
							{ label: "Approved", value: "approved" },
							{ label: "Pending", value: "pending" },
							{ label: "Rejected", value: "rejected" },
						]}
						selectedOptions={selectedStatus}
						onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "status")}
					/>
				</div>
			</div>

			<div style={{ height: `calc(100% - 80px)` }} className="overflow-x-auto mx-4">
				{loading ? (
					<div className="w-full flex justify-center my-8">
						<Loader height="h-fit w-fit" />
					</div>
				) : (
					<UsersListing
						isLoading={loading}
						users={filteredData}
						handleRowClick={handleRowClick}
						selectedUsers={selectedUsers}
						handleCheckboxChange={handleCheckboxChange}
					/>
				)}
			</div>
			<FooterActionItems genSelected={selectedUsers} generators={users} />
		</div>
	);
};

const UsersListing = ({ isLoading, users, handleCheckboxChange, selectedUsers, handleRowClick }) => {
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);

	useEffect(() => {
		if (selectedUsers.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedUsers.length === users.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedUsers, users]);

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			handleCheckboxChange("select_all");
		} else {
			handleCheckboxChange("deselect_all");
		}
	};

	return (
		<div className="overflow-x-scroll overflow-y-hidden">
			<div className="bg-gray-100 flex font-medium py-4 min-w-fit">
				<div className="w-8 mx-6 flex justify-center items-center">
					<input
						type="checkbox"
						className="w-4 h-4"
						checked={selectAll}
						ref={(input) => input && (input.indeterminate = indeterminate)}
						onChange={handleSelectAllChange}
					/>
				</div>
				<div className="flex items-center gap-2">
					<div className="truncate w-40">Company</div>
					<div className="truncate w-80">Category</div>
					<div className="truncate w-40">Phone</div>
					<div className="truncate w-60">Email</div>
					<div className="truncate w-40">Name</div>
					{/* <div className="truncate w-60">Address</div> */}
					<div className="truncate w-40">Created Date</div>
					<div className="truncate w-32">Status</div>
				</div>
			</div>

			{isLoading ? (
				<div className="text-center py-6">Loading...</div>
			) : (
				<div className="min-w-fit overflow-y-scroll">
					{users.length > 0 ? (
						users.map((user, index) => (
							<div
								key={index}
								className="flex items-center py-3 border-b hover:bg-cardTextGray hover:bg-opacity-15 border-gray-200"
							>
								<div className="w-8 mx-6 flex justify-center items-center">
									<input
										type="checkbox"
										className="w-4 h-4"
										checked={selectedUsers.includes(index)}
										onChange={() => handleCheckboxChange(index)}
									/>
								</div>
								<div className="flex items-center gap-2 w-full cursor-pointer" onClick={() => handleRowClick(user)}>
									<div className="truncate w-40">{user.companyName ?? "--"}</div>
									<div className="truncate w-80">
										{user?.category?.map((category) => initialFilterValue[category]).join(", ")}
									</div>
									<div className="truncate w-40">{user.phone}</div>
									<div className="truncate w-60">{user.email}</div>
									<div className="truncate w-40">{user.name}</div>
									{/* <div className="truncate w-60">{user.address}</div> */}
									<div className="truncate w-40">
										{user.createdAt?.toDate() instanceof Object ? formattedDate(user.createdAt?.toDate()) : "--"}
									</div>
									<div className="truncate w-32">
										{user.approved == true ? "Approved" : user?.rejected == true ? "Rejected" : "Pending"}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="text-center py-6">No users found</div>
					)}
				</div>
			)}
		</div>
	);
};

const FooterActionItems = ({ genSelected, generators }) => {
	const clickHandler = async (btnType) => {};
	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-80 px-8 bg-white transition-all duration-300 ease-in-out ${
				genSelected?.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{genSelected?.length} item(s) selected</span>
				<div className="flex gap-2">
					<ExportComponent genSelected={genSelected} generators={generators} />
					{["Edit", "Merge", "Create Lead", "Send To MailChimp", "Add To Audience", "Email", "Delete"].map(
						(actionBtn) => (
							<button
								disabled={true}
								key={actionBtn}
								className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
								onClick={() => clickHandler(actionBtn)}
							>
								{actionBtn}
							</button>
						)
					)}
				</div>
			</div>
		</div>
	);
};
// Separate Modal Component
const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
				  focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

// Main Export Component
const ExportComponent = ({ genSelected, generators }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Close modal when clicking outside
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {};

	return (
		<>
			<button
				onClick={handleExport}
				disabled={isExporting}
				className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 
			disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 
			border transition-colors duration-200 ease-in-out`}
			>
				{isExporting ? "Exporting..." : "Export"}
			</button>

			<ExportModal
				isOpen={isModalOpen}
				isExporting={isExporting}
				progress={progress}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	);
};

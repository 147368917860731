import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { initialFilterValue } from "../../../../utils/constants";

const SearchBox = ({ users }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	const inputRef = useRef(null);
	const searchContainerRef = useRef(null);

	const handleClear = () => {
		setQuery("");
		setResults({});
		inputRef.current?.focus();
	};

	// Fields to search in priority order
	const searchPriority = [
		"companyName",
		"name",
		"category",
		"shortDesc",
		"address",
		"bio",
		"websiteUrl",
		"serviceArea",
		"localArea",
	];

	let fieldMap = {
		companyName: "Company Name",
		category: "Category",
		shortDesc: "Short Description",
		address: "Address",
		bio: "Bio",
		websiteUrl: "Website URL",
		serviceArea: "Service Area",
		localArea: "Selected Areas",
		name: "Name",
	};

	// Debounced search function with cleanup
	const useDebouncedSearch = (searchFunction, delay = 100) => {
		return useCallback(
			debounce((query) => {
				searchFunction(query);
			}, delay),
			[searchFunction]
		);
	};

	const searchFunction = useCallback(
		(query) => {
			if (query.trim() !== "") {
				const searchResults = {
					users: searchItems(users, query),
				};
				setResults(searchResults);
			} else {
				setResults({});
			}
		},
		[users]
	);

	const debouncedSearch = useDebouncedSearch(searchFunction);

	useEffect(() => {
		debouncedSearch(query);
		return () => {
			debouncedSearch.cancel();
		};
	}, [query, debouncedSearch]);

	// Click outside handler
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Modified getMatchPriority function with adjusted weights
	const getMatchPriority = (value, query, field = "") => {
		if (!value) return 0;
		const lowerValue = String(value).toLowerCase();
		const isNameField = field === "companyName" || field === "name";

		// Exact match
		if (lowerValue === query) {
			return isNameField ? 10 : 4;
		}
		// Prefix match (starts with)
		if (lowerValue.startsWith(query)) {
			return isNameField ? 8 : 3;
		}
		// Contains match
		if (lowerValue.includes(query)) {
			return isNameField ? 6 : 2;
		}
		return 0;
	};

	const searchItems = (items, searchQuery) => {
		const lowerQuery = searchQuery.toLowerCase().trim();

		const filteredItems = items
			.map((item) => {
				const matches = {};
				let highestPriority = 0;
				let bestMatchField = null;

				// Process companyName and name first
				["companyName", "name"].forEach((field) => {
					const value = item[field];
					if (value) {
						const matchPriority = getMatchPriority(value, lowerQuery, field);
						if (matchPriority > highestPriority) {
							highestPriority = matchPriority;
							bestMatchField = field;
							matches[fieldMap[field]] = highlightMatch(value, lowerQuery);
						}
					}
				});

				// Handle service area matching
				if (item.serviceArea) {
					let serviceAreaPriority = 0;
					if (item.serviceArea === "nationwide") {
						serviceAreaPriority = getMatchPriority("nationwide", lowerQuery, "serviceArea");
						if (serviceAreaPriority > 0) {
							// Only update if it's higher priority than existing matches
							if (serviceAreaPriority > highestPriority) {
								matches[fieldMap.serviceArea] = highlightMatch("Nationwide", lowerQuery);
								highestPriority = serviceAreaPriority;
								bestMatchField = "serviceArea";
							}
						}
					} else if (item.serviceArea === "local" && Array.isArray(item.localAreas)) {
						const matchedAreas = item.localAreas.filter((area) => getMatchPriority(area, lowerQuery) > 0);

						if (matchedAreas.length > 0) {
							const localAreaPriority = 3;
							if (localAreaPriority > highestPriority) {
								matches[fieldMap.localArea] = highlightMatch(matchedAreas.join(", "), lowerQuery);
								highestPriority = localAreaPriority;
								bestMatchField = "localArea";
							}
						}
					}
				}

				// Process remaining fields
				searchPriority.forEach((field) => {
					if (field === "serviceArea" || field === "localArea" || field === "companyName" || field === "name") return;

					const value = item[field];
					if (value !== undefined && value !== null) {
						let matchPriority = 0;
						let matchedValue = null;

						if (Array.isArray(value)) {
							if (field === "category") {
								const matchedValues = value.filter((v) => {
									const processedValue = initialFilterValue[v];
									const currentPriority = getMatchPriority(processedValue, lowerQuery, field);
									matchPriority = Math.max(matchPriority, currentPriority);
									return currentPriority > 0;
								});

								if (matchedValues.length > 0) {
									matchedValue = highlightMatch(matchedValues.map((v) => initialFilterValue[v]).join(", "), lowerQuery);
								}
							} else {
								const matchedValues = value.filter((v) => {
									const currentPriority = getMatchPriority(v, lowerQuery, field);
									matchPriority = Math.max(matchPriority, currentPriority);
									return currentPriority > 0;
								});

								if (matchedValues.length > 0) {
									matchedValue = highlightMatch(matchedValues.join(", "), lowerQuery);
								}
							}
						} else if (typeof value === "string") {
							matchPriority = getMatchPriority(value, lowerQuery, field);
							if (matchPriority > 0) {
								matchedValue = highlightMatch(value, lowerQuery);
							}
						}

						if (matchPriority > highestPriority) {
							highestPriority = matchPriority;
							bestMatchField = field;
							matches[fieldMap[field]] = matchedValue;
						}
					}
				});

				return highestPriority > 0
					? {
							...item,
							matches,
							_matchPriority: highestPriority,
							_bestMatchField: bestMatchField,
					  }
					: null;
			})
			.filter(Boolean);

		return filteredItems
			.sort((a, b) => {
				// First sort by priority
				if (b._matchPriority !== a._matchPriority) {
					return b._matchPriority - a._matchPriority;
				}
				// Then by field priority if same match priority
				const priorityIndex = (field) => searchPriority.indexOf(field);
				return priorityIndex(a._bestMatchField) - priorityIndex(b._bestMatchField);
			})
			.slice(0, 10)
			.map((item) => {
				const { _matchPriority, _bestMatchField, ...cleanItem } = item;
				return cleanItem;
			});
	};

	// Highlight matched text
	const highlightMatch = (text, query) => {
		if (!text) return null;
		const parts = text.split(new RegExp(`(${query})`, "gi"));
		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<span key={index} className="bg-blue-200">
							{part}
						</span>
					) : (
						<span key={index}>{part}</span>
					)
				)}
			</span>
		);
	};

	const handleSearch = (e) => {
		setQuery(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			handleClear();
			setIsModalOpen(false);
			inputRef.current?.blur();
		}
	};

	const itemClickHandler = (item) => {
		setIsModalOpen(false);
		navigate(`/market/${item.id}`);
	};

	return (
		<div className="relative z-[60] max-w-md" ref={searchContainerRef}>
			<div className="relative z-[70] shadow-[0_4px_10px_rgba(0,0,0,0.1)] py-3 border border-cardTextGray border-opacity-15 flex w-full items-center gap-4 pl-6 rounded-full p-2 bg-white">
				<FiSearch className="text-[#868686]" size={22} />
				<input
					type="text"
					className="w-40 lg:w-40 xl:w-[300px] bg-inherit focus:outline-none"
					placeholder="Type to search"
					onKeyDown={handleKeyDown}
					ref={inputRef}
					value={query}
					onChange={handleSearch}
					onFocus={() => setIsModalOpen(true)}
				/>
				{query && (
					<button
						onClick={handleClear}
						className="px-3 py-1 text-sm text-gray-500 hover:text-gray-700 transition-colors duration-200 mr-2"
						aria-label="Clear search"
					>
						Clear
					</button>
				)}
			</div>

			{isModalOpen && <div className="fixed inset-0 bg-[#CCCCCC87] z-[65]" onClick={() => setIsModalOpen(false)} />}

			{isModalOpen && (
				<div className="absolute top-full left-0 min-w-full w-fit bg-white shadow-lg mr-1 rounded-cardRadii mt-2 z-[70] overflow-hidden">
					<AnimatePresence>
						<motion.div
							className="max-h-[40vh] overflow-y-auto"
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: "auto", opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
							transition={{ duration: 0.25 }}
						>
							{Object.entries(results).map(([category, items]) => (
								<div key={category}>
									{items.length > 0 && (
										<>
											{/* <h3 className="px-4 py-2 font-semibold rounded-cardRadii bg-gray-100">
												{category.charAt(0).toUpperCase() + category.slice(1)}
											</h3> */}
											<ul>
												{items.map((item, index) => (
													<li
														key={index}
														className="pl-8 pr-4 py-2 hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150"
														onClick={() => itemClickHandler(item)}
													>
														<div className="font-medium">{item.companyName || item.name}</div>
														<div className="text-sm text-gray-500">
															{Object.entries(item.matches).map(([key, value], i) => (
																<div key={i}>
																	<span className="font-semibold">{key}: </span>
																	{value}
																</div>
															))}
														</div>
													</li>
												))}
											</ul>
										</>
									)}
								</div>
							))}
							{query.length > 0 && Object.values(results).every((items) => items.length === 0) && (
								<li className="px-4 py-2 text-cardTextGray">No results found</li>
							)}
							{query.length === 0 && <li className="px-4 py-2 text-cardTextGray">Start typing</li>}
						</motion.div>
					</AnimatePresence>
				</div>
			)}
		</div>
	);
};

export default SearchBox;

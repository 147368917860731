import { baseUrl, version, driversroute, generatorsRoute, serviceScheduleRoute } from "./constants";
import axios from "axios";

export const createDriver = async (payload) => {
	try {
		const response = await axios.post(`${baseUrl}/${version}/${driversroute}`, payload);
		// console.log("Driver created successfully:", response.data);
		return response;
	} catch (error) {
		console.error("Error creating driver:", error.response ? error.response.data : error.message);
	}
};

export const deleteGeneratorScheduleReq = async (generatorId, scheduleReq) => {
	try {
		const response = await axios.delete(
			`${baseUrl}/${version}/${generatorsRoute}/${generatorId}/${serviceScheduleRoute}/${scheduleReq.id}`
		);
		return response;
	} catch (error) {
		console.error("Error creating driver:", error.response ? error.response.data : error.message);
	}
};

export const addOrUpdateGeneratorScheduleReq = async (generatorId, payload) => {
	console.log({ payload });

	try {
		const response = await axios.put(
			`${baseUrl}/${version}/${generatorsRoute}/${generatorId}/${serviceScheduleRoute}`,
			{
				serviceSchedules: payload,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		return response;
	} catch (error) {
		console.error("Error updating schedule:", error.response ? error.response.data : error.message);
		throw error;
	}
};

export const getGeneratorSchedules = async (generatorId, filterType = "upcoming") => {
	try {
		const response = await axios.get(
			`${baseUrl}/${version}/${generatorsRoute}/${generatorId}/schedules?filter=${filterType}`
		);

		return response.data;
	} catch (error) {
		console.error("Error updating schedule:", error.response ? error.response.data : error.message);
		throw error;
	}
};

export const sendEmailTemplates = async (to, templates, data) => {
	try {
		const response = await axios.post(
			`${baseUrl}/${version}/email`,
			{
				to,
				templates,
				data,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error sending email:", error.response ? error.response.data : error.message);
		throw error;
	}
};
export const sendOctoMarketMessage = async (to, data) => {
	try {
		let url = `${baseUrl}/${version}/email/contact-message`;
		console.log(url, to, data);

		const response = await axios.post(
			url,
			{
				to,
				data,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

import React from "react";

const ProgressBar = ({
	Labelone,
	Labeltwo,
	Labelthree,
	completed = 0,
	scheduled = 0,
	incompleted = 0,
	onClick,
	isLoading,
}) => {
	const totalTasks = completed + scheduled + incompleted;
	const total = totalTasks || 0;

	const incompletedWidth = ((incompleted / total) * 100).toFixed(2);
	const scheduledWidth = ((scheduled / total) * 100).toFixed(2);
	const completedWidth = ((completed / total) * 100).toFixed(2);

	let ShouldShow = Boolean(completedWidth || scheduledWidth || incompletedWidth);

	if (isLoading) {
		// Shimmer skeleton loader
		return (
			<div className="flex flex-col items-center w-full">
				<div className="relative w-full h-5 bg-gray-200 rounded-md overflow-hidden">
					<div className="h-full w-full bg-gray-300 animate-pulse" />
				</div>
			</div>
		);
	}

	return (
		<div className="bg-gray-200 rounded-full h-5 w-full text-gray-700 text-md grid grid-cols-3">
			<div
				className="relative bg-blue-500 h-5 rounded-l-full text-nowrap"
				style={{ width: `${incompletedWidth ?? 25}%` }}
			>
				<div className="absolute top-6 right-0">{incompleted}</div>
				<div className="absolute bottom-6 left-0">Type of Waste</div>
			</div>
			<div className="relative bg-red-500 h-5 text-nowrap" style={{ width: `${scheduledWidth ?? 50}%` }}>
				<div className="absolute top-6 right-0">{scheduled}</div>
				<div className="absolute bottom-6 left-0">Total Waste Collected</div>
			</div>
			<div
				className="relative bg-green-500 h-5 rounded-r-full text-nowrap"
				style={{ width: `${completedWidth ?? 75}%` }}
			>
				<div className="absolute top-6 right-0">{completed}</div>
				<div className="absolute bottom-6 left-0">Facilities Used</div>
			</div>
		</div>
		// <div className="flex flex-col items-center w-full">
		// 	{!completedWidth && !scheduledWidth && !incompletedWidth && (
		// 		<div className="relative w-full grid grid-cols-3 mb-2">
		// 			<div className="absolute -top-3.5 w-40 text-end" style={{ width: `${30}%` }}>
		// 				{Labelone}
		// 			</div>

		// 			<div className="absolute -top-3.5 w-40 text-end" style={{ width: `${60}%` }}>
		// 				{Labeltwo}
		// 			</div>

		// 			<div className="absolute -top-3.5 w-40 text-end" style={{ width: `${90}%` }}>
		// 				{Labelthree}
		// 			</div>
		// 		</div>
		// 	)}
		// 	{ShouldShow && (
		// 		<div className="relative w-full mb-2">
		// 			<div className="absolute -top-3.5" style={{ width: `${incompletedWidth}%`, left: "0%" }}>
		// 				{Labelone}
		// 			</div>

		// 			<div className="absolute -top-3.5" style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}>
		// 				{Labeltwo}
		// 			</div>

		// 			<div
		// 				className="absolute -top-3.5"
		// 				style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
		// 			>
		// 				{Labelthree}
		// 			</div>
		// 		</div>
		// 	)}

		// 	{!completedWidth && !scheduledWidth && !incompletedWidth && (
		// 		<div className="relative w-full grid grid-cols-3 mb-2">
		// 			<div className="absolute mt-8 w-40 text-end" style={{ width: `${30}%` }}>
		// 				<span className="absolute right-0">{incompleted}</span>
		// 			</div>

		// 			<div className="absolute mt-8 w-40 text-end" style={{ width: `${60}%` }}>
		// 				<span className="absolute right-0">{scheduled}</span>
		// 			</div>

		// 			<div className="absolute mt-8 w-40 text-end" style={{ width: `${90}%` }}>
		// 				<span className="absolute right-0">{completed}</span>
		// 			</div>
		// 		</div>
		// 	)}
		// 	{ShouldShow && (
		// 		<div className="relative w-full mb-2">
		// 			<div className="absolute mt-8" style={{ width: `${incompletedWidth}%`, left: "0%" }}>
		// 				<span className="absolute right-0">{incompleted}</span>
		// 			</div>

		// 			<div className="absolute mt-8" style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}>
		// 				<span className="absolute right-0">{scheduled}</span>
		// 			</div>

		// 			<div
		// 				className="absolute mt-8"
		// 				style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
		// 			>
		// 				<span className="absolute right-0">{completed}</span>
		// 			</div>
		// 		</div>
		// 	)}

		// 	{/* Clickable Progress Bar */}
		// 	<div className="relative w-full h-5 bg-gray-200 rounded-full overflow-hidden cursor-pointer" onClick={onClick}>
		// 		<div className="absolute top-0 left-0 h-full bg-red-500" style={{ width: `${incompletedWidth}%` }} />
		// 		<div
		// 			className="absolute top-0 h-full bg-green-400"
		// 			style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}
		// 		/>
		// 		<div
		// 			className="absolute top-0 h-full bg-blue-500"
		// 			style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
		// 		/>
		// 	</div>
		// </div>
	);
};

export default ProgressBar;

import { formattedDate } from "./helpers";

export const baseUrl = import.meta.env.VITE_PROD_BACKEND_BASE_URL;
export const version = "api/v1";
export const driversroute = "drivers";
export const generatorsRoute = "generators";
export const serviceScheduleRoute = "serviceRequirements";

export const menuItems = [
	{
		id: 1,
		name: "dashboard",
		label: "Dashboard",
		link: "/admin",
		iconActive: "stroke",
	},
	{
		id: 2,
		name: "generator",
		label: "Generator Management",
		link: "/admin/generators",
		iconActive: "stroke",
		children: [
			{
				id: 11,
				name: "submenu_users",
				label: "Generators",
				link: "/admin/generators",
			},
			{
				id: 21,
				name: "submenu_reports",
				label: "Reports",
				link: "/admin/generators/reports",
			},
		],
	},
	{
		id: 3,
		name: "asset",
		label: "Assets",
		link: "/admin/assets",
		iconActive: "stroke",
		children: [
			{
				id: 12,
				name: "submenu_drivers",
				label: "Drivers",
				link: "/admin/assets/drivers",
			},
			{
				id: 22,
				name: "submenu_treatments",
				label: "Treatment & Transfer",
				link: "/admin/assets/treatments-facilities",
			},
			{
				id: 32,
				name: "submenu_vendors",
				label: "Vendors",
				link: "/admin/assets/manage-vendor",
			},
			{
				id: 42,
				name: "submenu_routes",
				label: "Routes",
				link: "/admin/assets/routes",
			},
			{
				id: 52,
				name: "submenu_routes_optimization",
				label: "Routes Optimization",
				link: "/admin/assets/routes-optimization",
			},
			{
				id: 62,
				name: "submenu_satellite_location",
				label: "Satellite Locations",
				link: "/admin/assets/satellite-location",
			},
			{
				id: 72,
				name: "submenu_service_vehicles",
				label: "Service Vehicles",
				link: "/admin/assets/service-vehicles",
			},
		],
	},
	{
		id: 6,
		name: "waste",
		label: "Waste Management",
		link: "/admin/wastes",
		iconActive: "stroke",
		children: [
			{
				id: 16,
				name: "submenu_manage",
				label: "Manage Waste",
				link: "/admin/wastes/manage",
			},
			{
				id: 26,
				name: "submenu_finder",
				label: "Waste Finder",
				link: "/admin/wastes/wastefinder",
			},
			{
				id: 36,
				name: "submenu_boxlocations",
				label: "Box Locations",
				link: "/admin/wastes/boxlocations",
			},
			{
				id: 46,
				name: "submenu_weights",
				label: "Weighing Interface",
				link: "/admin/wastes/weights",
			},
			{
				id: 56,
				name: "submenu_service_tickets",
				label: "Service Tickets",
				link: "/admin/wastes/service-ticket",
			},
		],
	},
	{
		id: 7,
		name: "billing",
		label: "Billing and Finance",
		link: "/admin/billings",
		iconActive: "stroke",
		children: [],
	},
	{
		id: 8,
		name: "report",
		label: "Reporting and Analytics",
		link: "/admin/reports",
		iconActive: "stroke",
	},
	{
		id: 9,
		name: "user",
		label: "User Management",
		link: "/admin/",
		iconActive: "stroke",
		children: [],
	},
	{
		id: 10,
		name: "octo-connect",
		label: "OCTO Connect",
		link: "/admin/octo-connect",
		iconActive: "stroke",
		children: [
			{
				id: 102,
				name: "submenu_octo_finder",
				label: "Contractors List",
				link: "/admin/octo-connect/contractor-list",
			},
			{
				id: 202,
				name: "submenu_subcont_mngmt",
				label: "Subcontractor Management",
				link: "/admin/octo-connect/subcontractor-management",
			},
			{
				id: 302,
				name: "submenu_octo_mngmt",
				label: "Contractor Management",
				link: "/admin/octo-connect/contractor-management",
			},
			{
				id: 402,
				name: "submenu_match_req",
				label: "Match Request",
				link: "/admin/octo-connect/match-request",
			},
			{
				id: 502,
				name: "submenu_handling",
				label: "Linked Generators",
				link: "/admin/octo-connect/linked-generators",
			},
			{
				id: 602,
				name: "submenu_schedules",
				label: "Subcontractor Schedules",
				link: "/admin/octo-connect/subcontractor-schedules",
			},
		],
	},
];

export const transporter = [
	{
		id: 101,
		name: "transporter",
		label: "Transporter",
		link: "/transporters",
		iconActive: "stroke",
	},
];
export function generateTimeOptions() {
	const timeOptions = [];
	for (let hour = 0; hour < 24; hour++) {
		const hourString = hour < 10 ? `0${hour}` : hour;
		timeOptions.push({
			label: `${hourString}:00`,
			value: hourString,
		});
	}
	return timeOptions;
}

export const weekdayOptions = [
	{ label: "Monday", value: "MON" },
	{ label: "Tuesday", value: "TUE" },
	{ label: "Wednesday", value: "WED" },
	{ label: "Thursday", value: "THU" },
	{ label: "Friday", value: "FRI" },
	{ label: "Saturday", value: "SAT" },
	{ label: "Sunday", value: "SUN" },
];

export const frequencyPrimaryOptions = [
	{ value: "WC", label: "Will Call" },
	{ value: "MTWM", label: "Multiple Times Weekly" },
	{ value: "E1W", label: "Weekly (Every 1-Week)" },
];

export const frequencySecondaryOptions = [
	{ value: "E2W", label: "Every 2 Weeks" },
	{ value: "E3W", label: "Every 3 Weeks" },
	{ value: "E4W", label: "Every 4 Weeks" },
	{ value: "E5W", label: "Every 5 Weeks" },
	{ value: "E6W", label: "Every 6 Weeks" },
	{ value: "E7W", label: "Every 7 Weeks" },
	{ value: "E8W", label: "Every 8 Weeks" },
	{ value: "E9W", label: "Every 9 Weeks" },
	{ value: "E10W", label: "Every 10 Weeks" },
	{ value: "E11W", label: "Every 11 Weeks" },
	{ value: "E12W", label: "Every 12 Weeks" },
	{ value: "E13W", label: "Every 13 Weeks" },
	{ value: "E14W", label: "Every 14 Weeks" },
	{ value: "E15W", label: "Every 15 Weeks" },
	{ value: "E16W", label: "Every 16 Weeks" },
	{ value: "E17W", label: "Every 17 Weeks" },
	{ value: "E18W", label: "Every 18 Weeks" },
	{ value: "E19W", label: "Every 19 Weeks" },
	{ value: "E20W", label: "Every 20 Weeks" },
	{ value: "E22W", label: "Every 22 Weeks" },
	{ value: "E24W", label: "Every 24 Weeks" },
	{ value: "E26W", label: "Every 26 Weeks" },
	{ value: "E32W", label: "Every 32 Weeks" },
	{ value: "E36W", label: "Every 36 Weeks" },
	{ value: "E48W", label: "Every 48 Weeks" },
	{ value: "E52W", label: "Every 52 Weeks" },
];

export const MAP_ID = "MultiAddressMap";
export const serviceTypes = [
	{ label: "Medical Waste", value: "MEDICAL_WASTE" },
	{ label: "Paper Shredding", value: "PAPER_SHREDDING" },
	{ label: "Hazardous Waste", value: "HAZARDOUS_WASTE" },
];

export const industryTypes = [
	{
		label: "Urgent Care Clinics",
		value: "urgent_care_clinics",
	},
	{
		label: "Dental Clinics",
		value: "dental_clinics",
	},
	{
		label: "Surgery Centers",
		value: "surgery_centers",
	},
	{
		label: "Pharmacies & Biotech",
		value: "pharmacies_biotech",
	},
	{
		label: "Veterinary Clinics",
		value: "veterinary_clinics",
	},
	{
		label: "Skilled Nursing",
		value: "skilled_nursing",
	},
];

export const SERVICE_TYPES = {
	MEDICAL_WASTE: "MEDICAL_WASTE",
	PAPER_SHREDDING: "PAPER_SHREDDING",
	HAZARDOUS_WASTE: "HAZARDOUS_WASTE",
};

export const itemsOptions = [
	{ label: "5 gallons", value: "FIVE_GALLONS" },
	{ label: "10 gallons", value: "TEN_GALLONS" },
	{ label: "15 gallons", value: "FIFTEEN_GALLONS" },
	{ label: "20 gallons", value: "TWENTY_GALLONS" },
	{ label: "30 gallons", value: "THIRTY_GALLONS" },
	{ label: "40 gallons", value: "FORTY_GALLONS" },
	{ label: "55 gallons", value: "FIFTY_FIVE_GALLONS" },
	{ label: "96 gallons", value: "NINETY_SIX_GALLONS" },
	{ label: "2 cubic yards", value: "TWO_CUBIC_YARDS" },
	{ label: "3 cubic yards", value: "THREE_CUBIC_YARDS" },
	{ label: "4 cubic yards", value: "FOUR_CUBIC_YARDS" },
];

export const itemsMap = {
	FIVE_GALLONS: "5 gallons",
	TEN_GALLONS: "10 gallons",
	FIFTEEN_GALLONS: "15 gallons",
	TWENTY_GALLONS: "20 gallons",
	THIRTY_GALLONS: "30 gallons",
	FORTY_GALLONS: "40 gallons",
	FIFTY_FIVE_GALLONS: "55 gallons",
	NINETY_SIX_GALLONS: "96 gallons",
	TWO_CUBIC_YARDS: "2 cubic yards",
	THREE_CUBIC_YARDS: "3 cubic yards",
	FOUR_CUBIC_YARDS: "4 cubic yards",
};

export const generatorStatus = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
	{ label: "Prospect", value: "PROSPECT" },
	{ label: "Parking", value: "PARKING" },
	{ label: "NIGO", value: "NIGO" },
	{ label: "Dead File", value: "DEAD_FILE" },
];

export const wasteTypes = [
	{ value: "MW", label: "Medical Waste" },
	{ value: "SH", label: "Shredding" },
	{ value: "CSO", label: "Custom Service Options (if applicable)" },
];

export const customerTypes = [
	{ value: "HCF", label: "Healthcare Facility" },
	{ value: "MWC", label: "Medical Waste Company" },
	{ value: "SUB", label: "Subcontractor" },
];

export const mapLibraries = ["places"];

export const usTimeZones = [
	{ label: "EST (New York)", value: "America/New_York" },
	{ label: "CST (Chicago)", value: "America/Chicago" },
	{ label: "MST (Denver)", value: "America/Denver" },
	{ label: "PST (Los Angeles)", value: "America/Los_Angeles" },
	{ label: "AKST (Alaska)", value: "America/Anchorage" },
	{ label: "HST (Hawaii-Aleutian)", value: "Pacific/Honolulu" },
];

export const USStates = [
	{ label: "Alabama", value: "AL" },
	{ label: "Alaska", value: "AK" },
	{ label: "Arizona", value: "AZ" },
	{ label: "Arkansas", value: "AR" },
	{ label: "California", value: "CA" },
	{ label: "Colorado", value: "CO" },
	{ label: "Connecticut", value: "CT" },
	{ label: "Delaware", value: "DE" },
	{ label: "Florida", value: "FL" },
	{ label: "Georgia", value: "GA" },
	{ label: "Hawaii", value: "HI" },
	{ label: "Idaho", value: "ID" },
	{ label: "Illinois", value: "IL" },
	{ label: "Indiana", value: "IN" },
	{ label: "Iowa", value: "IA" },
	{ label: "Kansas", value: "KS" },
	{ label: "Kentucky", value: "KY" },
	{ label: "Louisiana", value: "LA" },
	{ label: "Maine", value: "ME" },
	{ label: "Maryland", value: "MD" },
	{ label: "Massachusetts", value: "MA" },
	{ label: "Michigan", value: "MI" },
	{ label: "Minnesota", value: "MN" },
	{ label: "Mississippi", value: "MS" },
	{ label: "Missouri", value: "MO" },
	{ label: "Montana", value: "MT" },
	{ label: "Nebraska", value: "NE" },
	{ label: "Nevada", value: "NV" },
	{ label: "New Hampshire", value: "NH" },
	{ label: "New Jersey", value: "NJ" },
	{ label: "New Mexico", value: "NM" },
	{ label: "New York", value: "NY" },
	{ label: "North Carolina", value: "NC" },
	{ label: "North Dakota", value: "ND" },
	{ label: "Ohio", value: "OH" },
	{ label: "Oklahoma", value: "OK" },
	{ label: "Oregon", value: "OR" },
	{ label: "Pennsylvania", value: "PA" },
	{ label: "Rhode Island", value: "RI" },
	{ label: "South Carolina", value: "SC" },
	{ label: "South Dakota", value: "SD" },
	{ label: "Tennessee", value: "TN" },
	{ label: "Texas", value: "TX" },
	{ label: "Utah", value: "UT" },
	{ label: "Vermont", value: "VT" },
	{ label: "Virginia", value: "VA" },
	{ label: "Washington", value: "WA" },
	{ label: "West Virginia", value: "WV" },
	{ label: "Wisconsin", value: "WI" },
	{ label: "Wyoming", value: "WY" },
];
export const stateTimezones = {
	AL: "America/Chicago", // Alabama
	AK: "America/Anchorage", // Alaska
	AZ: "America/Phoenix", // Arizona
	AR: "America/Chicago", // Arkansas
	CA: "America/Los_Angeles", // California
	CO: "America/Denver", // Colorado
	CT: "America/New_York", // Connecticut
	DE: "America/New_York", // Delaware
	FL: "America/New_York", // Florida
	GA: "America/New_York", // Georgia
	HI: "Pacific/Honolulu", // Hawaii
	ID: "America/Denver", // Idaho
	IL: "America/Chicago", // Illinois
	IN: "America/New_York", // Indiana
	IA: "America/Chicago", // Iowa
	KS: "America/Chicago", // Kansas
	KY: "America/New_York", // Kentucky
	LA: "America/Chicago", // Louisiana
	ME: "America/New_York", // Maine
	MD: "America/New_York", // Maryland
	MA: "America/New_York", // Massachusetts
	MI: "America/New_York", // Michigan
	MN: "America/Chicago", // Minnesota
	MS: "America/Chicago", // Mississippi
	MO: "America/Chicago", // Missouri
	MT: "America/Denver", // Montana
	NE: "America/Chicago", // Nebraska
	NV: "America/Los_Angeles", // Nevada
	NH: "America/New_York", // New Hampshire
	NJ: "America/New_York", // New Jersey
	NM: "America/Denver", // New Mexico
	NY: "America/New_York", // New York
	NC: "America/New_York", // North Carolina
	ND: "America/Chicago", // North Dakota
	OH: "America/New_York", // Ohio
	OK: "America/Chicago", // Oklahoma
	OR: "America/Los_Angeles", // Oregon
	PA: "America/New_York", // Pennsylvania
	RI: "America/New_York", // Rhode Island
	SC: "America/New_York", // South Carolina
	SD: "America/Chicago", // South Dakota
	TN: "America/Chicago", // Tennessee
	TX: "America/Chicago", // Texas
	UT: "America/Denver", // Utah
	VT: "America/New_York", // Vermont
	VA: "America/New_York", // Virginia
	WA: "America/Los_Angeles", // Washington
	WV: "America/New_York", // West Virginia
	WI: "America/Chicago", // Wisconsin
	WY: "America/Denver", // Wyoming
};

export const SERVICE_STATUS = {
	PENDING: "PENDING",
	COMPLETE: "COMPLETE",
	INPROGRESS: "INPROGRESS",
	CANCELLED: "CANCELLED",
	DELETED: "DELETED",
};

export const DEFAULT_SERVICE_VEHICLE_VALUES = {
	name: "",
	year: "",
	make: "",
	model: "",
	licensePlate: "",
	vin: "",
	vehicleType: "",
	vehicleClass: "",
	fuelType: "",
	payloadCapacity: "",
	weightOnVehicle: "",
	status: "ACTIVE",
	dot: "",
	intrastate: "",
	registrationExpirationDate: "",
	insuranceExpirationDate: "",
	registrationCopy: "",
	insuranceCopy: "",
	transporterPermit: "",
	specialEquipment: "",
	locationAssignment: "",
	notes: "",
	truckCostPerMile: "",
};

export const initialFilterValue = {
	CONSULTING_SERVICES: "Consulting Services",
	CONTAINER_SUPPLIER_MEDICAL_WASTE: "Container Supplier (Medical Waste)",
	CONTAINER_SUPPLIER_PAPER_SHREDDING: "Container Supplier (Paper Shredding)",
	E_WASTE_RECYCLING_SERVICES: "E-Waste Recycling Services",
	FINANCIAL_SERVICES: "Financial Services",
	HAZARDOUS_WASTE_SERVICES: "Hazardous Waste Services",
	LEGAL_SERVICES: "Legal Services",
	MEDICAL_WASTE_PROCESSING_EQUIPMENT: "Linen & Uniform Services",
	MEDICAL_WASTE_TRANSFER_STATION: "Medical Waste Transfer Station (TS)",
	MEDICAL_WASTE_TRANSPORTER: "Medical Waste Transporter",
	MEDICAL_WASTE_TREATMENT_FACILITY: "Medical Waste Treatment Facility (TF)",
	ONLINE_OSHA_TRAINING: "Online OSHA Training",
	PAPER_SHREDDING_SERVICES_OFFSITE: "Paper Shredding Services (Offsite)",
	PAPER_SHREDDING_SERVICES_ONSITE: "Paper Shredding Services (Onsite)",
	PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS: "Product Supplier Reverse Distributions",
	TRAINING_AND_CERTIFICATION_SERVICES: "Training & Certification Services",
	TRANSPORTATION_INSURANCE_PROVIDERS: "Transportation Insurance Providers",
};

export const simplifyConnections = (connections) => {
	if (!connections) return {};
	let x = Object.keys(connections).map((key) => {
		let timestamp = getTimeStampClear(connections[key]?.requestedAt ?? "");
		let timestamp2 = getTimeStampClear(connections[key]?.acceptedAt ?? "");
		let timestamp3 = getTimeStampClear(connections[key]?.receivedAt ?? "");

		connections[key].requestedAt = timestamp;
		connections[key].acceptedAt = timestamp2;
		connections[key].receivedAt = timestamp3;
	});
	return connections;
};

const getTimeStampClear = (timestamp) => {
	// If it's already a string or invalid, return as is
	if (!timestamp || typeof timestamp === "string") {
		timestamp = new Date(timestamp);
	}

	// If it's a Firestore Timestamp
	if (timestamp?.toDate instanceof Function) {
		timestamp = timestamp.toDate();
	}

	// If it's a regular Date object
	if (timestamp instanceof Date) {
		timestamp = timestamp;
	}

	console.log({ timestamp });
	return timestamp == "Invalid Date" ? "" : formattedDate(timestamp);
};

export const formatRelativeTimeDetailed = (timestamp) => {
	if (!timestamp) return "";

	const now = new Date();
	const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
	const diffInMs = now - date;

	// Helper for plural forms
	const plural = (value, unit) => (value === 1 ? `1 ${unit}` : `${value} ${unit}s`);

	// Less than a minute
	if (diffInMs < 60000) {
		const seconds = Math.floor(diffInMs / 1000);
		if (seconds < 5) return "just now";
		return `${plural(seconds, "second")} ago`;
	}

	// Less than an hour
	if (diffInMs < 3600000) {
		const minutes = Math.floor(diffInMs / 60000);
		return `${plural(minutes, "minute")} ago`;
	}

	// Less than a day
	if (diffInMs < 86400000) {
		const hours = Math.floor(diffInMs / 3600000);
		return `${plural(hours, "hour")} ago`;
	}

	// Less than a week
	if (diffInMs < 604800000) {
		const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		return days[date.getDay()];
	}

	// Less than a year
	if (diffInMs < 31536000000) {
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
		});
	}

	// More than a year
	return date.toLocaleDateString("en-US", {
		year: "numeric",
		month: "short",
		day: "numeric",
	});
};

export const LOCATION_TYPE = {
	GENERATOR: "GENERATOR",
	VENDOR: "VENDOR",
	TSTF: "TSTF",
	SATELLITE: "SATELLITE",
};
export const scheduledServiceStatus = {
	COMPLETE: "COMPLETE",
	INPROGRESS: "INPROGRESS",
	PENDING: "PENDING",
};

export const subWasteType = [
	{ label: "No Waste", value: "NO_WASTE" },
	{ label: "Regulated Medical Waste", value: "REGULATED_MEDICAL_WASTE" },
	{ label: "Pharmaceutical Waste", value: "PHARMACEUTICAL_WASTE" },
];

export const VEHICLE_WEIGHT_CLASS_TYPES = [
	{ label: "Class 1: 6,000 lbs or less", value: 1 },
	{ label: "Class 2: 6,001 to 10,000 lbs", value: 2 },
	{ label: "Class 3: 10,001 to 14,000 lbs", value: 3 },
	{ label: "Class 4: 14,001 to 16,000 lbs", value: 4 },
	{ label: "Class 5: 16,001 to 19,500 lbs", value: 5 },
	{ label: "Class 6: 19,501 to 26,000 lbs", value: 6 },
	{ label: "Class 7: 26,001 to 33,000 lbs", value: 7 },
	{ label: "Class 8: 33,001 lbs and over", value: 8 },
];

export const TRANSFER_STATUS = {
	ON_TRUCK: "ON_TRUCK",
	COMPLETE: "COMPLETE",
};

export const TRANSFER_TYPES_OPTIONS = [
	{ label: "TS/TF", value: "TS_TF" },
	{ label: "Service Vehicle", value: "SERVICE_VEHICLE" },
];

export const TRANSFER_TYPES = {
	"TS/TF": "TS_TF",
	"Service Vehicle": "SERVICE_VEHICLE",
};

export const ITEM_TYPE_MAP = {
	ROUTES: "ROUTES",
	SERVICE: "SERVICE",
};

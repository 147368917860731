import ServiceCard from "./ServiceCard";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { createRef, useEffect, useState } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ITEM_TYPE_MAP, LOCATION_TYPE } from "../../../../../../utils/constants";
import { MdDragIndicator } from "react-icons/md";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import {
	formattedDate,
	generateUTCDate,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers";
import { doc, serverTimestamp, Timestamp, updateDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../../config/firebase";
import { useDroppable } from "@dnd-kit/core";

const RouteColumn = ({
	id,
	waypoints,
	routeId,
	dailyRouteId,
	routeLabel,
	locations,
	defaultStartLocation,
	defaultEndLocation,
	treatmentFacilities,
	vendorMarkers,
	satelliteLocations,
	setRouteColumns,
	color,
	isRouteReversed,
	totalTimeInSeconds,
	totalDistanceInMeters,
	assignedDriverName,
	assignedServiceVehicleName,
	drivers,
	assignedDriverId,
	routes,
	setCurrentRouteSplitId,
	routeUpdatingIds = [],
}) => {
	const [toolsOpen, setToolsOpen] = useState(false);
	const [statsOpen, setStatsOpen] = useState(false);
	const [noOfSplit, setNoOfSplit] = useState(1);
	const [allExpanded, setAllExpanded] = useState(false);
	const [selectedStartLocation, setSelectedStartLocation] = useState(defaultStartLocation.id);
	const [selectedEndLocation, setSelectedEndLocation] = useState(defaultEndLocation.id);
	const [assignedDriver, setAssignedDriver] = useState(assignedDriverId);
	const [routeSplits, setRouteSplits] = useState([]);
	const [isRouteSplitOpen, setIsRouteSplitOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
		data: { type: ITEM_TYPE_MAP.ROUTES },
	});
	const { droppableNodeRef } = useDroppable({
		id: id + "droppable",
		data: {
			accepts: [ITEM_TYPE_MAP.SERVICE],
		},
	});
	const setRouteHandler = async () => {
		const newWaypoints = [];
		const job = waypoints.map(async (wp) => {
			newWaypoints.push({
				id: wp.id,
				isLocked: wp.isLocked,
				isPriority: wp.isPriority,
				lat: wp.lat,
				lng: wp.lng,
				serviceId: wp.serviceId,
				type: wp.type,
			});
			const serviceDate = generateUTCDate(dailyRouteId);
			const dataToUpdate = {
				date: Timestamp.fromDate(serviceDate),
				routeId,
				updatedAt: serverTimestamp(),
			};
			console.log({ dataToUpdate, serviceId: wp.serviceId, isoString: serviceDate.toISOString() });
			try {
				await updateDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId), dataToUpdate);
			} catch (error) {
				console.log({ error });
			}
		});
		await Promise.all(job);
		try {
			await updateDoc(doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId), {
				waypoints: newWaypoints,
				updatedAt: serverTimestamp(),
			});
			const res = await fetch(
				`https://us-central1-routes-123.cloudfunctions.net/dailyRoutePathUpdateHandler?dailyRouteId=${dailyRouteId}&routeId=${routeId}`
			);

			if (!res.ok) {
				throw new Error("Route path update failed.");
			}
			showSuccessToastMessage("Route Set Successfully");
		} catch (error) {
			console.log(error);
			// showInternalServerErrorToastMessage();
			showSuccessToastMessage("Route Set Successfully");
		}
	};
	const optimizeRouteHandler = async () => {
		if (!dailyRouteId) {
			showErrorToastMessage("No daily route id found.");
			return;
		}
		if (!routeId) {
			showErrorToastMessage("No route id found.");
			return;
		}
		if (typeof defaultEndLocation?.lat === "undefined" || typeof defaultEndLocation?.lng === "undefined") {
			showErrorToastMessage("End location not correct.");
			return;
		}
		if (typeof defaultStartLocation?.lat === "undefined" || typeof defaultStartLocation?.lng === "undefined") {
			showErrorToastMessage("Start location not correct.");
			return;
		}
		if (!waypoints?.length) {
			showErrorToastMessage("No service found on the route.");
			return;
		}
		try {
			showLoadingToastMessage("Optimizing Route");
			const res = await fetch(
				`https://us-central1-routes-123.cloudfunctions.net/routeOptimizationHandler?dailyRouteId=${dailyRouteId}&routeId=${routeId}`,
				{
					body: JSON.stringify({ waypoints, defaultEndLocation, defaultStartLocation }),
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (res.status) {
				const { finalWaypoints, optimizedOrder } = await res.json();

				console.log(finalWaypoints);
				console.log(optimizedOrder);

				if (finalWaypoints && finalWaypoints?.length) {
					console.log("updating route");
					setRouteColumns((prev) => {
						return prev.map((el) => {
							if (el.id === id) {
								el.waypoints = [...finalWaypoints];
							}
							return el;
						});
					});
				}
			}
			showSuccessToastMessage("Route Optimization Successful.");
		} catch (error) {
			console.log(error);
			showInternalServerErrorToastMessage();
		}
	};
	const updateServiceIsLockedHandler = (isLocked, serviceId) => {
		setRouteColumns((prev) => {
			return prev.map((route) => {
				if (route.id === id) {
					return {
						...route,
						waypoints: route.waypoints.map((wp) => {
							if (wp.serviceId === serviceId) {
								return { ...wp, isLocked: isLocked };
							}
							return wp;
						}),
					};
				}
				return route;
			});
		});
	};

	const routeReverseHandler = (isReversed) => {
		setRouteColumns((prev) => {
			return prev.map((route) => {
				if (route.id === id) {
					return {
						...route,
						originalWaypoints: [...route.waypoints],
						waypoints: isReversed ? [...route.waypoints.reverse()] : [...route.originalWaypoints],
						isRouteReversed: isReversed,
					};
				}
				return route;
			});
		});
	};
	const renderExpectedItemsQuantity = (waypoints) => {
		return waypoints.reduce((acc, curr) => {
			if (curr.scheduleData?.expectedItemOrService?.length) {
				const temp = acc + curr.scheduleData?.expectedItemOrService.reduce((acc, curr) => acc + curr.quantity, 0);
				console.log(temp);
				return temp;
			}
			return acc;
		}, 0);
	};
	const renderIsUpdating = (routeUpdatingIds, id) => {
		if (routeUpdatingIds.includes(id)) {
			return true;
		}
		return false;
	};
	return (
		<div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Transform.toString(transform),
			}}
			className={`border ${
				renderIsUpdating(routeUpdatingIds, id) ? "border-red-500 shadow-md shadow-red-500 " : "border-gray-300"
			} p-1 rounded-lg min-w-56 max-w-56 text-xs min-h-full bg-white ${isDragging ? "border-primary-500" : ""}`}
		>
			<div className={`flex flex-col gap-2 ${isDragging ? "opacity-0" : "opacity-100"} h-full`}>
				<div className="flex w-full item-center justify-center">
					<div className="text-sm px-1 relative text-center w-[90%]">
						{renderIsUpdating(routeUpdatingIds, id) ? (
							<span className="absolute top-0 left-[25%] text-[10px] text-white bg-red-500 rounded-full px-2">
								Order Not Saved
							</span>
						) : (
							""
						)}
						<p className="font-semibold pt-4">{routeLabel}</p>

						<p className="font-semibold">{formattedDate(new Date(dailyRouteId))}</p>
					</div>
				</div>

				<div className="border-2 rounded-md">
					<button
						className="transition-all duration-500 w-full flex items-center p-1 justify-between bg-gray-200"
						onClick={() => {
							setToolsOpen((prev) => !prev);
						}}
					>
						<p>Routing Tools</p>
						{!toolsOpen && <GoChevronDown />}
						{toolsOpen && <GoChevronUp />}
					</button>
					{toolsOpen && (
						<div className="p-1.5 flex flex-col gap-2">
							<div className="flex gap-1 items-center justify-between">
								<button
									onClick={() => {
										document.getElementById(`start_location_${id}`).showModal();
									}}
									className="text-[12px] border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Start Location
								</button>

								<button
									className="text-[12px] border border-red-500 text-red-500 hover:bg-red-500 hover:text-white transition-all duration-200 rounded-full px-2 whitespace-nowrap"
									onClick={() => {
										document.getElementById(`end_location_${id}`).showModal();
									}}
								>
									End Location
								</button>
								<dialog id={`start_location_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`start_location_${id}`).close();
												}}
											>
												✕
											</button>
											<h3 className="font-bold text-lg">Update Start Location</h3>

											<div className="overflow-visible z-10 flex flex-col py-5">
												<Dropdown
													styles="flex-col min-w-full text-sm"
													isRequired={true}
													onChange={(value) => {
														setSelectedStartLocation(value);
													}}
													placeholder="Start Location"
													label={"Select Start Location"}
													value={selectedStartLocation}
													options={locations}
												/>
											</div>
											<div className="h-20">
												<p>
													Note: Changing the start location from here only updates the start location of the selected
													date.
												</p>
											</div>
											<div className="flex justify-between">
												<button
													className="btn btn-primary btn-sm"
													disabled={!selectedStartLocation || selectedStartLocation === defaultStartLocation.id}
													onClick={async () => {
														let dataToUpdate = {};
														const foundSatellite = satelliteLocations.find((el) => el.id === selectedStartLocation);
														if (foundSatellite) {
															dataToUpdate.defaultStartLocation = {
																id: foundSatellite.id,
																lat: foundSatellite.satelliteCoordinates.lat,
																lng: foundSatellite.satelliteCoordinates.lng,
																name: foundSatellite.name,
																type: LOCATION_TYPE.SATELLITE,
															};
														}
														const foundVendor = vendorMarkers.find((el) => el.id === selectedStartLocation);
														console.log(foundVendor);
														if (foundVendor) {
															dataToUpdate.defaultStartLocation = {
																id: foundVendor.id,
																lat: foundVendor.vendorCoordinates.lat,
																lng: foundVendor.vendorCoordinates.lng,
																name: foundVendor.name,
																type: LOCATION_TYPE.VENDOR,
															};
														}
														const foundTreatment = treatmentFacilities.find((el) => el.id === selectedStartLocation);
														if (foundTreatment) {
															dataToUpdate.defaultStartLocation = {
																id: foundTreatment.id,
																lat: foundTreatment.treatmentCoordinates.lat,
																lng: foundTreatment.treatmentCoordinates.lng,
																name: foundTreatment.name,
																type: LOCATION_TYPE.TSTF,
															};
														}
														if (dataToUpdate.defaultStartLocation) {
															try {
																await updateDoc(
																	doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																	dataToUpdate
																);
																setRouteColumns((prev) => {
																	return prev.map((el) => {
																		if (el.id === id) {
																			el.defaultStartLocation = dataToUpdate.defaultStartLocation;
																		}
																		return el;
																	});
																});
																showSuccessToastMessage("Start Location Updated Successfully.");
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}
													}}
												>
													OK
												</button>
												<button
													className="btn btn-error btn-sm"
													onClick={() => {
														console.log("Cancelling");
														document.getElementById(`start_location_${id}`).close();
													}}
												>
													Cancel
												</button>
											</div>
										</form>
									</div>
								</dialog>
								<dialog id={`end_location_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`end_location_${id}`).close();
												}}
											>
												✕
											</button>
											<h3 className="font-bold text-lg">Update End Location</h3>

											<div className="overflow-visible z-10 flex flex-col py-5">
												<Dropdown
													styles="flex-col min-w-full text-sm"
													isRequired={true}
													onChange={(value) => {
														setSelectedEndLocation(value);
													}}
													placeholder="End Location"
													label={"Select End Location"}
													value={selectedEndLocation}
													options={locations}
												/>
											</div>
											<div className="h-20">
												<p>
													Note: Changing the end location from here only updates the end location of the selected date.
												</p>
											</div>
											<div className="flex justify-between">
												<button
													className="btn btn-primary btn-sm"
													disabled={!selectedEndLocation || selectedEndLocation === defaultEndLocation.id}
													onClick={async () => {
														let dataToUpdate = {};
														const foundSatellite = satelliteLocations.find((el) => el.id === selectedEndLocation);
														if (foundSatellite) {
															dataToUpdate.defaultEndLocation = {
																id: foundSatellite.id,
																lat: foundSatellite.satelliteCoordinates.lat,
																lng: foundSatellite.satelliteCoordinates.lng,
																name: foundSatellite.name,
																type: LOCATION_TYPE.SATELLITE,
															};
														}
														const foundVendor = vendorMarkers.find((el) => el.id === selectedEndLocation);
														console.log(foundVendor);
														if (foundVendor) {
															dataToUpdate.defaultEndLocation = {
																id: foundVendor.id,
																lat: foundVendor.vendorCoordinates.lat,
																lng: foundVendor.vendorCoordinates.lng,
																name: foundVendor.name,
																type: LOCATION_TYPE.VENDOR,
															};
														}
														const foundTreatment = treatmentFacilities.find((el) => el.id === selectedEndLocation);
														if (foundTreatment) {
															dataToUpdate.defaultEndLocation = {
																id: foundTreatment.id,
																lat: foundTreatment.treatmentCoordinates.lat,
																lng: foundTreatment.treatmentCoordinates.lng,
																name: foundTreatment.name,
																type: LOCATION_TYPE.TSTF,
															};
														}
														if (dataToUpdate.defaultEndLocation) {
															try {
																await updateDoc(
																	doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																	dataToUpdate
																);
																setRouteColumns((prev) => {
																	return prev.map((el) => {
																		if (el.id === id) {
																			el.defaultEndLocation = dataToUpdate.defaultEndLocation;
																		}
																		return el;
																	});
																});
																showSuccessToastMessage("End Location Updated Successfully.");
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}
													}}
												>
													OK
												</button>
												<button
													className="btn btn-error btn-sm"
													onClick={() => {
														console.log("Cancelling");
														document.getElementById(`end_location_${id}`).close();
													}}
												>
													Cancel
												</button>
											</div>
										</form>
									</div>
								</dialog>
							</div>
							<div className="">
								<label htmlFor={`direction_${id}`} className="flex items-center gap-2 justify-between cursor-pointer">
									<p className="text-sm">Route Direction</p>
									<div className="relative transition-all duration-500">
										<input
											id={`direction_${id}`}
											type="checkbox"
											className="toggle toggle-primary toggle-md"
											checked={!isRouteReversed}
											onChange={(e) => {
												routeReverseHandler(!e.target.checked);
											}}
										/>
										{
											<p
												className={`absolute top-1 text-[10px] text-primary-500 left-1 z-10 ${
													!isRouteReversed ? "opacity-100" : "opacity-0"
												}`}
											>
												FWD
											</p>
										}
										{
											<p
												className={`absolute top-1 text-[10px] text-gray-500 right-1 z-10 ${
													isRouteReversed ? "opacity-100" : "opacity-0"
												}`}
											>
												BCK
											</p>
										}
									</div>
								</label>
							</div>
							<div className="flex flex-col gap-1">
								<button
									onClick={optimizeRouteHandler}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Optimize Route
								</button>
								<button
									onClick={() => {
										setCurrentRouteSplitId(id);
										document.getElementById(`split_route`).showModal();
									}}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Split Route
								</button>

								<button
									className="p-1 text-xs border bg-primary-500 disabled:bg-gray-300 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
									onClick={setRouteHandler}
								>
									Save Route Order
								</button>
								<button className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap">
									Print Route Sheet
								</button>
								<button
									onClick={() => {
										document.getElementById(`assign_driver_${id}`).showModal();
									}}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Assign Driver
								</button>
								<dialog id={`assign_driver_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog" className="h-full">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`assign_driver_${id}`).close();
												}}
											>
												✕
											</button>
											<div className="flex flex-col h-full">
												<h3 className="font-bold text-lg">Assign Driver To Route</h3>

												<div className="overflow-visible z-10 flex flex-col py-5">
													<Dropdown
														styles="flex-col min-w-full text-sm"
														isRequired={true}
														onChange={(value) => {
															setAssignedDriver(value);
														}}
														placeholder="Driver"
														label={"Select Driver"}
														value={assignedDriver}
														options={drivers.map((el) => ({ label: el.firstName + " " + el.lastName, value: el.id }))}
													/>
												</div>

												<div className="flex justify-between mt-auto">
													<button
														className="btn btn-primary btn-sm"
														onClick={async () => {
															if (assignedDriver) {
																try {
																	await updateDoc(
																		doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																		{
																			assignedDriverId: assignedDriver,
																			assignedDriverName:
																				drivers.find((el) => el.id === assignedDriver).firstName +
																				" " +
																				drivers.find((el) => el.id === assignedDriver).lastName,
																			isAssignedByAdmin: true,
																			updatedAt: serverTimestamp(),
																		}
																	);
																	setRouteColumns((prev) => {
																		return prev.map((el) => {
																			if (el.id === id) {
																				el.assignedDriverId = assignedDriver;
																				el.assignedDriverName =
																					drivers.find((el) => el.id === assignedDriver).firstName +
																					" " +
																					drivers.find((el) => el.id === assignedDriver).lastName;
																				el.isAssignedByAdmin = true;
																			}
																			return el;
																		});
																	});
																	showSuccessToastMessage("Driver Assigned Successfully.");
																} catch (error) {
																	console.log(error);
																	showInternalServerErrorToastMessage();
																}
															}
														}}
													>
														OK
													</button>
													<button
														className="btn btn-error btn-sm"
														onClick={() => {
															console.log("Cancelling");
															document.getElementById(`assign_driver_${id}`).close();
														}}
													>
														Cancel
													</button>
												</div>
											</div>
										</form>
									</div>
								</dialog>
							</div>
						</div>
					)}
				</div>
				<div className="border-2 rounded-md">
					<button
						className="transition-all duration-500 w-full flex items-center p-1 justify-between bg-gray-200"
						onClick={() => {
							setStatsOpen((prev) => !prev);
						}}
					>
						<p>Stats For Nerds</p>
						{!statsOpen && <GoChevronDown />}
						{statsOpen && <GoChevronUp />}
					</button>
					{statsOpen && (
						<div className="p-1 flex flex-col gap-2">
							<p>
								Services: <span>{waypoints?.length ?? 0}</span>
							</p>
							<p>
								Time: <span>{((parseFloat(totalTimeInSeconds) / 60) * 60).toFixed(2)} Hr(s)</span>
							</p>
							<p>
								Distance: <span>{(parseFloat(totalDistanceInMeters) * 0.000621371).toFixed(2)} Mile(s)</span>
							</p>
							<p>
								Service Vehicle: <span>{assignedServiceVehicleName}</span>
							</p>
							<p>Driver: {assignedDriverName}</p>
							<p>Expected Containers: {renderExpectedItemsQuantity(waypoints)}</p>
						</div>
					)}
				</div>
				<button
					onClick={() => {
						setAllExpanded((prev) => !prev);
					}}
				>
					{allExpanded ? "Collapse" : "Expand"} All Services
				</button>
				<div className="flex flex-col h-full ">
					<SortableContext items={waypoints.map((waypoint) => waypoint.serviceId)}>
						{
							<div className="flex flex-col">
								{waypoints.map((waypoint, index) => (
									<ServiceCard
										columnId={id}
										index={index}
										dailyRouteId={dailyRouteId}
										key={waypoint.serviceId}
										id={waypoint.serviceId}
										text={waypoint.type + waypoint.id}
										generatorData={waypoint.generatorData}
										serviceData={waypoint.serviceData}
										isLocked={waypoint.isLocked}
										scheduleData={waypoint.scheduleData}
										allExpanded={allExpanded}
										updateServiceIsLockedHandler={updateServiceIsLockedHandler}
										routeColor={color ?? "#FFA500"}
										nextWaypoint={waypoints[index + 1] ?? null}
										lat={waypoint.lat}
										lng={waypoint.lng}
										defaultEndLocation={defaultEndLocation}
									/>
								))}
								<div className="mt-auto p-2 text-left bg-logoutBtn rounded-lg ">
									<div className="font-semibold text-center">End Location</div>
									<div className="font-semibold">Name:</div>
									<div>{defaultEndLocation?.name ?? ""}</div>
									<div className="font-semibold">Type:</div>
									<div className="capitalize">{defaultEndLocation?.type ?? ""}</div>
								</div>
							</div>
						}
					</SortableContext>
				</div>
			</div>
		</div>
	);
};
export default RouteColumn;

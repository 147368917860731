import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLLECTIONS, db } from "../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../utils/constants";
import {
	dateFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../utils/helpers";
import Loader from "../../../components/UI/loaders/Loader";
import CustomDatePicker from "../../../components/UI/CustomDatePicker";
import ReportCheckbox from "../../admin/generator-management/components/reports/ReportCheckBox";
import DropdownReport from "../../admin/generator-management/components/reports/ReportDropDown";
import Button from "../../../components/UI/Button";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";
import MultiselectDropdown from "../../../components/UI/dropdowns/MultiselectDropdown";
import { FiSearch } from "react-icons/fi";
const sortByOptions = [
	{ label: "Any", value: "any" },
	{ label: "By Date (asc)", value: "date_asc" },
	{ label: "By Date (desc)", value: "date_desc" },
	{ label: "By Generator(A-Z)", value: "generator_asc" },
	{ label: "By Generator(Z-A)", value: "generator_desc" },
	{ label: "By Route(A-Z)", value: "route" },
	{ label: "By Route(Z-A)", value: "route_desc" },
];

const handleCheckboxChange = (data, setData, index, field) => {
	const newData = [...data];
	newData[index][field] = !newData[index][field];
	setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
	const newData = [...data];
	newData[index].paidValue = value;
	setData(newData);
};

const GeneratorServiceReporting = () => {
	const { user, authLoading, generatorData, allServices } = useGeneratorUser();
	const [hoveredRow, setHoveredRow] = useState(null);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [completeServices, setCompleteServices] = useState([]);

	const [from, setFrom] = useState(() => new Date());
	const [to, setTo] = useState(new Date());
	const dropdownOptions = ["Edit", "Delete", "Share"];
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [selectedSort, setSelectedSort] = useState("any");
	const [allTreatments, setAllTreatments] = useState([{ label: "Loading", value: null }]);
	const [totalWeight, setTotalWeight] = useState(0);
	const [selectedReportTypes, setSelectedReportTypes] = useState([
		"all",
		"Product_Delivery",
		"Disposal_Documents",
		"Delivery_Receipts",
	]);
	const [selectedServiceTypes, setSelectedServiceTypes] = useState(["all", "Medical_Waste", "Paper_Shredding"]);
	const [selectedLocations, setSelectedLocations] = useState(["all"]);
	useEffect(() => {
		if (!generatorData) return;
		viewButtonHandler();
	}, [generatorData]);

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A"; // Handle missing data gracefully
		}

		return scheduleData.serviceType.reduce((accumulator, serviceType) => {
			const matchingService = serviceTypes.find((service) => service.value === serviceType);
			return accumulator.concat(matchingService ? matchingService.label : "N/A");
		}, []); // Use an empty array as the initial accumulator
	};

	const viewButtonHandler = async () => {
		console.log("getting all services");
		if (!from) {
			showErrorToastMessage("Please select from date.");
		}
		if (!to) {
			showErrorToastMessage("Please select to date.");
		}
		const fromDate = Timestamp.fromDate(new Date(from));
		const toDate = Timestamp.fromDate(new Date(to));
		let q = query(collection(db, "scheduledServices"), where("date", ">=", fromDate), where("date", "<=", toDate));
		q = query(q, where("status", "==", SERVICE_STATUS.COMPLETE));
		q = query(q, where("generatorId", "==", generatorData.id));
		if (selectedSort.length > 0 && selectedSort === "date_desc") {
			q = query(q, orderBy("date", "desc"));
		}
		if (selectedSort.length > 0 && selectedSort === "date_asc") {
			q = query(q, orderBy("date", "asc"));
		}

		try {
			setIsLoading(true);
			const snap = await getDocs(q);
			if (snap.docs.length < 1) {
				showErrorToastMessage("No completed services found in given range.");
			}
			let tempTotalWeight = 0;
			console.log({ items: snap.docs.length });

			const allCompletedServices = [];
			const jobs = snap.docs.map(async (serviceSnap) => {
				const serviceData = serviceSnap.data();
				console.log({ serviceData });

				// let isExist = false;
				// if (selectedTreatments.length > 0) {
				// 	selectedTreatments.forEach((value) => {
				// 		if (value === routeData.treatmentId) {
				// 			isExist = true;
				// 		}
				// 	});
				// }
				// if (selectedTreatments.length > 0 && !isExist) {
				// 	return;
				// }
				if (serviceData?.consolidated?.length > 0) {
					serviceData.consolidated.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
					});
				}
				if (serviceData?.itemized?.length > 0) {
					serviceData.itemized.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
					});
				}
				const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
				const scheduleRes = await getDoc(scheduleDataRef);
				if (!scheduleRes.exists()) {
					return;
				}
				const scheduleData = scheduleRes.data();

				const serviceTypeString = getStringOfServiceTypes(scheduleData);
				const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
				const generatorRes = await getDoc(generatorDataRef);
				if (!generatorRes.exists()) {
					return;
				}
				const generatorData = generatorRes.data();

				const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
				const routeRes = await getDoc(routeDataRef);
				if (!routeRes.exists()) {
					return;
				}
				const routeData = routeRes.data();

				allCompletedServices.push({
					id: serviceSnap.id,
					date: dateFormatter(serviceData.date.toDate().toLocaleDateString()),
					time: serviceData.date.toDate().toLocaleTimeString(),
					driverNote: serviceData?.driverNote?.length > 0 ? serviceData?.driverNote : "--",
					customer: `${generatorData?.generatorName ?? "N/A"} `,
					octoNumber: `${generatorData?.octoNumber ?? "N/A"}`,
					email: `${generatorData?.generatorEmail ?? "N/A"}`,
					address: `${generatorData.serviceAddress?.suite ?? ""} ${generatorData.serviceAddress?.street ?? ""} ${
						generatorData.serviceAddress?.city ?? ""
					} ${generatorData.serviceAddress?.state ?? ""} ${generatorData.serviceAddress?.zipCode ?? ""}`,
					billed: serviceData?.isBilled ?? false,
					billedDate: serviceData?.billedDate?.toDate() ?? null,
					paidDate: serviceData?.paidDate?.toDate() ?? null,
					paid: serviceData?.isPaid ?? false,
					paidAmount: serviceData?.paidAmount ?? "dummy amount",
					optionChecked: false,
					driverName: `${serviceData?.assignedDriverName ?? "N/A"}`,
					pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "N/A"}`,
					serviceType: serviceTypeString ?? "dummy service",
					routeName: routeData?.routeLabel ?? "dummy route name",
				});
			});
			await Promise.all(jobs);
			if (selectedSort.length > 0 && selectedSort === "generator_asc") {
				allCompletedServices.sort((a, b) => a.customer.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "generator_desc") {
				allCompletedServices.sort((a, b) => b.customer.localeCompare(a.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_asc") {
				allCompletedServices.sort((a, b) => a.routeName.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_desc") {
				allCompletedServices.sort((a, b) => b.routeName.localeCompare(a.customer));
			}
			console.log({
				allCompletedServices,
			});
			setTotalWeight(tempTotalWeight);
			setCompleteServices(allCompletedServices);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii h-full m-5">
			<div className="">
				<div className="text-lg font-normal mx-8 my-3 text-black flex">
					<p>Service History Report</p>
				</div>

				<div className="flex flex-col mx-8 my-5 gap-x-2">
					<div className="w-full">
						<div className="flex items-center py-2 z-50 gap-4 bg-white rounded-full px-5 border border-gray-200 h-12 relative max-w-1/2 w-1/2">
							<FiSearch className="text-cardTextGray" size={20} />
							<input
								type="text"
								placeholder="Search By Name, OCTO Number, Address, Document ID#"
								className="bg-transparent focus:outline-none w-full text-cardTextGray h-full"
							/>
						</div>
					</div>

					<div className="flex gap-2 items-end">
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">From</p>
							<div className="">
								<CustomDatePicker
									selectedDate={from}
									setSelectedDate={(value) => setFrom(new Date(value))}
									label={"From Date"}
									borderClass="rounded-lg"
								/>
							</div>
						</div>
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">To</p>
							<CustomDatePicker
								selectedDate={to}
								setSelectedDate={(value) => setTo(new Date(value))}
								label={"To Date"}
								borderClass="rounded-lg"
							/>
						</div>
						<div className="flex min-w-48 ">
							<MultiselectDropdown
								width="w-48"
								buttonText="Report Type"
								options={[
									{ label: "All", value: "all" },
									{ label: "Disposal Documents", value: "Disposal_Documents" },
									{ label: "Delivery Receipts", value: "Delivery_Receipts" },
									{ label: "Product Delivery", value: "Product_Delivery" },
								]}
								selectedOptions={selectedReportTypes}
								onSelectedOptionsChange={(values) => setSelectedReportTypes(values)}
							/>
						</div>
						<div className="flex min-w-48 ">
							<MultiselectDropdown
								width="w-48"
								buttonText="Service Type"
								options={[
									{ label: "All", value: "all" },
									{ label: "Medical Waste", value: "Medical_Waste" },
									{ label: "Paper Shredding", value: "Paper_Shredding" },
								]}
								selectedOptions={selectedServiceTypes}
								onSelectedOptionsChange={(values) => setSelectedServiceTypes(values)}
							/>
						</div>

						<div className="flex min-w-48 ">
							<MultiselectDropdown
								width="w-48"
								buttonText="Service Locations"
								options={[{ label: "All", value: "all" }]}
								selectedOptions={selectedLocations}
								onSelectedOptionsChange={(values) => setSelectedLocations(values)}
							/>
						</div>
						<div className="ml-auto max-w-fit cursor-pointer">
							<p
								onClick={() => {
									setSelectedReportTypes(["all", "Product_Delivery", "Disposal_Documents", "Delivery_Receipts"]);
									setSelectedServiceTypes(["all", "Medical_Waste", "Paper_Shredding"]);
									setSelectedLocations(["all"]);
									setTo(new Date());
									setFrom(new Date());
								}}
								className="text-sm whitespace-nowrap text-primary-500 ml-auto self-end py-2"
							>
								Clear Filter
							</p>
						</div>
					</div>
				</div>
				<div className=" min-h-[50vh] overflow-x-auto">
					<div className="flex flex-col md:flex-row text-sm font-medium pl-8 py-4 bg-indigo-50 w-[calc(9*240px+30px)]">
						<div className="min-w-60 flex gap-2 items-center">
							<input type="checkbox" onChange={() => {}} />
							<p>Date and Time</p>
						</div>
						<div className="min-w-60">Octo Number</div>
						<div className="min-w-60">Generator Name</div>
						<div className="min-w-60">Service Address</div>
						<div className="min-w-60">Driver Note</div>
						<div className="min-w-60">Qty (Items/Containers)</div>
						<div className="min-w-60">Total Weight</div>
						<div className="min-w-60">Document ID#</div>
						<div className="min-w-60">Option</div>
					</div>
					{completeServices.length > 0 ? (
						completeServices.map((item, index) => (
							<div
								key={index}
								className={`flex flex-col md:flex-row items-start py-4 border-b pl-8 border-gray-500 ${
									hoveredRow === index ? "bg-gray-200" : ""
								}`}
								onMouseEnter={() => setHoveredRow(index)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ minHeight: "70px" }}
							>
								<div className="min-w-60">
									<div className="flex gap-2">
										<ReportCheckbox checked={false} />
										<div>
											<div>{item.date}</div>
											<div className="text-sm font-medium">{item.time}</div>
										</div>
									</div>
								</div>

								<div className="min-w-60">
									<div>{item.octoNumber}</div>
								</div>
								<div className="min-w-60">
									<div className="text-sm font-medium">{item.customer}</div>
								</div>
								<div className="min-w-60">
									<div className="text-sm font-medium">{item.address}</div>
								</div>
								<div className="min-w-60">
									<div className="text-sm font-medium">{item.driverNote}</div>
								</div>
							</div>
						))
					) : (
						<div className="flex justify-center items-center min-h-[50vh]">No Data Found</div>
					)}
					<div className="flex flex-col md:flex-row text-sm font-medium pl-8 py-4 bg-indigo-50 w-[calc(9*240px+30px)]">
						<div className="min-w-60 flex gap-2 items-center">
							<div className="flex gap-2 items-center">Total Record(s) Found {completeServices.length}</div>
						</div>
						<div className="min-w-60"></div>
						<div className="min-w-60"></div>
						<div className="min-w-60"></div>
						<div className="min-w-60"></div>
						<div className="min-w-60"></div>
						<div className="min-w-60">
							<div className="mx-auto">{totalWeight.toFixed(2)} lbs</div>
						</div>
						<div className="min-w-60"></div>
						<div className="min-w-60"></div>
					</div>
				</div>
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 gap-5">
				<Button
					btnStyle="form_nav_primary"
					text={"Go To Dashboard"}
					type="button"
					onClick={() => {
						navigate("/generator-dashboard");
					}}
				/>
				<Button
					btnStyle="form_nav_secondary"
					text="Go To Invoice History"
					onClick={() => {
						navigate("/generator-dashboard/invoices-and-payments");
					}}
				/>
			</div>
		</div>
	);
};

export default GeneratorServiceReporting;

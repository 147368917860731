import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../../components/UI/Input";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import Checkbox from "../../../../../../components/UI/Checkbox";
import CustomDatePicker from "../../../../../../components/UI/CustomDatePicker";
import Button from "../../../../../../components/UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import DOBPicker from "../../../../../../components/UI/DobPicker";
import {
	PostDriverDetails,
	checkUsernameAvailability,
	GetDriverData,
	EditDriverDetails,
	getAllTransporters,
} from "../../../../../../utils/firebaseOperations.js";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../../../../../config/firebase.js";
import debounce from "lodash.debounce";
import MultiselectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded.jsx";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import FileUpload from "../../../../../../components/UI/FileUpload.jsx";
import { createDriver } from "../../../../../../utils/apiOps.js";
import PhoneInput from "../../../../../../components/UI/PhoneInput.jsx";
import FutureDatePicker from "../../../../../../components/UI/FutureDatePicker.jsx";
import USDInput from "../../../../../../components/UI/UsdInput.jsx";
import { doc, updateDoc } from "firebase/firestore";

const AddDriverForm = () => {
	const { id } = useParams();
	const [isChecking, setIsChecking] = useState(false);
	const [isAvailable, setIsAvailable] = useState(null);
	const [isedit, setIsEdit] = useState(false);
	const [driverData, setDriverData] = useState([]);
	const [loading, setIsloading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [driverId, setDriverId] = useState(null);
	const [existingFile, setExistingFile] = useState(null);
	const [TransPorterOptions, setTransportorOptions] = useState([]);

	console.log("existingFile", existingFile);

	const handleFileDelete = () => setExistingFile(null);

	console.log("driverData", driverData);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		getTransportordata();
	}, []);

	const getTransportordata = async () => {
		const dataWeGot = await getAllTransporters();
		const OptionsForDropdown = dataWeGot.map(({ name }) => ({ label: name, value: name }));
		setTransportorOptions(OptionsForDropdown);
	};

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			firstName: "",
			middleInitial: "",
			lastName: "",
			email: "",
			OctoDriverId: "",
			username: "",
			password: "",
			phoneNumber: "",
			zebraPrintingSize: "",
			driverLicenseInfo: "",
			driverLocation: "",
			licensePhoto: null,
			hourlyWage: "",
			startDate: null,
			dateOfBirth: null,
			scheduledWorkDays: [],
			scheduledWorkHours: "",
			limitedDutyStatus: "",
			driverStatus: "",
			licenseExpirationDate: "",
			emergencyContactPerson: "",
			emergencyContactPhone: "",
			portalAccess: {
				editCustomerInfo: false,
				adjustRoute: false,
				postponeStop: false,
				editPickups: false,
				weighPickups: false,
				adjustPricing: false,
				callCustomer: false,
				emailCustomer: false,
				billCustomersAndCollectPayments: false,
			},
		},
	});

	useEffect(() => {
		if (!id) {
			reset({
				firstName: "",
				middleInitial: "",
				lastName: "",
				email: "",
				OctoDriverId: "",
				username: "",
				password: "",
				zebraPrintingSize: "",
				driverLicenseInfo: "",
				driverLocation: "",
				licensePhoto: null,
				hourlyWage: "",
				startDate: "",
				dateOfBirth: "",
				scheduledWorkDays: [],
				scheduledWorkHours: "",
				limitedDutyStatus: "",
				driverStatus: "",
				licenseExpirationDate: "",
				emergencyContactPerson: "",
				emergencyContactPhone: "",

				portalAccess: {
					editCustomerInfo: false,
					adjustRoute: false,
					postponeStop: false,
					editPickups: false,
					weighPickups: false,
					adjustPricing: false,
					callCustomer: false,
					emailCustomer: false,
					billCustomersAndCollectPayments: false,
				},
			});
			setIsEdit(false);
			setDriverId(null);
		}
		if (id) {
			setIsEdit(true);
			getUserData(id);
			setDriverId(id);
		}
	}, [id]);

	useEffect(() => {
		if (driverData) {
			Object.keys(driverData).forEach((key) => {
				if (key === "scheduledWorkDays") {
					if (Array.isArray(driverData[key]?.arrayValue?.values)) {
						const values = driverData[key].arrayValue.values.map((v) => v.stringValue);
						setValue(key, values);
					}
				} else if (typeof driverData[key] === "object" && driverData[key] !== null) {
					// Handle stringValue and other object types
					const value = driverData[key]?.stringValue ?? Object.values(driverData[key])[0];
					setValue(key, value);
				} else {
					setValue(key, driverData[key]);
				}
			});

			if (driverData.portalAccess?.mapValue) {
				Object.keys(driverData.portalAccess.mapValue.fields).forEach((key) => {
					setValue(`portalAccess.${key}`, driverData.portalAccess.mapValue.fields[key].booleanValue);
				});
			}

			if (driverData.licensePhoto) {
				const licensePhotoFields = driverData.licensePhoto.mapValue?.fields;
				if (licensePhotoFields) {
					setExistingFile({
						name: licensePhotoFields?.name?.stringValue,
						url: licensePhotoFields?.url?.stringValue,
					});
				}
			}
		}
	}, [driverData, setValue]);

	const getUserData = async (id) => {
		let result = await GetDriverData(id);
		setDriverData(result);
	};

	const username = watch("username");
	const fileInputRef = useRef(null);
	const navigate = useNavigate();

	const debounceCheckUsername = useCallback(
		debounce(async (username) => {
			setIsChecking(true);
			try {
				const available = await checkUsernameAvailability(username);
				setIsAvailable(available);
			} catch (error) {
				console.error("Error checking username availability", error);
				setIsAvailable(null);
			} finally {
				setIsChecking(false);
			}
		}, 500),
		[]
	);

	const handleUsernameChange = (username) => {
		setIsAvailable(null);
		if (username.length > 3) {
			debounceCheckUsername(username);
		}
	};

	const onSubmit = async (data) => {
		setIsloading(true);
		const toastId = toast.loading("Saving...");

		try {
			if (data.contractFile) {
				const storageRef = ref(storage, `/admin1/driverlicense/${data.contractFile.name}`);
				const snapshot = await uploadBytes(storageRef, data.contractFile);
				const downloadURL = await getDownloadURL(snapshot.ref);
				// Set licensePhoto and remove contractFile
				data.licensePhoto = { name: data.contractFile.name, url: downloadURL };
				delete data.contractFile; // Remove contractFile after using it
			}

			// Check for undefined fields and remove them from the data
			const cleanedData = { ...data };
			for (const key in cleanedData) {
				if (cleanedData[key] === undefined) {
					delete cleanedData[key];
				}
			}

			let result;
			if (!isedit) {
				result = await PostDriverDetails(cleanedData);
				toast.success("Driver registered successfully!");
			} else {
				result = await EditDriverDetails(cleanedData, driverId);
				toast.success("Driver details updated successfully!");
			}
		} catch (error) {
			console.error("Error during submission:", error);
			toast.error(error.message || "An error occurred during submission.");
		} finally {
			setIsloading(false);
			toast.dismiss(toastId);
			navigate("/admin/assets/drivers");
		}
	};

	const driverStatuses = [
		{ label: "Active", value: "active" },
		{ label: "Inactive", value: "inactive" },
	];

	const handleFilePickerClick = () => {
		fileInputRef.current.click();
	};

	const daysOfWeek = [
		{ label: "Monday", value: "Monday" },
		{ label: "Tuesday", value: "Tuesday" },
		{ label: "Wednesday", value: "Wednesday" },
		{ label: "Thursday", value: "Thursday" },
		{ label: "Friday", value: "Friday" },
		{ label: "Saturday", value: "Saturday" },
		{ label: "Sunday", value: "Sunday" },
	];

	const handleback = () => navigate("/admin/assets/drivers");

	const HandleLoginEmail = () => console.log("HandleLoginEmail");

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="bg-white p-8 m-6 py-2 flex flex-col  rounded-cardRadii flex-grow"
		>
			<h6 className="font-medium text-lg py-2 text-black">Driver Profile</h6>

			<hr className="border-b-inputLabel pb-2" />

			{/* <div className='flex items-center justify-center gap-6'> flex-1 */}

			<div className="grid gap-x-8 gap-y-2 grid-cols-2 ">
				<div className="flex flex-col space-y-4">
					<div className="flex items-center justify-between col-span-1">
						<label htmlFor={"firstName"} className="text-inputLabel font-normal mt-3 w-32">
							First Name*
						</label>

						<div className="w-2/3">
							<Controller
								name="firstName"
								control={control}
								rules={{ required: "First Name is required" }}
								render={({ field }) => {
									return <Input {...field} />;
								}}
							/>
							{errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between col-span-1">
						<label htmlFor="middleInitial" className="text-inputLabel font-normal mt-3 w-32">
							Middle Initial
						</label>
						<div className="w-2/3">
							<Controller
								name="middleInitial"
								control={control}
								rules={{
									pattern: {
										value: /^[A-Za-z]$/,
										message: "Middle Initial must be a single letter",
									},
								}}
								render={({ field, fieldState: { error } }) => (
									<>
										<Input
											{...field}
											maxLength={1}
											onChange={(e) => {
												const value = e.target.value
													.replace(/[^A-Za-z]/g, "")
													.slice(0, 1)
													.toUpperCase();
												field.onChange(value);
											}}
											value={field.value?.toUpperCase() || ""}
										/>
										{error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
									</>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between col-span-1">
						<label htmlFor={"lastName"} className="text-inputLabel font-normal mt-3 w-32">
							Last Name*
						</label>
						<div className="w-2/3">
							<Controller
								name="lastName"
								control={control}
								render={({ field }) => <Input {...field} />}
								rules={{ required: "Last Name is required" }}
							/>
							{errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"OctoDriverId"} className="text-inputLabel font-normal mt-3 w-32">
							OCTO ID #*
						</label>

						<div className="w-2/3">
							<Controller
								name="OctoDriverId"
								control={control}
								rules={{
									required: "OCTO ID is required",
								}}
								render={({ field }) => <Input {...field} readOnly />}
							/>
							{errors.OctoDriverId && <p className="text-red-500 text-sm mt-1">{errors.OctoDriverId.message}</p>}
						</div>
					</div>

					<Controller
						name="phoneNumber"
						control={control}
						// rules={{
						//     required: "Phone number is required",

						// }}
						render={({ field, fieldState: { error } }) => (
							<>
								<PhoneInput id="phoneNumber" value={field.value} onChange={field.onChange} isDisabled={false} />
								{error && <p className="text-red-600 text-sm mt-1">{error.message}</p>}
							</>
						)}
					/>

					<div className="flex items-center justify-between">
						<label htmlFor={"email"} className="text-inputLabel font-normal mt-3 w-32">
							Email / Username*
						</label>

						<div className="w-2/3">
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: "Invalid email address",
									},
								}}
								render={({ field }) => <Input {...field} />}
							/>

							{errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
						</div>
					</div>

					{/* <div className="flex items-center  justify-between">
						<label htmlFor={"username"} className="text-inputLabel font-normal mt-3 w-32">
							Username*
						</label>

						<div className="w-2/3 relative">
							{isChecking && username && (
								<p className="text-blue-500 text-sm mt-1 absolute top-[-10px] left-3">Checking availability...</p>
							)}

							{isAvailable !== null && !isChecking && (
								<p
									className={`text-sm mt-1  absolute top-[-10px] left-3 ${
										isAvailable ? "text-green-500" : "text-red-500"
									}`}
								>
									{isAvailable ? "Username is available" : "Username is already taken"}
								</p>
							)}

							<Controller
								name="username"
								control={control}
								rules={{
									required: "Username is required",
								}}
								render={({ field }) => (
									<div>
										<Input
											{...field}
											onChange={(e) => {
												field.onChange(e);
												handleUsernameChange(e.target.value);
											}}
											// placeholder="Enter username"
										/>
									</div>
								)}
							/>

							{errors.username && <p className="text-red-500 text-sm mt-1">{errors.username.message}</p>}
						</div>
					</div> */}

					<div className="flex items-center justify-between">
						<label htmlFor="password" className="text-inputLabel font-normal mt-3 w-32">
							Password*
						</label>

						<div className="w-2/3 relative">
							<Controller
								name="password"
								control={control}
								rules={{
									required: "Password is required",
									minLength: {
										value: 8,
										message: "Password must be at least 8 characters long",
									},
								}}
								render={({ field }) => (
									<div className="relative">
										<Input {...field} type={showPassword ? "text" : "password"} className="pr-10" />
										<button
											type="button"
											className="absolute inset-y-0 right-0 pr-3 flex items-center"
											onClick={togglePasswordVisibility}
										>
											{showPassword ? (
												<FaEyeSlash className="h-5 w-5 text-gray-400" />
											) : (
												<FaEye className="h-5 w-5 text-gray-400" />
											)}
										</button>
									</div>
								)}
							/>

							{errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-4">
						<label htmlFor="dateOfBirth" className="text-inputLabel font-normal mt-3 w-32">
							Date of Birth
						</label>

						<div className="w-2/3">
							<Controller
								name="dateOfBirth"
								control={control}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={new Date().getFullYear()}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between ">
						<label htmlFor="driverLicenseInfo" className="text-inputLabel font-normal mt-3 w-32">
							Driver License Number
						</label>

						<div className="w-2/3">
							<Controller name="driverLicenseInfo" control={control} render={({ field }) => <Input {...field} />} />

							{errors.driverLicenseInfo && (
								<p className="text-red-500 text-sm mt-1">{errors.driverLicenseInfo.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor="licenseExpirationDate" className="text-inputLabel font-normal mt-3 w-32">
							License Expiration Date*
						</label>
						<div className="w-2/3">
							<Controller
								name="licenseExpirationDate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={2050}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>

							{errors.licenseExpirationDate && (
								<p className="text-red-500 text-sm mt-1">{errors.licenseExpirationDate.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"emergencyContactPerson"} className="text-inputLabel font-normal mt-3 w-32">
							Emergency Contact Person
						</label>
						<div className="w-2/3">
							<Controller
								name="emergencyContactPerson"
								control={control}
								render={({ field }) => <Input {...field} />}
							/>
							{errors.emergencyContactPerson && (
								<p className="text-red-500 text-sm mt-1">{errors.emergencyContactPerson.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"emergencyContactPhone"} className="text-inputLabel font-normal mt-3 w-32">
							Emergency Contact Phone
						</label>
						<div className="w-2/3">
							<Controller
								name="emergencyContactPhone"
								control={control}
								render={({ field }) => (
									<PhoneInput label="" id="emergencyContactPhone" value={field.value} onChange={field.onChange} />
								)}
							/>
							{errors.emergencyContactPhone && (
								<p className="text-red-600 text-sm mt-1">{errors.emergencyContactPhone.message}</p>
							)}
						</div>
					</div>

					{/* grid seperations */}
				</div>
				<div className="flex flex-col space-y-4">
					<div className="flex items-center justify-between">
						<label htmlFor={"driverLocation"} className="text-inputLabel font-normal mt-3 w-32">
							Driver Location Assignment
						</label>

						<div className="w-2/3 ">
							<Controller
								name="driverLocation"
								control={control}
								render={({ field }) => <Dropdown options={TransPorterOptions} {...field} />}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"startDate"} className="text-inputLabel font-normal mt-3 w-32">
							Start Date*
						</label>

						<div className="w-2/3">
							<Controller
								name="startDate"
								control={control}
								// rules={{ required: "Start Date is required" }}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={2050}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>

							{errors.startDate && <p className="text-red-500 text-sm mt-1">{errors.startDate.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"hourlyWage"} className="text-inputLabel font-normal mt-3 w-32">
							Hourly Wage*
						</label>

						<div className="w-2/3">
							<Controller
								name="hourlyWage"
								control={control}
								rules={
									{
										// required: "Hourly Wage is required",
										// pattern: {
										//     value: /^\d+(\.\d{1,2})?$/,
										//     message: "Please enter a valid number with up to 2 decimal places",
										//   },
									}
								}
								render={({ field }) => (
									<USDInput
										id="hourlyWage"
										value={field.value}
										onChange={(value) => field.onChange(value)}
										placeholder="$0.00"
										IsRequired={true}
									/>
								)}
							/>
							{errors.hourlyWage && <p className="text-red-500 text-sm mt-1">{errors.hourlyWage.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"scheduledWorkDays"} className="text-inputLabel font-normal mt-3 w-32">
							Days Scheduled to Work
						</label>

						<div className="w-2/3">
							<Controller
								name="scheduledWorkDays"
								control={control}
								render={({ field: { onChange, value } }) => (
									<MultiselectRounded
										styles="flex flex-col w-full gap-1"
										value={value}
										onChange={onChange}
										options={daysOfWeek}
										id="scheduledWorkDays"
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"scheduledWorkHours"} className="text-inputLabel font-normal mt-3 w-32">
							Hours Scheduled to Work
						</label>

						<div className="w-2/3">
							<Controller name="scheduledWorkHours" control={control} render={({ field }) => <Input {...field} />} />
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"zebraPrintingSize"} className="text-inputLabel font-normal mt-3 w-32">
							ZebraPrinting Default Size
						</label>

						<div className="w-2/3">
							<Controller
								name="zebraPrintingSize"
								control={control}
								render={({ field }) => (
									<Dropdown
										options={[
											{ label: "Small", value: "small" },
											{ label: "Medium", value: "medium" },
											{ label: "Large", value: "large" },
										]}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"limitedDutyStatus"} className="text-inputLabel font-normal mt-3 w-32">
							Duty Status
						</label>

						<div className="w-2/3">
							<Controller
								name="limitedDutyStatus"
								control={control}
								render={({ field }) => (
									<Dropdown
										options={[
											{ label: "Full Duty", value: "Full Duty" },
											{ label: "Limited Duty", value: "Limited Duty" },
											{ label: "Off Duty", value: "Off Duty" },
										]}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"driverStatus"} className="text-inputLabel font-normal mt-3 w-32">
							Driver Status
						</label>

						{/* label="Driver Status" */}
						<div className="w-2/3">
							<Controller
								name="driverStatus"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Dropdown options={driverStatuses} value={value} onChange={onChange} styles="flex-col min-w-full" />
								)}
							/>
						</div>
					</div>

					{/* <FileUpload
                        name="contractFile"
                        control={control}
                        errors={errors}
                        label="Copy of license"
                        existingFile={existingFile}
                        onDeleteFile={handleFileDelete}
                    // isRequired={true}
                    /> */}

					<div className="flex items-center justify-between mt-3">
						<label htmlFor="contractFile" className="truncate text-inputLabel font-normal mt-3 w-32">
							Copy of license
						</label>

						<div className="w-2/3">
							<Controller
								name="contractFile"
								control={control}
								// rules={{ required: "File attachment is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<div
											className="flex items-center w-full p-2 px-4 h-9 bg-gray-100 rounded-full shadow-sm cursor-pointer hover:bg-gray-200"
											onClick={() => fileInputRef.current.click()}
										>
											<input
												ref={fileInputRef}
												type="file"
												className="hidden"
												accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
												onChange={async (e) => {
													const file = e.target.files[0];
													setExistingFile(null); // Reset the existing file when a new file is selected
													if (file) {
														const fileExtension = file.name.split(".").pop();
														const newFileName = `${Date.now()}.${fileExtension}`;
														const renamedFile = new File([file], newFileName, { type: file.type });
														// Update the file in the form state
														onChange(renamedFile);
														const storageRef = ref(storage, `/admin1/driverlicense/${renamedFile.name}`);
														const snapshot = await uploadBytes(storageRef, renamedFile);
														const downloadURL = await getDownloadURL(snapshot.ref);
														updateDoc(doc(db, "drivers", driverId), {
															licensePhoto: { name: renamedFile.name, url: downloadURL },
														});
														// Set licensePhoto and remove contractFile
														setValue("licensePhoto", { name: renamedFile.name, url: downloadURL });
													}
												}}
											/>
											<span className="text-gray-400 flex-grow truncate">
												{existingFile ? existingFile.name : value ? value.name : "No file selected"}
											</span>
											<BsFileEarmarkArrowUp className="text-gray-500" size={20} />
										</div>

										{/* Display the uploaded file or existing file preview */}
										{existingFile || value ? (
											<div className="mt-2 bg-gray-50 p-3 rounded-md shadow-sm">
												<p className="text-sm text-gray-600">Uploaded File:</p>
												{existingFile ? (
													<img
														src={existingFile.url}
														alt="Existing Uploaded Preview"
														className="mt-2 max-w-full h-auto"
													/>
												) : (
													value &&
													(value.type.startsWith("image/") ? (
														<img
															src={URL.createObjectURL(value)}
															alt="Uploaded Preview"
															className="mt-2 max-w-full h-auto"
														/>
													) : (
														<p className="mt-2 text-gray-600">{value.name}</p>
													))
												)}
											</div>
										) : null}
									</div>
								)}
							/>
							{errors.contractFile && <p className="text-red-500 text-sm mt-1">{errors.contractFile.message}</p>}
						</div>
					</div>
				</div>
			</div>
			<div className="flex items-center justify-between pt-2">
				<h6 className="font-medium text-lg py-5 text-black">OCTO Field App Access</h6>
				<button className="px-5 py-2 bg-[#c2e7ff] rounded-cardRadii " onClick={HandleLoginEmail}>
					Send OCTO Field App Login Instructions{" "}
				</button>
			</div>

			<hr className="border-b-inputLabel pb-2" />

			<div className="grid gap-5 grid-cols-3 py-4">
				<Controller
					name="portalAccess.editCustomerInfo"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Edit customer information" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.adjustRoute"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Adjust route" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				{/* <Controller
                    name="portalAccess.postponeStop"
                    control={control}
                    render={({ field: { value, onChange } }) => <Checkbox label="Postpone stop" isChecked={value} setIsChecked={onChange} />}
                /> */}

				<Controller
					name="portalAccess.editPickups"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Edit pickups" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.weighPickups"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Weigh pickups" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.adjustPricing"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Adjust pricing" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.callCustomer"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Call customer" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.emailCustomer"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Email customer" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.billCustomersAndCollectPayments"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Bill customers and collect payments" isChecked={value} setIsChecked={onChange} />
					)}
				/>
			</div>

			<div className="flex justify-end gap-8 py-3">
				<Button btnStyle="form_nav_secondary" text={"Back"} onClick={handleback} />

				<Button btnStyle="form_nav_primary" text={loading ? " Saving..." : "Save"} type="submit" />
			</div>
		</form>
	);
};

export default AddDriverForm;

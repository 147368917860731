import { TextareaAutosize } from "@mui/material";
import React from "react";

const Textarea = ({ label, placeholder, type = "text", value, onChange, margin = "mt-4" }) => {
	return (
		<div className={`flex items-center justify-between ${margin}`}>
			{label && (
				<label htmlFor={label?.toLowerCase().replace(" ", "-")} className=" text-inputLabel font-normal">
					{label}
				</label>
			)}
			<TextareaAutosize
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={`${
					label ? "min-w-[66.66%]" : "w-full"
				} text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-9 px-6 leading-tight focus:outline-none  focus:ring-1 focus:ring-dashInActiveBtnText`}
			/>
		</div>
	);
};

export default Textarea;

const notificationOptions = [
	{ label: "Send email on pick-up", value: "EMAIL_ON_PICKUP" },
	{ label: "Send email on drop-off", value: "EMAIL_ON_DROPOFF" },
	{ label: "Send service email", value: "SERVICE_EMAIL" },
	{ label: "Send email for billing and payment receipts", value: "EMAIL_FOR_BILLING_PAYMENT" },
	{ label: "Send email on manifest edit", value: "EMAIL_ON_MANIFEST_CHANGE" },
];

const accessOptions = [
	{ label: "Contract Management", value: "CONTRACT_MANAGEMENT" },
	{ label: "Service", value: "SERVICE" },
	{ label: "Billing", value: "BILLING" },
	{ label: "Compliance", value: "COMPLIANCE" },
	{ label: "Other", value: "OTHER" },
];

import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { PiTrashBold } from "react-icons/pi";
import { FiEdit3, FiSave } from "react-icons/fi";
import Input from "../../../../../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import PhoneInput from "../../../../../../../../../components/UI/PhoneInput";
import Dropdown from "../../../../../../../../../components/UI/dropdowns/Dropdown";
import PasswordInput from "../../../../../../../../../components/UI/PasswordInput";
import { checkDuplicateEmail, checkDuplicateUsername } from "../../../../../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import { db } from "../../../../../../../../../config/firebase";
import { arrayRemove, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { updateContact } from "../GeneratorContacts";

const PhoneNumberChip = ({ number, onRemove, isDisabled }) => (
	<div className="inline-flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
		<span>{number}</span>
		{!isDisabled && (
			<button onClick={() => onRemove(number)} className="ml-2 text-red-500 font-bold">
				&times;
			</button>
		)}
	</div>
);

const ContactModal = ({ isModalOpen, onClose, contacts, onUpdate, onDelete, generatorData }) => {
	const [editableContacts, setEditableContacts] = useState(contacts);
	const [editMode, setEditMode] = useState({});
	const [currentPhoneNumber, setCurrentPhoneNumber] = useState({});

	useEffect(() => {
		setEditableContacts(contacts);
	}, [contacts]);

	const toggleEditMode = (index) => {
		setEditMode((prev) => ({ ...prev, [index]: !prev[index] }));
		setCurrentPhoneNumber((prev) => ({ ...prev, [index]: "" }));
	};

	const handleInputChange = (index, field, value) => {
		setEditableContacts((prevContacts) => {
			const updatedContacts = [...prevContacts];
			updatedContacts[index] = { ...updatedContacts[index], [field]: value };

			if (field === "access" && !value.includes("OTHER")) {
				updatedContacts[index].otherAccess = "";
			}

			return updatedContacts;
		});
	};

	const handlePhoneNumberChange = (index, value) => {
		setCurrentPhoneNumber((prev) => ({ ...prev, [index]: value }));
	};

	const addPhoneNumber = (index) => {
		const phoneNumber = currentPhoneNumber[index];
		if (phoneNumber && !editableContacts[index].phoneNumbers.includes(phoneNumber)) {
			setEditableContacts((prevContacts) => {
				const updatedContacts = [...prevContacts];
				updatedContacts[index] = {
					...updatedContacts[index],
					phoneNumbers: [...updatedContacts[index].phoneNumbers, phoneNumber],
				};
				return updatedContacts;
			});
			setCurrentPhoneNumber((prev) => ({ ...prev, [index]: "" }));
		}
	};

	const removePhoneNumber = (index, numberToRemove) => {
		setEditableContacts((prevContacts) => {
			const updatedContacts = [...prevContacts];
			updatedContacts[index] = {
				...updatedContacts[index],
				phoneNumbers: updatedContacts[index].phoneNumbers.filter((number) => number !== numberToRemove),
			};
			return updatedContacts;
		});
	};

	const handleSave = async (index) => {
		// toast.loading("Saving..");
		const updatedContact = { ...editableContacts[index] };
		if (updatedContact.otherAccess) {
			updatedContact.access = [...updatedContact.access.filter((a) => a !== "OTHER"), "OTHER"];
		} else {
			updatedContact.access = updatedContact.access.filter((a) => a !== "OTHER");
		}
		const phoneNumber = currentPhoneNumber[index];
		if (phoneNumber?.length > 9) updatedContact.phoneNumbers = [...updatedContact.phoneNumbers, phoneNumber];

		// Add email uniqueness check
		if (updatedContact.emailAdd) {
			const email = updatedContact.emailAdd.toLowerCase();
			const username = updatedContact.username.toLowerCase();
			const otherContacts = editableContacts.filter((_, i) => i !== index);

			const isDuplicateInContacts = otherContacts.some((contact) => contact.emailAdd?.toLowerCase() === email);
			const isDuplicateUsernameInContacts = otherContacts.some(
				(contact) => contact.username?.toLowerCase() === username
			);

			if (isDuplicateInContacts) {
				toast.dismiss();
				toast.error(`Duplicate email found within contacts: ${updatedContact.emailAdd}`);
				return;
			}

			if (isDuplicateUsernameInContacts) {
				toast.dismiss();
				toast.error(`Duplicate username found within contacts: ${updatedContact.username}`);
				return;
			}

			updatedContact.generatorId = generatorData.id;

			try {
				const resp = await updateContact(updatedContact.contactId, updatedContact);

				console.log({ resp });

				if (!resp.success) return;
			} catch (error) {
				toast.dismiss();
				console.error("Error checking email:", error);
				toast.error("Error checking email uniqueness");
				return;
			}
		}

		toast.dismiss();
		setCurrentPhoneNumber({});
		onUpdate(index, updatedContact);
		toggleEditMode(index);
	};

	const handleDelete = (index) => {
		onDelete(index);
		setEditableContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
	};

	const renderAccessField = (contact, index) => {
		if (editMode[index]) {
			return (
				<>
					<MultiSelectRounded
						label="Access"
						value={contact?.access || []}
						onChange={(value) => handleInputChange(index, "access", value)}
						options={accessOptions}
						margin="0"
					/>
					{contact?.access && contact?.access.includes("OTHER") && (
						<Input
							label="Other Access"
							value={contact?.otherAccess || ""}
							margin="mt-1"
							onChange={(e) => handleInputChange(index, "otherAccess", e.target.value)}
						/>
					)}
				</>
			);
		} else {
			const accessLabels = contact?.access
				?.filter((a) => a !== "OTHER")
				?.map((a) => accessOptions?.find((opt) => opt?.value === a)?.label)
				?.filter(Boolean);

			if (contact?.otherAccess) {
				accessLabels.push(`Other: ${contact.otherAccess}`);
			}

			return (
				<div className="text-cardTextGray flex items-center">
					<label className="block mb-1 w-1/3">Access</label>
					<div className="px-4">{accessLabels?.join(", ")}</div>
				</div>
			);
		}
	};

	const renderPhoneNumbers = (contact, index) => {
		if (editMode[index]) {
			return (
				<div>
					<PhoneInput
						label="Phone Number"
						type="multi"
						onClick={() => addPhoneNumber(index)}
						value={currentPhoneNumber[index] || ""}
						onChange={(value) => handlePhoneNumberChange(index, value)}
						margin="mt-2"
					/>
					{contact.phoneNumbers?.length > 0 && (
						<div className="mt-2">
							{contact.phoneNumbers?.map((number, phoneIndex) => (
								<PhoneNumberChip
									key={phoneIndex}
									number={number}
									onRemove={(numberToRemove) => removePhoneNumber(index, numberToRemove)}
									isDisabled={false}
								/>
							))}
						</div>
					)}
				</div>
			);
		} else {
			return (
				<div className="text-cardTextGray mt-2 flex items-center">
					<label className="block my-2 w-1/3">Phone Numbers</label>
					<div className="px-4">
						{contact.phoneNumbers?.map((number, phoneIndex) => (
							<PhoneNumberChip key={phoneIndex} number={number} onRemove={() => {}} isDisabled={true} />
						))}
					</div>
				</div>
			);
		}
	};

	return (
		<>
			{isModalOpen && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center">
					<div className="bg-white mx-8 z-20 rounded-cardRadii max-w-6xl w-full min-h-[65vh] max-h-[80vh]">
						<div className="flex h-full justify-between items-center border-b border-[#CCCCCC] p-6 px-10 lg:px-12">
							<h2 className="text-2xl font-medium">Generator Contacts</h2>
							<RxCross2 size={24} className="hover:cursor-pointer" onClick={onClose} />
						</div>
						<div
							className={`h-[54vh] pb-20 max-h-[60vh] flex-1 ${
								editableContacts.length > 0 ? "px-6 overflow-auto" : "flex flex-grow justify-center items-center"
							}`}
						>
							{editableContacts.length > 0 ? (
								editableContacts.map((contact, index) => (
									<div
										key={index}
										className={`py-2 pb-4 flex gap-8 px-4 lg:px-6 ${
											index < editableContacts.length - 1 ? "border-b" : ""
										}`}
									>
										<div className="w-4/5">
											<Input
												label="Contact Name"
												value={contact.name || ""}
												margin="mt-1"
												onChange={(e) => handleInputChange(index, "name", e.target.value)}
												isDisabled={!editMode[index]}
											/>
											{renderPhoneNumbers(contact, index)}
											<Input
												label="Email Address"
												value={contact.emailAdd || ""}
												onChange={(e) => handleInputChange(index, "emailAdd", e.target.value)}
												isDisabled={!editMode[index]}
												margin="mt-2"
											/>
											<Input
												label="User Name"
												value={contact.username || ""}
												onChange={(e) => handleInputChange(index, "username", e.target.value)}
												isDisabled={!editMode[index]}
												margin="mt-2"
											/>
											<PasswordInput
												label="Password"
												value={contact.password || ""}
												onChange={(e) => handleInputChange(index, "password", e.target.value)}
												isDisabled={!editMode[index]}
												margin="mt-2"
											/>
											<Dropdown
												label="Contact Status"
												options={[
													{ label: "Active", value: "ACTIVE" },
													{ label: "Inactive", value: "INACTIVE" },
												]}
												value={contact.contactStatus || ""}
												onChange={(value) => handleInputChange(index, "contactStatus", value)}
												isDisabled={!editMode[index]}
												margin="2"
											/>
											<Input
												label="URL Link"
												value={contact.urlLink || ""}
												onChange={(e) => handleInputChange(index, "urlLink", e.target.value)}
												isDisabled={!editMode[index]}
												type="url"
												margin="mt-2"
											/>
											<MultiSelectRounded
												label="Notification"
												value={contact.notification || []}
												onChange={(value) => handleInputChange(index, "notification", value)}
												options={notificationOptions}
												isDisabled={!editMode[index]}
												margin="2"
											/>
											<Input
												label="Contact Note"
												value={contact.contactNote || ""}
												onChange={(e) => handleInputChange(index, "contactNote", e.target.value)}
												isDisabled={!editMode[index]}
												margin="my-2"
											/>
											{renderAccessField(contact, index)}
										</div>
										<div className="flex justify-end w-1/5 mt-1">
											<span className="hover:cursor-pointer h-fit hover:bg-gray-200 transition-colors duration-300 rounded-full p-2">
												{editMode[index] ? (
													<FiSave size={20} onClick={() => handleSave(index)} stroke="#007AFF" />
												) : (
													<FiEdit3 size={20} onClick={() => toggleEditMode(index)} />
												)}
											</span>
											<span className="hover:cursor-pointer h-fit hover:bg-gray-200 transition-colors duration-300 rounded-full p-2">
												<PiTrashBold size={20} fill="#007AFF" onClick={() => handleDelete(index)} />
											</span>
										</div>
									</div>
								))
							) : (
								<div className="text-dashInActiveBtnText text-lg">No contact</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ContactModal;

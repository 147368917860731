import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SidebarItem from "./SIdebarItem";
import Icon from "../Icon";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";

const GeneratorSidebar = ({ active }) => {
	const { logout } = useGeneratorUser();
	const [expandedItemId, setExpandedItemId] = useState(null);
	const [expandedItemIds, setExpandedItemIds] = useState([]);
	const [isScrollable, setIsScrollable] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const menuItems = [
		{
			id: 1,
			name: "generator_dashboard",
			label: "Dashboard",
			link: "/generator-dashboard",
			iconActive: "stroke",
		},

		{
			id: 4,
			name: "service_reports",
			label: "Service Reports",
			link: "/generator-dashboard/service-reports",
			iconActive: "stroke",
		},
		{
			id: 5,
			name: "invoices_and_payments",
			label: "Invoices and Payments",
			link: "/generator-dashboard/invoices-and-payments",
			iconActive: "stroke",
			children: [
				{
					id: 11,
					name: "submenu_users",
					label: "Saved Payment Methods",
					link: "saved-payment-methods",
				},
				{
					id: 21,
					name: "submenu_reports",
					label: "Make Payment",
					link: "make-payment",
				},
			],
		},
		{
			id: 8,
			name: "service_calendar",
			label: "Service Calendar",
			link: "/generator-dashboard/service-calendar",
			iconActive: "stroke",
		},
	];

	useEffect(() => {
		const shouldCollapseSubmenu = () => {
			if (!expandedItemId) return false;
			const expandedItem = menuItems.find(
				(item) => item.id === expandedItemId && location.pathname.startsWith(item.link) && item.children
			);
			if (!expandedItem) setExpandedItemId(null);
		};
		shouldCollapseSubmenu();
	}, [location, expandedItemId]);

	const logOutHandler = async () => {
		logout();
		navigate("/");
	};

	const scrollHandler = (e) => {
		setIsScrollable(e.target.scrollTop > 0);
	};

	return (
		<nav className="w-72 bg-white fixed hidden md:flex flex-col h-screen">
			{/* Top section */}
			<div className="px-10 min-h-20 max-h-20 flex items-center gap-4 border border-borderCol">
				<img src="" alt="" className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-dashBtnGradient" />
				<h6 className="font-bold text-xl text-black">Octopus</h6>
			</div>

			<div
				className="flex-grow border border-borderCol overflow-hidden "
				style={{
					zIndex: 9999,
				}}
			>
				<div className="h-full overflow-y-auto" onScroll={scrollHandler}>
					<ul className="flex flex-col pt-6">
						{menuItems.map((item, index) => (
							<SidebarItem
								key={item.label + index}
								item={item}
								active={active === item.name}
								expandedItemId={expandedItemId}
								setExpandedItemId={setExpandedItemId}
								expandedItemIds={expandedItemIds}
								setExpandedItemIds={setExpandedItemIds}
							/>
						))}
					</ul>
				</div>
			</div>

			{/* Fixed bottom section */}
			<ul
				className={`p-4 pb-8 flex flex-col w-full ${
					isScrollable ? " border-t border-borderCol border-opacity-50" : ""
				}`}
			>
				<li
					onClick={logOutHandler}
					className="group rounded-full text-sm xl:text-base flex items-center gap-3 bg-cardBorderCol w-full pl-4 py-2 hover:text-cardTextBlue hover:bg-[#cccccc] hover:cursor-pointer"
				>
					<Icon name={"logout"} active={active == "logout"} />
					<h6>Logout</h6>
				</li>
			</ul>
		</nav>
	);
};

export default GeneratorSidebar;

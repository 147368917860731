import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { deleteVehicleService } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { motion, AnimatePresence } from "framer-motion";
import MiniServiceVehicleRegModal from "../../../../../components/userAccBtn/MiniServiceVehicleRegModal";
import { showErrorToastMessage, showLoadingToastMessage, showSuccessToastMessage } from "../../../../../utils/helpers";
import { statusOptions, vehicleOptions } from "../../../../../utils/vehicleConstants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, COLLECTIONS, db } from "../../../../../config/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
const LIST_HEADER = [
	{ title: "Nickname", width: "60" },
	{ title: "Vehicle ID#", width: "60" },
	{ title: "Vehicle Location Assignment", width: "60" },
	{ title: "Vehicle Status", width: "60" },
	{ title: "Vehicle Type", width: "60" },
	{ title: "Payload Capacity", width: "60" },
	{ title: "Weight On Vehicle", width: "60" },
	{ title: "Last Driver", width: "60" },
	{ title: "Notes", width: "60" },
];
const ServiceVehicleList = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", ...statusOptions.map((el) => el.value)]);
	const [selectedServiceVehicles, setSelectedServiceVehicles] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [anyChecked, setAnyChecked] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipTimer, setTooltipTimer] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const [isTooltipActive, setIsTooltipActive] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();
	const [user, authLoading, authError] = useAuthState(auth);

	const formatPhoneNumber = (value) => {
		if (!value) return "";
		const phoneNumber = value.replace(/\D/g, "");
		const match = phoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
		return value;
	};

	useEffect(() => {
		let unsubscribe = null;
		try {
			unsubscribe = onSnapshot(
				query(collection(db, COLLECTIONS.serviceVehicles), where("transporterId", "==", user.uid)),
				(snap) => {
					setLoading(true);
					const tempGeneratorData = [];
					snap.docs.forEach((vehicle) => {
						if (vehicle.exists()) {
							tempGeneratorData.push({
								...vehicle.data(),
								id: vehicle.id,
							});
						}
					});
					setData(tempGeneratorData);
					setLoading(false);
				}
			);
		} catch (error) {
			console.log(error);
			showErrorToastMessage("Internal Server Error.");
		}
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const handleRowClick = (id) => {
		navigate(`/admin/assets/service-vehicles/${id}/edit`);
	};

	const handleDelete = async (id) => {
		try {
			showLoadingToastMessage("Deleting Service Vehicle.");
			await deleteVehicleService(id);
			showSuccessToastMessage("Service Vehicle Deleted Successfully.");
		} catch (error) {
			showErrorToastMessage("Internal Server Error.");
			console.error("Error deleting vendor: ", error);
		}
	};

	const statusFilterChangeHandler = (selected) => {
		console.log({ selected });

		if (selected.includes("All")) {
			setSelectedStatuses(["all", ...statusOptions.map((el) => el.value)]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};
	useEffect(() => {
		console.log({ selectedStatuses });
	}, [selectedStatuses]);

	const filteredData = useMemo(() => {
		return data.filter((vehicle) => {
			if (selectedStatuses.includes(vehicle.status)) {
				return true;
			}
			return false;
		});
	}, [data, selectedStatuses]);
	const handleCheckboxChange = (index) => {
		const newSelected = selectedServiceVehicles.includes(index)
			? selectedServiceVehicles.filter((i) => i !== index)
			: [...selectedServiceVehicles, index];

		setSelectedServiceVehicles(newSelected);
		setSelectAll(newSelected.length === filteredData.length);
		setIndeterminate(newSelected.length > 0 && newSelected.length < filteredData.length);
		setAnyChecked(newSelected.length > 0);
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedServiceVehicles([]);
			setAnyChecked(false);
		} else {
			setSelectedServiceVehicles(filteredData.map((_, index) => index));
			setAnyChecked(true);
		}
		setSelectAll(!selectAll);
		setIndeterminate(false);
	};

	const clickHandler = (action) => {
		if (action === "Delete") {
			selectedServiceVehicles.forEach((index) => {
				handleDelete(filteredData[index].id);
			});
			setSelectedServiceVehicles([]);
			setAnyChecked(false);
		} else if (action === "Edit") {
			selectedServiceVehicles.forEach((index) => {
				handleRowClick(filteredData[index].id);
			});
		}
	};

	const handleMouseEnter = (item, event) => {
		clearTimeout(tooltipTimer);
		const timer = setTimeout(() => {
			setActiveTooltip(item.id);
			setModalData(item);
			updateTooltipPosition(event);
			setIsTooltipActive(true);
		}, 3000);
		setTooltipTimer(timer);
	};

	const handleMouseLeave = () => {
		clearTimeout(tooltipTimer);
		if (!isTooltipHovered) {
			setActiveTooltip(null);
			setIsTooltipActive(false);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
		} else {
			left = cursorX + offset;
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const openAddVendorModal = () => {
		setIsModalOpen(true);
	};

	if (error) {
		return <div>{error}</div>;
	}

	const renderServiceVehicleList = () => {
		return filteredData?.length > 0 ? (
			filteredData.map((item, index) => (
				<div
					id={item.id}
					key={item.id}
					className={`flex flex-col min-w-fit md:flex-row items-start py-1 ${
						index < filteredData.length - 1 ? "border-b border-gray-300" : ""
					} hover:bg-gray-200`}
					onMouseEnter={(e) => handleMouseEnter(item, e)}
					onMouseLeave={handleMouseLeave}
				>
					<div className="mx-8 py-4 flex justify-center items-center">
						<input
							type="checkbox"
							className="w-4 h-4 bg-white"
							checked={selectedServiceVehicles.includes(index)}
							onChange={() => handleCheckboxChange(index)}
						/>
					</div>
					<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
						{item.name || "Nick was not provided"}
					</div>
					<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
						{item.serviceVehicleId || "Id not generated"}
					</div>
					<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
						{item?.locationAssignmentName || " Location not provided"}
					</div>
					<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
						{(item.status && statusOptions.find((el) => el.value === item.status)?.label) || " N/A"}
					</div>

					<div className="truncate min-w-60 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
						{(item?.vehicleType && vehicleOptions.find((el) => el.value === item.vehicleType)?.label) || "--"}
					</div>
					<div className="truncate min-w-60 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
						{item.payloadCapacity || "--"}
					</div>
					<div className="truncate min-w-60 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
						{item.weightOnVehicle || "--"}
					</div>
					<div className="truncate min-w-60 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
						{item?.assignedDriverName?.length ? item?.assignedDriverName : "--"}
					</div>
					<div className="truncate min-w-60 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
						{item.notes || "--"}
					</div>
				</div>
			))
		) : (
			<div className="w-full text-center py-4 text-cardTextGray">No Service Vehicle Found.</div>
		);
	};

	return (
		<div className="p-6">
			{modalOpen && (
				<MiniServiceVehicleRegModal
					onClose={() => {
						setModalOpen(false);
					}}
				/>
			)}
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="flex justify-between items-center mx-8">
					<div className="text-lg font-medium text-black">Service Vehicle List</div>
					<div className="my-4 px-2">
						<MultiselectDropdown
							buttonText="Service Vehicle Status"
							options={[{ label: "All", value: "all" }, ...statusOptions]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div className="overflow-scroll">
					<div className="flex font-medium py-4 min-w-fit bg-whiteBlue">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center">
							{LIST_HEADER.map((el, i) => (
								<div key={i} className={`truncate min-w-${el.width}`}>
									{el.title}
								</div>
							))}
						</div>
					</div>

					<div className={`second-child min-w-full`}>
						{loading
							? Array.from({ length: 9 }).map((_, index) => (
									<div
										key={index}
										className="flex flex-col md:flex-row items-center border-b border-gray-300 animate-pulse"
										style={{ minHeight: "60px" }}
									>
										<div className="min-w-20"></div>

										{LIST_HEADER.map((el, i) => (
											<div key={i} className={`min-w-${el.width} text-sm font-normal`}>
												<div className="h-4 bg-gray-300 rounded w-3/4"></div>
											</div>
										))}
									</div>
							  ))
							: renderServiceVehicleList()}
					</div>
				</div>

				<div
					className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${
						selectedServiceVehicles.length > 0 ? "translate-y-0" : "translate-y-full"
					}`}
				>
					<div className="w-full py-4 flex justify-between items-center">
						<span className="truncate">{selectedServiceVehicles.length} item(s) selected</span>
						<div className="space-x-2">
							{["Edit", "Save as PDF", "Share", "Delete"].map((actionBtn) => (
								<button
									key={actionBtn}
									className={`px-4 py-2 rounded-lg ${
										actionBtn === "Delete"
											? "bg-lightBlue text-white hover:bg-cardTextBlue"
											: "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
									} border transition-colors duration-200 ease-in-out`}
									onClick={() => clickHandler(actionBtn)}
								>
									{actionBtn}
								</button>
							))}
						</div>
					</div>
				</div>

				{!anyChecked && (
					<div className="fixed bottom-8 right-8">
						<button
							onClick={() => setModalOpen(true)}
							className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
							</svg>
							<span>Add New Service Vehicle</span>
						</button>
					</div>
				)}
			</div>

			<AnimatePresence>
				{activeTooltip && modalData && isTooltipActive && (
					<motion.div
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
						}}
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setIsTooltipHovered(true)}
						onMouseLeave={() => setIsTooltipHovered(false)}
					>
						<ToolTipContent vehicle={modalData} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ToolTipContent = ({ vehicle }) => {
	const details = [
		{ label: "Nickname", value: vehicle.name || "--" },
		{ label: "Year", value: vehicle.year || "--" },
		{ label: "Make", value: vehicle.make || "--" },
		{ label: "Model", value: vehicle.model || "--" },
		{ label: "License Plate", value: vehicle.licensePlate || "--" },
		{
			label: "vehicleType",
			value: vehicleOptions[vehicleOptions.findIndex((el) => el.value === vehicle.vehicleType)]?.label || "--",
		},
		{
			label: "Status",
			value: statusOptions[statusOptions.findIndex((el) => el.value === vehicle.status)]?.label || "--",
		},
		{ label: "Payload Capacity", value: vehicle.payloadCapacity || "--" },
		{ label: "Registration Expiration Date", value: vehicle.registrationExpirationDate || "--" },
		{ label: "Insurance Expiration Date", value: vehicle.insuranceExpirationDate || "--" },
		{ label: "Assigned Location", value: vehicle.locationAssignmentName || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className={`w-full flex`}>
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

ToolTipContent.propTypes = {
	vehicle: PropTypes.shape({
		insuranceExpirationDate: PropTypes.string,
		licensePlate: PropTypes.string,
		locationAssignmentName: PropTypes.string,
		make: PropTypes.string,
		model: PropTypes.string,
		name: PropTypes.string,
		payloadCapacity: PropTypes.string,
		registrationExpirationDate: PropTypes.string,
		status: PropTypes.string,
		vehicleType: PropTypes.string,
		year: PropTypes.string,
	}),
};

export default ServiceVehicleList;

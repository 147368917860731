import React, { useEffect, useState } from "react";
import AuthForm from "./auth/AuthForm.jsx";
import GoogleAuthButton from "./auth/GoogleAuthButton.jsx";  // Keep GoogleAuthButton
import { emailRegex } from "../../utils/mimes.js";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createNewUserWithEmailAndPassword, signInWithGooglePopup, auth } from "../../config/firebase";
import { toast } from "react-toastify";

const TransporterAuth = () => {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});
	const navigate = useNavigate();
	const [user, loading, error] = useAuthState(auth);

	useEffect(() => {
		if (!loading && user) {
			navigate("/transporter");
		}

		if (error) {
			console.error(error);
		}
	}, [user, loading, auth]);

	const handleGoogleAuth = async () => {
		try {
			toast.loading("Please wait while we are authenticating...");
			await signInWithGooglePopup();  // Handle Google sign-up
			toast.dismiss();
		} catch (error) {
			toast.dismiss();
			console.log("Error", error);
			if (error.code === "auth/popup-closed-by-user") {
				toast.error("User closed the pop-up without signing in.");
			} else {
				toast.error("Something went wrong while logging in with Google");
			}
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Signup form validation
		if (!emailRegex.test(formData.email)) {
			toast.error("Invalid email");
		} else if (!formData.password?.trim()) {
			toast.error("Invalid password");
		} else if (!formData.confirmPassword?.trim()) {
			toast.error("Invalid confirm password");
		} else if (formData.password?.trim() !== formData.confirmPassword?.trim()) {
			toast.error("Passwords don't match");
		} else {
			try {
				toast.loading("Signing Up...");
				// Create new user with email/password
				await createNewUserWithEmailAndPassword(formData.email, formData.password);
				toast.dismiss();
			} catch (error) {
				toast.dismiss();
				console.log(error);
				if (error.code === "auth/email-already-in-use") {
					toast.error("Email already in use");
				} else {
					toast.error("Error during signing up");
				}
			}
		}
	};

	return (
		<div className="h-screen w-full bg-creamWhite flex items-center">
			<div className="mx-auto bg-white p-6 min-w-96 shadow-lg rounded-lg">
				<h2 className="text-2xl font-bold mb-4 text-center">Sign Up</h2>

				<AuthForm handleInputChange={handleInputChange} formData={formData} handleSubmit={handleSubmit} />

				{/* Google Auth button for sign up */}
				{/* <GoogleAuthButton handleGoogleAuth={handleGoogleAuth} /> */}
			</div>
		</div>
	);
};

export default TransporterAuth;

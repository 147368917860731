import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import GeneratorRoutes from "./forms/GeneratorRoutes";
import Loader from "../../../../../../../components/UI/loaders/Loader";
import GeneratorProfile from "./forms/GeneratorProfile";
import GeneratorContacts from "./forms/GeneratorContacts";
import ServiceNotes from "./forms/ServiceNotes";
import NotFound from "../../../../../../../components/NotFound";
import { getGeneratorById } from "../../../../../../../utils/firebaseOperations";
import { useBreadcrumb } from "../../../../../../../context/BreadcrumbContext";
import { useDispatch } from "react-redux";
// import { fetchGenerator } from "../../../../../../../store/sliceReducers/generatorSlice";

const PROFILE = "generator-profile";
const CONTACTS = "generator-contacts";
const NOTES = "service-notes";
const ROUTES = "routes";
const GEN_HOME_ROUTE = "/admin/generators";

const GeneratorDetails = () => {
	const { id: genId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [generatorData, setGeneratorData] = useState({});
	const [error, setError] = useState("");
	const formName = location.pathname.split("/").pop();
	const { handleSetBreadcrumb, handleStepCompletion } = useBreadcrumb();

	useEffect(() => {
		if (![PROFILE, CONTACTS, NOTES, ROUTES].includes(formName)) {
			return navigate(`${GEN_HOME_ROUTE}/${genId}/${PROFILE}`);
		}
	}, [location.pathname]);

	useEffect(() => {
		const fetchDataOfGenerator = async () => {
			const data = await getGeneratorById(genId);
			if (!data) setError("Generator not found");
			else {
				setGeneratorData(data);
				handleSetBreadcrumb("generators", data?.octoNumber?.toString());
				handleStepCompletion("Generator Profile", data?.onboardingStatus?.profileCompleted);
				handleStepCompletion("Generator Contacts", data?.onboardingStatus?.contactsCompleted);
				handleStepCompletion("Service Notes", data?.onboardingStatus?.serviceNotesCompleted);
				handleStepCompletion("Routes", data?.onboardingStatus?.routesCompleted);
			}
			setIsLoading(false);
		};

		if (genId) {
			fetchDataOfGenerator(genId);
			// dispatch(fetchGenerator());
		}
	}, [genId, navigate]);

	const handleClick = (nextForm) => {
		navigate(`${GEN_HOME_ROUTE}/${genId}/${nextForm}`);
	};

	const formComponents = useMemo(
		() => ({
			[PROFILE]: (
				<GeneratorProfile
					onNextClick={() => handleClick(CONTACTS)}
					onClickBack={() => navigate()}
					generatorData={generatorData}
				/>
			),
			[CONTACTS]: (
				<GeneratorContacts
					onNextClick={() => handleClick(ROUTES)}
					onClickBack={() => handleClick(PROFILE)}
					generatorData={generatorData}
				/>
			),
			// [NOTES]: (
			// 	<ServiceNotes
			// 		onNextClick={() => handleClick(ROUTES)}
			// 		onClickBack={() => handleClick(CONTACTS)}
			// 		generatorData={generatorData}
			// 	/>
			// ),
			[ROUTES]: (
				<GeneratorRoutes
					onNextClick={() => console.log("Final submit")}
					onClickBack={() => handleClick(NOTES)}
					generatorData={generatorData}
				/>
			),
		}),
		[generatorData, handleClick, navigate]
	);

	if (isLoading) return <Loader />;

	if (error) return <NotFound text="Generator not found" />;

	return <div className={`overflow-y-auto`}>{formComponents[formName]}</div>;
};

export default GeneratorDetails;

import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../../components/UI/Input";
import Button from "../../../../../../components/UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import RouteSetupMap from "./RouteSetupMap";
import {
	formattedDate,
	showErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
	splitArrayIntoChunks,
} from "../../../../../../utils/helpers";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../../../../../config/firebase";
import Loader from "../../../../../../components/UI/loaders/Loader";
import {
	getAllGeneratorsLocationSnapshot,
	getAllRoutesPathData,
	getAllSatelliteLocationSnapshot,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
} from "../../../../../../utils/firebaseOperations";
import Checkbox from "../../../../../../components/UI/Checkbox";
import MultiSelectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded";
import { LOCATION_TYPE, serviceTypes } from "../../../../../../utils/constants";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import { DEFAULT_ROUTE_VALUES, statusOptions } from "../../../../../../utils/routeConstants";
import DraggableList from "./DraggableList";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useAuthState } from "react-firebase-hooks/auth";
import { HexColorInput, HexColorPicker } from "react-colorful";

const dummyTransporter = {
	id: "123456",
	name: "SD Med Waste",
	formatted_address: "7630 Miramar Rd, San Diego, CA 92126, USA",
	coordinates: {
		lat: 32.88652,
		lng: -117.15268,
	},
};

const AddRouteForm = () => {
	const [user, isAuthLoading, error] = useAuthState(auth);
	const { id } = useParams();
	const [isedit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("Route Details");
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [path, setPath] = useState([]);
	const [otherPaths, setOtherPaths] = useState([]);
	const [pastService, setPastService] = useState([]);
	const [upcomingService, setUpcomingService] = useState([]);
	const [waypoints, setWaypoints] = useState([]);
	const [otherGeneratorMarkers, setOtherGeneratorMarkers] = useState([]);
	const [vendorMarkers, setVendorMarkers] = useState([]);
	const [treatmentFacilities, setTreatmentFacilities] = useState([]);
	const [satelliteLocations, setSatelliteLocations] = useState([]);
	const [startLocationOptions, setStartLocationOptions] = useState([]);
	const [endLocationOptions, setEndLocationOptions] = useState([]);
	const [items, setItems] = useState([]);
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthLoading && !user) {
			navigate("/auth");
		}
	}, [isAuthLoading, user, navigate]);

	useEffect(() => {
		if (routesLibrary) {
			setDirectionsService(new routesLibrary.DirectionsService());
		}
	}, [routesLibrary]);

	useEffect(() => {
		const unsubscribe = getAllGeneratorsLocationSnapshot(setOtherGeneratorMarkers, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = getAllVendorsLocationSnapshot(setVendorMarkers, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = getAllTreatmentsLocationSnapshot(setTreatmentFacilities, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = getAllSatelliteLocationSnapshot(setSatelliteLocations, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const options = [];
		if (satelliteLocations.length > 0) {
			options.push({ label: "Satellite Locations", value: null, isDisabled: true });
			satelliteLocations.forEach((satellite) => options.push({ label: satellite.name, value: satellite.id }));
		}
		if (vendorMarkers.length > 0) {
			options.push({ label: "Vendors Locations", value: null, isDisabled: true });
			vendorMarkers.forEach((vendor) => options.push({ label: vendor.name, value: vendor.id }));
		}
		if (treatmentFacilities.length > 0) {
			options.push({ label: "TS/TF Facilities", value: null, isDisabled: true });
			treatmentFacilities.forEach((treatment) => options.push({ label: treatment.name, value: treatment.id }));
		}
		console.log({ options, satelliteLocations, vendorMarkers, treatmentFacilities });

		setStartLocationOptions(options);
		setEndLocationOptions(options);
	}, [satelliteLocations, vendorMarkers, treatmentFacilities]);

	useEffect(() => {
		if (id) {
			fetchData();
		} else {
			reset(DEFAULT_ROUTE_VALUES);
		}
	}, [id, setTitle]);
	const fetchData = async () => {
		try {
			setLoading(true);
			const dataRes = await getDoc(doc(db, COLLECTIONS.routes, id));

			if (!dataRes.exists()) {
				throw new Error("Data Not found");
			}

			const { defaultEndLocation, defaultStartLocation, status, routeLabel, type, truckCostPerMile, waypoints } =
				dataRes.data();
			setValue("routeLabel", routeLabel);
			setValue("status", status);
			setValue("truckCostPerMile", truckCostPerMile);
			setValue("truckCostPerMile", truckCostPerMile);
			setEnd(defaultEndLocation);
			setStart(defaultStartLocation);
			setWaypoints(waypoints);
			if (Array.isArray(type)) {
				setValue("type", type);
			} else {
				setValue("type", []);
			}
			setLoading(false);
			showSuccessToastMessage("Form data loaded.Plotting routes on map.(It may take a while please hold on.)");
			// const pathDataRes = await getDocs(query(collection(db, COLLECTIONS.routes, id, "path"), orderBy("index", "asc")));
			// if (pathDataRes.docs.length > 0) {
			// 	let tempPaths = [];
			// 	for (const el of pathDataRes.docs) {
			// 		const { path } = el.data();

			// 		tempPaths.push(...path);
			// 	}

			// 	setPath(tempPaths);
			// } else {
			// 	throw new Error("No path found for this route. Please setup path for this route.");
			// }
			const allRoutePathData = await getAllRoutesPathData(id);
			setOtherPaths(allRoutePathData);
		} catch (error) {
			console.log({ error });
			showErrorToastMessage(error.message);
		} finally {
			setLoading(false);
		}
	};

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: DEFAULT_ROUTE_VALUES,
	});

	useEffect(() => {
		if (end) {
			setValue("defaultEndLocation", end);
		} else {
			setValue("defaultEndLocation", {
				name: "",
			});
		}
	}, [end, setValue]);
	useEffect(() => {
		if (start) {
			setValue("defaultStartLocation", start);
		} else {
			setValue("defaultStartLocation", {
				name: "",
			});
		}
	}, [start, setValue]);

	const onSubmit = async (data) => {
		console.log({ submitData: data });
		console.log({ path });
		if (!start) {
			showErrorToastMessage("Please set up start location.");
			return;
		}
		if (!end) {
			showErrorToastMessage("Please set up end location.");
			return;
		}

		showLoadingToastMessage("Saving...");
		try {
			setLoading(true);
			if (!id) {
				const routeDocREf = await addDoc(collection(db, "routes"), {
					...data,
					transporterId: user.uid,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
				});
				const routeId = routeDocREf.id;
				const res = await directionsService.route({
					origin: { lat: data.defaultStartLocation.lat, lng: data.defaultStartLocation.lng },
					destination: { lat: data.defaultEndLocation.lat, lng: data.defaultEndLocation.lng },
					travelMode: "DRIVING",
					waypoints: [],
					provideRouteAlternatives: false,
				});
				if (res.routes[0]?.legs) {
					console.log(res.routes[0]?.legs);

					let calculatedDistance = 0;
					let calculatedTime = 0;
					res.routes[0]?.legs.map((leg) => {
						if (leg?.duration?.value) {
							calculatedTime += leg.duration.value;
						}
						if (leg?.distance?.value) {
							calculatedDistance += leg.distance.value;
						}
					});

					console.log({ calculatedDistance, calculatedTime });
					data["totalDistanceInMeters"] = calculatedDistance;
					data["totalTimeInSeconds"] = calculatedTime;

					await setDoc(
						doc(db, COLLECTIONS.routes, routeId, "default", "default"),
						{
							totalDistanceInMeters: data.totalDistanceInMeters,
							totalTimeInSeconds: data.totalTimeInSeconds,
							updatedAt: serverTimestamp(),
						},
						{ merge: true }
					);
					const steps = res.routes[0]?.legs.flatMap((el) => el.steps);
					let path = steps.flatMap((el) => el.path);
					path = path.map((el) => ({ lat: el.lat(), lng: el.lng() }));
					if (path.length > 0) {
						const pathRes = await getDocs(collection(db, COLLECTIONS.routes, routeId, "default", "default", "path"));
						for (const el of pathRes.docs) {
							await deleteDoc(el.ref);
						}
						const pathChunks = splitArrayIntoChunks(path, 10000);

						pathChunks.forEach((chunk, i) => {
							let data = {
								index: i,
								path: chunk,
							};

							addDoc(collection(db, COLLECTIONS.routes, routeId, "default", "default", "path"), data);
						});
						showSuccessToastMessage("Route created successfully.");
					}
				}
			} else {
				await setDoc(
					doc(db, COLLECTIONS.routes, id),
					{
						...data,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				console.log(data.defaultStartLocation);
				console.log(data.defaultEndLocation);

				const res = await directionsService.route({
					origin: { lat: data.defaultStartLocation.lat, lng: data.defaultStartLocation.lng },
					destination: { lat: data.defaultEndLocation.lat, lng: data.defaultEndLocation.lng },
					travelMode: "DRIVING",
					waypoints: [],
					provideRouteAlternatives: false,
				});
				if (res.routes[0]?.legs) {
					let calculatedDistance = 0;
					let calculatedTime = 0;
					res.routes[0]?.legs.map((leg) => {
						if (leg?.duration?.value) {
							calculatedTime += leg.duration.value;
						}
						if (leg?.distance?.value) {
							calculatedDistance += leg.distance.value;
						}
					});

					console.log({ calculatedDistance, calculatedTime });
					data["totalDistanceInMeters"] = calculatedDistance;
					data["totalTimeInSeconds"] = calculatedTime;
				}
				await setDoc(
					doc(db, COLLECTIONS.routes, id, "default", "default"),
					{
						totalDistanceInMeters: data.totalDistanceInMeters,
						totalTimeInSeconds: data.totalTimeInSeconds,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				const steps = res.routes[0]?.legs.flatMap((el) => el.steps);
				let path = steps.flatMap((el) => el.path);
				console.log({ path });
				path = path.map((el) => ({ lat: el.lat(), lng: el.lng() }));
				if (path.length > 0) {
					const pathRes = await getDocs(collection(db, COLLECTIONS.routes, id, "default", "default", "path"));
					for (const el of pathRes.docs) {
						await deleteDoc(el.ref);
					}
					const pathChunks = splitArrayIntoChunks(path, 10000);
					pathChunks.forEach((chunk, i) => {
						let data = {
							index: i,
							path: chunk,
						};
						addDoc(collection(db, COLLECTIONS.routes, id, "default", "default", "path"), data);
					});
					showSuccessToastMessage("Route updated successfully.");
				}
			}
		} catch (error) {
			console.error("Error during submission:", error);
			showErrorToastMessage("An error occurred during submission. Internal Server Error.");
		} finally {
			setLoading(false);
			handleBack();
		}
	};

	const handleBack = () => navigate("/admin/assets/routes");

	if (loading) {
		return <Loader />;
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="bg-white p-8 m-6 py-2 flex flex-col rounded-cardRadii flex-grow">
			<div className="font-medium flex items-center gap-4 text-lg py-2 border-b border-cardTextGray border-opacity-40 text-black">
				{title}
			</div>

			<div className="grid grid-cols-4 gap-5">
				<div className="col-span-4">
					<div className="w-full h-96 my-4">
						<RouteSetupMap
							setStart={setStart}
							setEnd={setEnd}
							start={start}
							end={end}
							path={path}
							setPath={setPath}
							otherPaths={otherPaths}
							waypoints={waypoints}
							otherGeneratorMarkers={otherGeneratorMarkers}
							vendorMarkers={vendorMarkers}
							treatmentFacilities={treatmentFacilities}
							transporter={dummyTransporter}
							setFormValue={setValue}
						/>
					</div>
					<div className="grid gap-x-8 gap-y-4 grid-cols-2">
						<div className="">
							<Controller
								name="routeLabel"
								control={control}
								rules={{ required: "Route Name is required" }}
								render={({ field }) => {
									return <Input label={"Route Name"} isRequired {...field} margin={"mt-4 mb-0"} />;
								}}
							/>
							{errors.routeLabel && <p className="text-red-500 text-sm mt-1">{errors.routeLabel.message}</p>}
							<Controller
								name="defaultStartLocation.id"
								control={control}
								rules={{ required: "Default Start Location is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											onChange={(value) => {
												console.log({ value });
												onChange(value);
												const foundSatellite = satelliteLocations.find((el) => el.id === value);
												if (foundSatellite) {
													setStart({
														id: foundSatellite.id,
														lat: foundSatellite.satelliteServiceCoordinates.lat,
														lng: foundSatellite.satelliteServiceCoordinates.lng,
														name: foundSatellite.name,
														type: LOCATION_TYPE.SATELLITE,
													});
													return;
												}
												const foundVendor = vendorMarkers.find((el) => el.id === value);
												console.log(foundVendor);
												if (foundVendor) {
													setStart({
														id: foundVendor.id,
														lat: foundVendor.vendorCoordinates.lat,
														lng: foundVendor.vendorCoordinates.lng,
														name: foundVendor.name,
														type: LOCATION_TYPE.VENDOR,
													});
													return;
												}
												const foundTreatment = treatmentFacilities.find((el) => el.id === value);
												if (foundTreatment) {
													setStart({
														id: foundTreatment.id,
														lat: foundTreatment.treatmentCoordinates.lat,
														lng: foundTreatment.treatmentCoordinates.lng,
														name: foundTreatment.name,
														type: LOCATION_TYPE.TSTF,
													});
													return;
												}
											}}
											options={startLocationOptions}
											label="Default Start Location *"
											className="text-sm"
										/>
										{errors.defaultStartLocation?.id?.message && (
											<p className="text-red-500 text-sm mt-1">{errors.defaultStartLocation?.id?.message}</p>
										)}
									</div>
								)}
							/>
							{/* <Controller
								name="truckCostPerMile"
								control={control}
								rules={{ required: "Vehicle Cost Per Mile is required" }}
								render={({ field: { onChange, value } }) => {
									return <Input label="Vehicle Cost Per Mile" value={value} onChange={onChange} isRequired={true} />;
								}}
							/>
							{errors.truckCostPerMile && (
								<p className="text-red-500 text-sm mt-1">{errors.truckCostPerMile?.message}</p>
							)} */}
							<Controller
								name="color"
								control={control}
								rules={{ required: "Color for a route is required" }}
								render={({ field: { onChange, value } }) => {
									return (
										<div className="w-full py-2 grid grid-cols-2 items-start gap-2">
											<div className="grid grid-cols-3 items-center col-span-2 ">
												<p>Route Color *</p>
												<div className="col-span-2 bg-inputBg rounded-full">
													{/* <div className={`w-[40px] h-full bg-[${value}] bg-red-500`}></div> */}
													<HexColorInput
														className=" py-1.5 px-4 bg-transparent focus:outline-none"
														color={value}
														onChange={onChange}
														prefixed
													/>
												</div>
											</div>
											<div className="col-span-2 grid grid-cols-2">
												<HexColorPicker
													className="w-full ml-auto col-span-2"
													style={{ width: "100%" }}
													color={value}
													onChange={onChange}
												/>
											</div>
										</div>
									);
								}}
							/>
							{errors.truckCostPerMile && <p className="text-red-500 text-sm mt-1">{errors.color?.message}</p>}
						</div>
						<div className="">
							<Controller
								name={`type`}
								control={control}
								rules={{ required: "Route Type is required" }}
								render={({ field: { onChange, value } }) => (
									<MultiSelectRounded
										value={value}
										onChange={onChange}
										options={serviceTypes}
										isRequired={true}
										label="Route Type"
										id={`route-type`}
									/>
								)}
							/>
							{errors.type && <p className="text-red-500 text-sm mt-1">{errors.type?.message}</p>}
							<Controller
								name="defaultEndLocation.id"
								control={control}
								rules={{ required: "Default End Location is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											onChange={(value) => {
												onChange(value);
												const foundSatellite = satelliteLocations.find((el) => el.id === value);
												if (foundSatellite) {
													setEnd({
														id: foundSatellite.id,
														lat: foundSatellite.satelliteServiceCoordinates.lat,
														lng: foundSatellite.satelliteServiceCoordinates.lng,
														name: foundSatellite.name,
														type: LOCATION_TYPE.SATELLITE,
													});
													return;
												}
												const foundVendor = vendorMarkers.find((el) => el.id === value);
												console.log(foundVendor);
												if (foundVendor) {
													setEnd({
														id: foundVendor.id,
														lat: foundVendor.vendorCoordinates.lat,
														lng: foundVendor.vendorCoordinates.lng,
														name: foundVendor.name,
														type: LOCATION_TYPE.VENDOR,
													});
													return;
												}
												const foundTreatment = treatmentFacilities.find((el) => el.id === value);
												if (foundTreatment) {
													setEnd({
														id: foundTreatment.id,
														lat: foundTreatment.treatmentCoordinates.lat,
														lng: foundTreatment.treatmentCoordinates.lng,
														name: foundTreatment.name,
														type: LOCATION_TYPE.TSTF,
													});
													return;
												}
											}}
											options={endLocationOptions}
											label="Default End Location *"
											className="text-sm"
										/>
										{errors.defaultEndLocation?.id && (
											<p className="text-red-500 text-sm mt-1">{errors.defaultEndLocation?.id?.message}</p>
										)}
									</div>
								)}
							/>
							<Controller
								name="status"
								control={control}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											onChange={onChange}
											options={statusOptions}
											label="Status"
											className="text-sm"
										/>
										{errors.status && <p className="text-red-500 text-sm mt-1">{errors.status?.message}</p>}
									</div>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="pt-4">
				<h6 className="font-medium text-lg pb-2 border-b border-cardTextGray border-opacity-40">Stats For Nerds</h6>
				<div className="py-4 flex flex-col gap-8">
					<div>
						<h6 className="text-lg">Number of services performed on this route over the last 30 days</h6>
						{pastService?.length > 0 ? (
							pastService.map((service, index) => (
								<div
									key={service?.id ?? index}
									className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}
								>
									<div className="flex items-center gap-2">{formattedDate(service.date)}</div>
									<div>{service.route?.routeLabel}</div>
									<div>Stops</div>
									<div>{service.serviceSchedule?.notes}</div>
									<div>{service.serviceSchedule?.serviceType?.toString()}</div>
								</div>
							))
						) : (
							<div className="w-full text-cardTextGray">No data</div>
						)}
					</div>
					<div>
						<h6 className="text-lg">Number of scheduled services in the next 30 days</h6>
						{upcomingService?.length > 0 ? (
							upcomingService.map((service, index) => (
								<div
									key={service?.id ?? index}
									className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}
								>
									<div className="flex items-center gap-2">{formattedDate(service.date)}</div>
									<div>{service.route?.routeLabel}</div>
									<div>Stops</div>
									<div>{service.serviceSchedule?.notes}</div>
									<div>{service.serviceSchedule?.serviceType?.toString()}</div>
								</div>
							))
						) : (
							<div className="w-full text-cardTextGray">No data</div>
						)}
					</div>
				</div>
			</div>
			<div className="flex justify-end gap-8 py-3">
				<Button btnStyle="form_nav_secondary" text={"Back"} onClick={handleBack} />
				<Button btnStyle="form_nav_primary" text={loading ? " Saving..." : "Save"} type="submit" />
			</div>
		</form>
	);
};

export default AddRouteForm;

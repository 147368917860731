import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import GeneratorSidebar from "../../components/sidebars/admin-sidebar/GeneratorSidebar";
import GeneratorHeader from "../../components/header/GeneratorHeader";
import GeneratorDashboard from "./dashboard/GeneratorDashboard";

import Documents from "./documents/Documents";
import GeneratorServiceReporting from "./generatorServiceReporting/GeneratorServiceReporting";
import Billing from "./billing/Billing";
import GroupAdministrator from "./groupAdministrator/GroupAdministrator";
import Communication from "./communication/Communication";
import { GeneratorBreadcrumbProvider } from "../../context/GeneratorBreadcrumbContext";
import { GeneratorUserProvider, useGeneratorUser } from "../../context/GeneratorUserContext";
import GeneratorInvoiceAndHistory from "./generatorInvoiceAndHistory/GeneratorInvoiceAndHistory";
import GeneratorSavedPaymentMethods from "./generatorSavedPaymentMethods/GeneratorSavedPaymentMethods";
import GeneratorMakePayment from "./generatorMakePayment/GeneratorMakePayment";
import ServiceCalender from "./dashboard/components/ServiceCalender/ServiceCalender";
const GradientDefinition = () => (
	<svg width="0" height="0" style={{ position: "absolute" }}>
		<defs>
			<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stopColor="#007AFF" />
				<stop offset="100%" stopColor="#4CD964" />
			</linearGradient>
		</defs>
	</svg>
);
const GeneratorLayout = () => {
	const { user, authLoading, generatorData, allServices } = useGeneratorUser();

	const [active, setActive] = useState(null);
	const scrollContainerRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		const pathMap = {
			"/generator-dashboard": "generator-dashboard",
			"generator-dashboard/documents": "documents",
			"/generator-dashboard/service-reports": "service-reports",
			"/generator-dashboard/invoices-and-payments": "invoices",
			"/generator-dashboard/saved-payment-methods": "saved-payment-methods",
			"/generator-dashboard/make-payment": "make-payment",
			"/generator-dashboard/service-calendar": "service-calendar",
		};
		console.log(location.pathname);
		setActive(pathMap[location.pathname] || "generator-dashboard");
	}, [location]);

	return (
		<GeneratorBreadcrumbProvider>
			<div className="flex h-screen">
				<GradientDefinition />
				<GeneratorSidebar active={active} />
				<div className="flex-1 h-screen ml-0 md:ml-72 bg-primaryGray max-w-[calc(100vw-18rem)]">
					<GeneratorHeader />
					<div ref={scrollContainerRef} className="h-[calc(100%-80px)] overflow-scroll">
						{active === "generator-dashboard" && <GeneratorDashboard />}
						{active === "documents" && <Documents />}
						{active === "service-reports" && <GeneratorServiceReporting />}
						{active === "invoices" && <GeneratorInvoiceAndHistory />}
						{active === "saved-payment-methods" && <GeneratorSavedPaymentMethods />}
						{active === "make-payment" && <GeneratorMakePayment />}
						{active === "service-calendar" && <ServiceCalender />}
					</div>
				</div>
			</div>
		</GeneratorBreadcrumbProvider>
	);
};

export default GeneratorLayout;

import React from 'react';
import Handlebars from 'handlebars';

const template = `
  <div style="font-family: Arial, sans-serif; font-size: 12px; display: flex; justify-content: center; align-items: center; min-height: 100vh;">
    <div style="border: 1px solid black; padding:6px;  width: 80%; max-width: 1000px;">
      <div class="text-center mb-2 text-lg font-bold">ROUTE SHEET</div>
      <div class="text-center mb-4 text-base font-bold">UN3291, Regulated Medical Waste, n.o.s., 6.2, PGII</div>

      <div class="flex justify-between">
        <div>
          <div>Day DD/MM/YYYY</div>
          <div>[Route Name]</div>
          <div>Transporter Name</div>
          <div>Transporter Place</div>
          <div>Transporter Address</div>
        </div>

        <div>
          <div>Minimum Containers Needed to Complete Route</div>
          <div>38 Gallon</div>
          <div>38 Gallon</div>
          <div>38 Gallon</div>
        </div>
      </div>

      <div class="border-2 border-black mt-4"></div>

      <div class="flex justify-between">
        <div> 
          <div> OCTO # </div>
          <div> Generator Name </div>
          <div> Service Address </div>
          <div> Service Address </div>
          <div> Phone </div>
          <div> Service  Hours </div>
        </div>

        <div> 
          <div> DEL:</div>
          <div> PRK: </div>
          <div> LOC: </div>
          <div> Note: </div>
          <div> Service Freq </div>
          <div> Expected Container </div>
        </div>

      <div>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="border: 1px solid black; padding: 4px;">Waste Type</th>
              <th style="border: 1px solid black; padding: 4px;">Size</th>
              <th style="border: 1px solid black; padding: 4px;">Qty</th>
              <th style="border: 1px solid black; padding: 4px;">Weight (Lbs)</th>
            </tr>
          </thead>
          <tbody>
            {{#each wasteTypes}}
              <tr>
                <td style="border: 1px solid black; padding: 4px;">{{this.type}}</td>
                <td style="border: 1px solid black; padding: 4px;">{{this.size}}</td>
                <td style="border: 1px solid black; padding: 4px;">{{this.qty}}</td>
                <td style="border: 1px solid black; padding: 4px;">{{this.weight}}</td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </div>
     </div>
        <div class="flex justify-between gap-6 mt-6">
        <div>
        <div><span class="font-semibold">Generator’s Certification: </span> I certify that the information provided is accurate and that the materials have been properly classified, described, packaged, labeled, and are in suitable condition for transportation in compliance with the applicable U.S. Department of Transportation regulations. </div>
              <div class="mt-16"> Representative Signature:___________________________________ </div> 
              <div>Generator Representative (Print): Sammy Joe Spaccamonti </div>   
        </div> 
     <div>

      <div>
        <div><span class="font-semibold">Transporter's Certification: </span> I certify that the information provided is true and correct, and that this load contains only untreated medical waste. I understand that any falsification of this manifest may result in the loss of my transporter's registration and/or the right to use State-authorized facilities </div>
              <div class="mt-16"> Representative Signature:___________________________________ </div> 
              <div>Generator Representative (Print): Sammy Joe Spaccamonti </div>   
        </div> 
    </div>
    </div>
          <div class="border-2 border-gray mt-1"></div>
    <div class="">Note: </div>
  </div>
`;

const RouteSheet = () => {
  const wasteTypes = [
    { type: 'Sharps/Bio', size: 'Large', qty: 5, weight: 100 },
    { type: 'Pharmaceutical', size: 'Medium', qty: 3, weight: 60 },
    { type: 'Pathological', size: 'Small', qty: 2, weight: 40 },
    { type: 'Chemotherapy', size: 'Small', qty: 2, weight: 40 },

  ];

  const compiledTemplate = Handlebars.compile(template);
  const htmlContent = compiledTemplate({ wasteTypes });

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default RouteSheet;

import React from "react";

const Textarea = ({
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  margin = "mt-4",
}) => {
  return (
    <div className={`flex ${margin}`}>
      {label && (
        <label
          htmlFor={label?.toLowerCase().replace(" ", "-")}
          className="text-inputLabel font-normal w-1/2"
        >
          {label}
        </label>
      )}
      <div className="w-full">
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`${
            label ? "min-w-[100%]" : "w-full"
          } text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-9 px-6 leading-tight focus:outline-none  focus:ring-1 focus:ring-dashInActiveBtnText`}
        />
      </div>
    </div>
  );
};

export default Textarea;

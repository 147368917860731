import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { auth, db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";

const fetchContractorData = async (transporterId) => {
	try {
		// Get the transporter's document
		const transporterDoc = await getDoc(doc(db, "transporters", transporterId));

		if (!transporterDoc.exists()) {
			console.log("No such transporter!");
			return [];
		}

		const data = transporterDoc.data();
		const contractorRelationships = data.subcontractorTo || {};

		// Get all contractor details
		const contractorPromises = Object.entries(contractorRelationships).map(async ([contractorId, relationship]) => {
			const contractorDoc = await getDoc(doc(db, "transporters", contractorId));

			if (!contractorDoc.exists()) return null;

			const contractorData = contractorDoc.data();

			return {
				id: contractorData,
				name: contractorData.name ?? "--",
				// octoNumber: contractorData.octoNumber ?? "--",
				address: formatAdd(contractorData),
				generalEmail: contractorData.billingEmail?.length > 0 ? contractorData.billingEmail : "--",
				phoneNumber: contractorData.billingPhone ?? "--",
				website: contractorData.website ?? "--",
				sharedGenerators: relationship.sharedGenerators,
				startDate: relationship.startDate,
				status: relationship.status,
			};
		});

		const contractors = await Promise.all(contractorPromises);
		return contractors.filter(Boolean); // Remove any null entries
	} catch (error) {
		console.error("Error fetching contractor data:", error);
		return [];
	}
};

let formatAdd = (data) => {
	let formattedAdd = "";
	if (!data.billingSuite && !data.billingStreet && !data.billingCity && !data.billingZip) return "--";
	data.billingSuite?.trim()?.length ? (formattedAdd += data.billingSuite) : (formattedAdd = "");
	data.billingStreet?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.billingStreet}`)
		: (formattedAdd += data.billingCity);
	data.billingState?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.billingState}`)
		: (formattedAdd += data.billingState);
	data.billingCity?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.billingCity}`)
		: (formattedAdd += data.billingCity);
	data.billingZip?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.billingZip}`)
		: (formattedAdd += data.billingZip);

	return formattedAdd.length ? formattedAdd : "--";
};

const ContractorManagement = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();
	const [user, loading] = useAuthState(auth);
	const [userData, setUserData] = useState({});

	useEffect(() => {
		const fetchTransporterDoc = async () => {
			const docRef = doc(db, "transporters", user.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				console.log("Document data:", docSnap.data());
			} else {
				// docSnap.data() will be undefined in this case
				console.log("No such document!");
			}
		};

		fetchTransporterDoc();
	}, []);

	console.log({ user });

	// Dummy data for contractors
	const dummyContractorData = Array.from({ length: 5 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Generator Name details`,
	}));

	// const [data, setData] = useState(dummyContractorData);
	const [contractorData, setContractorData] = useState([]);
	const dropdownOptions = ["Listed", "Unlisted"];

	useEffect(() => {
		const loadContractors = async () => {
			if (!user) return;

			setIsLoading(true);
			try {
				const contractors = await fetchContractorData(user.uid);
				console.log({ contractors });

				setContractorData(contractors);
			} catch (error) {
				console.error("Error loading contractors:", error);
			} finally {
				setIsLoading(false);
			}
		};

		loadContractors();
	}, [user]);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/match-request");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/subcontractor-management");
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	const handleSubmit = async (gens, subContractor) => {
		console.log({ gens });
		localStorage.setItem("gens", JSON.stringify(gens));
		localStorage.setItem("subContractor", subContractor);
		navigate("/admin/octo-connect/service-locations");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border text-sm xl:text-base border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 text-black">Contractor Management</div>
				<div className="text-base mx-8 mb-3 text-black">Current Partners Management</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-60">Contractor Name</div>
							{/* <div className="truncate w-60">OCTO Number</div> */}
							<div className="truncate w-80">Contractor Address</div>
							<div className="truncate w-60">Contractor Email</div>
							<div className="truncate w-60">Contractor Phone</div>
							<div className="truncate w-60">Contractor Website</div>
							<div className="truncate w-60 text-center">Number of Service Locations</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : contractorData.length > 0 ? (
							contractorData.map((contractor, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < contractorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-60 h-full">{contractor.name ?? "--"}</div>
										{/* <div className="w-60 h-full text-cardTextBlue truncate">{contractor.octoNumber ?? "--"}</div> */}
										<div className="truncate h-full w-80">{contractor.address ?? "--"}</div>
										<div className="truncate h-full w-60">{contractor.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													contractor.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) => (contractor.phoneNumber ? handlePhoneClick(e, contractor.phoneNumber) : "")}
											>
												{contractor.phoneNumber?.length > 0 ? contractor.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{contractor.website ?? "--"}</div>
										<div className="truncate h-full w-60 text-center">
											<button
												onClick={() => handleSubmit(contractor.sharedGenerators, contractor.name)}
												className={`${
													contractor.sharedGenerators?.length ? "hover:text-blueText hover:underline" : "cursor-default"
												}`}
											>
												{contractor.sharedGenerators?.length?.toString() ?? "--"}
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default ContractorManagement;

import React, { useCallback, useEffect, useState } from "react";
import Calender from "./Calenders/Calenders";
import { generateServiceMonths } from "../../../../../utils/helpers";
import CalendarWithTimeSlots from "../open-calendar/CalendarComponent";
import { useGeneratorUser } from "../../../../../context/GeneratorUserContext";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
const ServiceCalender = () => {
	const { user, authLoading, generatorData, allServices } = useGeneratorUser();
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [months, setMonths] = useState([]);
	useEffect(() => {
		const dates = [];

		// Previous 3 months
		for (let i = 0; i < 12; i++) {
			const year = i >= 0 ? currentYear : currentYear - 1; // Adjust year for negative months
			const month = (i + 12) % 12; // Adjust month for negative values
			dates.push(new Date(year, month, 1));
		}
		setMonths(dates);
		return () => {};
	}, [currentYear]);

	return (
		<div className="grid p-5">
			<div className="rounded-xl grid gap-5">
				<div className="flex gap-5">
					<div className="flex items-center gap-5">
						<button
							className="btn btn-ghost btn-sm btn-circle text-xl"
							onClick={() => {
								setCurrentYear((prev) => prev - 1);
							}}
						>
							<FaAngleLeft />
						</button>
						<p className="text-xl">{currentYear}</p>
						<button
							className="btn btn-ghost btn-sm btn-circle text-xl"
							onClick={() => {
								setCurrentYear((prev) => prev + 1);
							}}
						>
							<FaAngleRight />
						</button>
					</div>
					<div className="flex gap-5 ml-auto">
						<button
							className="bg-white min-w-40  text-black rounded-full py-3 px-4 transition-all duration-300 flex justify-center items-center space-x-2"
							onClick={() => {
								setCurrentYear(new Date().getFullYear());
							}}
						>
							Today
						</button>
					</div>
				</div>
				<div className="p-5 bg-white rounded-xl grid gap-5">
					<div className="grid grid-cols-3 gap-4 ">
						{months.map((month, index) => (
							<CalendarWithTimeSlots key={month} allServices={allServices} startDate={month} />
						))}
					</div>
					<div className="grid gap-5 w-full">
						<div className="grid grid-cols-3">
							<div className="flex flex-col gap-5">
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-white border border-red-500 mr-2"></div>
									<span>{"Today's Date"}</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-red-500 mr-2"></div>
									<span>Medical Waste Service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-green-500 mr-2"></div>
									<span>Paper Shredding Service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="flex w-8 h-8 col-span-2">
										<div className="min-w-4 h-8 bg-green-500 rounded-r-full rotate-180"></div>
										<div className="min-w-4 h-8 bg-red-500 rounded-l-full rotate-180"></div>
									</div>
									<span>Medical Waste & Paper Shredding</span>
								</div>
							</div>
							<div className="flex flex-col gap-5">
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-red-500 mr-2"></div>
									<span>Today has either/or service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-yellow-500 mr-2"></div>
									<span>Holiday (No services on this day)</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-gray-500 mr-2"></div>
									<span>Completed (Past) Service dates</span>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceCalender;

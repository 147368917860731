import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../config/firebase";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";

const LinkedGenerators = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [generators, setGenerators] = useState([]);
	const [transporterNames, setTransporterNames] = useState({});
	const [currentTransporterData, setCurrentTransporterData] = useState(null);
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();

	// Fetch a single transporter's data
	const fetchTransporterData = async (transporterId) => {
		try {
			const transporterDoc = await getDoc(doc(collection(db, "transporters"), transporterId));
			if (transporterDoc.exists()) {
				return { id: transporterId, ...transporterDoc.data() };
			}
		} catch (error) {
			console.error(`Error fetching transporter ${transporterId}:`, error);
		}
		return null;
	};

	// Fetch all required transporter data upfront
	const fetchAllTransporterData = async (transporterIds) => {
		const uniqueIds = Array.from(new Set(transporterIds.filter(Boolean)));
		const transporters = await Promise.all(uniqueIds.map(fetchTransporterData));
		const namesMap = {};
		transporters.forEach((transporter) => {
			if (transporter) {
				namesMap[transporter.id] = transporter.name;
			}
		});
		return namesMap;
	};

	const processGeneratorRelationships = (genId, currentTransporterId, transporterData, allTransporterNames) => {
		let contractorInfo = null;
		let subcontractorInfo = null;

		// Check if we are the contractor
		if (transporterData.generators?.includes(genId)) {
			Object.entries(transporterData.contractorTo || {}).forEach(([otherTransporterId, data]) => {
				if (data.sharedGenerators?.includes(genId)) {
					contractorInfo = {
						transporterId: currentTransporterId,
						transporterName: allTransporterNames[currentTransporterId],
						status: data.status,
						startDate: data.startDate,
					};
					subcontractorInfo = {
						transporterId: otherTransporterId,
						transporterName: allTransporterNames[otherTransporterId],
						status: data.status,
						startDate: data.startDate,
					};
				}
			});
		}

		// Check if we are the subcontractor
		Object.entries(transporterData.subcontractorTo || {}).forEach(([otherTransporterId, data]) => {
			if (data.managedGenerators?.includes(genId)) {
				if (!contractorInfo) {
					contractorInfo = {
						transporterId: otherTransporterId,
						transporterName: allTransporterNames[otherTransporterId],
						status: data.status,
						startDate: data.startDate,
					};
				}
				if (!subcontractorInfo) {
					subcontractorInfo = {
						transporterId: currentTransporterId,
						transporterName: allTransporterNames[currentTransporterId],
						status: data.status,
						startDate: data.startDate,
					};
				}
			}
		});

		return { contractorInfo, subcontractorInfo };
	};

	useEffect(() => {
		const fetchAllData = async (transporterId) => {
			setIsLoading(true);
			try {
				// 1. Fetch current transporter data
				const transporterData = await fetchTransporterData(transporterId);
				if (!transporterData) {
					throw new Error("Could not fetch current transporter data");
				}
				setCurrentTransporterData(transporterData);

				// 2. Collect all transporter IDs we need to fetch
				const transporterIdsToFetch = new Set([transporterId]);
				Object.keys(transporterData.contractorTo || {}).forEach((id) => transporterIdsToFetch.add(id));
				Object.keys(transporterData.subcontractorTo || {}).forEach((id) => transporterIdsToFetch.add(id));

				// 3. Fetch all transporter names at once
				const allTransporterNames = await fetchAllTransporterData(Array.from(transporterIdsToFetch));
				setTransporterNames(allTransporterNames);

				// 4. Process generators with all data available
				const processedGenerators = [];

				// Process owned generators
				const ownedGeneratorIds = transporterData.generators || [];
				for (const genId of ownedGeneratorIds) {
					const genDoc = await getDoc(doc(collection(db, "generators"), genId));
					if (genDoc.exists()) {
						const relationshipInfo = processGeneratorRelationships(
							genId,
							transporterId,
							transporterData,
							allTransporterNames
						);

						if (relationshipInfo.contractorInfo || relationshipInfo.subcontractorInfo) {
							processedGenerators.push({
								id: genDoc.id,
								...genDoc.data(),
								name: genDoc.data()?.generatorName,
								ownership: "owned",
								...relationshipInfo,
							});
						}
					}
				}

				// Process subcontracted generators
				for (const [otherTransporterId, data] of Object.entries(transporterData.subcontractorTo || {})) {
					for (const genId of data.managedGenerators || []) {
						const genDoc = await getDoc(doc(collection(db, "generators"), genId));
						if (genDoc.exists()) {
							const relationshipInfo = processGeneratorRelationships(
								genId,
								transporterId,
								transporterData,
								allTransporterNames
							);

							processedGenerators.push({
								id: genDoc.id,
								...genDoc.data(),
								name: genDoc.data()?.generatorName,
								ownership: "subcontracted",
								...relationshipInfo,
							});
						}
					}
				}

				setGenerators(processedGenerators);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		if (user) {
			fetchAllData(user.uid);
		}
	}, [user]);

	console.log({ generators });

	const dropdownOptions = ["Listed", "Unlisted"];

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/subcontractor-schedules");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/match-request");
	};

	const handleAccept = async (generator) => {
		setIsLoading(true);
		try {
			// Get references to both contractor and subcontractor documents
			const contractorRef = doc(collection(db, "transporters"), generator.contractorInfo.transporterId);
			const subcontractorRef = doc(collection(db, "transporters"), generator.subcontractorInfo.transporterId);

			// Get current documents
			const contractorDoc = await getDoc(contractorRef);
			const subcontractorDoc = await getDoc(subcontractorRef);

			if (contractorDoc.exists() && subcontractorDoc.exists()) {
				const contractorData = contractorDoc.data();
				const subcontractorData = subcontractorDoc.data();

				// Update the status in both contractorTo and subcontractorTo relationships
				if (contractorData.contractorTo?.[generator.subcontractorInfo.transporterId]) {
					await updateDoc(contractorRef, {
						[`contractorTo.${generator.subcontractorInfo.transporterId}.status`]: "accepted",
					});
				}

				if (subcontractorData.subcontractorTo?.[generator.contractorInfo.transporterId]) {
					await updateDoc(subcontractorRef, {
						[`subcontractorTo.${generator.contractorInfo.transporterId}.status`]: "accepted",
					});
				}

				// Update local state to reflect the change
				setGenerators((prevGenerators) =>
					prevGenerators.map((gen) => {
						if (gen.id === generator.id) {
							return {
								...gen,
								subcontractorInfo: {
									...gen.subcontractorInfo,
									status: "accepted",
								},
							};
						}
						return gen;
					})
				);
			}
		} catch (error) {
			console.error("Error accepting generator:", error);
		}
		setIsLoading(false);
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 text-black">Linked Generators</div>
				<div className="text-base mx-8 mb-3 text-black">Confirmed Matches</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Contractor Name</div>
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">Status</div>
							{/* <div className="truncate w-60 text-center">Options</div> */}
							<div className="truncate w-60">Pricebook</div>
							<div className="truncate w-24 text-center">Action</div>
						</div>
					</div>
					<div className={`min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : generators.length > 0 ? (
							generators.map((generator, index) => (
								<div
									key={generator.id}
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generators.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.name ?? "--"}</div>
										<div className="truncate w-80 h-full">
											{generator?.contractorInfo?.transporterId !== user.uid
												? generator.subcontractorInfo?.transporterName
												: generator?.contractorInfo?.transporterName ?? "--"}
										</div>
										<div className="truncate w-80 h-full">
											{generator.subcontractorInfo?.transporterId == user.uid
												? generator?.contractorInfo?.transporterName
												: generator.subcontractorInfo?.transporterName ?? "--"}
										</div>
										<div className="truncate h-full w-60">
											{generator.subcontractorInfo.status == "accepted"
												? "Accepted"
												: generator?.contractorInfo?.status ?? "--"}
										</div>
										{/* <div className="flex justify-center w-60 text-xs font-medium">
											<ServiceDropdown buttonText="Options" options={dropdownOptions} />
										</div> */}
										<div className="truncate h-full w-60">{generator.pricebook ?? "--"}</div>
										<button
											className={`px-4 py-1 w-24 bg-primary transition-colors duration-200 text-white rounded-full
                ${generator.subcontractorInfo?.transporterId === user?.uid ? "block" : "hidden"}
                ${
									generator.subcontractorInfo?.status === "accepted"
										? "opacity-50 cursor-not-allowed"
										: "bg-opacity-90 hover:bg-opacity-100"
								}`}
											onClick={() => handleAccept(generator)}
											disabled={generator.subcontractorInfo?.status === "accepted" || isLoading}
										>
											{generator.subcontractorInfo?.status === "accepted" ? "Accepted" : "Accept"}
										</button>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={handlePreviousBtn}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={handleNextClick}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default LinkedGenerators;

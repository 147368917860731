import React, { useState, useEffect } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import PropTypes from 'prop-types';

const RouteMap = ({ 
  apiKey, 
  stops, 
  mapOptions = {}, 
  routeColor = '#1A73E8', 
  routeWeight = 4 
}) => {
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  useEffect(() => {
    if (!window.google || !window.google.maps) return;

    const mapInstance = new window.google.maps.Map(
      document.getElementById('map'), 
      {
        zoom: 7,
        center: { 
          lat: stops[0].latitude, 
          lng: stops[0].longitude 
        },
        ...mapOptions
      }
    );
    setMap(mapInstance);

    const directionsServiceInstance = new window.google.maps.DirectionsService();
    const directionsRendererInstance = new window.google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: routeColor,
        strokeWeight: routeWeight
      }
    });
    directionsRendererInstance.setMap(mapInstance);
    setDirectionsService(directionsServiceInstance);
    setDirectionsRenderer(directionsRendererInstance);
  }, [stops, mapOptions, routeColor, routeWeight]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer || stops.length < 2) return;

    const waypoints = stops.slice(1, -1).map(stop => ({
      location: { 
        lat: stop.latitude, 
        lng: stop.longitude 
      },
      stopover: true
    }));

    directionsService.route(
      {
        origin: { 
          lat: stops[0].latitude, 
          lng: stops[0].longitude 
        },
        destination: { 
          lat: stops[stops.length - 1].latitude, 
          lng: stops[stops.length - 1].longitude 
        },
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      }
    );
  }, [directionsService, directionsRenderer, stops]);

  return (
    <div className="w-full h-96 rounded-lg overflow-hidden shadow-lg">
      <div 
        id="map" 
        className="w-full h-full"
      />
    </div>
  );
};

const GoogleMapsRouteWrapper = ({ stops, ...props }) => {
  if (!props.apiKey) {
    return (
      <div className="p-4 text-red-500">
        Google Maps API Key is required
      </div>
    );
  }

  return (
    <Wrapper apiKey={props.apiKey}>
      <RouteMap stops={stops} {...props} />
    </Wrapper>
  );
};

GoogleMapsRouteWrapper.propTypes = {
  apiKey: PropTypes.string.isRequired,
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired
    })
  ).isRequired,
  mapOptions: PropTypes.object,
  routeColor: PropTypes.string,
  routeWeight: PropTypes.number
};

export default GoogleMapsRouteWrapper;
import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { createPortal } from "react-dom";

const MultiselectDropdown = ({ buttonText, options, selectedOptions, onSelectedOptionsChange, width = "" }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
	const [checkedState, setCheckedState] = useState(
		options.reduce((acc, option) => ({ ...acc, [option.value]: selectedOptions.includes(option.value) }), {})
	);

	const buttonRef = useRef(null);
	const dropdownRef = useRef(null);

	const handleToggle = () => {
		if (!isOpen) {
			const buttonRect = buttonRef.current.getBoundingClientRect();
			setDropdownPosition({
				top: buttonRect.bottom + window.scrollY,
				left: buttonRect.left + window.scrollX,
				width: buttonRect.width,
			});
		}
		setIsOpen((prev) => !prev);
	};

	const handleSelect = (option) => {
		let updatedCheckedState;
		if (option.value === "all") {
			const selectAll = !checkedState["all"];
			updatedCheckedState = options.reduce((acc, opt) => ({ ...acc, [opt.value]: selectAll }), {});
		} else {
			updatedCheckedState = {
				...checkedState,
				all: false,
				[option.value]: !checkedState[option.value],
			};
		}

		const allSelected = Object.keys(updatedCheckedState).every((key) => updatedCheckedState[key] || key === "all");
		if (allSelected) {
			updatedCheckedState = options.reduce((acc, opt) => ({ ...acc, [opt.value]: true }), {});
		}

		setCheckedState(updatedCheckedState);
		const selectedOptions = Object.keys(updatedCheckedState).filter((key) => updatedCheckedState[key]);
		onSelectedOptionsChange(selectedOptions);
	};

	const handleClickOutside = (event) => {
		if (
			buttonRef.current &&
			!buttonRef.current.contains(event.target) &&
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		const updatedCheckedState = options.reduce(
			(acc, option) => ({ ...acc, [option.value]: selectedOptions.includes(option.value) }),
			{}
		);
		setCheckedState(updatedCheckedState);
	}, [selectedOptions, options]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	// Update position on scroll
	useEffect(() => {
		const handleScroll = () => {
			if (isOpen && buttonRef.current) {
				const buttonRect = buttonRef.current.getBoundingClientRect();
				setDropdownPosition({
					top: buttonRect.bottom + window.scrollY,
					left: buttonRect.left + window.scrollX,
					width: buttonRect.width,
				});
			}
		};

		window.addEventListener("scroll", handleScroll, true);
		return () => window.removeEventListener("scroll", handleScroll, true);
	}, [isOpen]);

	return (
		<div className={`inline-block text-left ${width}`}>
			<button
				ref={buttonRef}
				type="button"
				onClick={handleToggle}
				className="bg-creamWhite border min-w-fit flex justify-between gap-2 px-4 w-full items-center hover:bg-cardTextGray hover:bg-opacity-10 text-cardTextGray py-2 rounded-lg focus:outline-none duration-200 ease-in-out"
				aria-expanded={isOpen}
				aria-haspopup="true"
			>
				<h6 className="min-w-fit text-nowrap">{buttonText}</h6>
				<IoIosArrowDown
					size={20}
					className={`font-bold min-w-fit transform transition-transform text-cardTextGray duration-200 ${
						isOpen ? "rotate-180" : "rotate-0"
					}`}
				/>
			</button>

			{isOpen &&
				createPortal(
					<div
						ref={dropdownRef}
						className="fixed min-w-fit max-h-96 bg-white overflow-hidden border border-borderCol rounded-lg shadow-lg z-50"
						style={{
							top: `${dropdownPosition.top}px`,
							left: `${dropdownPosition.left}px`,
							width: `${dropdownPosition.width}px`,
						}}
					>
						{options.map((option, index) => (
							<button
								key={index}
								onClick={() => handleSelect(option)}
								className="flex items-center w-fit min-w-full px-4 py-2 text-nowrap text-cardTextGray hover:bg-gray-100 text-left focus:outline-none relative"
								aria-checked={checkedState[option.value]}
							>
								<div className="relative flex items-center">
									<input
										type="checkbox"
										checked={checkedState[option.value]}
										onChange={() => handleSelect(option)}
										className="absolute opacity-0 w-0 h-0"
									/>
									<div
										className={`w-4 h-4 border rounded flex items-center justify-center relative ${
											checkedState[option.value] ? "border-green-600" : "border-gray-300 bg-white"
										}`}
									>
										{checkedState[option.value] && (
											<svg
												className="w-4 h-4 text-green-600 absolute"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
											</svg>
										)}
									</div>
									<span className="ml-2">{option.label}</span>
								</div>
							</button>
						))}
					</div>,
					document.body
				)}
		</div>
	);
};

export default MultiselectDropdown;

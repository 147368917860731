import AdminLayout from "./pages/admin/AdminLayout";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import Generators from "./pages/admin/generator-management/components/generators/Generators";
import Reports from "./pages/admin/generator-management/components/reports/Reports";
import GeneratorDetails from "./pages/admin/generator-management/components/generators/components/detail-forms/GeneratorDetails";
import Audit from "./pages/admin/audit/Audit";
import Routes from "./pages/admin/assets/components/routes/index";
import Vehicles from "./pages/admin/vehicles/Vehicles";
import Settings from "./pages/admin/settings/Settings";
import Test from "./Test";
import Auth from "./pages/auth/Auth";
import WasteManagement from "./pages/admin/wastes/WasteManagement";
import Billing from "./pages/admin/billings/Billing";
import Users from "./pages/admin/users/Users";
import NotFound from "./components/NotFound";
import ServiceReporting from "./pages/admin/generator-management/components/reports/ServiceReporting";
import BoxLocation from "./pages/admin/generator-management/components/reports/BoxLocation";
import WeighingContainer from "./pages/admin/generator-management/components/reports/WeighingContainer";
import Gate_Waste_Finder from "./pages/admin/generator-management/components/reports/Gate_Waste_Finder";
import ServiceTicket from "./pages/admin/generator-management/components/reports/ServiceTicket";
import Assets from "./pages/admin/assets/Assets";
import DriverManagement from "./pages/admin/assets/components/drivers/DriverManagement";
import AddDriverForm from "./pages/admin/assets/components/drivers/components/AddDriver";
import BoxLocations from "./pages/admin/wastes/BoxLocations";
import WeighingInterface from "./pages/admin/wastes/WeighingInterface";
import WasteFinder from "./pages/admin/wastes/WasteFinder";
import ManageWastes from "./pages/admin/wastes/ManageWastes";
import TransferReports from "./pages/admin/generator-management/components/reports/TransferReports";
import OctoMarket from "./pages/octo-market/OctoMarket";

import AddNewVendor from "./pages/admin/assets/components/vendors/AddNewVendor";
import VendorFacilites from "./pages/admin/assets/components/vendors/VendorFacilites";

import AddTreatment from "./pages/admin/assets/components/treatment-facilities/AddTreatment";
import TreatmentFacilities from "./pages/admin/assets/components/treatment-facilities/TreatmentFacilities";

import AddTransporter from "./pages/transporters/AddTransporter";
import TransportersMng from "./pages/transporters/TransportersMng";

import Subcontractor from "./pages/admin/octo-connect/Subcontractor";
import Subcontractor_Finder from "./pages/admin/octo-connect/Subcontractor_Finder";
import LinkedGenerators from "./pages/admin/octo-connect/LinkedGenerators";
import Match_Request from "./pages/admin/octo-connect/Match_Request";
import ContractorManagement from "./pages/admin/octo-connect/ContractorManagement";
import Subcontractor_Management from "./pages/admin/octo-connect/Subcontractor_Management";
import Subcontractor_Schedules from "./pages/admin/octo-connect/Subcontractor_Schedules";
import AddRouteForm from "./pages/admin/assets/components/routes/components/AddRouteForm";
import ServiceTickets from "./pages/admin/wastes/ServiceTickets";
import ServiceLocationList from "./pages/admin/octo-connect/ServiceLocationList";
import ProfileCard from "./pages/octo-market/components/ProfileDetail";
import Index from "./pages/octo-market/Index";
import ProfileSettings from "./pages/octo-market/components/profile/Profile";
import ServiceVehicleList from "./pages/admin/assets/components/service-vehicle/ServiceVehicleList";
import AddNewServiceVehicle from "./pages/admin/assets/components/service-vehicle/AddNewServiceVehicle";
import Connections from "./pages/octo-market/components/connections/Connections";
import AddSatellite from "./pages/admin/assets/components/satellite/AddSatellite";
import SatelliteMng from "./pages/admin/assets/components/satellite/SatelliteMng";
import OctoAdminLanding from "./pages/octo-market-admin/OctoAdminLanding";
import Dashboard, { UsersList } from "./pages/octo-market-admin/Dashboard";
import ApproveDetail from "./pages/octo-market-admin/ApproveDetail";
import RoutesOptimization from "./pages/admin/assets/components/routes-optimization/components/RoutesOptimization";
import TransporterAuth from "./pages/transporters/Auth";
import ProtectedRoute from "./pages/transporters/auth/ProtectedRoute";
import LogIn from "./pages/auth/LogIn";
import ProtectedRoute1 from "./pages/auth/ProtectedRoute1";
import GeneratorPortalAuth from "./pages/generator-portal/auth/GeneratorPortalAuth";
import GeneratorLayout from "./pages/generator-portal/GeneratorLayout";
import GeneratorServiceReporting from "./pages/generator-portal/generatorServiceReporting/GeneratorServiceReporting";
import GeneratorInvoiceAndHistory from "./pages/generator-portal/generatorInvoiceAndHistory/GeneratorInvoiceAndHistory";
import GeneratorSavedPaymentMethods from "./pages/generator-portal/generatorSavedPaymentMethods/GeneratorSavedPaymentMethods";
import GeneratorMakePayment from "./pages/generator-portal/generatorMakePayment/GeneratorMakePayment";
import ServiceCalender from "./pages/generator-portal/dashboard/components/ServiceCalender/ServiceCalender";
import Profile from "./pages/admin/Profile";
import TodaysReports from "./pages/reporting/TodaysReports";

import SmartManifest from "./template/SmartManifest";
import RouteSheet from "./template/RouteSheet"

function App() {
	const navigate = useNavigate();
	return (
		<div className="h-screen w-full flex flex-col gap-4 justify-center items-center">
			<button
				className="py-2 px-4 w-full max-w-sm bg-cardTextBlue text-white rounded-md"
				onClick={() => navigate("/log-in")}
			>
				Go to admin dashboard
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/auth")}
			>
				Sign Up/Log In
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/transporter-auth")}
			>
				Transporter
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/market")}
			>
				OCTO MARKET
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/market-admin")}
			>
				OCTO MARKET ADMIN DASHBOARD
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/generator-portal-auth")}
			>
				Generator Portal Login
			</button>
		</div>
	);
}

export const AppRouter = createBrowserRouter([
	{
		path: "/",
		element: <App />,

	},
	{
		path: "/test",
		element: <Test />,
	},
	{
		path: "/auth",
		element: <Auth />,
	},
	{
		path: "/generator-portal-auth",
		element: <GeneratorPortalAuth />,
	},
	{
		path: "/generator-dashboard",
		element: <GeneratorLayout />,
		children: [
			{
				path: "service-reports",
				element: <GeneratorServiceReporting />,
			},
			{
				path: "invoices-and-payments",
				element: <GeneratorInvoiceAndHistory />,
			},
			{
				path: "saved-payment-methods",
				element: <GeneratorSavedPaymentMethods />,
			},
			{
				path: "make-payment",
				element: <GeneratorMakePayment />,
			},
			{
				path: "service-calendar",
				element: <ServiceCalender />,
			},
		],
	},
	{
		path: "/transporter",
		element: <AddTransporter />,
		children: [
			{
				path: "add-transporter",
				element: <AddTransporter />,
			},
			{
				path: "add-transporter/:id/edit",
				element: <AddTransporter />,
			},
		],
	},
	{
		path: "transporter",
		element: (
			<ProtectedRoute>
				<AddTransporter />
			</ProtectedRoute>
		),
	},

	{
		path: "/transporter-auth",
		element: <TransporterAuth />,
	},
	// {
	// 	path: "transporters",
	// 	element: <TransportersMng />,
	// },
	{
		path: "/log-in",
		element: <LogIn />,
	},
	{
		path: "/docs",
		element: <RouteSheet />
	},
	{
		path: "/admin",
		element: (
			<ProtectedRoute1>
				<AdminLayout />
			</ProtectedRoute1>
		),
		children: [
			{
				path: "profile",
				element: <Profile />
			},
			{
				path: "wastes",
				// element: <WasteManagement />,
				children: [
					{
						index: "",
						element: <ManageWastes />,
					},
					{
						path: "manage",
						element: <WasteManagement />,
					},
					{
						path: "wastefinder",
						element: <WasteFinder />,
					},
					{
						path: "boxlocations",
						element: <BoxLocations />,
					},
					{
						path: "weights",
						element: <WeighingInterface />,
					},
					{
						path: "service-ticket",
						element: <ServiceTickets />,
					},
				],
			},
			{
				path: "generators",
				children: [
					{
						index: true,
						element: <Generators />,
					},
					{
						path: "map",
						element: <Generators viewOption="map" />,
					},
					{
						path: "list",
						element: <Generators viewOption="list" />,
					},
					{
						path: "reports",
						children: [
							{
								index: true,
								element: <Reports />,
							},
							{
								path: "service-reporting",
								index: true,
								element: <ServiceReporting />,
							},
							{
								path: "transfer-reports",
								index: true,
								element: <TransferReports />,
							},
							{
								path: "boxlocation",
								index: true,
								element: <BoxLocation />,
							},
							{
								path: "weighingcontainer",
								index: true,
								element: <WeighingContainer />,
							},
							{
								path: "gate-waste-finder",
								index: true,
								element: <Gate_Waste_Finder />,
							},
							{
								path: "serviceticket",
								element: <ServiceTicket />,
							},
						],
					},
					{
						path: "new",
						element: <GeneratorDetails />,
					},
					{
						path: "new/generator-profile",
						element: <GeneratorDetails />,
					},
					{
						path: "new/generator-contacts",
						element: <GeneratorDetails />,
					},
					{
						path: "new/service-notes",
						element: <GeneratorDetails />,
					},
					{
						path: "new/routes",
						element: <GeneratorDetails />,
					},
					{
						path: ":id",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/generator-profile",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/generator-contacts",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/service-notes",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/routes",
						element: <GeneratorDetails />,
					},
				],
			},
			{
				path: "assets",
				element: <Assets />,
				children: [
					{
						path: "drivers",
						children: [
							{
								path: "",
								element: <DriverManagement />,
							},
							{
								path: "add",
								element: <AddDriverForm mode="add" />,
							},
							{
								path: ":id/edit",
								element: <AddDriverForm mode="edit" />,
							},
						],
					},
					{
						path: "routes-optimization",
						children: [
							{
								path: "",
								element: <RoutesOptimization />,
							},
						],
					},
					{
						path: "routes",
						children: [
							{
								path: "",
								element: <Routes />,
							},
							{
								path: "add",
								element: <AddRouteForm />,
							},
							{
								path: ":id/edit",
								element: <AddRouteForm />,
							},
						],
					},
					{
						path: "service-vehicles",
						children: [
							{
								path: "",
								element: <ServiceVehicleList />,
							},
							{
								path: "add",
								element: <AddNewServiceVehicle />,
							},
							{
								path: ":id/edit",
								element: <AddNewServiceVehicle />,
							},
						],
					},
					{
						path: "satellite",
						element: <AddSatellite />,
						children: [
							{
								path: "add-satellite",
								element: <AddSatellite />,
							},
							{
								path: "add-satellite/:id/edit",
								element: <AddSatellite />,
							},
						],
					},

					{
						path: "vendors",
						element: <AddNewVendor />,
						children: [
							{
								path: "add-vendors",
								element: <AddNewVendor />,
							},
							{
								path: "add-vendors/:id/edit",
								element: <AddNewVendor />,
							},
						],
					},

					{
						path: "manage-vendor",
						element: <VendorFacilites />,
					},
					{
						path: "treatment",
						element: <AddTreatment />,
						children: [
							{
								path: "add-treatment",
								element: <AddTreatment />,
							},
							{
								path: "add-treatment/:id/edit",
								element: <AddTreatment />,
							},
						],
					},

					{
						path: "treatments-facilities",
						element: <TreatmentFacilities />,
					},

					{
						path: "satellite-location",
						element: <SatelliteMng />,
					},
				],
			},

			{
				path: "octo-connect",
				element: <Subcontractor />,
				children: [
					{
						path: "contractor-list",
						element: <Subcontractor_Finder />,
					},

					{
						path: "subcontractor-management",
						element: <Subcontractor_Management />,
					},
					{
						path: "service-locations",
						element: <ServiceLocationList />,
					},
					{
						path: "contractor-management",
						element: <ContractorManagement />,
					},
					{
						path: "match-request",
						element: <Match_Request />,
					},
					{
						path: "linked-generators",
						element: <LinkedGenerators />,
					},
					{
						path: "subcontractor-schedules",
						element: <Subcontractor_Schedules />,
					},
				],
			},

			{
				path: "audit",
				element: <Audit />,
			},
			{
				path: "routes",
				element: <Routes />,
			},
			{
				path: "settings",
				element: <Settings />,
			},
			{
				path: "vehicles",
				element: <Vehicles />,
			},
			{
				path: "billings",
				element: <Billing />,
			},
			{
				path: "users",
				element: <Users />,
			},
		],
	},
	{
		path: "market-admin",
		element: <OctoAdminLanding />,
		children: [
			{
				path: "users",
				children: [
					{
						index: true,
						element: <UsersList />,
					},
					{
						path: ":id",
						element: <ApproveDetail />,
					},
				],
			},
			{
				path: "dashboard",
				element: <Dashboard />,
			},
		],
	},
	{
		path: "market",
		element: <Index />,
		children: [
			{
				index: true,
				element: <OctoMarket />,
			},
			{
				path: "profile",
				element: <ProfileSettings />,
			},
			{
				path: "connections",
				element: <Connections />,
			},
			{
				path: ":id",
				element: <ProfileCard />,
			},
		],
	},
	{
		path: "*",
		element: <NotFound />,
	},
]);

export default App;

// import AdminLayout from "./pages/AdminLayout";
// import Dashboard from "./pages/Dashboard/Dashboard";
// import Documents from "./pages/Documents/Documents.jsx";
// import ServiceReporting from "./pages/ServiceReporting/ServiceReporting";
// import Billing from "./pages/Billing/Billing.jsx";
// import GroupAdministrator from "./pages/GroupAdministrator/GroupAdministrator";
// import Communication from "./pages/Communication/Communication";
// import NotFound from "./components/NotFound";

import React, { useState, useEffect } from "react";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Textarea from "../../../../../components/UI/Textarea";
import { useGeneratorUser } from "../../../../../context/GeneratorUserContext";
import { showErrorToastMessage, showSuccessToastMessage } from "../../../../../utils/helpers";
import Button from "../../../../../components/UI/Button";
import { emailRegex } from "../../../../../utils/mimes";
import Input from "../../../../../components/UI/Input";

const ContactUsForm = () => {
	const { user, authLoading, generatorData, allServices } = useGeneratorUser();
	const [contactInfo, setContactInfo] = useState({
		locationName: "San Diego Medical Waste Services HQ",
		octoNumber: "OCTO-12345",
		yourName: "John Doe",
		email: "john.doe@example.com",
		phone: "+1 619-555-1234",
	});
	console.log({ user });
	const [emailDisabled, setEmailDisabled] = useState(true);
	const [phoneDisabled, setPhoneDisabled] = useState(true);
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState(user?.emailAdd ?? "");
	const [phone, setPhone] = useState(user?.phoneNumbers[0] ?? "");

	// Fetch contact data from the API
	useEffect(() => {
		const fetchContactData = async () => {
			try {
				const response = await fetch("/api/contact-info"); // Replace with your actual endpoint
				if (response.ok) {
					const data = await response.json();
					setContactInfo(data);
				} else {
					console.error("Failed to fetch contact information");
				}
			} catch (error) {
				console.error("Error fetching contact information:", error);
			}
		};
		fetchContactData();
	}, []);

	const handleSubmit = async (e) => {
		if (!email.length) {
			showErrorToastMessage("Email cannot be empty.");
			return;
		}
		if (!emailRegex.test(email)) {
			showErrorToastMessage("Invalid email.");
			return;
		}
		if (!phone.length) {
			showErrorToastMessage("Phone cannot be empty.");
			return;
		}
		if (!subject.length) {
			showErrorToastMessage("Subject cannot be empty.");
			return;
		}
		if (!message.length) {
			showErrorToastMessage("Message cannot be empty.");
			return;
		}

		showSuccessToastMessage("Message sent successfully.");
		setMessage("");
		setSubject("");
		setEmail(user?.emailAdd ?? "");
		setPhone(user?.phoneNumbers[0] ?? "");
	};

	return (
		<div className="grid gap-2">
			<h2 className="text-2xl font-bold ">Contact Us</h2>
			<div className="grid grid-cols-1 gap-5 bg-white rounded-xl shadow-md p-5">
				<div>
					<p className="text-gray-700 font-medium">
						San Diego Medical Waste Services, LLC 8940 Activity Rd. Suite H, San Diego, CA 92126
					</p>
					<p className="text-gray-700 font-medium">Email: info@medicalwastecompany.com</p>
					<p className="text-gray-700 font-medium">Phone: 619-990-4604</p>
				</div>

				<div className="grid grid-cols-4 ">
					<p className="text-gray-700 font-medium block ">Location Name</p>
					<p className="col-span-3 px-4 ">{generatorData.generatorName || "Loading..."}</p>
				</div>

				<div className="grid grid-cols-4">
					<p className="text-gray-700 font-medium block ">OCTO Number</p>
					<p className="col-span-3 px-4">{generatorData.octoNumber || "Loading..."}</p>
				</div>

				<div className="grid grid-cols-4">
					<p className="text-gray-700 font-medium block ">Your Name</p>
					<p className="col-span-3 px-4">{user.name || "Loading..."}</p>
				</div>

				<div className="grid grid-cols-4 items-center">
					<p className="text-gray-700 font-medium block ">Email</p>
					<Input
						value={email}
						style="flex-col"
						onChange={(e) => {
							setEmail(e.currentTarget.value);
						}}
						isDisabled={emailDisabled}
					/>
					<div className="px-4 text-primary self-end">
						<p
							className="cursor-pointer max-w-fit"
							onClick={() => {
								setEmailDisabled((prev) => !prev);
							}}
						>
							{emailDisabled ? "(Edit)" : "(Save)"}
						</p>
					</div>
				</div>

				<div className="grid grid-cols-4 items-center">
					<p className="text-gray-700 font-medium block">Phone</p>
					<Input
						value={phone}
						style="flex-col"
						onChange={(e) => {
							setPhone(e.currentTarget.value);
						}}
						isDisabled={phoneDisabled}
					/>
					<div className="px-4 text-primary self-end">
						<p
							className="cursor-pointer max-w-fit"
							onClick={() => {
								setPhoneDisabled((prev) => !prev);
							}}
						>
							{phoneDisabled ? "(Edit)" : "(Save)"}
						</p>
					</div>
				</div>

				{/* Subject Dropdown */}
				<div className="grid grid-cols-4 items-center">
					<p className="text-gray-700 font-medium block ">Subject</p>
					<div className="">
						<Dropdown
							options={[
								{ label: "General Billing Request", value: "GENERAL_BILLING_REQUEST" },
								{ label: "General Service Request", value: "GENERAL_SERVICE_REQUEST" },
								{ label: "Re-supply Request", value: "RE_SUPPLY_REQUEST" },
							]}
							value={subject}
							onChange={setSubject}
							styles="flex-col min-w-full"
						/>
					</div>
				</div>

				{/* Message Textarea */}
				<div className="grid grid-cols-4">
					<p className="text-gray-700 font-medium block mb-2 w-[30%]">Message</p>
					<textarea
						className="textarea-ghost textarea bg-inputBg rounded-xl"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder="Enter your message here"
					/>
				</div>

				{/* Submit Button */}
				<div className="flex w-full items-end justify-end px-12 py-6">
					<Button btnStyle="form_nav_primary" text={"Send Message"} type="button" onClick={handleSubmit} />
				</div>
			</div>
		</div>
	);
};

export default ContactUsForm;

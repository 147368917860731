import React from "react";

const Lock = ({ className = "" }) => {
	return (
		<svg
			width="10"
			height="10"
			className={className}
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.832031 5.00033C0.832031 2.69914 2.69751 0.833658 4.9987 0.833658C7.29988 0.833658 9.16537 2.69914 9.16537 5.00033C9.16537 7.30151 7.29988 9.16699 4.9987 9.16699C2.69751 9.16699 0.832031 7.30151 0.832031 5.00033ZM5.4987 5.50033V5.00033L5.4987 2.50033V2.00033H4.4987V2.50033L4.4987 5.00033V5.50033H5.4987ZM4.4987 7.08366V6.58366H5.4987V7.08366V7.50033V8.00033H4.4987V7.50033V7.08366Z"
			/>
		</svg>
	);
};

export default Lock;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";

const BoxLocation = () => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const navigate = useNavigate();

	// Dummy data
	// const dummyData = Array.from({ length: 3 }, (_, index) => {
	// 	const date = new Date(2024, 8, index + 1);
	// 	return {
	// 		id: index + 1,
	// 		date: date.toLocaleDateString(),
	// 		time: date.toLocaleTimeString(),
	// 		Customer: `${index + 1} Customer Name`,
	// 		address: `${index + 1} Address details details`,
	// 	};
	// });

	const [data, setData] = useState([]);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("WeighingContainer");
	};

	const handlePreviousClick = () => {
		console.log("Previous button clicked");
		navigate(-1);
	};

	return (
		<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-auto mb-8">
			<div>
				<div className="ml-6 mt-4">
					<div className="font-semibold text-2xl">Generator Name:</div>
					<div>OCTO Number:</div>
					<div>Customer Since:</div>
					<div>Service Address:</div>
				</div>
				<div className="border-b border-gray-500 mt-4"></div>
				{/* Map Section */}
				<div className="w-full mt-4">
					<h6 className="font-medium py-2 mx-6 text-lg">View Map</h6>
					<div className="rounded-3xl overflow-hidden pr-6 pl-6" style={{ height: "400px", width: "100%" }}>
						<MapWithMarkers
							geocodedMarkers={[]}
							initialCenter={{ lat: 32.7157, lng: -117.1611 }}
							isAddressesLoaded={true}
						/>
					</div>
				</div>
				<div className="">
					<div className="text-lg font-normal ml-10 my-4 text-black">Current Location Report</div>
					<div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
						<div className="w-full md:w-1/3">Date and Time</div>
						<div className="w-full md:w-1/3">Box</div>
						<div className="w-full md:w-1/3">Generator</div>
					</div>
				</div>

				<div
					className={`second-child overflow-y-scroll ${isScrollable ? "shadow-inner" : ""}`}
					onScroll={(e) => setIsScrollable(e.target.scrollTop > 0)}
					style={{ maxHeight: "300px" }} // Set a max height for scrolling
				>
					{data.length == 0 ? (
						<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
							<p>No result found</p>
						</div>
					) : (
						data.map((item, index) => (
							<div
								key={index}
								className={`flex flex-col md:flex-row items-start py-2 border-b pl-8 border-gray-300 ${
									hoveredRow === index ? "bg-gray-200" : ""
								}`}
								onMouseEnter={() => setHoveredRow(index)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ minHeight: "80px" }}
							>
								<div className="w-full md:w-1/3 text-sm font-medium">
									<div>{item.date}</div>
									<div className="text-sm font-medium">{item.time}</div>
								</div>
								<div className="w-full md:w-1/3 text-sm font-medium pl-2 pt-4">{item.boxName ?? "--"}</div>
								<div className="w-full md:w-1/3 text-sm font-medium">
									<div className="text-sm font-medium">{item.Customer}</div>
									<div>
										<span className="font-medium">Account:</span> {item.account ?? "--"}
									</div>
								</div>
							</div>
						))
					)}
				</div>
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 pb-16 mt-12 ">
				<button
					onClick={() => navigate("/admin/generators/reports/transfer-reports")}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={() => navigate("/admin/generators/reports/WeighingContainer")}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default BoxLocation;

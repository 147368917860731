import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { PiCalendarDotsLight } from "react-icons/pi";
import { dateFormatter, formattedDate } from "../../utils/helpers";
import DatePicker from "react-multi-date-picker";
const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
import "react-multi-date-picker/styles/colors/green.css";
const CustomMultiDateRangePicker = ({ value, onChange }) => {
	function CustomMultipleInput({ onFocus, value, openCalendar, separator }) {
		console.log(value);

		value = value.split("&")[0];
		let values = value.split(separator);
		let from = values[0] || "";
		let to = values[1] || "";
		if (from) from = new Date(from);
		if (to) to = new Date(to);
		
		value = from && to ? formattedDate(from) + " to " + formattedDate(to) : from.length > 0 ? formattedDate(from) : "";
		return (
			<div
				onFocus={onFocus}
				className="bg-inputBg rounded-full  overflow-ellipsis  text-cardTextGray py-2 px-4 h-9 justify-between gap-4 flex items-center ${borderClass} cursor-pointer max-w-60"
				onClick={openCalendar}
			>
				<span className="text-cardTextGray whitespace-nowrap">
					{value.length > 20 ? value.slice(0, 20) + "..." : value}
				</span>
				<PiCalendarDotsLight
					className={`text-cardTextGray ${"hover:text-blueText hover:cursor-pointer"} min-w-5`}
					size={20}
				/>
			</div>
		);
	}

	const CustomHeader = ({
		date,
		changeYear,
		changeMonth,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled,
	}) => {
		const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		// Ensure date is a valid Date object
		const currentDate = date instanceof Date ? date : new Date();

		return (
			<div className="custom-header flex items-center justify-between p-2">
				<button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="nav-button p-1">
					<ChevronLeft size={18} />
				</button>
				<div className="flex space-x-2">
					<select
						value={currentDate.getFullYear()}
						onChange={({ target: { value } }) => changeYear(Number(value))}
						className="bg-white text-gray-700 px-2 py-1 rounded"
					>
						{years.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</select>
					<select
						value={months[currentDate.getMonth()]}
						onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
						className="bg-white text-gray-700 px-2 py-1 rounded"
					>
						{months.map((month) => (
							<option key={month} value={month}>
								{month}
							</option>
						))}
					</select>
				</div>
				<button
					type="button"
					onClick={increaseMonth}
					disabled={nextMonthButtonDisabled}
					className="nav-button p-1"
				></button>
			</div>
		);
	};

	return (
		<DatePicker
			value={value}
			onChange={onChange}
			className="custom-multi-date-range-picker"
			multiple
			range
			dateSeparator=" to "
			multipleRangeSeparator="&"
			editable={false}
			inputMode="none"
			render={<CustomMultipleInput />}
			renderButton={(direction, handleClick) => (
				<button onClick={handleClick} className="px-2">
					{direction === "right" ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
				</button>
			)}
			weekDays={weekDays}
			// plugins={[<MyPlugin position="top" />]}
		/>
	);
};

export default CustomMultiDateRangePicker;

import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import PropTypes from "prop-types";
import { createContext, useState, useContext, useEffect } from "react";
import { COLLECTIONS, db } from "../config/firebase";
import { showErrorToastMessage, showInternalServerErrorToastMessage, showSuccessToastMessage } from "../utils/helpers";

const GeneratorUserContext = createContext({
	user: null,
	authLoading: true,
	generatorData: null,
	allServices: [],
	login: () => {},
	logout: () => {},
});
const GENERATOR_USER = "GENERATOR_USER";
export const GeneratorUserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [authLoading, setAuthLoading] = useState(true);
	const [generatorData, setGeneratorData] = useState(null);
	const [allServices, setAllServices] = useState([]);
	useEffect(() => {
		const tempUserJson = localStorage.getItem(GENERATOR_USER);
		const tempUser = JSON.parse(tempUserJson);
		if (tempUser) {
			setUser(tempUser);
		}
	}, []);
	useEffect(() => {
		if (user && generatorData) {
			setAuthLoading(false);
		}
	}, [authLoading, user, generatorData]);

	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.generators, user?.generatorId), (snap) => {
			if (snap.exists()) {
				setGeneratorData({ ...snap.data(), id: snap.id });
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);

	useEffect(() => {
		if (!generatorData) return;
		const unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.scheduledServices),
				where("generatorId", "==", generatorData.id),
				orderBy("date", "asc")
			),
			async (snap) => {
				const tempServices = [];
				const jobs = snap.docs.map(async (el) => {
					if (el.exists()) {
						const serviceData = el.data();
						const scheduleRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, serviceData.serviceScheduleId));
						if (scheduleRes.exists()) {
							serviceData.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
						}
						tempServices.push({ ...serviceData, id: el.id });
					}
				});
				await Promise.all(jobs);
				setAllServices(tempServices);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [generatorData]);

	const login = async (email, password) => {
		try {
			setAuthLoading(true);
			const res = await getDocs(query(collection(db, COLLECTIONS.generatorContacts), where("emailAdd", "==", email)));
			if (!res.docs.length || !res.docs[0].exists()) {
				showErrorToastMessage("Invalid Email or Password");
				return;
			}
			const data = res.docs[0].data();

			if (data.password !== password) {
				showErrorToastMessage("Invalid Email or Password");
				return;
			}

			setUser(data);
			localStorage.setItem(GENERATOR_USER, JSON.stringify(data));
			showSuccessToastMessage("Login Successful...");
		} catch (error) {
			console.log({ error });
			showInternalServerErrorToastMessage();
		} finally {
			setAuthLoading(false);
		}
	};
	const logout = () => {
		setUser(null);
		localStorage.removeItem(GENERATOR_USER);
	};
	return (
		<GeneratorUserContext.Provider value={{ login, logout, user, authLoading, generatorData, allServices }}>
			{children}
		</GeneratorUserContext.Provider>
	);
};

GeneratorUserProvider.propTypes = {
	children: PropTypes.node,
};

export const useGeneratorUser = () => {
	return useContext(GeneratorUserContext);
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../../../utils/constants";
import DropdownReport from "./ReportDropDown";
import {
	dateFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../../../utils/helpers";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { FiSearch } from "react-icons/fi";

// // Sample transfer data
// const transferData = [
// 	{
// 		serviceDate: "2024-01-05",
// 		octoNumber: "123456",
// 		service: "Medical Waste Collection",
// 		serviceType: "Medical Waste",
// 		route: "SD Monday",
// 		serviceVehicle: "012345",
// 		driver: "Sammy",
// 		transporterId: "123456",
// 		documentId: "1234567899",
// 		transferStatus: "On Truck TS/TF",
// 		serviceSummary: {
// 			documentId: "1234567899",
// 			subwasteType: "Medical Waste Type A",
// 			container: "30 Gallon Container",
// 			quantity: "2 units",
// 			weight: "150 lbs",
// 		},
// 		deliverySummary: {
// 			documentId: "1234567899",
// 			description: {
// 				line1: "Standard Medical Waste",
// 				line2: "Category B",
// 			},
// 			usage: "Hospital Waste",
// 			quantity: "2 containers",
// 		},
// 		options: {
// 			finalTreatment: true,
// 			date: "2024-01-05",
// 			billingNote: "Invoiced Through External Billing Platform",
// 		},
// 		additional: {
// 			picture: "Picture",
// 			pictureId: "PIC123",
// 			driverNote: "Pickup completed as scheduled",
// 			contractor: "MedWaste Inc",
// 			subcontractor: "Local Transport LLC",
// 		},
// 	},
// 	// Add more sample data as needed
// ];

const ServiceCard = ({ service, index, selectedServices, handleCheckboxChange }) => {
	return (
		<div className={`py-2 font-normal pb-8 border-b ${selectedServices.includes(index) ? "bg-bgDefaultColor" : ""}`}>
			<div className="flex items-center pt-2 pb-6">
				<div className="min-w-8 mx-8 flex justify-center hover:cursor-pointer">
					<input
						type="checkbox"
						className="w-4 h-4 bg-white"
						checked={selectedServices.includes(index)}
						onChange={() => handleCheckboxChange(index)}
					/>
				</div>
				<div className="grid gap-2">
					<div className="flex items-center">
						<div className="truncate w-40">{service.serviceDate}</div>
						<div className="truncate w-40 text-thatblue">{service.transferId ?? "--"}</div>
						<div className="truncate w-40">{service.serviceType}</div>
						<div className="truncate w-40 text-thatblue">{service.route}</div>
						<div className="truncate w-40 text-thatblue">{service.serviceVehicle}</div>
						<div className="truncate w-52 text-thatblue">{service.driver}</div>
						<div className="truncate w-60">{service.additional.contractor}</div>
						<div className="truncate w-40">{service.transporterId}</div>
						<div className="truncate w-60">{service.transferStatus}</div>
					</div>
				</div>
			</div>
			<div className="flex w-full overflow-hidden gap-6 border-dashInActiveBtnText px-6 pl-10">
				<div className="w-[45%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
					<div className="bg-[#DADADA] h-10 py-2 items-center justify-center">
						<h6 className="font-medium text-center">Transfer Summary</h6>
					</div>
					<div>
						<div className="grid grid-cols-3 text-center py-2 font-medium">
							<p>Manifest</p>
							<p>Container</p>
							<p>Weight</p>
						</div>
						<div className="grid grid-cols-3 text-center">
							<p>{service.serviceSummary.documentId}</p>
							<p>{service.serviceSummary.container}</p>
							<p>{service.serviceSummary.weight}</p>
						</div>
					</div>
				</div>
				<div className="w-[30%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
					<div className="bg-[#DADADA] h-10 py-2 grid items-center justify-center">
						<h6 className="font-medium text-center">Manifests</h6>
					</div>
					<div>
						<div className="grid grid-cols-1 text-center py-1">
							<p>View All Associated Manifests</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col overflow-hidden justify-between w-[25%] border border-dashInActiveBtnText rounded-lg">
					<div className="bg-[#DADADA] h-10 py-2 grid items-center justify-center">
						<h6 className="font-medium text-center">Options</h6>
					</div>
					<div className="h-20 flex flex-col justify-between">
						<div className="grid grid-cols-3 text-center py-2 font-medium">
							<p className="text-nowrap">Final Treatment</p>
							<div className="flex justify-center items-center">
								<input
									type="checkbox"
									className="w-4 h-4 bg-white"
									checked={service.options.finalTreatment}
									onChange={() => handleCheckboxChange(index)}
								/>
							</div>
							<p>{service.options.date}</p>
						</div>
						<div className="w-full flex py-1 items-center justify-center border-t border-[#DADADA] bg-primaryGreen">
							<p>Log Final Treatment</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const TransferReports = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [from, setFrom] = useState(new Date("12-01-2024"));
	const [to, setTo] = useState(new Date("01-01-2025"));
	const [selectedServices, setSelectedServices] = useState([]);
	const [pickupOptions, setPickupOptions] = useState([]);
	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [driverOptions, setDriverOptions] = useState([]);
	const [treatFaciOptions, setTreatFaciOptions] = useState([]);
	const [transStatusOptions, setTransStatusOptions] = useState([]);
	const [transferData, setTransferData] = useState([]);
	const navigate = useNavigate();

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		if (newSelectAll) {
			setSelectedServices(transferData.map((_, i) => i));
		} else {
			setSelectedServices([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedServices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
	};

	const handlePreviousButton = () => {
		// Handle previous button click
	};

	return (
		<div className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii">
			<div className="flex-grow overflow-y-auto">
				<div className="text-lg font-medium px-8 py-4 text-black">Transfer History Report</div>
				<div className="px-8 flex items-center justify-between">
					<div className="flex items-center bg-white rounded-full p-3 px-4 border border-gray-200 w-full max-w-[50%]">
						<FiSearch className="text-cardTextGray opacity-50" size={20} />
						<input
							type="text"
							placeholder="Search by Name, OCTO Number, Phone, Email, Address, Documents "
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="pl-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
						/>
					</div>
					<button className="text-primary hover:text-blue-900">Clear Filter</button>
				</div>
				<div className="w-11/12 flex px-8 py-5">
					<div className="flex flex-wrap gap-5 gap-y-3 items-end">
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">From</p>
							<CustomDatePicker
								selectedDate={from}
								setSelectedDate={(value) => setFrom(new Date(value))}
								label="From Date"
							/>
						</div>
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">To</p>
							<CustomDatePicker selectedDate={to} setSelectedDate={(value) => setTo(new Date(value))} label="To Date" />
						</div>
						{/* <MultiselectDropdown
							width="w-48"
							buttonText="Pickup"
							options={[]}
							selectedOptions={pickupOptions}
							onSelectedOptionsChange={(values) => setPickupOptions(values)}
						/> */}
						<MultiselectDropdown
							width="w-48"
							buttonText="Service Type"
							options={[]}
							selectedOptions={serviceTypeOptions}
							onSelectedOptionsChange={(values) => setServiceTypeOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Route"
							options={[]}
							selectedOptions={routeOptions}
							onSelectedOptionsChange={(values) => setRouteOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Driver"
							options={[]}
							selectedOptions={driverOptions}
							onSelectedOptionsChange={(values) => setDriverOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Treatment Facility"
							options={[]}
							selectedOptions={treatFaciOptions}
							onSelectedOptionsChange={(values) => setTreatFaciOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Location"
							options={[]}
							selectedOptions={locationOptions}
							onSelectedOptionsChange={(values) => setLocationOptions(values)}
						/>
					</div>
				</div>
				<div className="grid font-medium w-full overflow-x-scroll py-4">
					<div className="flex bg-[#E5F2FF] py-4">
						<div className="w-8 mx-8 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center">
							<div className="truncate w-40">Transfer Date</div>
							<div className="truncate w-40">Transfer ID</div>
							<div className="truncate w-40">Service Type</div>
							<div className="truncate w-40">Route</div>
							<div className="truncate w-40">Service Vehicle</div>
							<div className="truncate w-52">Driver</div>
							<div className="truncate w-60">Transporter</div>
							<div className="truncate w-40">Transporter ID</div>
							<div className="truncate w-40">Transfer Status</div>
						</div>
					</div>
					<div className="grid">
						{transferData.length == 0 ? (
							<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
								<p>No result found</p>
							</div>
						) : (
							transferData.map((item, index) => (
								<ServiceCard
									key={index}
									service={item}
									index={index}
									selectedServices={selectedServices}
									handleCheckboxChange={handleCheckboxChange}
								/>
							))
						)}
					</div>
					<div className="bg-whiteBlue p-4 px-10 mt-4">
						<div className="flex gap-16">
							<span>
								Totals: Manifests: 0
								{/* {transferData?.reduce((acc, curr) => (curr ? acc + curr?.transferSummary?.manifests?.length : 0), 0)} */}
							</span>
							<span>
								Weight: 0
								{/* {transferData?.reduce(
									(acc, curr) =>
										acc +
										curr.transferSummary?.manifests?.reduce(
											(sum, manifest) => sum + parseInt(manifest?.weight ? manifest?.weight : 0),
											0
										),
									0
								)}{" "}
								lbs */}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={() => navigate("/admin/generators/reports/service-reporting")}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={() => navigate("/admin/generators/reports/boxlocation")}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div>
			{/* <div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={handlePreviousButton}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
			</div> */}
		</div>
	);
};

export default TransferReports;

import RoundedPin from "./RoundedPin";
import {
	convertMetersToMiles,
	convertSecondsToHours,
	daysOfWeek,
	renderAddress,
} from "../../../../../../utils/helpers";
import Lock from "./Lock";
import Priority from "./Priority";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useEffect, useState } from "react";
import { ITEM_TYPE_MAP, itemsMap, SERVICE_STATUS, SERVICE_TYPES } from "../../../../../../utils/constants";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SquarePin from "./SquarePin";
import Loader from "../../../../../../components/UI/loaders/Loader";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

const ServiceCard = ({
	id,
	columnId,
	text,
	index,
	generatorData,
	serviceData,
	scheduleData,
	dailyRouteId,
	allExpanded,
	isLocked,
	updateServiceIsLockedHandler,
	routeColor = "#FFA500",
	nextWaypoint,
	lat,
	lng,
	defaultEndLocation,
}) => {
	const [serviceOpen, setServiceOpen] = useState(true);
	const [priority, setPriority] = useState(serviceData?.isPriority ?? false);
	const [time, setTime] = useState("");
	const [distance, setDistance] = useState("");
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
		disabled: isLocked,
		data: { type: ITEM_TYPE_MAP.SERVICE, dailyRouteId, columnId },
	});
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	useEffect(() => {
		if (!routesLibrary) return;
		setDirectionsService(new routesLibrary.DirectionsService());
	}, [routesLibrary]);

	useEffect(() => {
		if (!directionsService || !lat || !lng) return;
		console.log("Fetching route from google maps");

		directionsService
			.route({
				origin: { lat, lng },
				destination:
					nextWaypoint !== null
						? { lat: nextWaypoint.lat, lng: nextWaypoint.lng }
						: { lat: defaultEndLocation.lat, lng: defaultEndLocation.lng },
				travelMode: "DRIVING",
				provideRouteAlternatives: false,
			})
			.then((response) => {
				if (response.routes[0]?.legs) {
					let calculatedDistance = 0;
					let calculatedTime = 0;
					response.routes[0]?.legs.map((leg) => {
						if (leg?.duration?.value) {
							calculatedTime += leg.duration.value;
						}
						if (leg?.distance?.value) {
							calculatedDistance += leg.distance.value;
						}
					});

					setDistance(calculatedDistance);
					setTime(calculatedTime);
				}
			});
	}, [directionsService, lat, lng, nextWaypoint, nextWaypoint]);
	useEffect(() => {
		setServiceOpen(allExpanded);
	}, [allExpanded]);
	useEffect(() => {
		setServiceOpen(false);
	}, [isDragging]);

	return (
		<div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Transform.toString(transform),
			}}
			className={`text-xs ${isDragging ? "rounded-xl max-h-20 h-20" : " "}`}
		>
			<div className={`${isDragging ? "opacity-0" : "opacity-100"}`}>
				<div className={`text-center ${"bg-logoutBtn"} rounded-xl`}>
					<div className="flex">
						<div className="flex flex-col items-center justify-center">
							{typeof scheduleData?.serviceType === "string" &&
							scheduleData.serviceType === SERVICE_TYPES.MEDICAL_WASTE ? (
								<RoundedPin
									className={`w-10 h-10 flex items-center justify-center relative`}
									color={routeColor}
									text={typeof index !== "undefined" ? index + 1 : ""}
								/>
							) : null}
							{typeof scheduleData?.serviceType === "string" &&
							scheduleData.serviceType === SERVICE_TYPES.PAPER_SHREDDING ? (
								<SquarePin
									className="w-7 h-7 m-1"
									color={routeColor}
									text={typeof index !== "undefined" ? index + 1 : ""}
								/>
							) : null}
							{typeof scheduleData?.serviceType !== "string" &&
							scheduleData?.serviceType?.length &&
							scheduleData.serviceType[0] === SERVICE_TYPES.MEDICAL_WASTE ? (
								<RoundedPin
									className="w-10 h-10 flex items-center justify-center relative"
									color={routeColor}
									text={typeof index !== "undefined" ? index + 1 : ""}
								/>
							) : null}
							{typeof scheduleData?.serviceType === "string" &&
							scheduleData?.serviceType?.length &&
							scheduleData.serviceType[0] === SERVICE_TYPES.PAPER_SHREDDING ? (
								<SquarePin
									className="w-7 h-7 m-1"
									color={routeColor}
									text={typeof index !== "undefined" ? index + 1 : ""}
								/>
							) : null}
							{typeof scheduleData?.serviceType === "undefined" ? (
								<RoundedPin
									className="w-10 h-10 flex items-center justify-center relative"
									color={routeColor}
									text={typeof index !== "undefined" ? index + 1 : ""}
								/>
							) : null}
						</div>
						<div className="text-sm text-left w-full font-semibold">
							<p>{generatorData?.generatorName ?? "N/A"}</p>
							<p>{generatorData?.octoNumber ?? "N/A"}</p>
						</div>
					</div>
					{serviceOpen && (
						<div className="flex p-2 flex-col gap-2 text-left">
							<div>{renderAddress(generatorData.serviceAddress)}</div>
							<div>Service Frequency:{scheduleData?.serviceFrequency?.type ?? "N/A"}</div>
							<div>
								{generatorData?.workingHours[daysOfWeek[new Date(dailyRouteId).getDay()]]?.open ?? "N/A"} -{" "}
								{generatorData?.workingHours[daysOfWeek[new Date(dailyRouteId).getDay()]]?.close ?? "N/A"}
							</div>
							<div>
								<p>Expected Containers</p>
								{scheduleData?.expectedItemOrService?.length
									? scheduleData?.expectedItemOrService.map((el, i) => (
											<p key={i} className="grid grid-cols-2">
												<span>{itemsMap[el.item]}:</span>
												{el.quantity}
											</p>
									  ))
									: ""}
							</div>
							<div>
								<p>DEL: {generatorData?.deliveryNote ?? "N/A"}</p>
								<p>PRK: {generatorData?.parkingNote ?? "N/A"}</p>
								<p>LOC: {generatorData?.locationOfWaste ?? "N/A"}</p>
								<p>Service Notes: {generatorData?.serviceInstructions ?? "N/A"}</p>
								<p>Temp Notes: {serviceData?.temporaryServiceInstruction ?? "N/A"}</p>
							</div>
							<div className="flex">
								<button className="border px-2 py-1 rounded-full text-gray-500 border-gray-500 hover:text-gray-700 hover:border-gray-700">
									Cancel Service
								</button>
							</div>
						</div>
					)}
					<div className="w-full p-2 flex">
						<p className="text-red-500">
							{generatorData?.workingHours[daysOfWeek[new Date(dailyRouteId).getDay()]]?.closed ? "Closed" : ""}
						</p>
						<div className="ml-auto flex gap-1">
							<div
								className="max-w-fit"
								onClick={() => {
									updateServiceIsLockedHandler(!isLocked, id);
								}}
							>
								<Lock className={`w-5 h-5 ${isLocked ? "stroke-primary-500" : "stroke-gray-400"}`} />
							</div>
							<div className="w-5 h-5 max-w-fit">
								<Priority className={`w-5 h-5  ${priority ? "fill-primary-500" : "fill-gray-400"}`} />
							</div>
							<div
								className="max-w-fit flex items-center"
								onClick={() => {
									setServiceOpen((prev) => !prev);
								}}
							>
								{!serviceOpen && <GoChevronDown />}
								{serviceOpen && <GoChevronUp />}
							</div>
						</div>
					</div>
					<div></div>
				</div>
				<div className="px-3 flex items-center gap-5">
					<div
						className={`h-8 w-2`}
						style={{
							backgroundColor: `${routeColor}${
								serviceData && serviceData?.status && serviceData?.status === SERVICE_STATUS.COMPLETE ? "7F" : ""
							}`,
						}}
					></div>
					<div className="w-full grid grid-cols-2 text-left">
						<span className="">{isNaN(parseInt(time)) ? "" : convertSecondsToHours(parseInt(time))}</span>
						<span className="">{isNaN(parseInt(distance)) ? "" : convertMetersToMiles(parseInt(distance))} miles</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ServiceCard;

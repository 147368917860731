import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signOutUser } from "../../config/firebase";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const UserAccBtn = () => {
	const [isAccDropOpen, setIsAccDropOpen] = useState(false);
	const accDropRef = useRef(null);
	const [user] = useAuthState(auth);
	const userName = user?.displayName ?? user?.email?.split("@")[0];
	const navigate = useNavigate();

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Check if the click target is outside the dropdown container
			if (accDropRef.current && !accDropRef.current.contains(event.target)) {
				setIsAccDropOpen(false);
			}
		};

		// Use mousedown and touchstart to handle both mouse and touch events
		document.addEventListener("mousedown", handleClickOutside, true);
		document.addEventListener("touchstart", handleClickOutside, true);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside, true);
			document.removeEventListener("touchstart", handleClickOutside, true);
		};
	}, []);

	const toggleDropdown = (event) => {
		// Prevent event from bubbling up
		event.stopPropagation();
		setIsAccDropOpen((prev) => !prev);
	};

	const handleItemClick = async (action) => {
		// Close dropdown first
		setIsAccDropOpen(false);

		// Then perform the action
		if (action === "logout") {
			await signOutUser();
		} else if (action === "profile") {
			navigate("/admin/profile");
		}
	};

	return (
		<div className="relative px-2 mx-2" ref={accDropRef}>
			<button
				onClick={toggleDropdown}
				className="flex items-center gap-4 hover:cursor-pointer focus:outline-none"
				type="button"
				aria-expanded={isAccDropOpen}
				aria-haspopup="true"
			>
				<div className="flex items-center justify-center h-12 w-12 bg-white border border-gray-200 rounded-full">
					<img
						src={user?.photoURL ?? `https://api.dicebear.com/9.x/initials/png?seed=${userName}`}
						alt="User Avatar"
						className="rounded-full w-full h-full object-cover"
					/>
				</div>
				<div className="flex flex-col">
					<h6 className="text-sm font-semibold text-gray-700 truncate">
						{userName ? user.displayName || userName.charAt(0).toUpperCase() + userName.slice(1) : "Default User"}
					</h6>
					<p className="text-xs text-cardTextGray truncate">{user?.email ?? "user@test.com"}</p>
				</div>
			</button>

			<AnimatePresence>
				{isAccDropOpen && (
					<motion.div
						className="absolute z-50 bg-white top-14 right-0 min-w-full w-48 rounded-xl overflow-hidden shadow-lg"
						initial={{ opacity: 0, y: -10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -10 }}
						transition={{ duration: 0.2 }}
					>
						<ul className="">
							<li>
								<button
									onClick={() => handleItemClick("profile")}
									className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-lightBlue hover:text-white transition-colors duration-150"
								>
									Profile
								</button>
							</li>
							<li>
								<button
									onClick={() => handleItemClick("logout")}
									className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-lightBlue hover:text-white transition-colors duration-150"
								>
									Logout
								</button>
							</li>
						</ul>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default UserAccBtn;

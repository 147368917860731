import React, { useState, useRef, useEffect } from "react";
import {
	format,
	startOfMonth,
	endOfMonth,
	eachDayOfInterval,
	isSameMonth,
	getDay,
	isToday,
	setYear,
	setMonth,
} from "date-fns";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { SERVICE_TYPES } from "../../../../../utils/constants";

const CalendarWithTimeSlots = ({ allServices = [], startDate = new Date(), wholeCalender = false }) => {
	const [currentDate, setCurrentDate] = useState(startDate);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isSelectingDate, setIsSelectingDate] = useState(false);
	const datePickerRef = useRef(null);

	const monthStart = startOfMonth(currentDate);
	const monthEnd = endOfMonth(currentDate);
	const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });
	const startDay = getDay(monthStart);
	const daysWithEmptyCells = [...Array(startDay).fill(null), ...monthDays];
	const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const years = Array.from({ length: 21 }, (_, i) => currentDate.getFullYear() - 10 + i);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
				setIsSelectingDate(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [datePickerRef]);

	const handleDateClick = (date) => {
		if (date) setSelectedDate(date);
	};

	const handlePrevMonth = () => {
		setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
	};

	const handleNextMonth = () => {
		setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
	};

	const handleToday = () => {
		const today = new Date();
		setCurrentDate(today);
		setSelectedDate(today);
	};

	const handleMonthYearClick = () => {
		setIsSelectingDate(true);
	};

	const handleMonthChange = (event) => {
		const newMonth = parseInt(event.target.value, 10);
		setCurrentDate((prevDate) => setMonth(prevDate, newMonth));
	};

	const handleYearChange = (event) => {
		const newYear = parseInt(event.target.value, 10);
		setCurrentDate((prevDate) => setYear(prevDate, newYear));
	};
	const renderTodaysButton = (day, index) => {
		const today = new Date();
		let isCurrentDateToday = false;
		let isPastDay = false;
		if (
			today.getDate() === new Date(day).getDate() &&
			today.getMonth() === new Date(day).getMonth() &&
			today.getFullYear() === new Date(day).getFullYear()
		) {
			isCurrentDateToday = true;
		}
		const foundService = allServices.find((el) => {
			const serviceDate = new Date(el.date.toDate());
			const currentDate = new Date(day);

			if (
				serviceDate.getDate() === currentDate.getDate() &&
				serviceDate.getMonth() === currentDate.getMonth() &&
				serviceDate.getFullYear() === currentDate.getFullYear()
			) {
				return el;
			}
		});
		if (foundService) {
			console.log(today.getDate());

			console.log(new Date(foundService.date.toDate()).getDate());
		}

		if (foundService && today > new Date(foundService.date.toDate())) {
			isPastDay = true;
		}
		if (!foundService) {
			return (
				<button
					key={day ? day.toString() : `empty-${index}`}
					onClick={() => day && handleDateClick(day)}
					className={`
                min-h-6 min-w-6 md:h-10 md:w-10 rounded-full flex items-center text-center text-cardTextGray justify-center
                ${
									day && format(selectedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") && !isToday(day)
										? "bg-green-200"
										: ""
								}
              ${isCurrentDateToday ? "border border-red-500" : ""}
			  ${isPastDay ? "bg-gray-500" : ""}
			  `}
					disabled={!day || !isSameMonth(day, currentDate)}
				>
					{day ? format(day, "d") : ""}
				</button>
			);
		} else {
			if (
				foundService.scheduleData.serviceType.includes(SERVICE_TYPES.MEDICAL_WASTE) &&
				foundService.scheduleData.serviceType.includes(SERVICE_TYPES.PAPER_SHREDDING)
			) {
				console.log("Paper Shredding and Medical");

				return (
					<button
						key={day ? day.toString() : `empty-${index}`}
						onClick={() => day && handleDateClick(day)}
						className={`
                min-h-6 min-w-6 md:h-10 md:w-10 rounded-full flex items-center text-center text-cardTextGray justify-center relative
                ${isCurrentDateToday ? "border border-red-500" : ""}
                ${isPastDay ? "bg-gray-500" : ""}
				${" text-white"}
                ${
									day && format(selectedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") && !isToday(day)
										? "bg-green-200"
										: ""
								}
              `}
						disabled={!day || !isSameMonth(day, currentDate)}
					>
						{!isPastDay && <div className="w-5 h-10 bg-green-500 rounded-r-full rotate-180 absolute left-0"></div>}
						{!isPastDay && <div className="w-5 h-10 bg-red-500 rounded-l-full rotate-180 absolute right-0"></div>}
						<p className="z-10">{day ? format(day, "d") : ""}</p>
					</button>
				);
			} else if (foundService.scheduleData.serviceType.includes(SERVICE_TYPES.MEDICAL_WASTE)) {
				return (
					<button
						key={day ? day.toString() : `empty-${index}`}
						onClick={() => day && handleDateClick(day)}
						className={`
                min-h-6 min-w-6 md:h-10 md:w-10 rounded-full flex items-center text-center text-cardTextGray justify-center
                ${isCurrentDateToday ? "border border-red-500" : ""}
                ${"bg-red-500 text-white"}
                ${isPastDay ? "bg-gray-500" : ""}
				${day && format(selectedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") && !isToday(day) ? "bg-green-200" : ""}
              `}
						disabled={!day || !isSameMonth(day, currentDate)}
					>
						{day ? format(day, "d") : ""}
					</button>
				);
			} else if (foundService.scheduleData.serviceType.includes(SERVICE_TYPES.PAPER_SHREDDING)) {
				return (
					<button
						key={day ? day.toString() : `empty-${index}`}
						onClick={() => day && handleDateClick(day)}
						className={`
                min-h-6 min-w-6 md:h-10 md:w-10 rounded-full flex items-center text-center text-cardTextGray justify-center
                ${isCurrentDateToday ? "border border-red-500" : ""}
                ${"bg-primaryGreen text-white"}
                ${isPastDay ? "bg-gray-500" : ""}
				${day && format(selectedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") && !isToday(day) ? "bg-green-200" : ""}
              `}
						disabled={!day || !isSameMonth(day, currentDate)}
					>
						{day ? format(day, "d") : ""}
					</button>
				);
			} else {
				return (
					<button
						key={day ? day.toString() : `empty-${index}`}
						onClick={() => day && handleDateClick(day)}
						className={`
                min-h-6 min-w-6 md:h-10 md:w-10 rounded-full flex items-center text-center text-cardTextGray justify-center
                ${isCurrentDateToday ? "border border-red-500" : ""}
				${isPastDay ? "bg-gray-500" : ""}
				${day && format(selectedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") && !isToday(day) ? "bg-green-200" : ""}
              `}
						disabled={!day || !isSameMonth(day, currentDate)}
					>
						{day ? format(day, "d") : ""}
					</button>
				);
			}
		}
	};

	return (
		<div
			className={`w-full text-calendarHeading min-h-80p mx-auto flex flex-col gap-5 shadow-xl rounded-xl p-5 ${
				new Date().getFullYear() === currentDate.getFullYear() &&
				new Date().getMonth() === currentDate.getMonth() &&
				!wholeCalender
					? "border-2 border-solid border-red-500"
					: ""
			}`}
		>
			<div className="flex items-center justify-between mb-2">
				<div className="relative" ref={datePickerRef}>
					<button
						onClick={wholeCalender ? handleMonthYearClick : () => {}}
						className="text-lg xl:pl-1 font-bold focus:outline-none"
					>
						{format(currentDate, "MMM yyyy")}
					</button>
					{isSelectingDate && (
						<div className="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg p-2 z-10">
							<select
								value={currentDate.getMonth()}
								onChange={handleMonthChange}
								className="block w-full mb-2 p-1 border border-gray-300 rounded"
							>
								{months.map((month, index) => (
									<option key={month} value={index}>
										{month}
									</option>
								))}
							</select>
							<select
								value={currentDate.getFullYear()}
								onChange={handleYearChange}
								className="block w-full p-1 border border-gray-300 rounded"
							>
								{years.map((year) => (
									<option key={year} value={year}>
										{year}
									</option>
								))}
							</select>
						</div>
					)}
				</div>
				{wholeCalender ? (
					<button
						onClick={handleToday}
						className={`px-4 py-1 rounded-full hover:bg-cardTextGray hover:bg-opacity-10 ${
							currentDate == selectedDate ? "bg-cardTextGray bg-opacity-10" : ""
						}`}
					>
						Today
					</button>
				) : null}
				{wholeCalender ? (
					<div className="flex items-center">
						<button onClick={handlePrevMonth} className="mr-2">
							<IoIosArrowBack />
						</button>
						<button onClick={handleNextMonth}>
							<IoIosArrowForward />
						</button>
					</div>
				) : null}
			</div>
			<div className="grid grid-cols-7 gap-1">
				{weekDays.map((day) => (
					<div key={day} className="text-center font-medium">
						{day}
					</div>
				))}
			</div>
			<div className="grid grid-cols-7 gap-1">
				{daysWithEmptyCells.map((day, index) => (
					<div key={index} className="flex justify-center">
						{renderTodaysButton(day, index)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CalendarWithTimeSlots;

import PropTypes from "prop-types";
import { createContext, useState, useContext } from "react";

const GeneratorBreadcrumbContext = createContext();

export const GeneratorBreadcrumbProvider = ({ children }) => {
	const [breadcrumbData, setBreadcrumbData] = useState({
		type: "", // 'generator', 'driver', 'vendor'
		id: "", // octoNumber, driverId, vendorId
		steps: {
			"Generator Profile": true,
			"Generator Contacts": true,
			"Service Notes": false,
			Routes: false,
		},
	});

	const handleSetBreadcrumb = (type, id) => {
		setBreadcrumbData((prev) => ({ ...prev, type, id }));
	};

	const handleStepCompletion = (step, isCompleted) => {
		setBreadcrumbData((prev) => ({
			...prev,
			steps: { ...prev.steps, [step]: isCompleted },
		}));
	};

	return (
		<GeneratorBreadcrumbContext.Provider value={{ breadcrumbData, handleSetBreadcrumb, handleStepCompletion }}>
			{children}
		</GeneratorBreadcrumbContext.Provider>
	);
};

GeneratorBreadcrumbProvider.propTypes = {
	children: PropTypes.node,
};

export const useGeneratorBreadcrumb = () => {
	return useContext(GeneratorBreadcrumbContext);
};

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SidebarItem from "./SIdebarItem";
import Icon from "../Icon";
import { signOutUser, auth } from "../../../config/firebase";
import { menuItems } from "../../../utils/constants";
import { GetTransporterDataByUID } from "../../../utils/firebaseOperations";

const AdminSidebar = ({ active, showOnlyIcons }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [transporterData, setTransporterData] = useState(null);
	const [isScrollable, setIsScrollable] = useState(false);
	const [expandedItemIds, setExpandedItemIds] = useState([]);

	useEffect(() => {
		// Update expanded items when route changes
		const currentPath = location.pathname;
		const matchedIds = [];

		menuItems.forEach((item) => {
			if (item.children?.some((child) => currentPath.startsWith(child.link))) {
				matchedIds.push(item.id);
			}
		});

		if (matchedIds.length > 0) {
			setExpandedItemIds((prevIds) => {
				// Keep existing expanded items and add new matches
				const newIds = [...new Set([...prevIds, ...matchedIds])];
				return newIds;
			});
		}
	}, []);

	useEffect(() => {
		const fetchTransporter = () => {
			try {
				const user = auth.currentUser;
				if (!user) {
					console.log("No user logged in");
					return;
				}

				const unsubscribe = GetTransporterDataByUID(user.uid, (data) => {
					if (data) {
						// console.log("Transporter data:", data);
						setTransporterData(data);
					}
				});

				// Cleanup the real-time listener when the component unmounts
				return () => unsubscribe();
			} catch (error) {
				console.error("Error fetching transporter data:", error);
			}
		};

		fetchTransporter();
	}, []);

	const logOutHandler = async () => {
		await signOutUser();
		navigate("/");
	};

	const clickHandler = (to = "/") => navigate(to);

	const scrollHandler = (e) => {
		const target = e.target;
		target.scrollTop > 0 ? setIsScrollable(true) : setIsScrollable(false);
	};
	useEffect(() => {}, []);

	return (
		<nav
			className={`${
				showOnlyIcons ? "w-24" : "w-72"
			} transition-all duration-300 bg-white fixed hidden md:flex flex-col h-screen`}
		>
			{/* Top section */}
			{!showOnlyIcons && (
				<div className="px-10 min-h-20 max-h-20 items-center gap-4 border border-borderCol">
					<div className="h-14 w-14 md:h-16 md:w-16 rounded-full ml-16">
						{typeof transporterData?.companyLogo?.link == "string" &&
							transporterData?.companyLogo?.link?.length > 0 && (
								<div className="existing-file">
									<img
										src={transporterData?.companyLogo?.link}
										style={{ minWidth: "60px", minHeight: "60px", borderRadius: "50%" }}
										alt="Company Logo"
										className="object-cover"
									/>
								</div>
							)}
						{typeof transporterData?.companyLogo?.link !== "string" && transporterData?.companyLogo?.link?.length && (
							<div className="py-2 hover:bg-primaryGray cursor-pointer hover:underline  text-center mt-2 border border-borderCol text-sm px-2">
								<Link to={"/admin/profile"}>Add logo</Link>
							</div>
						)}
					</div>
					{<h6 className="text-xs text-black text-center">{transporterData?.name}</h6>}
				</div>
			)}
			{showOnlyIcons && (
				<div className=" p-2 border border-borderCol">
					<div className="">
						{transporterData?.companyLogo?.link ? (
							<div className="existing-file">
								<img
									src={transporterData.companyLogo.link}
									style={{ minWidth: "60px", minHeight: "60px", borderRadius: "50%" }}
									alt="Company Logo"
									className="object-cover max-h-16 max-w-16"
								/>
							</div>
						) : (
							<div className="h-10 w-10 md:h-16 md:w-16 rounded-full bg-dashBtnGradient" />
						)}
					</div>
				</div>
			)}
			{/* Scrollable middle section */}
			<div className="flex-grow border-r border-borderCol overflow-hidden" style={{ zIndex: 9999 }}>
				<div className="h-full overflow-y-auto" onScroll={scrollHandler}>
					<ul className="flex flex-col pt-6">
						{menuItems.map((item, index) => (
							<SidebarItem
								key={item.label + index}
								item={item}
								active={active === item.name}
								expandedItemIds={expandedItemIds}
								setExpandedItemIds={setExpandedItemIds}
								showOnlyIcons={showOnlyIcons}
							/>
						))}
					</ul>
				</div>
			</div>

			{/* Fixed bottom section */}
			<ul className={`p-4 flex flex-col w-full ${isScrollable ? "border-t border-borderCol border-opacity-50" : ""}`}>
				{isScrollable && (
					<div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
				)}
				<li
					onClick={() => clickHandler("/market")}
					className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${
						active === "market" ? "text-cardTextBlue" : ""
					}`}
				>
					<Icon name="market" active={active === "market"} />
					{!showOnlyIcons && <h6>OCTO Market</h6>}
				</li>
				<li
					onClick={() => clickHandler("/admin/connect")}
					className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${
						active === "octo-connect" ? "text-cardTextBlue" : ""
					}`}
				>
					<Icon name="connect" active={active === "connect"} />
					{!showOnlyIcons && <h6>OCTO GPS</h6>}
				</li>
				<li
					onClick={() => clickHandler("/admin/audit")}
					className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${
						active === "audit" ? "text-cardTextBlue" : ""
					}`}
				>
					<Icon name="audit" active={active === "audit"} />
					{!showOnlyIcons && <h6>Audit Log</h6>}
				</li>
				<li
					onClick={() => clickHandler("/admin/settings")}
					className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${
						active === "setting" ? "text-cardTextBlue" : ""
					}`}
				>
					<Icon name="setting" active={active === "setting"} />
					{!showOnlyIcons && <h6>Settings</h6>}{" "}
				</li>
				<li
					onClick={logOutHandler}
					className="group rounded-full text-sm xl:text-base flex items-center gap-3 bg-cardBorderCol w-full pl-4 py-2 hover:text-cardTextBlue hover:bg-[#cccccc] hover:cursor-pointer"
				>
					<Icon name="logout" active={active === "logout"} />
					{!showOnlyIcons && <h6>Logout</h6>}{" "}
				</li>
			</ul>
		</nav>
	);
};

export default AdminSidebar;

import React from "react";

const Radio = ({ isChecked, label, setIsChecked, type = "primary", styles = "gap-3", color = "", minwidth = "", reverse = false }) => {
    const handleRadioChange = () => {
        setIsChecked(true);
    };

    return (
        <div 
            className={`flex items-center cursor-pointer rounded-lg ${minwidth ? "justify-between w-full" : ""} ${styles} ${reverse ? "flex-row-reverse justify-end" : ""}`} 
            onClick={handleRadioChange}
        >
            <div 
                className={`${type === "primary" ? "min-w-[26px] min-h-[26px]" : "min-w-5 min-h-5"} border-2 rounded-full flex items-center justify-center ${
                    isChecked ? "bg-lightBlue border-cardTextBlue" : color ? "bg-white border-cardTextBlue" : "bg-white border-[#DADADA]"
                }`}
            >
                {isChecked && (
                    <div 
                        className={`${type === "primary" ? "w-3 h-3" : "w-2 h-2"} bg-white rounded-full`}
                    />
                )}
            </div>
            {label && <span className={`text-cardTextGray truncate select-none ${type === "primary" ? "" : "text-sm"}`}>{label}</span>}
        </div>
    );
};

export default Radio;
import React from "react";
import { formattedDate } from "../../../../../../../../../utils/helpers";

const GeneratorInfoHeader = ({ generatorData }) => {
	return (
		<div className="flex justify-between border-b h-24 pb-2 items-end border-[#CCCCCC]">
			<div>
				<h6 className="font-semibold text-2xl">{generatorData?.["generatorName"]}</h6>
				<h6 className="font-medium text-lg">{generatorData?.["octoNumber"]}</h6>
				<h6 className="font-medium text-lg">
					{/* Service Address:{" "} */}
					<span className="text-cardTextGray">
						{generatorData?.["serviceAddress"] && Object.keys(generatorData?.["serviceAddress"]).length > 0
							? [
									generatorData?.["serviceAddress"]?.["street"],
									generatorData?.["serviceAddress"]?.["suite"] || "",
									generatorData?.["serviceAddress"]?.["city"],
									generatorData?.["serviceAddress"]?.["state"],
									generatorData?.["serviceAddress"]?.["zipCode"],
							  ]
									.filter(Boolean)
									.join(", ") || "--"
							: "--"}
					</span>
				</h6>
			</div>
			<h6 className="font-medium text-lg">
				Created Date: <span className="text-cardTextGray">{formattedDate(generatorData?.["createdAt"]) ?? "--"}</span>
			</h6>
		</div>
	);
};

export default GeneratorInfoHeader;

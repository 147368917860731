const sortOptions = [
	{ label: "Most Popular", value: "Popular" },
	{ label: "Newest First", value: "New" },
];

const initialCount = {
	CONSULTING_SERVICES: 0,
	CONTAINER_SUPPLIER_MEDICAL_WASTE: 0,
	CONTAINER_SUPPLIER_PAPER_SHREDDING: 0,
	E_WASTE_RECYCLING_SERVICES: 0,
	FINANCIAL_SERVICES: 0,
	HAZARDOUS_WASTE_SERVICES: 0,
	LEGAL_SERVICES: 0,
	MEDICAL_WASTE_PROCESSING_EQUIPMENT: 0,
	MEDICAL_WASTE_TRANSFER_STATION: 0,
	MEDICAL_WASTE_TRANSPORTER: 0,
	MEDICAL_WASTE_TREATMENT_FACILITY: 0,
	ONLINE_OSHA_TRAINING: 0,
	PAPER_SHREDDING_SERVICES_OFFSITE: 0,
	PAPER_SHREDDING_SERVICES_ONSITE: 0,
	PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS: 0,
	TRAINING_AND_CERTIFICATION_SERVICES: 0,
	TRANSPORTATION_INSURANCE_PROVIDERS: 0,
};

const initialFilter = {
	CONSULTING_SERVICES: false,
	CONTAINER_SUPPLIER_MEDICAL_WASTE: false,
	CONTAINER_SUPPLIER_PAPER_SHREDDING: false,
	E_WASTE_RECYCLING_SERVICES: false,
	FINANCIAL_SERVICES: false,
	HAZARDOUS_WASTE_SERVICES: false,
	LEGAL_SERVICES: false,
	MEDICAL_WASTE_PROCESSING_EQUIPMENT: false,
	MEDICAL_WASTE_TRANSFER_STATION: false,
	MEDICAL_WASTE_TRANSPORTER: false,
	MEDICAL_WASTE_TREATMENT_FACILITY: false,
	ONLINE_OSHA_TRAINING: false,
	PAPER_SHREDDING_SERVICES_OFFSITE: false,
	PAPER_SHREDDING_SERVICES_ONSITE: false,
	PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS: false,
	TRAINING_AND_CERTIFICATION_SERVICES: false,
	TRANSPORTATION_INSURANCE_PROVIDERS: false,
};

import React, { useState, useEffect, useRef, useTransition } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiSearch } from "react-icons/fi";
import { BiSolidDownArrow } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import Loader from "../../../../components/UI/loaders/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../../../config/firebase";
import { initialFilterValue } from "../../../../utils/constants";
import { useAuthState } from "react-firebase-hooks/auth";
import SearchBox from "./Searchbox";

const Home = () => {
	const [transporterLoading, setTransporterLoading] = useState(false);
	const [inputVal, setInputVal] = useState("");
	const [searchQuery, setSearchQuery] = useState(""); // Separate state for active search query
	const [filterIsOpen, setFilterIsOpen] = useState(true);
	const [isFiltering, startTransition] = useTransition();
	const [isFilterCountFiltering, startFilterTransition] = useTransition();
	const [allServices, setAllServices] = useState([]);
	const [filteredServices, setFilteredServices] = useState([]);
	const [counts, setCounts] = useState(initialCount);
	const [sortOption, setSortOption] = useState(sortOptions[0]);
	const [filters, setFilters] = useState(initialFilter);
	const [user, loading] = useAuthState(auth);
	const [userDoc, setUserDoc] = useState(null);
	const [shouldShowClear, setShouldShowClear] = useState(false);

	// Fetch initial data
	useEffect(() => {
		startFilterTransition(() => {
			const fetchOctoMarketUsers = async () => {
				const snapshots = await getDocs(collection(db, "octoMarketUsers"));
				let mapData = (doc) => ({
					...doc.data(),
					id: doc.id,
					name: doc.data()?.companyName ?? doc.data()?.name,
					description: doc.data()?.shortDesc,
					type: doc.data()?.category,
					tag: Number(doc.data()?.popularityIndexCount) ?? 0,
					approved: doc.data()?.approved,
					hidden: Boolean(doc.data()?.hidden),
					profileCompleted: doc.data()?.profileCompleted,
					profileImage: doc.data()?.profileImage ?? "",
					createdAt: doc.data()?.createdAt?.toDate() ?? new Date(0), // Convert Firestore timestamp
				});
				let data = snapshots.docs
					.map((doc) => mapData(doc))
					.filter((docData) => {
						if (docData.approved && docData.profileCompleted && docData.hidden !== true) {
							console.log({ [docData.id]: docData.hidden });

							if (docData.id == user.uid) {
								setUserDoc(docData);
							} else {
								return docData;
							}
						}
					});

				setAllServices(data);
				setFilteredServices(data);

				// Update counts
				const newCounts = { ...counts };
				Object.keys(newCounts).forEach((key) => {
					newCounts[key] = 0;
				});
				data.forEach((service) => {
					service.type?.forEach((element) => {
						newCounts[element]++;
					});
				});
				setCounts(newCounts);
			};
			fetchOctoMarketUsers();
		});
	}, [user, sortOption]); // Added sortOption dependency

	console.log("====================================");
	console.log({ userDoc });
	console.log("====================================");

	// Handle real-time filtering
	useEffect(() => {
		startTransition(() => {
			// Start with all services
			let results = [...allServices];

			// Always include user's document
			const userDoc = results.find((doc) => doc.id === user?.id);

			// Apply category filters if any are selected
			const optionsToFilter = Object.keys(filters).filter((key) => filters[key] === true);
			if (optionsToFilter.length > 0) {
				results = results.filter(
					(service) =>
						service.id === user?.id || // Keep user's document
						optionsToFilter.find((item) => service.type?.includes(item))
				);
				setShouldShowClear(true);
			} else {
				setShouldShowClear(false);
			}

			// Apply search filter if there's an active search query
			if (searchQuery.trim() === inputVal?.trim()) {
				results = results.filter(
					(service) =>
						service.id === user?.id || // Keep user's document
						service.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						service.description?.toLowerCase().includes(searchQuery.toLowerCase())
				);
			} else {
				setSearchQuery(inputVal);
			}

			// Ensure user's document is always first
			if (userDoc && !results.includes(userDoc)) {
				results.unshift(userDoc);
			} else {
				results.sort((a, b) => {
					if (a.id === user?.id) return -1;
					if (b.id === user?.id) return 1;
					return 0;
				});
			}

			setFilteredServices(results);
		});
	}, [filters, allServices, searchQuery, user]);

	// Handle Find button click
	const findTransporterHandler = async () => {
		setTransporterLoading(true);
		// Set searchQuery to empty string if input is empty, otherwise set to inputVal
		setSearchQuery(inputVal.trim() === "" ? "" : inputVal);
		setTransporterLoading(false);
	};

	const clearFilterHandler = () => {
		setFilters(initialFilter);
	};

	let finalFilteredServices = userDoc ? [userDoc, ...filteredServices] : filteredServices;

	return (
		<div className="container mx-auto px-4">
			{/* Search Banner Section */}
			<div className="mx-auto my-20 flex items-center justify-between gap-8 h-[300px]">
				<div className="flex flex-col h-[90%] justify-between">
					<div className="py-2 flex flex-col justify-between">
						<motion.h2
							initial={{ x: -50, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							transition={{ delay: 0.3 }}
							className="text-2xl md:text-4xl xl:text-5xl max-w-xl leading-snug font-extrabold"
						>
							Powering Our Industry, One Connection at a Time.
						</motion.h2>
					</div>
					<SearchBox users={userDoc ? [userDoc, ...allServices] : allServices} />
					{/* </div> */}
				</div>
				<div className="h-[200px] w-[400px] lg:h-[300px] lg:w-[550px] flex justify-center items-center rounded-xl">
					<img
						src="https://storage.googleapis.com/routes-123.appspot.com/assets/logo%203.png"
						alt=""
						className="w-full"
					/>
				</div>
			</div>

			{/* Filtering Section */}
			<div className="container mx-auto">
				<div className="grid items-center grid-cols-4 mb-6 gap-4">
					<div></div>
					<h1 className="text-[#202020] font-semibold">Showing: {filteredServices.length} results</h1>
					<div></div>
					<div className="relative inline-flex justify-end text-left">
						<CustomDropdown label="Sort By" selectedOption={sortOption} setSelectedOption={setSortOption} />
					</div>
				</div>
				<div className="grid grid-cols-4 gap-8">
					<div className="col-span-1">
						<div className="flex mb-4 justify-between rounded-md px-2 py-1 items-center">
							<h2 className="text-lg font-medium">Filters</h2>
							<div className="flex items-center gap-2">
								{shouldShowClear && (
									<button
										className="border rounded-full border-cardTextGray border-opacity-20 px-2.5 hover:bg-[#eeeeee]"
										onClick={clearFilterHandler}
									>
										clear
									</button>
								)}
								<IoIosArrowDown
									color="#898989"
									className={`font-semibold transition-all w-7 h-7 p-1 rounded-full hover:bg-[#eeeeee] ${
										filterIsOpen ? "rotate-180" : "rotate-0"
									}`}
									onClick={() => setFilterIsOpen(!filterIsOpen)}
								/>
							</div>
						</div>

						<AnimatePresence>
							{filterIsOpen && (
								<motion.div
									initial={{ height: 0, opacity: 0 }}
									animate={{ height: "auto", opacity: 1 }}
									exit={{ height: 0, opacity: 0 }}
									transition={{ duration: 0.3 }}
									className="overflow-hidden flex flex-col gap-2 px-2 py-1"
								>
									{isFilterCountFiltering ? (
										<Loader />
									) : (
										Object.entries(filters).map(([label, checked]) => (
											<div key={label} className="flex justify-between gap-2">
												<FilterCheckbox
													label={label}
													checked={checked}
													onChange={() => setFilters({ ...filters, [label]: !checked })}
												/>
												<span className="text-[#898989]">{counts?.[label] ?? 0}</span>
											</div>
										))
									)}
								</motion.div>
							)}
						</AnimatePresence>
					</div>
					<div className="col-span-3">
						{isFiltering ? (
							<Loader height="h-screen w-full" />
						) : (
							<div className="grid grid-cols-3 gap-4">
								{finalFilteredServices.map((service, index) => (
									<ServiceCard
										key={index}
										name={service.name}
										index={index}
										id={service.id}
										description={service.description}
										categories={service.type}
										profileImg={service.profileImage}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;

const CustomDropdown = ({ label, selectedOption, setSelectedOption }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const handleSelect = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
	};

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative inline-block text-left" ref={dropdownRef}>
			{/* Button to toggle dropdown */}
			<button
				onClick={toggleDropdown}
				className="flex items-center gap-3 justify-between w-[230px] px-4 py-2 border border-[#DCDCDC] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
			>
				<span className="text-[#898989]">
					{label}: <span className="font-medium text-[#202020]">{selectedOption?.label || "Select"}</span>
				</span>
				<BiSolidDownArrow
					size={16}
					className={`transform ${isOpen ? "rotate-180" : "rotate-0"} text-[#898989] transition-transform`}
				/>
			</button>

			{/* Dropdown Menu */}
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="absolute left-0 z-10 w-full mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow-lg"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.2 }}
					>
						{sortOptions.map((option) => (
							<button
								key={option.value}
								className={`w-full px-4 py-2 text-left hover:bg-gray-200 ${
									selectedOption?.value === option.value ? "font-medium text-black" : "text-[#202020]"
								}`}
								onClick={() => handleSelect(option)}
							>
								{option.label}
							</button>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const FilterCheckbox = ({ label, checked, onChange, className = "" }) => {
	return (
		<motion.div
			whileTap={{ scale: 0.98 }}
			className={`flex gap-2 items-center ${className}`}
			onClick={() => onChange(!checked)}
		>
			<motion.div
				transition={{ duration: 0.1 }}
				className={`min-h-5 h-5 w-5 min-w-5 rounded-sm flex items-center border-2 ${
					checked ? "border-[#007AFF] bg-[#007aff]" : "border-[#898989]"
				} justify-center transition-colors duration-200 cursor-pointer`}
			>
				<AnimatePresence>
					{checked && (
						<motion.svg
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.5 }}
							transition={{ duration: 0.05 }}
							className="h-5 w-5 text-white"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
						</motion.svg>
					)}
				</AnimatePresence>
			</motion.div>
			<label className="text-[#202020] cursor-default">{initialFilterValue?.[label]}</label>
		</motion.div>
	);
};

const ServiceCard = ({ name, index, id, description, categories, profileImg }) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// Categories truncation logic: Display up to 2 categories as a list
	let simplifiedCategories = (categories ?? []).slice(0, 2);

	// Description truncation logic
	// const MAX_DESCRIPTION_LENGTH = 80;
	// const truncatedDescription =
	// 	description?.length > MAX_DESCRIPTION_LENGTH ? description.slice(0, MAX_DESCRIPTION_LENGTH) + "..." : description;

	const onClickHandler = () => {
		setLoading(true);
		setLoading(false);
		navigate(`/market/${id}`);
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			whileInView={{ opacity: 1, y: 0 }}
			viewport={{ once: true }}
			transition={{ duration: 0.5 }}
			className="flex flex-col gap-2 bg-white rounded-2xl p-6"
		>
			<div className="flex gap-2 h-20 w-full items-center">
				<div className="h-20 w-20">
					<img
						src={
							profileImg.length > 0
								? profileImg
								: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
						}
						className="w-20 h-20 object-cover rounded-lg"
						alt="Profile"
					/>
				</div>
				<h3 className="text-xl font-semibold">{name}</h3>
			</div>

			<ul className="list-disc list-inside text-sm text-gray-600">
				{simplifiedCategories.map((category, idx) => (
					<li key={idx}>{initialFilterValue[category]}</li>
				))}
			</ul>

			<p className="text-[#898989] text-sm leading-4 h-fit">{description?.slice(0, 100)}</p>

			<motion.button
				whileHover={{ scale: 1.02 }}
				whileTap={{ scale: 0.95 }}
				disabled={loading}
				className={`py-1 px-6 rounded-full mt-4 w-16 h-8 flex items-center justify-center hover:text-white hover:bg-[#007AFF] transition-all duration-200 ${
					loading ? "border border-gray-400 cursor-not-allowed" : "border border-[#007AFF] text-[#007AFF]"
				}`}
				onClick={onClickHandler}
			>
				{loading ? <Loader height="h-fit w-fit" /> : "View"}
			</motion.button>
		</motion.div>
	);
};

import PropTypes from "prop-types";
import React from "react";

const GeneratorDetailsForm = ({ billingAddress, serviceAddress, name, changeRequestHandler }) => {
	const service = {
		"generator Name": name,
		street: serviceAddress.street,
		suite: serviceAddress.suite,
		city: serviceAddress.city,
		state: serviceAddress.state,
		zip: serviceAddress.zipCode,
		email: serviceAddress.email,
		phone: serviceAddress.phone,
	};
	const billing = {
		"generator Name": name,
		street: billingAddress.street,
		suite: billingAddress.suite,
		city: billingAddress.city,
		state: billingAddress.state,
		zip: billingAddress.zipCode,
		email: billingAddress.email,
		phone: billingAddress.phone,
	};

	return (
		<div className="">
			<div className="flex justify-between items-center mb-6 px-3">
				<h2 className="text-2xl font-bold">Generator Details</h2>
				<a className="text-thatblue hover:underline text-sm cursor-pointer" onClick={changeRequestHandler}>
					Change Request
				</a>
			</div>
			<div className="grid grid-cols-2 gap-6 bg-white rounded-cardRadii shadow-md p-8">
				<div>
					<h3 className="text-gray-900 font-medium capitalize mb-2">Billing Information</h3>
					<hr className="text-gray-400" />

					<div className="py-3">
						{Object.entries(service).map(([key, value]) => (
							<div key={key} className="mb-4 w-full flex items-center gap-3 ">
								<p className="text-gray-700 font-medium mb-2 w-1/2 capitalize">{key}</p>
								<p className="text-gray-600  w-1/2">{value}</p>
							</div>
						))}
					</div>
				</div>
				<div>
					<h3 className="text-gray-900 font-medium capitalize mb-2">Service Information</h3>
					<hr className="text-gray-400" />

					<div className="py-3">
						{Object.entries(billing).map(([key, value]) => (
							<div key={key} className="mb-4 w-full flex items-center gap-3">
								<p className="text-gray-700 font-medium mb-2 w-1/2 capitalize">{key}</p>
								<p className="text-gray-700">{value}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

GeneratorDetailsForm.propTypes = {
	billingAddress: PropTypes.shape({
		city: PropTypes.any,
		email: PropTypes.any,
		phone: PropTypes.any,
		state: PropTypes.any,
		street: PropTypes.any,
		suite: PropTypes.any,
		zipCode: PropTypes.any,
	}),
	name: PropTypes.any,
	serviceAddress: PropTypes.shape({
		city: PropTypes.any,
		email: PropTypes.any,
		phone: PropTypes.any,
		state: PropTypes.any,
		street: PropTypes.any,
		suite: PropTypes.any,
		zipCode: PropTypes.any,
	}),
};

export default GeneratorDetailsForm;

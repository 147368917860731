import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Input from "../../components/UI/Input";
import Dropdown from "../../components/UI/dropdowns/Dropdown";
import Loader from "../../components/UI/loaders/Loader";
import { createNewTreatment } from "../../utils/firebaseOperations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
];

const notificationTypeOptions = [
    { value: "Full Manifest", label: "Full Manifest" },
    { value: "Combined Manifest", label: "Combined Manifest" },
];

const MiniTreatmentRegModal = ({ onClose }) => {
    const [inProgress, setInProgress] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email1: "",
        octoNumber: "",
        notificationTypeOption: "Combined Manifest",
        treatmentStatus: "Active"
    });

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const showError = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    };

    const showSuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    };

    const handleSubmit = async () => {
        if (inProgress) return;
        
        // Validation
        if (!formData.name.trim()) {
            showError("Name is required");
            return;
        }

        if (formData.email1 && !validateEmail(formData.email1)) {
            showError("Please enter a valid email address");
            return;
        }

        try {
            setInProgress(true);
            const treatmentData = {
                name: formData.name || "--",
                email1: formData.email1 || "",
                 notificationTypeOption: formData.notificationTypeOption,
                treatmentStatus: formData.treatmentStatus
            };

            await createNewTreatment(treatmentData);
            showSuccess("Treatment details saved successfully");
            onClose();
        } catch (error) {
            console.error('Error saving treatment details:', error);
            showError("Failed to save treatment details. Please try again.");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center z-40">
            <ToastContainer />
            <div className="bg-white rounded-cardRadii max-w-md w-full min-h-fit z-20">
                <div className="flex flex-col justify-between min-h-80 p-6">
                    <div className="flex justify-between pb-2">
                        <h6 className="font-bold text-xl text-black">Add New TF/TS</h6>
                        <button
                            type="button"
                            disabled={inProgress}
                            className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${inProgress ? "" : "hover:bg-gray-200"}`}
                            onClick={onClose}
                        >
                            <RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
                        </button>
                    </div>

                    <div className="space-y-4 w-full">
                        <Input
                            label="Name*"
                            style="flex-col"
                            className="w-full px-2 py-1 text-sm"
                            value={formData.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                        />

                        <Input
                            label="Email"
                            style="flex-col"
                            className="w-full px-2 py-1 text-sm"
                            value={formData.email1}
                            onChange={(e) => handleInputChange('email1', e.target.value)}
                        />

                        <Dropdown
                            label="Notification Type"
                            styles="flex-col min-w-full"
                            value={formData.notificationTypeOption}
                            onChange={(value) => handleInputChange('notificationTypeOption', value)}
                            options={notificationTypeOptions}
                        />

                        <Dropdown
                            label="Treatment Status"
                            styles="flex-col min-w-full"
                            value={formData.treatmentStatus}
                            onChange={(value) => handleInputChange('treatmentStatus', value)}
                            options={statusOptions}
                        />
                    </div>

                    <button
                        onClick={handleSubmit}
                        disabled={inProgress}
                        className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"} w-full rounded-full text-white`}
                    >
                        {inProgress ? <Loader height="h-auto" /> : "Add New TF/TS"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MiniTreatmentRegModal;
import { useEffect, useState } from "react";
import AuthForm from "./components/AuthForm.jsx";
import { emailRegex } from "../../../utils/mimes.js";
import { useNavigate } from "react-router-dom";
import { createNewUserWithEmailAndPassword } from "../../../config/firebase.js";
import { toast } from "react-toastify";
import { useGeneratorUser } from "../../../context/GeneratorUserContext.jsx";

const GeneratorPortalAuth = () => {
	const [isLogin, setIsLogin] = useState(true);
	const { user, authLoading, login } = useGeneratorUser();
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});
	useEffect(() => {
		if (!authLoading && user) {
			navigate("/generator-dashboard");
		}
	}, [authLoading, user]);

	const navigate = useNavigate();
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (isLogin) {
			if (!emailRegex.test(formData.email)) {
				toast.error("Invalid email");
			} else if (!formData.password?.trim()) {
				toast.error("Invalid password");
			} else {
				login(formData.email, formData.password);
			}
		}
	};

	return (
		<div className="h-screen w-full bg-creamWhite flex items-center">
			<div className="mx-auto bg-white p-6 min-w-96 shadow-lg rounded-lg">
				<h2 className="text-2xl font-bold mb-4 text-center">{isLogin ? "Login" : "Sign Up"}</h2>

				<AuthForm
					isLogin={isLogin}
					handleInputChange={handleInputChange}
					formData={formData}
					handleSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default GeneratorPortalAuth;

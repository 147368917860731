import { useEffect, useRef, useState } from "react";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import { Plus } from "lucide-react";
import { CheckCircleIcon, ClockIcon, ArrowRightIcon } from "lucide-react";
import CalendarWithTimeSlots from "./components/open-calendar/CalendarComponent";
import GoogleMapsRouteWrapper from "./components/MapComponent/MapComponent";
import GeneratorDetailsForm from "./components/GeneratorDetailsForm/GeneratorDetailsForm";
import GeneratorOfficeHours from "./components/GeneratorOfficeHours/GeneratorOfficeHours";
import ContactUsForm from "./components/ContactUsForm/ContactUsForm";
import ServiceCalender from "./components/ServiceCalender/ServiceCalender";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/UI/loaders/Loader";
import { dateFormatter, daysOfWeek, renderAddress } from "../../../utils/helpers";
import { SERVICE_STATUS, SERVICE_TYPES } from "../../../utils/constants";
import { Map } from "@vis.gl/react-google-maps";
import { collection, doc, getDoc, limit, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../config/firebase";

const GeneratorDashboard = () => {
	const { user, authLoading, generatorData, allServices } = useGeneratorUser();
	const [parentAccount, setParentAccount] = useState(null);
	const [serviceLocation, setServiceLocation] = useState(null);
	const [showServiceCalender, setShowServiceCalender] = useState(false);
	const navigate = useNavigate();
	const [nextService, setNextService] = useState(null);
	const [lastCompleted, setLastCompleted] = useState(null);
	const contactFormRef = useRef();
	useEffect(() => {
		if (!authLoading && !user) {
			navigate("/generator-portal-auth");
		}
	}, [authLoading, user, navigate, generatorData]);
	useEffect(() => {
		if (!allServices.length) return;
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		allServices.forEach((el) => console.log(el.date.toDate().toISOString()));
		const filteredDate = allServices.filter((el) => el.date.toDate() > today);
		const filteredCompleted = allServices.filter((el) => el.status === SERVICE_STATUS.COMPLETE);
		if (filteredCompleted.length) {
			setLastCompleted(filteredCompleted[filteredCompleted.length - 1]);
		}
		console.log({ filteredDate });
		filteredDate.forEach((el) => console.log(el.date.toDate().toISOString()));
		if (filteredDate.length) {
			setNextService(filteredDate[0]);
		}
	}, [allServices]);

	const renderWorkHours = () => {
		const todaysWorkHours = generatorData.workingHours[daysOfWeek[new Date().getDay()]];
		if (todaysWorkHours.closed) {
			return "Closed";
		}
		if (todaysWorkHours) {
			return `${todaysWorkHours.open ?? "N/A"} - ${todaysWorkHours.close ?? "N/A"}`;
		}
		return "N/A";
	};

	if (authLoading || !user || !generatorData) {
		return <Loader />;
	}

	return (
		<div className="bg-bgDefaultColor p-5 flex flex-col gap-5">
			<div className="grid grid-cols-3 gap-5 items-center ">
				<select name="" id="" className="select select-bordered rounded-full">
					<option value="" disabled selected hidden>
						Parent Account
					</option>
				</select>
				<select name="" id="" className="select select-bordered select-md rounded-full">
					<option value="" disabled selected hidden>
						Service Location
					</option>
					{generatorData && generatorData.serviceAddress ? (
						<option value="serivce location">
							{generatorData.serviceAddress?.suite?.length > 0 ? generatorData.serviceAddress?.suite + ", " : ""}
							{generatorData.serviceAddress?.street?.length > 0 ? generatorData.serviceAddress?.street + ", " : ""}
							{generatorData.serviceAddress?.city?.length > 0 ? generatorData.serviceAddress?.city + ", " : ""}
							{generatorData.serviceAddress?.state?.length > 0 ? generatorData.serviceAddress?.state + ", " : ""}
							{generatorData.serviceAddress?.zipCode?.length > 0 ? generatorData.serviceAddress?.zipCode + ", " : ""}
						</option>
					) : null}
				</select>

				{/* <button className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full py-3 px-4 shadow-lg transition-all duration-300 flex justify-between items-center space-x-2">
					<span>Add Service Location</span>
					<span>
						<Plus />
					</span>
				</button> */}
			</div>

			<div className="bg-white w-full rounded-xl flex flex-col items-start justify-evenly p-5 shadow-sm">
				<p className="font-semibold">{generatorData.generatorName}</p>
				<p className="font-semibold">{generatorData.octoNumber}</p>
				<p className="font-semibold">{renderAddress(generatorData.serviceAddress)}</p>
			</div>

			<div className="flex justify-between gap-5">
				<div className="bg-white p-10 flex items-center gap-5 flex-col w-full text-center rounded-xl shadow-md">
					<h1 className="font-bold text-3xl">Service</h1>

					<div className="">
						<h2 className="font-semibold text-lg">Next Service</h2>
						<h1 className="text-[#4CD964] text-xl">{nextService ? dateFormatter(nextService.date.toDate()) : "N/A"}</h1>
					</div>

					<div className="grid gap-2 w-full max-w-80">
						<button
							className="w-full  bg-thatblue hover:bg-blue-500 rounded-full text-white p-3"
							onClick={() => {
								navigate("/generator-dashboard/service-reports");
							}}
						>
							Manage Service History
						</button>
						<button
							className="w-full  bg-white hover:bg-[#DEDEDE] rounded-full text-[#666666] border border-[#DEDEDE] p-3"
							onClick={() => {
								navigate("/generator-dashboard/service-calendar");
							}}
						>
							Manage Future Services
						</button>
					</div>

					<div className="w-full bg-thatblue text-white  rounded-xl p-5 grid  gap-2">
						<h1 className="font-semibold text-center">Service Summary</h1>
						<hr className="" />

						<div className="grid gap-1">
							<div className="grid grid-cols-2">
								<span className="text-left">Service Address:</span>
								<span className="text-left">{renderAddress(generatorData.serviceAddress)}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Next Service:</span>

								<span className="text-left">[{nextService ? dateFormatter(nextService.date.toDate()) : "N/A"}]</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Office Hours:</span>
								<span className="text-left">{renderWorkHours()}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Service Type:</span>
								<span className="text-left">{dummyServiceData.serviceType}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Last Completed Service:</span>
								<span className="text-left">
									[{lastCompleted ? dateFormatter(lastCompleted.date.toDate()) : "N/A"}]
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Service Frequency:</span>
								<span className="text-left">{dummyServiceData.serviceFrequency}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white p-10 flex items-center gap-5 flex-col w-full text-center rounded-xl shadow-md">
					<h1 className="font-bold text-3xl">Billing</h1>

					<div className="">
						<h2 className="font-semibold text-lg">Open balance</h2>
						<h1 className="text-[#4CD964] text-xl">${dummyBillingData.openBalance.toFixed(2)}</h1>
					</div>

					<div className="grid gap-2 w-full max-w-80">
						<button
							className="w-full p-3 bg-thatblue hover:bg-blue-500 rounded-full text-white"
							onClick={() => {
								navigate("/generator-dashboard/invoices-and-payments");
							}}
						>
							Manage Invoices
						</button>
						<button
							className="w-full p-3 bg-white hover:bg-[#DEDEDE] rounded-full text-[#666666] border border-[#DEDEDE]"
							onClick={() => {
								navigate("/generator-dashboard/saved-payment-methods");
							}}
						>
							Manage Payment Options
						</button>
					</div>

					<div className="w-full bg-thatblue text-white  rounded-xl p-5 grid  gap-2">
						<h1 className="font-semibold text-center">Billing Summary</h1>

						<hr />

						<div className="grid gap-1">
							<div className="grid grid-cols-2">
								<span className="text-left">Billing Address:</span>
								<span className="text-left">{renderAddress(generatorData.billingAddress)}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Last Payment:</span>
								<span className="text-left">{dummyBillingData.lastPayment}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Overdue Balance:</span>
								<span className="text-left">{dummyBillingData.overdueBalance}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Unpaid Invoices:</span>
								<span className="text-left">{dummyBillingData.unpaidInvoices}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Billing Frequency:</span>
								<span className="text-left">{dummyBillingData.billingFrequency}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Payment Type</span>
								<span className="text-left">{dummyBillingData.paymentType}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full grid grid-cols-2 gap-5 ">
				<div className="bg-white rounded-cardRadii shadow-md p-5 grid grid-cols-1 gap-2">
					<h2 className="text-xl font-bold text-center">Medical Waste Service Schedule</h2>
					<div className="grid grid-cols-3 gap-2">
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-red-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Medical Waste</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-green-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Paper Shredding Schedule</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="flex w-6 h-6 col-span-2">
								<div className="w-3 h-6 bg-green-500 rounded-r-full rotate-180"></div>
								<div className="w-3 h-6 bg-red-500 rounded-l-full rotate-180"></div>
							</div>
							<span className="text-gray-700 col-span-6">Medical Waste & Paper Shredding</span>
						</div>
					</div>
				</div>

				<div className="bg-white rounded-xl shadow-md p-6 flex-1">
					<h2 className="text-lg font-bold  mb-4 ">Next Services</h2>
					<div className="flex items-center justify-between mb-2">
						<div className="flex items-center">
							<CheckCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
							<span className="text-gray-700">Route Started</span>
						</div>
						<div className="flex items-center">
							<ClockIcon className="w-5 h-5 text-gray-500 mr-2" />
							<span className="text-gray-700">Service Away</span>
						</div>
						<div className="flex items-center">
							<ArrowRightIcon className="w-5 h-5 text-gray-500 mr-2" />
							<span className="text-gray-700">Service Completed</span>
						</div>
					</div>
					<div className="w-full bg-gray-200 rounded-full h-4">
						<div className="bg-blue-500 h-4 rounded-full" style={{ width: "50%" }} />
					</div>
				</div>
			</div>

			<div className="w-full grid grid-cols-2 gap-5">
				<div className="bg-white p-3 rounded-cardRadii shadow-md flex-1 flex flex-col">
					<h2 className="font-bold text-lg ml-1 pb-2">Service Date</h2>
					<CalendarWithTimeSlots allServices={allServices} wholeCalender={true} />
					<p
						className="text-thatblue text-md py-2 text-end hover:cursor-pointer"
						onClick={() => {
							navigate("/generator-dashboard/service-calendar");
						}}
					>
						View Service Calendar
					</p>
				</div>

				<div className="shadow-sm w-full  bg-white rounded-xl">
					{/* <Map
						className="rounded-xl"
						reuseMaps
						mapId={`routeSetupMap`}
						defaultZoom={12}
						defaultCenter={{ lat: 32.7364432, lng: -117.1460877 }}
						gestureHandling={"greedy"}
						// onClick={handleMapClick}
						disableDefaultUI
					/> */}
				</div>
			</div>

			<GeneratorDetailsForm
				billingAddress={generatorData.billingAddress}
				serviceAddress={generatorData.serviceAddress}
				name={generatorData.generatorName}
				changeRequestHandler={() => {
					contactFormRef.current.scrollIntoView({ behavior: "smooth" });
				}}
			/>
			<GeneratorOfficeHours workingHours={generatorData.workingHours} />
			<div ref={contactFormRef}>
				<ContactUsForm />
			</div>
		</div>
	);
};

export default GeneratorDashboard;

const dummyGeneratorData = {
	name: "MedWaste Solutions Inc.",
	octoNumber: "OCTO-2024-001",
	serviceAddress: "123 Medical Lane, Healthcare City, MC 12345",
};

const dummyServiceData = {
	nextServiceDate: "06/15/2024",
	serviceAddress: "456 Clinical Road, Medical District, MC 67890",
	officeHours: "8:00 AM - 5:00 PM",
	serviceType: "Medical Waste Disposal",
	lastCompletedService: "05/15/2024",
	serviceFrequency: "Monthly",
};

const dummyBillingData = {
	openBalance: 12000.0,
	billingAddress: "789 Finance Street, Business Complex, BC 54321",
	lastPayment: "$10,000 [Mon, 05/01/2024]",
	overdueBalance: "$2,000",
	unpaidInvoices: "2",
	billingFrequency: "Monthly",
	paymentType: "ACH Transfer",
};

const AccountOptions = [
	{ value: "option1", label: "MedWaste Solutions Inc." },
	{ value: "option2", label: "HealthCare Disposal Group" },
	{ value: "option3", label: "Clinical Waste Management" },
];

const ServiceLocationOptions = [
	{ value: "location1", label: "Main Facility" },
	{ value: "location2", label: "Downtown Clinic" },
	{ value: "location3", label: "Suburban Branch" },
];

const stops = [
	{
		name: "Start - Main Facility",
		latitude: 34.1184,
		longitude: -118.3004,
	},
	{
		name: "Midpoint - Downtown Clinic",
		latitude: 34.1016,
		longitude: -118.3269,
	},
	{
		name: "End - Suburban Branch",
		latitude: 34.0092,
		longitude: -118.4972,
	},
];

import React, { useState, useRef, useEffect, forwardRef } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { RiHome6Line } from "react-icons/ri";

const BreadcrumbLoader = () => (
	<div className="flex items-center justify-center h-5 w-5">
		<div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-200 border-t-blue-600" />
	</div>
);

const Breadcrumb = ({ items = [], navigate }) => {
	const [isPreDropdownOpen, setIsPreDropdownOpen] = useState(false);
	const [isPostDropdownOpen, setIsPostDropdownOpen] = useState(false);
	const preDropdownRef = useRef(null);
	const postDropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (preDropdownRef.current && !preDropdownRef.current.contains(event.target)) {
				setIsPreDropdownOpen(false);
			}
			if (postDropdownRef.current && !postDropdownRef.current.contains(event.target)) {
				setIsPostDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const getVisibleItems = () => {
		const activeIndex = items.findIndex((item) => item.path === location.pathname);

		if (items.length <= 5) {
			return { start: items, pre: [], active: null, post: [], end: [] };
		}

		if (activeIndex <= 0) {
			return {
				start: [items[0]],
				pre: [],
				active: null,
				post: items.slice(1, -1),
				end: [items[items.length - 1]],
			};
		}

		if (activeIndex === items.length - 1) {
			return {
				start: [items[0]],
				pre: items.slice(1, -1),
				active: null,
				post: [],
				end: [items[items.length - 1]],
			};
		}

		return {
			start: [items[0]],
			pre: items.slice(1, activeIndex),
			active: items[activeIndex],
			post: items.slice(activeIndex + 1, -1),
			end: [items[items.length - 1]],
		};
	};

	const { start, pre, active, post, end } = getVisibleItems();
	const hasPreItems = pre.length > 0;
	const hasPostItems = post.length > 0;

	const BreadcrumbItem = ({ item, showArrow = true }) => (
		<div className="flex items-center">
			<button
				onClick={() => item.path && navigate(item.path)}
				className={`
          px-2 py-1 rounded-md hover:bg-gray-100 transition-all duration-200 flex items-center
          ${item.path ? "cursor-pointer" : "cursor-default"}
          ${location.pathname === item.path ? "font-semibold text-blueText" : "text-cardTextGray hover:text-gray-900"}
        `}
			>
				{item.label ? item.label : <BreadcrumbLoader />}
			</button>
			{showArrow && <IoIosArrowForward className="mx-1 text-gray-400" size={16} />}
		</div>
	);

	const Dropdown = forwardRef(({ isOpen, setIsOpen, items }, ref) => {
		return (
			<div className="relative inline-block" ref={ref}>
				<button
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
					className={`
            px-2 py-1 rounded-md transition-all duration-200
            ${isOpen ? "bg-gray-100 text-gray-900" : "text-gray-600 hover:bg-gray-100 hover:text-gray-900"}
          `}
				>
					...
				</button>

				{isOpen && (
					<div
						className="absolute top-full left-0 mt-1 py-1 bg-white rounded-md shadow-lg border border-gray-200 z-50 min-w-[180px]"
						onClick={(e) => e.stopPropagation()}
					>
						{items.map((item, index) => (
							<button
								key={index}
								onClick={() => {
									item.path && navigate(item.path);
									setIsOpen(false);
								}}
								className={`
                  w-full px-3 py-2 text-left transition-all duration-200 flex items-center
                  ${
										location.pathname === item.path
											? "font-semibold text-blueText bg-blue-50"
											: "text-cardTextGray hover:bg-gray-50 hover:text-gray-900"
									}
                `}
							>
								{item.label ? item.label : <BreadcrumbLoader />}
							</button>
						))}
					</div>
				)}
			</div>
		);
	});

	return (
		<nav className="flex items-center space-x-1 text-sm">
			<button
				onClick={() => navigate("/admin")}
				className="p-1 rounded-md hover:bg-gray-100 transition-all duration-200"
			>
				<RiHome6Line className="text-cardTextGray" size={20} />
			</button>
			<IoIosArrowForward className="text-cardTextGray" size={16} />

			{/* Start items */}
			{start.map((item, index) => (
				<BreadcrumbItem key={`start-${index}`} item={item} showArrow={true} />
			))}

			{/* Pre-active dropdown */}
			{hasPreItems && (
				<>
					<Dropdown isOpen={isPreDropdownOpen} setIsOpen={setIsPreDropdownOpen} items={pre} ref={preDropdownRef} />
					<IoIosArrowForward className="text-cardTextGray" size={16} />
				</>
			)}

			{/* Active item */}
			{active && <BreadcrumbItem item={active} showArrow={hasPostItems || end.length > 0} />}

			{/* Post-active dropdown */}
			{hasPostItems && (
				<>
					<Dropdown isOpen={isPostDropdownOpen} setIsOpen={setIsPostDropdownOpen} items={post} ref={postDropdownRef} />
					<IoIosArrowForward className="text-cardTextGray" size={16} />
				</>
			)}

			{/* End items */}
			{end.map((item, index) => (
				<BreadcrumbItem key={`end-${index}`} item={item} showArrow={index !== end.length - 1} />
			))}
		</nav>
	);
};

export default Breadcrumb;

import { initializeApp } from "firebase/app";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { doc, getDoc, setDoc, initializeFirestore } from "firebase/firestore";
import {
	getAuth,
	signInWithPopup,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

console.log({ key: import.meta.env.VITE_DEV_API_KEY });
const ENVIRONMENT_TYPES = {
	development: "development",
	production: "production",
};
const ENVIRONMENT = ENVIRONMENT_TYPES.production;
const slug = ENVIRONMENT === ENVIRONMENT_TYPES.production ? "PROD" : "DEV";
const firebaseConfig = {
	apiKey: import.meta.env[`VITE_${slug}_API_KEY`],
	authDomain: import.meta.env[`VITE_${slug}_AUTH_DOMAIN`],
	projectId: import.meta.env[`VITE_${slug}_PROJECT_ID`],
	storageBucket: import.meta.env[`VITE_${slug}_STORAGE_BUCKET`],
	messagingSenderId: import.meta.env[`VITE_${slug}_MESSAGING_SENDER_ID`],
	appId: import.meta.env[`VITE_${slug}_APP_ID`],
};

const APP_CHECK_TOKEN = import.meta.env[`VITE_${slug}_APP_CHECK_TOKEN`];
const FIRESTORE_ID = import.meta.env[`VITE_${slug}_FIRESTORE_ID`];
const USE_EMULATOR = import.meta.env.VITE_USE_EMULATOR === "true"; // Add this in `.env`

console.log({ firebaseConfig });
console.log({ APP_CHECK_TOKEN });

const firebaseApp = initializeApp(firebaseConfig);

// Firestore setup
export const db = initializeFirestore(
	firebaseApp,
	{ ignoreUndefinedProperties: true },
	FIRESTORE_ID // Only use FIRESTORE_ID in production
);
// if (USE_EMULATOR) {
// 	console.log("Connecting to Firestore Emulator");
// 	connectFirestoreEmulator(db, "localhost", 8080); // Emulator Firestore port
// }

// Auth setup
export const auth = getAuth(firebaseApp);
// if (USE_EMULATOR) {
// 	console.log("Connecting to Auth Emulator");
// 	connectAuthEmulator(auth, "http://localhost:9099"); // Emulator Auth port
// }

// App Check setup
initializeAppCheck(firebaseApp, {
	provider: new ReCaptchaEnterpriseProvider(APP_CHECK_TOKEN),
	isTokenAutoRefreshEnabled: true,
});

// Google Auth Provider setup
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
	prompt: "select_account",
});

// Storage setup
export const storage = getStorage(firebaseApp);
// if (USE_EMULATOR) {
// 	console.log("Connecting to Storage Emulator");
// 	connectStorageEmulator(storage, "localhost", 9199); // Emulator Storage port
// }

// Functions for Auth and Firestore operations remain unchanged
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const sendResetPasswordEmail = (email) => sendPasswordResetEmail(auth, email);

export const createUserDocFromAuth = async (userAuth, role = "User", collectionName = "users", additionalInfo = {}) => {
	if (!userAuth) return;
	const userDocRef = doc(db, collectionName, userAuth.uid);
	const userSnapshot = await getDoc(userDocRef);

	if (!userSnapshot.exists()) {
		const { displayName, email } = userAuth;
		const createdAt = new Date();

		try {
			await setDoc(
				userDocRef,
				{
					name: displayName,
					email,
					createdAt,
					role,
					...additionalInfo,
				},
				{ merge: true }
			);
			return role;
		} catch (error) {
			console.log("Error creating user", error.message);
		}
	} else {
		let userData = userSnapshot.data();
		return userData.role;
	}
};

export const createNewUserWithEmailAndPassword = async (email, password) => {
	if (!email || !password) return;
	return createUserWithEmailAndPassword(auth, email, password);
};

export const signInUserWithEmailAndPassword = async (email, password) => {
	if (!email || !password) return;
	return signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
	try {
		let res = await signOut(auth);
		return res;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

// Define your Firestore collections
export const COLLECTIONS = {
	scheduledServices: "scheduledServices",
	generators: "generators",
	routes: "routes",
	drivers: "drivers",
	serviceSchedules: "serviceSchedules",
	treatments: "treatments",
	path: "path",
	vendors: "vendors",
	satelliteLocation: "satelliteLocation",
	serviceVehicles: "serviceVehicles",
	generatorContacts: "generatorContacts",
	dailyRoutes: "dailyRoutes",
	counters: "counters",
};

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Subcontractor = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname == "/admin/octo-connect") navigate("/admin/octo-connect/contractor-list");
		else navigate(location.pathname);
	}, [location.pathname]);

	return (
		<div>
			<Outlet />
		</div>
	);
};

export default Subcontractor;

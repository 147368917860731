import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import DatePicker from "react-datepicker";
import { PiCalendarDotsLight } from "react-icons/pi";
import { formattedDate } from "../../utils/helpers";

const CustomDatePicker = ({ 
    selectedDate, 
    setSelectedDate, 
    label, 
    labelClass = "", 
    borderClass = "", 
    isDisabled = false, 
}) => {
    const CustomInputForCalendar = React.forwardRef(({ value, onClick }, ref) => (
        <div 
            className={`bg-inputBg rounded-full text-cardTextGray py-2 px-4 h-9 justify-between gap-4 flex items-center ${borderClass} cursor-pointer`} 
            ref={ref} 
            onClick={isDisabled ? () => {} : onClick}
        >
            <span className="text-cardTextGray">{value}</span>
            <PiCalendarDotsLight 
                className={`text-cardTextGray ${isDisabled ? "" : "hover:text-blueText hover:cursor-pointer"}`} 
                size={20} 
            />
        </div>
    ));

    const CustomHeader = ({ 
        date, 
        changeYear, 
        changeMonth, 
        decreaseMonth, 
        increaseMonth, 
        prevMonthButtonDisabled, 
        nextMonthButtonDisabled, 
    }) => {
        const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
        const months = [
            "January", 
            "February", 
            "March", 
            "April", 
            "May", 
            "June", 
            "July", 
            "August", 
            "September", 
            "October", 
            "November", 
            "December",
        ];

        // Ensure date is a valid Date object
        const currentDate = date instanceof Date ? date : new Date();

        return (
            <div className="custom-header flex items-center justify-between p-2">
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="nav-button p-1">
                    <ChevronLeft size={18} />
                </button>
                <div className="flex space-x-2">
                    <select 
                        value={currentDate.getFullYear()} 
                        onChange={({ target: { value } }) => changeYear(Number(value))} 
                        className="bg-white text-gray-700 px-2 py-1 rounded"
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    <select 
                        value={months[currentDate.getMonth()]} 
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} 
                        className="bg-white text-gray-700 px-2 py-1 rounded"
                    >
                        {months.map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="nav-button p-1">
                    <ChevronRight size={18} />
                </button>
            </div>
        );
    };

    return (
        <DatePicker 
            selected={selectedDate} 
            onChange={(date) => setSelectedDate(formattedDate(date))} 
            customInput={<CustomInputForCalendar />} 
            renderCustomHeader={CustomHeader} 
            dateFormat="MMM d" 
            showPopperArrow={false} 
            className="custom-datepicker responsive-datepicker"
        />
    );
};

CustomDatePicker.propTypes = {
    borderClass: PropTypes.string,
    isDisabled: PropTypes.bool,
    label: PropTypes.any,
    labelClass: PropTypes.string,
    selectedDate: PropTypes.any,
    setSelectedDate: PropTypes.func,
};

export default CustomDatePicker;
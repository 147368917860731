import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../../../utils/constants";
import DropdownReport from "./ReportDropDown";
import {
	dateFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../../../utils/helpers";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";

// const servicesData = [
// 	{
// 		serviceDate: "Day MM/DD/YYYY",
// 		octoNumber: "123456",
// 		service: "Lorem ipsum dolor sit amet",
// 		serviceType: "Medical Waste",
// 		route: "SD Monday",
// 		serviceVehicle: "012345",
// 		driver: "Sammy",
// 		transporterId: "123456",
// 		documentId: "1234567899",
// 		transferStatus: "On Truck TS/TF",

// 		serviceSummary: {
// 			documentId: "1234567899",
// 			subwasteType: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum",
// 			container: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
// 			quantity: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum",
// 			weight: "Lorem ipsum sit amet, Lorem",
// 		},

// 		deliverySummary: {
// 			documentId: "1234567899",
// 			description: {
// 				line1: "Lorem ipsum dolor sit amet, consectetur",
// 				line2: "Lorem ipsum",
// 			},
// 			usage: "Lorem ipsum dolor sit amet, consectetur",
// 			quantity: "Lorem ipsum dolor sit amet, consectetur",
// 		},

// 		options: {
// 			billed: true,
// 			date: "01-12-2025",
// 			method: "external",
// 			billingNote: "Invoiced Through External Billing Platform",
// 		},

// 		additional: {
// 			picture: "Picture",
// 			pictureId: "Picture Id",
// 			driverNote: "Customer was closed. 28 gallon bin left outside front door",
// 			contractor: "Lorem ipsum",
// 			subcontractor: "Lorem ipsum dolor sit amet",
// 		},
// 	},
// 	{
// 		serviceDate: "Day MM/DD/YYYY",
// 		octoNumber: "789012",
// 		service: "Lorem ipsum dolor sit amet",
// 		serviceType: "Medical Waste",
// 		route: "SD Tuesday",
// 		serviceVehicle: "067890",
// 		driver: "Tommy",
// 		transporterId: "789012",
// 		documentId: "9876543210",
// 		transferStatus: "On Truck TS/TF",

// 		serviceSummary: {
// 			documentId: "9876543210",
// 			subwasteType: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum",
// 			container: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
// 			quantity: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum",
// 			weight: "Lorem ipsum sit amet, Lorem",
// 		},

// 		deliverySummary: {
// 			documentId: "9876543210",
// 			description: {
// 				line1: "Lorem ipsum dolor sit amet, consectetur",
// 				line2: "Lorem ipsum",
// 			},
// 			usage: "Lorem ipsum dolor sit amet, consectetur",
// 			quantity: "Lorem ipsum dolor sit amet, consectetur",
// 		},

// 		options: {
// 			billed: true,
// 			date: "01-22-2025",
// 			method: "internal",
// 			billingNote: "Send To Billing",
// 		},

// 		additional: {
// 			picture: "Picture",
// 			pictureId: "Picture Id",
// 			driverNote: "Customer was closed. 28 gallon bin left outside front door",
// 			contractor: "Lorem ipsum",
// 			subcontractor: "Lorem ipsum dolor sit amet",
// 		},
// 	},
// ];

const handleCheckboxChange = (data, setData, index, field) => {
	const newData = [...data];
	newData[index][field] = !newData[index][field];
	setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
	const newData = [...data];
	newData[index].paidValue = value;
	setData(newData);
};

const ServiceReporting = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [completeServices, setCompleteServices] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [from, setFrom] = useState(() => new Date("12-01-2024"));
	const [to, setTo] = useState(new Date("01-01-2025"));
	const dropdownOptions = ["Edit", "Delete", "Share"];
	const [pickupOptions, setPickupOptions] = useState([]);
	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [driverOptions, setDriverOptions] = useState([]);
	const [contractorOptions, setContractorOptions] = useState([]);
	const [subContractorOptions, setSubContractorOptions] = useState([]);
	const [transStatusOptions, setTransStatusOptions] = useState([]);
	const [selectedServices, setSelectedServices] = useState([]);
	const [selectedSort, setSelectedSort] = useState("any");
	const [filteredData, setFilteredData] = useState([]);
	const [allTreatments, setAllTreatments] = useState([{ label: "Loading", value: null }]);
	const handleNextClick = () => {
		navigate("/admin/generators/reports/transfer-reports");
	};

	const handlePerviousButton = () => {
		navigate(-1);
	};

	// useEffect(() => {
	// 	const treatmentRef = collection(db, COLLECTIONS.treatments);
	// 	const unsubscribe = onSnapshot(treatmentRef, async (snap) => {
	// 		const list = [{ label: "All", value: "all" }];
	// 		snap.docs.forEach((treatmentSnap) => {
	// 			if (!treatmentSnap.exists()) {
	// 				return;
	// 			}
	// 			const data = treatmentSnap.data();
	// 			list.push({ label: data.name, value: treatmentSnap.id });
	// 		});
	// 		setAllTreatments(list);
	// 		viewButtonHandler();
	// 	});

	// 	return () => {
	// 		if (unsubscribe) unsubscribe();
	// 	};
	// }, []);

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A"; // Handle missing data gracefully
		}

		return scheduleData.serviceType.reduce((accumulator, serviceType) => {
			const matchingService = serviceTypes.find((service) => service.value === serviceType);
			return accumulator.concat(matchingService ? matchingService.label : "N/A");
		}, []); // Use an empty array as the initial accumulator
	};

	const viewButtonHandler = async () => {
		console.log("getting all services");
		if (!from) {
			showErrorToastMessage("Please select from date.");
		}
		if (!to) {
			showErrorToastMessage("Please select to date.");
		}
		const fromDate = Timestamp.fromDate(new Date(from));
		const toDate = Timestamp.fromDate(new Date(to));
		let q = query(collection(db, "scheduledServices"), where("date", ">=", fromDate), where("date", "<=", toDate));
		q = query(q, where("status", "==", SERVICE_STATUS.COMPLETE));
		if (selectedSort.length > 0 && selectedSort === "date_desc") {
			q = query(q, orderBy("date", "desc"));
		}
		if (selectedSort.length > 0 && selectedSort === "date_asc") {
			q = query(q, orderBy("date", "asc"));
		}

		try {
			setIsLoading(true);
			const snap = await getDocs(q);
			if (snap.docs.length < 1) {
				showErrorToastMessage("No completed services found in given range.");
			}

			console.log({ items: snap.docs.length });

			const allCompletedServices = [];
			const jobs = snap.docs.map(async (serviceSnap) => {
				const serviceData = serviceSnap.data();
				// console.log({ serviceData });

				// let isExist = false;
				// if (selectedTreatments.length > 0) {
				// 	selectedTreatments.forEach((value) => {
				// 		if (value === routeData.treatmentId) {
				// 			isExist = true;
				// 		}
				// 	});
				// }
				// if (selectedTreatments.length > 0 && !isExist) {
				// 	return;
				// }

				const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
				const scheduleRes = await getDoc(scheduleDataRef);
				if (!scheduleRes.exists()) {
					return;
				}
				const scheduleData = scheduleRes.data();

				const serviceTypeString = getStringOfServiceTypes(scheduleData);
				const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
				const generatorRes = await getDoc(generatorDataRef);
				if (!generatorRes.exists()) {
					return;
				}
				const generatorData = generatorRes.data();

				const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
				const routeRes = await getDoc(routeDataRef);
				if (!routeRes.exists()) {
					return;
				}
				const routeData = routeRes.data();

				allCompletedServices.push({
					id: serviceSnap.id,
					date: dateFormatter(serviceData.date.toDate().toLocaleDateString()),
					time: serviceData.date.toDate().toLocaleTimeString(),
					customer: `${generatorData?.generatorName ?? "N/A"} `,
					octoNumber: `${generatorData?.octoNumber ?? "N/A"}`,
					email: `${generatorData?.generatorEmail ?? "N/A"}`,
					address: `${generatorData?.suite ?? ""} ${generatorData?.street ?? ""} ${generatorData?.city ?? ""} ${
						generatorData?.state ?? ""
					} ${generatorData?.zipCode ?? ""}`,
					billed: serviceData?.isBilled ?? false,
					billedDate: serviceData?.billedDate?.toDate() ?? null,
					paidDate: serviceData?.paidDate?.toDate() ?? null,
					paid: serviceData?.isPaid ?? false,
					paidAmount: serviceData?.paidAmount ?? "dummy amount",
					optionChecked: false,
					driverName: `${serviceData?.assignedDriverName ?? "N/A"}`,
					pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "N/A"}`,
					serviceType: serviceTypeString ?? "dummy service",
					routeName: routeData?.routeLabel ?? "dummy route name",
				});
			});
			await Promise.all(jobs);
			if (selectedSort.length > 0 && selectedSort === "generator_asc") {
				allCompletedServices.sort((a, b) => a.customer.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "generator_desc") {
				allCompletedServices.sort((a, b) => b.customer.localeCompare(a.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_asc") {
				allCompletedServices.sort((a, b) => a.routeName.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_desc") {
				allCompletedServices.sort((a, b) => b.routeName.localeCompare(a.customer));
			}
			console.log({
				allCompletedServices,
			});
			setCompleteServices(allCompletedServices);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			handleCheckboxChange("select_all");
		} else {
			handleCheckboxChange("deselect_all");
		}
	};

	const handleCheckboxChange = (index) => {
		if (index == "select_all") {
			setSelectedServices(filteredData.map((_, i) => i));
		} else if (index == "deselect_all") {
			setSelectedServices([]);
		} else {
			setSelectedServices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
		}
	};

	useEffect(() => {
		if (selectedServices.length > 0) {
			setSelectedServices([]);
		}
	}, [filteredData]);

	const handleRowClick = (data) => {
		// navigate(`/admin/generators/${data.id}`);
	};

	if (isLoading) {
		return <Loader />;
	}

	// const handleSelectAllChange = () => {
	// 	const newSelectAll = !selectAll;
	// 	setSelectAll(newSelectAll);
	// 	setSelectedServices(newSelectAll ? servicesData.map((_, i) => i) : []);
	//   };

	//   const handleCheckboxChange = (index) => {
	// 	setSelectedServices(prev =>
	// 	  prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
	// 	);
	//   };

	return (
		<div className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii">
			<div className="flex-grow overflow-y-auto">
				<div className="text-lg font-normal px-8 py-4 text-black">Service History Report</div>
				<div className="px-8 flex items-center justify-between">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-full max-w-[50%]">
						<input
							type="text"
							placeholder="Search by Name, OCTO Number, Phone, Email, Address, Documents "
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="pl-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
						/>
					</div>
					<button className="text-primary hover:text-blue-900">Clear Filter</button>
				</div>
				<div className="w-11/12 flex px-8 py-5">
					<div className="flex flex-wrap gap-5 gap-y-3 items-end">
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">From</p>
							<CustomDatePicker
								selectedDate={from}
								setSelectedDate={(value) => setFrom(new Date(value))}
								label={"From Date"}
							/>
						</div>
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">To</p>
							<CustomDatePicker
								selectedDate={to}
								setSelectedDate={(value) => setTo(new Date(value))}
								label={"To Date"}
							/>
						</div>
						{/* <div className="flex flex-col min-w-80 "> */}
						<MultiselectDropdown
							width="w-48"
							buttonText="Pickup"
							options={[]}
							selectedOptions={pickupOptions}
							onSelectedOptionsChange={(values) => setPickupOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Service Type"
							options={[]}
							selectedOptions={serviceTypeOptions}
							onSelectedOptionsChange={(values) => setServiceTypeOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Route"
							options={[]}
							selectedOptions={routeOptions}
							onSelectedOptionsChange={(values) => setRouteOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Location"
							options={[]}
							selectedOptions={locationOptions}
							onSelectedOptionsChange={(values) => setLocationOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Driver"
							options={[]}
							selectedOptions={driverOptions}
							onSelectedOptionsChange={(values) => setDriverOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Contractor"
							options={[]}
							selectedOptions={contractorOptions}
							onSelectedOptionsChange={(values) => setContractorOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Subcontractor"
							options={[]}
							selectedOptions={subContractorOptions}
							onSelectedOptionsChange={(values) => setSubContractorOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Transfer Status"
							options={[]}
							selectedOptions={transStatusOptions}
							onSelectedOptionsChange={(values) => setTransStatusOptions(values)}
						/>
					</div>
				</div>
			</div>
			<div className="grid font-medium w-full overflow-x-scroll py-4">
				<div className="flex bg-[#E5F2FF] py-4">
					<div className="w-8 mx-8 flex justify-center items-center hover:cursor-pointer">
						<input type="checkbox" className="w-4 h-4 bg-white" checked={selectAll} onChange={handleSelectAllChange} />
					</div>
					<div className="flex items-center">
						<div className="truncate w-40">Service Date</div>
						<div className="truncate w-40">OCTO Number</div>
						<div className="truncate w-80">Generator</div>
						<div className="truncate w-40">Service Type</div>
						<div className="truncate w-40">Route</div>
						<div className="truncate w-40">Service Vehicle</div>
						<div className="truncate w-52">Driver</div>
						<div className="truncate w-40 text-center">Transporter ID</div>
						<div className="truncate w-40 text-center">Document ID</div>
						<div className="truncate w-40 text-center">Transfer Status</div>
					</div>
				</div>

				{filteredData.length == 0 ? (
					<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
						<p>No result found</p>
					</div>
				) : (
					filteredData.map((service, index) => (
						<div key={index} className="py-2 font-normal border-b">
							<div className="flex items-center pt-2 pb-6">
								<div className="min-w-8 mx-8 flex justify-center hover:cursor-pointer">
									<input
										type="checkbox"
										className="w-4 h-4 bg-white"
										checked={selectedServices.includes(index)}
										onChange={() => handleCheckboxChange(index)}
									/>
								</div>
								<div className="flex items-center">
									<div className="truncate w-40">{service.serviceDate}</div>
									<div className="truncate w-40 text-thatblue">{service.octoNumber}</div>
									<div className="truncate w-80 text-thatblue">{service.generatorName ?? service.service}</div>
									<div className="truncate w-40">{service.serviceType}</div>
									<div className="truncate w-40">{service.route}</div>
									<div className="truncate w-40">{service.serviceVehicle}</div>
									<div className="truncate w-52 text-thatblue">{service.driver}</div>
									<div className="truncate w-40 text-center">{service.transporterId}</div>
									<div className="truncate w-40 text-thatblue text-center">{service.documentId}</div>
									<div className="truncate w-40 text-center">{service.transferStatus}</div>
								</div>
							</div>

							<div className="flex w-full overflow-hidden gap-6 border-dashInActiveBtnText px-6 pl-10">
								<div className="w-[45%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
									<div className="bg-[#DADADA] h-14 py-1 items-center justify-center">
										<h6 className="font-medium text-center">Service Summary</h6>
										<p className="text-center text-sm text-primary">{service.serviceSummary.documentId}</p>
									</div>
									<div className="pl-4 pb-2">
										<div className="grid grid-cols-4 text-left py-2 font-medium">
											<p>Subwaste Type</p>
											<p>Container</p>
											<p>Quantity</p>
											<p>Weight</p>
										</div>
										<div className="grid grid-cols-4 text-left">
											<p>{service.serviceSummary.subwasteType}</p>
											<p>{service.serviceSummary.container}</p>
											<p>{service.serviceSummary.quantity}</p>
											<p>{service.serviceSummary.weight}</p>
										</div>
									</div>
								</div>

								<div className="w-[35%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
									<div className="bg-[#DADADA] h-14 py-1 grid items-center justify-center">
										<h6 className="font-medium text-center">Delivery Summary</h6>
										<p className="text-center text-sm text-primary">{service.deliverySummary.documentId}</p>
									</div>
									<div className="pl-4 pb-2">
										<div className="grid grid-cols-3 text-left py-2 font-medium">
											<p>Description</p>
											<p>Usage</p>
											<p>Quantity</p>
										</div>
										<div className="grid grid-cols-3 text-left py-1">
											<p>{service.deliverySummary.description.line1}</p>
											<p>{service.deliverySummary.usage}</p>
											<p>{service.deliverySummary.quantity}</p>
										</div>
									</div>
								</div>

								<div className="flex flex-col overflow-hidden justify-between w-[20%] border border-dashInActiveBtnText rounded-lg">
									<div>
										<div className="bg-[#DADADA] h-14 py-1 grid items-center justify-center">
											<h6 className="font-medium text-center">Options</h6>
										</div>
										<div className="flex justify-around items-center text-center py-2 font-medium">
											<p className="w-10">Billed</p>
											<input type="checkbox" className="w-4 h-4 bg-white" checked={service.options.billed} readOnly />
											<p>{service.options.date}</p>
										</div>
										{service.options.method == "internal" && (
											<div className="flex justify-around items-center text-center py-2 font-medium">
												<p className="w-10">Paid</p>
												<input type="checkbox" className="w-4 h-4 bg-white" checked={service.options.billed} readOnly />
												<p>{service.options.date}</p>
											</div>
										)}
									</div>
									<div className="flex flex-col justify-between">
										<div
											className={`w-full flex py-2 items-center justify-center border-t font-medium border-[#DADADA] ${
												service.options.method == "internal" ? " bg-primaryGreen" : "text-red-600"
											}`}
										>
											<p>{service.options.billingNote}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="flex flex-col px-10 py-6">
								<div className="flex justify-between">
									<div className="flex gap-4">
										<p className="w-32 font-medium">Picture:</p>
										<p>{service.additional.pictureId}</p>
									</div>
									<div className="flex gap-4 w-[18%]">
										<p className="w-32 font-medium">Contractor:</p>
										<p>{service.additional.contractor}</p>
									</div>
								</div>
								<div className="flex justify-between">
									<div className="flex gap-4">
										<p className="text-nowrap w-32 font-medium">Driver Note:</p>
										<p>{service.additional.driverNote}</p>
									</div>
									<div className="flex gap-4 w-[18%] h-fit">
										<p className="text-nowrap min-w-32 font-medium">Subcontractor:</p>
										<p>{service.additional.subcontractor}</p>
									</div>
								</div>
							</div>
						</div>
					))
				)}
			</div>

			<div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={() => navigate("/admin/generators/reports")}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={handleNextClick}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default ServiceReporting;

const ServiceCard = ({ service, index, selectedServices, handleCheckboxChange }) => {
	console.log({ service });

	return (
		<div className="py-2 font-normal border-b">
			<div className="flex items-center pt-2 pb-6">
				<div className="min-w-8 mx-8 flex justify-center hover:cursor-pointer">
					<input
						type="checkbox"
						className="w-4 h-4 bg-white"
						checked={selectedServices.includes(index)}
						onChange={() => handleCheckboxChange(index)}
					/>
				</div>
				<div className="grid gap-2">
					<div className="flex items-center">
						<div className="truncate w-40">Service Date</div>
						<div className="truncate w-40">OCTO Number</div>
						<div className="truncate w-80">Generator</div>
						<div className="truncate w-40">Service Type</div>
						<div className="truncate w-40">Route</div>
						<div className="truncate w-40">Service Vehicle</div>
						<div className="truncate w-52">Driver</div>
						<div className="truncate w-40 text-center">Transporter ID</div>
						<div className="truncate w-40 text-center">Document ID</div>
						<div className="truncate w-40 text-center">Transfer Status</div>
					</div>
				</div>
			</div>
			<div className="flex w-full overflow-hidden gap-6 border-dashInActiveBtnText px-6 pl-10">
				<div className="h-40 w-[45%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
					<div className="bg-[#DADADA] h-14 py-1 items-center justify-center">
						<h6 className="font-medium text-center">Service Summary</h6>
						<p className="text-center text-sm text-primary">Service Number</p>
					</div>
					<div>
						<div className="grid grid-cols-4 text-center py-2 font-medium">
							<p>Subwaste Type</p>
							<p>Container</p>
							<p>Quantity</p>
							<p>Weight</p>
						</div>
						<div className="grid grid-cols-4 text-center">
							<p>Subwaste Type</p>
							<p>Container</p>
							<p>Quantity</p>
							<p>Weight</p>
						</div>
					</div>
				</div>
				<div className="h-40 w-[35%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
					<div className="bg-[#DADADA] h-14 py-1 grid items-center justify-center">
						<h6 className="font-medium text-center">Delivery Summary</h6>
						<p className="text-center text-sm text-primary">Service Number</p>
					</div>
					<div>
						<div className="grid grid-cols-3 text-center py-2 font-medium">
							<p>Description</p>
							<p>Usage</p>
							<p>Quantity</p>
						</div>
						<div className="grid grid-cols-3 text-center py-1">
							<p>Description</p>
							<p>Usage</p>
							<p>Quantity</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col overflow-hidden justify-between h-40 w-[20%] border border-dashInActiveBtnText rounded-lg">
					<div className="bg-[#DADADA] h-14 py-1 grid items-center justify-center">
						<h6 className="font-medium text-center">Options</h6>
						{/* <p className="text-center text-sm text-primary">Service Number</p> */}
					</div>
					<div className="h-24 flex flex-col justify-between">
						<div className="flex justify-around items-center text-center py-2 font-medium">
							<p>Billed</p>
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectedServices.includes(index)}
								onChange={() => handleCheckboxChange(index)}
							/>
							<p>Date</p>
						</div>
						<div
							className={`w-full flex py-2 items-center justify-center border-t border-[#DADADA] ${
								(index + 1) % 2 == 0 ? "bg-primaryGreen" : ""
							}`}
						>
							<p>{(index + 1) % 2 == 0 ? "Invoiced Through External Billing Platform" : "Send To Billing"}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col px-10 py-6">
				<div className="flex justify-between">
					<div className="flex gap-4">
						<p className="w-32 font-medium">Picture:</p>
						<p className="">Picture Id</p>
					</div>
					<div className="flex gap-4 w-[18%]">
						<p className="w-32 font-medium">Contractor:</p>
						<p className="">Lorem Ipsum</p>
					</div>
				</div>
				<div className="flex justify-between">
					<div className="flex gap-4">
						<p className="text-nowrap w-32 font-medium">Driver Note:</p>
						<p className="">Customer was closed. 28 gallon bin left outside front door</p>
					</div>
					<div className="flex gap-4 w-[18%] h-fit">
						<p className="text-nowrap min-w-32 font-medium">Subcontractor:</p>
						<p className="">Lorem Ipsum dolor sit amet</p>
					</div>
				</div>
			</div>
		</div>
	);
};

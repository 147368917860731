import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createOrUpdateGenerator } from "../../utils/firebaseOperations";
import { toast } from "react-toastify";
import { db } from "../../config/firebase";
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";

// Initial State
const initialState = {
	serviceNotes: {},
	profile: {},
	contacts: {},
	routeAssignments: {},
};

// Thunks for updating in DB and Redux store
// export const updateProfileInDB = createAsyncThunk("form/updateProfileInDB", async (profileData, thunkAPI) => {
// 	const dataToSave = {
// 		...profileData,
// 		updatedAt: new Date(),
// 	};
// 	try {
// 		await createOrUpdateGenerator(dataToSave);
// 		// toast.success("Saved successfully!");
// 		return profileData;
// 	} catch (error) {
// 		console.log(error);
// 		return thunkAPI.rejectWithValue(error?.message);
// 	}
// });
export const updateProfileInDB = createAsyncThunk("form/updateProfileInDB", async (profileData, thunkAPI) => {
	const dataToSave = {
		...profileData,
		updatedAt: new Date(),
	};

	try {
		// First, update the current profile
		await createOrUpdateGenerator(dataToSave);

		// If there's a parentAccount, handle the parent-child relationship
		if (dataToSave.parentAccount) {
			const parentRef = doc(db, "generators", dataToSave.parentAccount);
			const parentDoc = await getDoc(parentRef);

			if (parentDoc.exists()) {
				const parentData = parentDoc.data();
				const childrenArray = parentData.childs || [];

				// Check if this child is not already in the parent's children array
				if (!childrenArray.includes(dataToSave.id)) {
					// Add this profile's ID to parent's children array
					await updateDoc(parentRef, {
						childs: arrayUnion(dataToSave.id),
						updatedAt: new Date(),
					});
				}
			} else {
				console.warn(`Parent account ${dataToSave.parentAccount} not found`);
			}
		}

		// If this profile was previously linked to a different parent, remove it from their children array
		if (profileData.previousParentAccount && profileData.previousParentAccount !== dataToSave.parentAccount) {
			const previousParentRef = doc(db, "generators", profileData.previousParentAccount);
			await updateDoc(previousParentRef, {
				childs: arrayRemove(dataToSave.id),
				updatedAt: new Date(),
			});
		}

		return profileData;
	} catch (error) {
		console.error("Error updating profile:", error);
		return thunkAPI.rejectWithValue(error?.message);
	}
});

export const updateContactsInDB = createAsyncThunk("form/updateContactsInDB", async (contactsData, thunkAPI) => {
	const dataToSave = {
		...contactsData,
		updatedAt: new Date(),
	};
	try {
		await createOrUpdateGenerator(dataToSave);
		// toast.success("Saved successfully!");
		return contactsData;
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue(error?.message);
	}
});

export const updateServiceNotesInDB = createAsyncThunk(
	"form/updateServiceNotesInDB",
	async (serviceNotesData, thunkAPI) => {
		const dataToSave = {
			...serviceNotesData,
			updatedAt: new Date(),
		};
		try {
			await createOrUpdateGenerator(dataToSave);
			// toast.success("Saved successfully!");
			return serviceNotesData;
		} catch (error) {
			console.log(error);
			return thunkAPI.rejectWithValue(error?.message);
		}
	}
);

// Redux slice
export const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		updateProfileData: (state, action) => {
			state.profile = action.payload;
		},
		updateContacts: (state, action) => {
			state.contacts = action.payload;
		},
		updateServiceNotes: (state, action) => {
			state.serviceNotes = action.payload;
		},
	},
	extraReducers: (builder) => {
		// Async thunks for updating the store after DB update
		builder
			.addCase(updateProfileInDB.fulfilled, (state, action) => {
				state.profile = action.payload;
			})
			.addCase(updateContactsInDB.fulfilled, (state, action) => {
				state.contacts = action.payload;
			})
			.addCase(updateServiceNotesInDB.fulfilled, (state, action) => {
				state.serviceNotes = action.payload;
			})
			.addCase(updateProfileInDB.rejected, (state, action) => {
				console.log("Error updating profile:", action.payload);
			})
			.addCase(updateContactsInDB.rejected, (state, action) => {
				console.log("Error updating contacts:", action.payload);
			})
			.addCase(updateServiceNotesInDB.rejected, (state, action) => {
				console.log("Error updating service notes:", action.payload);
			});
	},
});

// Export the synchronous actions
export const { updateProfileData, updateContacts, updateServiceNotes } = formSlice.actions;

export default formSlice.reducer;

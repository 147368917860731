import React, { useState } from "react";
import CrudDropdown from "../../../../components/UI/dropdowns/CrudDropdown";

const Wastetable = ({ loading, data, handleRowClick, handleDelete, handleSaveAsPDF }) => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);

	return (
		<div className="">
			<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
				<div className="grid grid-cols-4 w-full">
					<div>Date and Time</div>
					<div>Generator Name</div>
					<div>Service Type</div>
					<div className="text-center">Options</div>
				</div>
			</div>

			{/* Skeleton */}
			{loading && (
				<div className="overflow-y-scroll">
					{Array.from({ length: 9 }).map((_, index) => (
						<div
							key={index}
							className="flex flex-col md:flex-row items-start pt-2 border-b pl-8 border-gray-300 animate-pulse"
							style={{ minHeight: "80px" }}
						>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
								<div className="h-4 bg-gray-300 rounded mt-2 w-1/2"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
						</div>
					))}
				</div>
			)}

			{!loading && (
				<div
					className={`second-child overflow-y-auto ${isScrollable ? "shadow-inner" : ""}`}
					onScroll={(e) => setIsScrollable(e.target.scrollTop > 0)}
				>
					{data.length == 0 ? (
						<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
							<p>No result found</p>
						</div>
					) : (
						data.map((item, index) => (
							<div
								key={item.id}
								className={`grid grid-cols-4 px-8 items-center text-sm border-b border-gray-300 
                            ${hoveredRow === index ? "bg-gray-200" : ""}
                         cursor-pointer`}
								onMouseEnter={() => setHoveredRow(index)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ minHeight: "80px" }}
							>
								{/* Date and Time */}
								<div onClick={() => handleRowClick(item.id)}>
									<div>{item.date || "Date not provided"}</div>
									<div>{item.time || "Time not provided"}</div>
								</div>

								{/* Generator Name */}
								<div onClick={() => handleRowClick(item.id)}>
									<div>{item.generatorName || "Generator not provided"}</div>
								</div>

								{/* Service Type */}
								<div onClick={() => handleRowClick(item.id)}>
									<div>{item.serviceType || "Service type not provided"}</div>
								</div>

								{/* Options */}
								<div>
									<div className="flex justify-center">
										<CrudDropdown
											onEdit={() => handleRowClick(item.id)}
											onDelete={() => handleDelete(item.id)}
											onSaveAsPDF={() => handleSaveAsPDF(item.id)}
										/>
									</div>
								</div>
							</div>
						))
					)}
				</div>
			)}
		</div>
	);
};

export default Wastetable;

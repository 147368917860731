import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/UI/loaders/Loader";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { AnimatePresence, motion } from "framer-motion";

const ContractorList = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [contractors, setContractors] = useState([]);
	const [filteredContractors, setFilteredContractors] = useState([]); // Fixed typo in state name
	const [contractorLoading, setContractorLoading] = useState(true);
	const [error, setError] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const [hoveredSteps, setHoveredSteps] = useState(false);

	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);
	const rowRef = useRef(null);
	const navigate = useNavigate();

	const getAllTransporter = async () => {
		const transportersRef = collection(db, "transporters");
		const q = query(transportersRef);
		const transportersSnap = await getDocs(q);

		return transportersSnap.docs?.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				name: typeof data.name === "string" ? data.name : "--",
				generalEmail: typeof data.generalEmail === "string" ? data.generalEmail : "--",
				phoneNumber: typeof data.phoneNumber === "string" ? data.phoneNumber : "--",
				octoNumber: typeof data.octoNumber === "string" ? data.octoNumber : "--",
				licensePlate: typeof data.licensePlate === "string" ? data.licensePlate : "--",
				capacity: typeof data.capacity === "string" ? data.capacity : "--",
				assignedDriver: typeof data.assignedDriver === "string" ? data.assignedDriver : "--",
				apartment: typeof data.apartment === "string" ? data.apartment : "--",
				transportorStatus: typeof data.transportorStatus === "string" ? data.transportorStatus : "--",
				website: typeof data.website === "string" ? data.website : "--",
				cageCode: typeof data.cageCode === "string" ? data.cageCode : "--",
				epaId: typeof data.epaId === "string" ? data.epaId : "--",
				billingStreet: data.billingStreet || "",
				billingSuite: data.billingSuite || "",
				billingCity: data.billingCity || "",
				billingState: data.billingState || "",
				billingZip: data.billingZip || "",
				address: formatAdd(data),
			};
		});
	};

	// Single useEffect for initial data fetch
	useEffect(() => {
		const fetchContractors = async () => {
			try {
				const contractorsData = await getAllTransporter();
				setContractors(contractorsData);
				setFilteredContractors(contractorsData);
			} catch (err) {
				console.error("Error fetching transporter data:", err);
				setError("Failed to load transporter data.");
			} finally {
				setContractorLoading(false);
			}
		};

		fetchContractors();
	}, []); // Empty dependency array for initial load only

	// Separate useEffect for search filtering
	useEffect(() => {
		const filteredResults = contractors.filter((transporter) => {
			return Object.values(transporter).some((value) =>
				String(value).toLowerCase().includes(searchQuery.toLowerCase())
			);
		});
		setFilteredContractors(filteredResults);
	}, [searchQuery, contractors]);

	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	const handleNextClick = () => {
		navigate("/admin/octo-connect/subcontractor-management");
	};

	const handlePreviousBtn = () => {
		navigate("/admin");
	};

	const handleMouseEnter = (transporterId, event) => {
		if (hoveredSteps) return;

		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(transporterId);
			updateTooltipPosition(event);
		}, 3000);
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 800;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (
			!tooltipRef.current ||
			!event.relatedTarget ||
			!(event.relatedTarget instanceof Node) ||
			!tooltipRef.current.contains(event.relatedTarget)
		) {
			setActiveTooltip(null);
		}
	};

	return (
		<div className="p-6 text-sm xl:text-base">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="mx-8 font-semibold my-4 text-xl text-black">Contractors List</div>

				<div className="pl-8 pb-4">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-2/5">
						<input
							type="text"
							placeholder="Search contractor"
							value={searchQuery}
							onChange={handleSearch}
							className="ml-2 bg-transparent focus:outline-none text-gray-500 flex-1 w-full"
						/>
					</div>
				</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Contractor Name</div>
							<div className="truncate w-96">Contractor Address</div>
							<div className="truncate w-60">Contractor Email</div>
							<div className="truncate w-60">Contractor Phone</div>
							<div className="truncate w-60">Contractor Website</div>
						</div>
					</div>

					<div className="min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden">
						{contractorLoading ? (
							<Loader height="h-12 pt-4" />
						) : filteredContractors.length > 0 ? (
							filteredContractors.map((transporter, index) => (
								<div
									key={transporter.id}
									ref={rowRef}
									className={`flex w-full items-center transition-colors duration-300 border-b border-gray-300 ${
										hoveredSteps ? "" : "hover:bg-cardTextGray hover:bg-opacity-20"
									}`}
									onMouseEnter={(e) => !hoveredSteps && handleMouseEnter(transporter.id, e)}
									onMouseLeave={handleMouseLeave}
									style={{ height: "45px" }}
								>
									<div className="w-full px-8 flex items-center">
										<div className="truncate w-80 h-full">{transporter.name}</div>
										<div className="truncate h-full w-96">{transporter.address}</div>
										<div className="truncate h-full w-60">{transporter.generalEmail}</div>
										<div className="w-60 h-full">
											<span
												className={`truncate ${
													transporter.phoneNumber !== "--" ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) =>
													transporter.phoneNumber !== "--" && handlePhoneClick(e, transporter.phoneNumber)
												}
											>
												{transporter.phoneNumber}
											</span>
										</div>
										<div className="truncate h-full w-60">{transporter.website}</div>
									</div>
									<AnimatePresence>
										{activeTooltip === transporter.id && !hoveredSteps && (
											<motion.div
												ref={tooltipRef}
												className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
												style={{
													left: `${tooltipPosition.left}px`,
													top: `${tooltipPosition.top}px`,
													width: "300px",
												}}
												initial={{ opacity: 0, scale: 0.8, transformOrigin }}
												animate={{ opacity: 1, scale: 1 }}
												exit={{ opacity: 0, scale: 0.8 }}
												transition={{ duration: 0.2 }}
												onMouseEnter={() => setActiveTooltip(transporter.id)}
												onMouseLeave={handleMouseLeave}
											>
												<ToolTipContent transporter={transporter} />
											</motion.div>
										)}
									</AnimatePresence>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={handlePreviousBtn}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					style={{ width: "184px", height: "44px" }}
				>
					Previous
				</button>
				<button
					onClick={handleNextClick}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					style={{ width: "184px", height: "44px" }}
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default ContractorList;

let formatAdd = (transporter) => {
	let formattedAdd = "";

	// Handle street
	if (transporter.billingStreet?.trim()?.length) {
		formattedAdd += transporter.billingStreet.trim();
	}

	// Handle suite
	if (transporter.billingSuite?.trim()?.length) {
		formattedAdd += formattedAdd.length ? `, ${transporter.billingSuite.trim()}` : transporter.billingSuite.trim();
	}

	// Handle city
	if (transporter.billingCity?.trim()?.length) {
		formattedAdd += formattedAdd.length ? `, ${transporter.billingCity.trim()}` : transporter.billingCity.trim();
	}

	// Handle state
	if (transporter.billingState?.trim()?.length) {
		formattedAdd += formattedAdd.length ? `, ${transporter.billingState.trim()}` : transporter.billingState.trim();
	}

	// Handle zip
	if (transporter.billingZip?.trim()?.length) {
		formattedAdd += formattedAdd.length ? `, ${transporter.billingZip.trim()}` : transporter.billingZip.trim();
	}

	console.log({ formattedAdd });

	return formattedAdd.length ? formattedAdd : "--";
};

const ToolTipContent = ({ transporter }) => {
	const details = [
		{ label: "Name", value: transporter.name },
		{ label: "Phone", value: transporter.phoneNumber || "--" },
		{ label: "Email", value: transporter.generalEmail || "--" },
		// { label: "OCTO Number", value: transporter?.octoNumber || "--" },
		{ label: "Appartment", value: transporter?.apartment || "--" },
		{ label: "Street", value: transporter?.billingStreet || "--" },
		{ label: "Suite Number", value: transporter?.billingSuite || "--" },
		{ label: "City", value: transporter?.billingCity || "--" },
		{ label: "State", value: transporter?.billingState || "--" },
		{ label: "ZIP Code", value: transporter?.billingZip || "--" },
		{ label: "License Plate", value: transporter?.licensePlate || "--" },
		{ label: "Status", value: transporter?.transportorStatus || "--" },
		{ label: "Website", value: transporter?.website || "--" },
		{ label: "Capacity", value: transporter?.capacity || "--" },
		{ label: "Cage Code", value: transporter?.cageCode || "--" },
		{ label: "EPA ID", value: transporter?.epaId || "--" },
		{ label: "Assigned Driver", value: transporter?.assignedDriver || "--" },
	];

	return (
		<div className="w-full p-2">
			<h2 className="text-lg font-medium">Contractor</h2>
			{details.map((item) => (
				<div key={item.label} className={`w-full flex`}>
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 text-wrap">{item.value}</div>
				</div>
			))}
		</div>
	);
};

import { addDays, isAfter, addMonths, format } from "date-fns"; // Using date-fns for date calculations
import { Timestamp } from "firebase/firestore";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";
import { LOCATION_TYPE } from "./constants";

export const getContractEndDate = (startDate, contractTerm) => {
	let monthsToAdd = 0;
	if (contractTerm == "MTM") {
		monthsToAdd = 1;
	} else if (contractTerm == "12_MONTHS") {
		monthsToAdd = 12;
	} else if (contractTerm == "24_MONTHS") {
		monthsToAdd = 24;
	} else if (contractTerm == "36_MONTHS") {
		monthsToAdd = 36;
	} else if (contractTerm == "48_MONTHS") {
		monthsToAdd = 48;
	} else if (contractTerm == "60_MONTHS") {
		monthsToAdd = 60;
	}
	let endDate = addMonths(startDate, monthsToAdd);
	return formattedDate(endDate);
};

// Utility function to get upcoming dates based on frequency
export const getUpcomingDates = (anchorDate, frequency, numberOfDates = 5) => {
	let daysToAdd = 0;

	// Define intervals for each frequency type
	if (frequency === "E1W" || frequency === "W") {
		daysToAdd = 7;
	} else if (frequency === "E2WB" || frequency === "E2W") {
		daysToAdd = 14;
	} else if (frequency === "E3W") {
		daysToAdd = 21;
	} else if (frequency === "E4W" || frequency === "E4WM") {
		daysToAdd = 28;
	} else if (frequency === "E5W") {
		daysToAdd = 35;
	} else if (frequency === "E6W") {
		daysToAdd = 42;
	} else if (frequency === "E7W") {
		daysToAdd = 49;
	} else if (frequency === "E8WB" || frequency === "E8W") {
		daysToAdd = 56;
	} else if (frequency === "E9W") {
		daysToAdd = 63;
	} else if (frequency === "E10W") {
		daysToAdd = 70;
	} else if (frequency === "E11W") {
		daysToAdd = 77;
	} else if (frequency === "E12W" || frequency === "E12WQ") {
		daysToAdd = 84;
	} else if (frequency === "E13W") {
		daysToAdd = 91;
	} else if (frequency === "E14W") {
		daysToAdd = 98;
	} else if (frequency === "E15W") {
		daysToAdd = 105;
	} else if (frequency === "E16W") {
		daysToAdd = 112;
	} else if (frequency === "E17W") {
		daysToAdd = 119;
	} else if (frequency === "E18W") {
		daysToAdd = 126;
	} else if (frequency === "E19W") {
		daysToAdd = 133;
	} else if (frequency === "E20W") {
		daysToAdd = 140;
	} else if (frequency === "E22W") {
		daysToAdd = 154;
	} else if (frequency === "E24W" || frequency === "E24WBA") {
		daysToAdd = 168;
	} else if (frequency === "E26W") {
		daysToAdd = 182;
	} else if (frequency === "E32W") {
		daysToAdd = 224;
	} else if (frequency === "E36W") {
		daysToAdd = 252;
	} else if (frequency === "E48W") {
		daysToAdd = 336;
	} else if (frequency === "E52W" || frequency === "E52WA") {
		daysToAdd = 364;
	}

	const today = new Date();
	let upcomingDates = [anchorDate];
	let currentIteration = 1;

	// Continue until we collect the required number of future dates
	while (upcomingDates.length < numberOfDates) {
		const nextDate = addDays(anchorDate, currentIteration * daysToAdd);

		// Only add dates in the future
		if (isAfter(nextDate, today)) {
			upcomingDates.push(nextDate);
		}

		currentIteration++;
	}

	return upcomingDates;
};

export const getUpcomingWeekdays = (startDate, selectedWeekdays, count) => {
	const weekdayMap = {
		SUN: 0,
		MON: 1,
		TUE: 2,
		WED: 3,
		THU: 4,
		FRI: 5,
		SAT: 6,
	};

	// Start from the later of startDate or today
	const today = new Date();
	let currentDate = new Date(
		Math.max(new Date(startDate), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0))
	);
	console.log(currentDate);
	console.log(new Date(startDate), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
	console.log(new Date(startDate) >= new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));

	const dates =
		new Date(startDate) >= new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
			? [new Date(startDate)]
			: [];
	if (dates.length) {
		currentDate.setDate(currentDate.getDate() + 1);
	}
	console.log({ dates });

	while (dates.length < count) {
		const currentWeekday = currentDate.getDay();
		const weekdayString = Object.keys(weekdayMap).find((key) => weekdayMap[key] === currentWeekday);

		// If the current date's weekday is in the selected weekdays, add it to the result
		if (selectedWeekdays.includes(weekdayString)) {
			dates.push(new Date(currentDate));
		}

		// Move to the next day
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
};

export const formattedDate = (date) => {
	if (date !== null && date !== undefined && Object.keys(date)?.includes("seconds")) {
		return format(date.toDate(), "EEE, MM/dd/yyyy");
	}
	return date !== null && date !== undefined ? format(date, "EEE, MM/dd/yyyy") : "";
};
export const formattedDateTime = (date) => {
	if (date !== null && date !== undefined && Object.keys(date).includes("seconds")) {
		return format(date.toDate(), "EEE, MM/dd/yyyy h:mm aa");
	}
	return date !== null && date !== undefined ? format(new Date(date), "EEE, MM/dd/yyyy h:mm aa") : "";
};

export function getDateTimeFromTimestamp(timestamp) {
	if (!timestamp || !(timestamp instanceof Timestamp)) {
		throw new Error("Invalid Firebase Timestamp provided");
	}

	const date = timestamp.toDate();
	const formattedDate = date.toLocaleDateString();

	return date;
}

export const showErrorToastMessage = (message) => {
	toast.dismiss();
	toast.error(message);
};
export const showSuccessToastMessage = (message) => {
	toast.dismiss();
	toast.success(message);
};
export const showInfoToastMessage = (message) => {
	toast.dismiss();
	toast.info(message);
};
export const showLoadingToastMessage = (message) => {
	toast.dismiss();
	toast.loading(message);
};
export const showInternalServerErrorToastMessage = () => {
	toast.dismiss();
	toast.loading("Internal Server Error. Please try again later.");
};

export const horizontalScrollHandler = (event) => {
	const container = event.currentTarget;
	const scrollAmount = event.deltaY * 3;
	container.scrollTo({
		top: 0,
		left: container.scrollLeft + scrollAmount,
		behavior: "smooth",
	});
};

export const splitArrayIntoChunks = (array, chunkSize = 100) => {
	const chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize));
	}
	return chunks;
};

export const validateImage = (file) => {
	const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

	// Check if the file is an image
	if (!file.type.startsWith("image/")) {
		return false;
	}

	// Check if the file type is supported
	if (!validImageTypes.includes(file.type)) {
		return false;
	}

	// Check file size (optional, adjust limit as needed)
	const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
	if (file.size > maxSizeInBytes) {
		return false;
	}

	return true;
};

export const uploadFileToFirebaseStorage = async (file, storageRef) => {
	try {
		const res = await uploadBytes(storageRef, file, { type: file?.type ?? "" });
		console.log({ res });

		return await getDownloadURL(res.ref);
	} catch (error) {
		console.error("Error uploading file:", error);
		throw error; // Re-throw the error to handle it at the calling level
	}
};

export const dateFormatter = (d) => {
	const date = new Date(d);
	const formattedDate = date.toLocaleDateString("en-US", {
		weekday: "short",
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	});
	return formattedDate;
};
export const timeFormatter = (d) => {
	const date = new Date(d);
	const formattedDate = date.toLocaleDateString("en-US", {
		hour12: true,
	});
	return formattedDate;
};

const getLocationType = () => {};

export const getPinImage = (type) => {
	if (type === LOCATION_TYPE.GENERATOR) {
		return "/generator.svg";
	}
	if (type === LOCATION_TYPE.VENDOR) {
		return "/vendor.svg";
	}
	if (type === LOCATION_TYPE.SATELLITE) {
		return "/satellite.svg";
	}
	if (type === LOCATION_TYPE.TSTF) {
		return "/transfer_facilities.svg";
	}
};

export const renderAddress = (address) => {
	return `${address?.suite ? `${address?.suite + ", "}` : ""}${address?.street ? `${address?.street + ", "}` : ""}${
		address?.city ? `${address?.city + ", "}` : ""
	}${address?.state ? `${address?.state + ", "}` : ""}${address?.zipCode ? `${address?.zipCode + ""}` : ""}`;
};

export function generateServiceMonths(year = new Date().getFullYear()) {
	const currentYear = year;

	const dates = [];

	// Previous 3 months
	for (let i = 0; i < 12; i++) {
		const year = i >= 0 ? currentYear : currentYear - 1; // Adjust year for negative months
		const month = (i + 12) % 12; // Adjust month for negative values
		dates.push(new Date(year, month, 1));
	}

	return dates;
}

export const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const generateUTCDate = (date) => {
	const currentDate = new Date(date);
	return new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0));
};

export const groupGeneratorsByParent = (generators) => {
	const groupedGenerators = {};
	const parentGenerators = [];

	// Group generators by parentAccount
	generators.forEach((generator, index) => {
		if (!generator.parentAccount) {
			parentGenerators.push(generator); // Parent generator
		} else {
			if (!groupedGenerators[generator.parentAccount]) {
				groupedGenerators[generator.parentAccount] = [];
			}
			groupedGenerators[generator.parentAccount].push(generator);
		}
	});

	// Merge parent generators with their children
	const orderedGenerators = [];
	parentGenerators.forEach((parent) => {
		orderedGenerators.push(parent);
		if (groupedGenerators[parent.id]) {
			orderedGenerators.push(...groupedGenerators[parent.id]);
		}
	});

	return orderedGenerators;
};

export const convertSecondsToHours = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	if (hours === 0) {
		return `${minutes} min`;
	}
	return `${hours} hr ${minutes} min`;
};

export const convertMetersToMiles = (meters) => {
	const miles = meters / 1609.34;
	return miles.toFixed(2); // Round to 2 decimal places
};

import React from "react";

const Lock = ({ className = "" }) => {
	return (
		<svg
			width="11"
			height="11"
			className={className}
			viewBox="0 0 11 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.33463 3.66667V3.20833C7.33463 2.19581 6.51382 1.375 5.5013 1.375V1.375C4.48878 1.375 3.66797 2.19581 3.66797 3.20833V3.66667"
				strokeLinecap="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.66789 3.5009C1.375 3.79379 1.375 4.2652 1.375 5.20801V8.08301C1.375 9.02582 1.375 9.49722 1.66789 9.79011C1.96079 10.083 2.43219 10.083 3.375 10.083H7.625C8.56781 10.083 9.03921 10.083 9.33211 9.79011C9.625 9.49722 9.625 9.02582 9.625 8.08301V5.20801C9.625 4.2652 9.625 3.79379 9.33211 3.5009C9.03921 3.20801 8.56781 3.20801 7.625 3.20801H3.375C2.43219 3.20801 1.96079 3.20801 1.66789 3.5009ZM5.5 6.79134C5.70711 6.79134 5.875 6.62345 5.875 6.41634C5.875 6.20923 5.70711 6.04134 5.5 6.04134C5.29289 6.04134 5.125 6.20923 5.125 6.41634C5.125 6.62345 5.29289 6.79134 5.5 6.79134ZM6.875 6.41634C6.875 6.99934 6.51217 7.49759 6 7.69761V8.70801H5V7.69761C4.48783 7.49759 4.125 6.99934 4.125 6.41634C4.125 5.65695 4.74061 5.04134 5.5 5.04134C6.25939 5.04134 6.875 5.65695 6.875 6.41634Z"
			/>
		</svg>
	);
};

export default Lock;

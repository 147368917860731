import PropTypes from "prop-types";
import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerLoader from "../../../../../../components/UI/loaders/ShimmerLoader";
import { horizontalScrollHandler, showErrorToastMessage, showInfoToastMessage } from "../../../../../../utils/helpers";
import Tooltip from "../../../../../../components/UI/Tooltip";
import MultiselectDropdown from "../../../../../../components/UI/dropdowns/MultiselectDropdown";
import { statusOptions } from "../../../../../../utils/routeConstants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, COLLECTIONS, db } from "../../../../../../config/firebase";
import { saveAs } from "file-saver";
import { createPortal } from "react-dom";
import * as XLSX from "xlsx";
import { AnimatePresence, motion } from "framer-motion";
import { SERVICE_TYPES, serviceTypes } from "../../../../../../utils/constants";
import { collection, onSnapshot, query, where } from "firebase/firestore";

const ManageRoutes = () => {
	const [user, isAuthLoading, error] = useAuthState(auth);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", ...statusOptions.map((el) => el.value)]);
	const navigate = useNavigate();
	const filteredData = useMemo(() => {
		return data.filter((route) => {
			console.log({ route });
			console.log({ selectedStatuses });
			if (selectedStatuses.includes(route.status)) {
				return true;
			}
			return false;
		});
	}, [data, selectedStatuses]);
	useEffect(() => {
		if (!isAuthLoading && !user) {
			navigate("/auth");
		}
	}, [isAuthLoading, user]);

	useEffect(() => {
		let unsubscribe = null;
		setLoading(true);
		try {
			unsubscribe = onSnapshot(
				query(collection(db, COLLECTIONS.routes), where("transporterId", "==", user.uid)),
				(routesSnap) => {
					const docs = routesSnap.docs.map((snap) => {
						const { routeLabel, truckCostPerMile } = snap.data();
						return {
							id: snap.id,
							name: routeLabel,
							endLocation: snap.data()?.endLocation ?? snap.data()?.defaultEndLocation,
							startLocation: snap.data()?.startLocation ?? snap.data()?.defaultStartLocation,
							truckCostPerMile,
							...snap.data(),
						};
					});
					setData(docs);
				}
			);
		} catch (err) {
			console.log(err);
			showErrorToastMessage("Unable to get routes.");
		} finally {
			setLoading(false);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	useEffect(() => {
		if (selectedRoutes.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedRoutes.length === filteredData.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedRoutes, filteredData]);

	const handleSelectAllChange = () => {
		setSelectedRoutes((prev) => {
			if (prev.length === data.length) {
				return [];
			} else {
				return data.map((el) => el.id);
			}
		});
	};

	const handleCheckboxChange = (index) => {
		setSelectedRoutes((prev) => {
			if (prev.includes(index)) {
				return prev.filter((i) => i !== index);
			} else {
				return [...prev, index];
			}
		});
	};

	const handleRowClick = (route) => {
		navigate(`${route.id}/edit`);
	};

	const newRouteClickHandler = () => {
		navigate("/admin/assets/routes/add");
	};
	const statusFilterChangeHandler = (selected) => {
		console.log({ selected });

		if (selected.includes("All")) {
			setSelectedStatuses(["all", ...statusOptions.map((el) => el.value)]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};

	const renderFooterItems = () => {
		// const deleteGenerators = () => {
		// 	const selectedGenIds = generators.filter((item, i) => genSelected.includes(i)).map((item) => item.id);
		// 	try {
		// 		dispatch(deleteGenerator(selectedGenIds));
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// };

		const editButtonHandler = async () => {
			console.log({ selectedRoutes });
			navigate(`/admin/assets/routes/${selectedRoutes[0]}/edit`);
		};
		const deleteButtonHandler = async () => {
			showInfoToastMessage("This feature is in development.");
		};
		return (
			<div
				className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${
					selectedRoutes?.length > 0 ? "translate-y-0" : "translate-y-full"
				}`}
			>
				<div className="w-full py-4 flex justify-between items-center">
					<span className="truncate">{selectedRoutes?.length} item(s) selected</span>
					<div className="flex gap-2">
						<ExportComponent selectedRoutes={selectedRoutes} routes={data} />

						<button
							disabled={selectedRoutes.length > 1 ? true : false}
							className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed ${"bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"}  border transition-colors duration-200 ease-in-out`}
							onClick={editButtonHandler}
						>
							Edit
						</button>
						<button
							disabled={false}
							className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed ${"bg-lightBlue text-white hover:bg-primaryBlue"}  border transition-colors duration-200 ease-in-out`}
							onClick={deleteButtonHandler}
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div>Routes List</div>
					<div className=" px-2">
						<MultiselectDropdown
							buttonText="Route Status"
							options={[{ label: "All", value: "all" }, ...statusOptions]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div
					className="overflow-x-scroll overflow-y-hidden"
					style={{
						height: `calc(100% - 10px)`,
					}}
				>
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center gap-2">
							<div className="truncate w-60">Name</div>
							<div className="truncate w-32">Route Id #</div>
							<div className="truncate w-80">Start Location</div>
							<div className="truncate w-80">End Location</div>
							<div className="truncate w-32">Status</div>
							<div className="truncate w-60">Vehicle Cost Per Mile</div>
							{/* <div className="truncate w-32">Duty Status</div> */}
						</div>
					</div>

					{loading ? (
						<ShimmerLoader cols={6} rows={20} />
					) : (
						<div style={{ height: `calc(100% - 60px)` }} className="min-w-fit overflow-y-scroll overflow-x-hidden pb-2">
							{filteredData.length > 0 ? (
								filteredData.map((route, index) => (
									<RouteItem
										key={route.id}
										route={route}
										index={route.id}
										selectedRoutes={selectedRoutes}
										handleCheckboxChange={handleCheckboxChange}
										handleRowClick={handleRowClick}
									/>
								))
							) : (
								<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
									<p>No Route found.</p>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{/* Add New Driver Button - Hidden when checkboxes are selected */}
			{selectedRoutes.length === 0 && (
				<div className="fixed bottom-8 right-8">
					<button
						onClick={newRouteClickHandler}
						className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
						</svg>
						<span>Add New Route</span>
					</button>
				</div>
			)}
			{renderFooterItems()}
		</div>
	);
};

const RouteItem = ({ route, index, selectedRoutes, handleCheckboxChange, handleRowClick }) => {
	console.log({ selectedRoutes });
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);

	const handleMouseEnter = (driverId, event) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(driverId);
			updateTooltipPosition(event);
		}, 1000);
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (
			!tooltipRef.current ||
			!event.relatedTarget ||
			!(event.relatedTarget instanceof Node) ||
			!tooltipRef.current.contains(event.relatedTarget)
		) {
			setActiveTooltip(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 300;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;
		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<div
			className="flex w-full items-center transition-colors duration-300 border-b border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20"
			style={{ height: "45px" }}
		>
			<div className="min-w-8 mx-6 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedRoutes.includes(index)}
					onChange={() => handleCheckboxChange(index)}
				/>
			</div>
			<div
				className="w-full flex items-center cursor-pointer gap-2"
				onClick={() => handleRowClick(route)}
				onMouseEnter={(e) => handleMouseEnter(route.id, e)}
				onMouseLeave={handleMouseLeave}
			>
				<div className="truncate w-60">{`${route?.name ?? "--"} `}</div>
				<div className="truncate w-32">{route.id?.slice(0, 6) || "--"}</div>
				<div className="w-80 truncate">
					<span

					// onClick={(e) => route.phoneNumber && handlePhoneClick(e, route.phoneNumber)}
					>
						{route?.startLocation?.name ?? "--"} [{route?.startLocation?.type ?? "--"}]
					</span>
				</div>
				<div className="w-80 truncate">
					<span

					// onClick={(e) => route.phoneNumber && handlePhoneClick(e, route.phoneNumber)}
					>
						{route?.endLocation?.name ?? "--"} [{route?.endLocation?.type ?? "--"}]
					</span>
				</div>
				<div className="truncate w-32">
					{statusOptions[statusOptions.findIndex((el) => el.value === route.status)].label || "--"}
				</div>
				<div className="truncate w-60">{route.truckCostPerMile || "--"}</div>
			</div>
			<AnimatePresence>
				{activeTooltip === route.id && (
					<motion.div
						ref={tooltipRef}
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
							width: "300px",
						}}
						initial={{ opacity: 0, scale: 0.8, transformOrigin }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setActiveTooltip(route.id)}
						onMouseLeave={handleMouseLeave}
					>
						<ToolTipContent item={route} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

RouteItem.propTypes = {
	handleCheckboxChange: PropTypes.func,
	handleRowClick: PropTypes.func,
	index: PropTypes.any,
	route: PropTypes.shape({
		endLocation: PropTypes.shape({
			formattedAddress: PropTypes.any,
			formatted_address: PropTypes.any,
		}),
		id: PropTypes.shape({
			slice: PropTypes.func,
		}),
		name: PropTypes.string,
		startLocation: PropTypes.shape({
			formattedAddress: PropTypes.any,
			formatted_address: PropTypes.any,
		}),
		status: PropTypes.any,
		truckCostPerMile: PropTypes.string,
	}),
	selectedDrivers: PropTypes.shape({
		includes: PropTypes.func,
	}),
};

const ToolTipContent = ({ item }) => {
	const details = [
		{ label: "Route Name", value: item?.routeLabel ?? "" },
		{ label: "Color", value: item?.color || "--" },
		{ label: "Default Start Location", value: item?.defaultStartLocation?.name || "--" },
		{ label: "Default End Location", value: item?.defaultEndLocation?.name || "--" },
		{ label: "Status", value: item?.status || "--" },
		{ label: "Total Distance In Meters", value: item?.totalDistanceInMeters || "--" },
		{ label: "Total Time In Seconds", value: item?.totalTimeInSeconds || "--" },
		{ label: "Truck Cost Per Mile", value: item?.truckCostPerMile || "Active" },
		{
			label: "Route Type",
			value:
				item?.type?.reduce((prev, curr) => {
					return `${prev} ${serviceTypes[serviceTypes.findIndex((el) => el.value === curr)]?.label ?? ""}`;
				}, "") || "--",
		},
	];
	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

const ExportComponent = ({ selectedRoutes, routes }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Close modal when clicking outside
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// Step 1: Data preparation (20% of progress)
			let data = routes.filter((_, i) => selectedRoutes.includes(_.id));
			const worksheetData = data.map((item, index) => {
				setProgress((index / data.length) * 20);
				return {
					"Route Name": item?.routeLabel ?? "",
					Color: item?.color ?? "",
					"Default Start Location": item?.defaultStartLocation.name ?? "",
					"Default End Location": item?.defaultEndLocation.name ?? "",
					Status: item?.status ?? "",
					"Total Distance In Meters": item?.totalDistanceInMeters ?? "",
					"Total Time In Seconds": item?.totalTimeInSeconds ?? "",

					Type:
						item?.type?.reduce((prev, curr) => {
							return `${prev} ${curr}`;
						}, "") ?? "",
					"Vehicle Cost Per Mile": item?.truckCostPerMile ?? "",
				};
			});
			setProgress(20);

			// Step 2: Create worksheet (40% of progress)
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			setProgress(40);

			// Step 3: Convert to binary (70% of progress)
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			setProgress(70);

			// Step 4: Create blob and prepare for download (90% of progress)
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			setProgress(90);

			// Step 5: Trigger download (100% of progress)
			saveAs(blob, "exported_data.xlsx");
			setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<>
			<button
				onClick={handleExport}
				disabled={isExporting}
				className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 
				disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 
				border transition-colors duration-200 ease-in-out`}
			>
				{isExporting ? "Exporting..." : "Export"}
			</button>

			<ExportModal
				isOpen={isModalOpen}
				isExporting={isExporting}
				progress={progress}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	);
};

const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
				  focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

export default ManageRoutes;

const generateTimeSlots = (start = 7, end = 19, intervalMinutes = 15) => {
	const slots = [];
	let current = new Date();
	current.setHours(start, 0, 0, 0);

	while (current.getHours() < end || (current.getHours() === end && current.getMinutes() === 0)) {
		const hours = current.getHours();
		const minutes = current.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
		slots.push(`${formattedHour}:${formattedMinutes} ${ampm}`);
		current.setMinutes(current.getMinutes() + intervalMinutes);
	}

	return slots;
};

// const routeData = [
// 	{
// 		routeId: "RT001",
// 		routeColor: "#FF4136",
// 		routeName: "SD Monday",
// 		routeStatus: "completed",
// 		completedAt: "3:30 PM",
// 		startPoint: "Central Depot",
// 		endPoint: "South Facility",
// 		startTime: "8:00 AM",
// 		expectedEndTime: "4:00 PM",

// 		driver: {
// 			id: "DRV123",
// 			name: "John Smith",
// 			phoneNumber: "+1-555-0123",
// 			licenseNumber: "DL789456",
// 			experience: "5 years",
// 			rating: 4.8,
// 			activeStatus: true,
// 		},

// 		vehicle: {
// 			id: "VEH456",
// 			type: "Heavy Duty Truck",
// 			model: "Peterbilt 579",
// 			year: 2023,
// 			licensePlate: "XYZ 789",
// 			capacity: "40000 lbs",
// 			fuelType: "Diesel",
// 			lastMaintenance: "2024-12-15",
// 			nextMaintenance: "2025-01-15",
// 		},

// 		stops: [
// 			{
// 				stopId: "STP001",
// 				generatorName: "North Hospital",
// 				serviceStatus: {
// 					status: "completed",
// 					completedAt: "9:30 AM",
// 					completedBy: "DRV123",
// 					issuesReported: false,
// 					notes: "Service completed as scheduled",
// 				},
// 				serviceAddress: {
// 					street: "123 Healthcare Ave",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62701",
// 					coordinates: {
// 						latitude: 39.78373,
// 						longitude: -89.65063,
// 					},
// 				},
// 				scheduledTime: "9:00 AM",
// 				actualServiceTime: "9:05 AM",
// 				isServiced: true,
// 				serviceDuration: 25, // minutes
// 				serviceType: "medicalWaste",
// 				containerCount: 3,
// 				notes: "Access through rear entrance",
// 			},
// 			{
// 				stopId: "STP002",
// 				generatorName: "East Medical Center",
// 				serviceStatus: {
// 					status: "completed",
// 					completedAt: "11:15 AM",
// 					completedBy: "DRV123",
// 					issuesReported: true,
// 					notes: "One container had damaged lid",
// 				},
// 				serviceAddress: {
// 					street: "456 Medical Parkway",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62702",
// 					coordinates: {
// 						latitude: 39.78893,
// 						longitude: -89.64511,
// 					},
// 				},
// 				scheduledTime: "10:30 AM",
// 				actualServiceTime: "10:45 AM",
// 				isServiced: true,
// 				serviceDuration: 30, // minutes
// 				serviceType: "paperShredding",
// 				containerCount: 4,
// 				notes: "Check in at security desk",
// 			},
// 			{
// 				stopId: "STP003",
// 				generatorName: "West Clinic",
// 				serviceStatus: {
// 					status: "completed",
// 					completedAt: "1:35 PM",
// 					completedBy: "DRV123",
// 					issuesReported: false,
// 					notes: "Service completed ahead of schedule",
// 				},
// 				serviceAddress: {
// 					street: "789 Clinical Drive",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62703",
// 					coordinates: {
// 						latitude: 39.77932,
// 						longitude: -89.66354,
// 					},
// 				},
// 				scheduledTime: "1:00 PM",
// 				actualServiceTime: "1:15 PM",
// 				isServiced: false,
// 				serviceDuration: 20, // minutes
// 				serviceType: "medicalWaste",
// 				containerCount: 2,
// 				notes: "Parking available in staff lot",
// 			},
// 			{
// 				stopId: "STP003",
// 				generatorName: "West Clinic",
// 				serviceStatus: {
// 					status: "completed",
// 					completedAt: "4:30 PM",
// 					completedBy: "DRV123",
// 					issuesReported: false,
// 					notes: "Service completed ahead of schedule",
// 				},
// 				serviceAddress: {
// 					street: "789 Clinical Drive",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62703",
// 					coordinates: {
// 						latitude: 39.77932,
// 						longitude: -89.66354,
// 					},
// 				},
// 				scheduledTime: "4:30 PM",
// 				actualServiceTime: "4:45 PM",
// 				isServiced: false,
// 				serviceDuration: 20, // minutes
// 				serviceType: "medicalWaste",
// 				containerCount: 2,
// 				notes: "Parking available in staff lot",
// 			},
// 			{
// 				stopId: "STP003",
// 				generatorName: "West Clinic",
// 				serviceStatus: {
// 					status: "completed",
// 					completedAt: "6:30 PM",
// 					completedBy: "DRV123",
// 					issuesReported: false,
// 					notes: "Service completed ahead of schedule",
// 				},
// 				serviceAddress: {
// 					street: "789 Clinical Drive",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62703",
// 					coordinates: {
// 						latitude: 39.77932,
// 						longitude: -89.66354,
// 					},
// 				},
// 				scheduledTime: "6:30 PM",
// 				actualServiceTime: "7:00 PM",
// 				isServiced: false,
// 				serviceDuration: 20, // minutes
// 				serviceType: "paperShredding",
// 				containerCount: 2,
// 				notes: "Parking available in staff lot",
// 			},
// 		],

// 		routeMetrics: {
// 			totalDistance: 45.6, // miles
// 			totalStops: 3,
// 			completedStops: 3,
// 			plannedDuration: "8 hours",
// 			actualDuration: "7.5 hours",
// 			fuelConsumption: 12.3, // gallons
// 			averageSpeed: 35.4, // mph
// 		},
// 	},
// 	{
// 		routeId: "RT002",
// 		routeName: "SD Friday",
// 		routeColor: "#2ECC40",
// 		routeStatus: "pending",
// 		startPoint: "East Depot",
// 		endPoint: "North Facility",
// 		startTime: "9:00 AM",
// 		expectedEndTime: "5:00 PM",

// 		driver: {
// 			id: "DRV124",
// 			name: "Sarah Johnson",
// 			phoneNumber: "+1-555-0124",
// 			licenseNumber: "DL789457",
// 			experience: "7 years",
// 			rating: 4.9,
// 			activeStatus: true,
// 		},

// 		vehicle: {
// 			id: "VEH457",
// 			type: "Medium Duty Truck",
// 			model: "Freightliner M2",
// 			year: 2024,
// 			licensePlate: "ABC 123",
// 			capacity: "26000 lbs",
// 			fuelType: "Diesel",
// 			lastMaintenance: "2024-12-20",
// 			nextMaintenance: "2025-01-20",
// 		},

// 		stops: [
// 			{
// 				stopId: "STP004",
// 				generatorName: "South Medical Complex",
// 				serviceStatus: {
// 					status: "pending",
// 					scheduledFor: "10:00 AM",
// 					assignedTo: "DRV124",
// 					priority: "normal",
// 					notes: "Regular scheduled pickup",
// 				},
// 				serviceAddress: {
// 					street: "321 Hospital Road",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62704",
// 					coordinates: {
// 						latitude: 39.76821,
// 						longitude: -89.65872,
// 					},
// 				},
// 				scheduledTime: "10:00 AM",
// 				isServiced: false,
// 				serviceDuration: 35, // minutes
// 				serviceType: "medicalWaste",
// 				containerCount: 5,
// 				notes: "Call facility manager upon arrival",
// 			},
// 			{
// 				stopId: "STP005",
// 				generatorName: "Downtown Urgent Care",
// 				serviceStatus: {
// 					status: "delayed",
// 					scheduledFor: "11:30 AM",
// 					assignedTo: "DRV124",
// 					priority: "high",
// 					notes: "Delayed due to traffic congestion",
// 				},
// 				serviceAddress: {
// 					street: "654 Main Street",
// 					city: "Springfield",
// 					state: "IL",
// 					zipCode: "62701",
// 					coordinates: {
// 						latitude: 39.77654,
// 						longitude: -89.64982,
// 					},
// 				},
// 				scheduledTime: "11:30 AM",
// 				isServiced: false,
// 				serviceDuration: 25, // minutes
// 				serviceType: "paperShredding",
// 				containerCount: 2,
// 				notes: "Limited parking available",
// 			},
// 		],

// 		routeMetrics: {
// 			totalDistance: 38.2, // miles
// 			totalStops: 2,
// 			completedStops: 0,
// 			plannedDuration: "8 hours",
// 			actualDuration: null,
// 			fuelConsumption: null,
// 			averageSpeed: null,
// 		},
// 	},
// ];

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, onSnapshot, query, setDoc, Timestamp, where } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../config/firebase";
import { scheduledServiceStatus, serviceTypes } from "../../../../../utils/constants";
import Loader from "../../../../../components/UI/loaders/Loader";
import { toast } from "react-toastify";
import { dateFormatter, showInfoToastMessage } from "../../../../../utils/helpers";
import TodaysReportsProgressBar from "./TodaysReportsProgressBar";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { IoIosArrowDown } from "react-icons/io";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";

const hourMarkers = generateTimeSlots();

const Reports = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [totalServices, setTotalServices] = useState(0);
	const [totalPendingServices, setTotalPendingServices] = useState(0);
	const [totalInprogressServices, setTotalInprogressServices] = useState(0);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [routeData, setRouteData] = useState([]);
	const [selectedZones, setSelectedZones] = useState([]);
	const [selectedTransporterLocations, setSelectedTransporterLocations] = useState([]);
	const [selectedSubContractors, setSelectedSubContractors] = useState([]);

	const [openRoutes, setOpenRoutes] = useState({});

	const toggleRouteDetails = (routeId) => {
		setOpenRoutes((prev) => ({
			...prev,
			[routeId]: !prev[routeId],
		}));
	};

	const handleNextClick = () => {
		navigate("/admin/generators/reports/service-reporting");
	};

	const handlePerviousbtn = () => {
		console.log("Save button clicked");
		navigate(-1);
	};
	const today = new Date();
	const currentDate = today.toLocaleDateString("en-US", {
		weekday: "short",
		month: "numeric",
		day: "numeric",
		year: "numeric",
	});

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A"; // Handle missing data gracefully
		}

		return scheduleData.serviceType.reduce((accumulator, serviceType) => {
			const matchingService = serviceTypes.find((service) => service.value === serviceType);
			return accumulator.concat(matchingService ? matchingService.label : "N/A");
		}, []); // Use an empty array as the initial accumulator
	};

	useEffect(() => {
		const startDate = new Date(today);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);
		const endDate = new Date(today);
		endDate.setHours(23);
		endDate.setMinutes(59);
		endDate.setSeconds(59);
		const fromDate = Timestamp.fromDate(new Date(startDate));
		const toDate = Timestamp.fromDate(new Date(endDate));
		const q = query(
			collection(db, COLLECTIONS.scheduledServices),
			where("date", ">=", fromDate),
			where("date", "<=", toDate)
		);
		let unsubscribe = onSnapshot(q, async (snap) => {
			try {
				// setIsLoading(true);
				const allCompletedServices = [];
				if (snap.docs.length === 0) {
					showInfoToastMessage("No complete service found for today.");
					return;
				}

				let localPending = 0;
				let localInProgress = 0;
				const jobs = snap.docs.map(async (serviceSnap) => {
					const serviceData = serviceSnap.data();

					if (serviceData.status != scheduledServiceStatus.COMPLETE) {
						if (serviceData.status == scheduledServiceStatus.PENDING) {
							localPending++;
						}
						if (serviceData.status == scheduledServiceStatus.INPROGRESS) {
							localInProgress++;
						}
						return;
					}
					const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
					const scheduleRes = await getDoc(scheduleDataRef);
					if (!scheduleRes.exists()) {
						return;
					}
					const scheduleData = scheduleRes.data();

					const serviceTypeString = getStringOfServiceTypes(scheduleData);
					const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
					const generatorRes = await getDoc(generatorDataRef);
					if (!generatorRes.exists()) {
						return;
					}
					const generatorData = generatorRes.data();

					const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
					const routeRes = await getDoc(routeDataRef);
					if (!routeRes.exists()) {
						return;
					}
					const routeData = routeRes.data();

					allCompletedServices.push({
						id: serviceSnap.id,
						date: dateFormatter(serviceData.date.toDate().toLocaleDateString()),
						time: serviceData.date.toDate().toLocaleTimeString(),
						customer: `${generatorData?.generatorName ?? "N/A"} `,
						octoNumber: `${generatorData?.octoNumber ?? "N/A"}`,
						email: `${generatorData?.generatorEmail ?? "N/A"}`,
						address: `${generatorData?.suite ?? ""} ${generatorData?.street ?? ""} ${generatorData?.city ?? ""} ${
							generatorData?.state ?? ""
						} ${generatorData?.zipCode ?? ""}`,
						billed: serviceData?.isBilled ?? false,
						billedDate: serviceData?.billedDate?.toDate() ?? null,
						paidDate: serviceData?.paidDate?.toDate() ?? null,
						paid: serviceData?.isPaid ?? false,
						paidAmount: serviceData?.paidAmount ?? "dummy amount",
						optionChecked: false,
						driverName: `${serviceData?.assignedDriverName ?? "N/A"}`,
						pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "N/A"}`,
						serviceType: serviceTypeString ?? "dummy service",
						routeName: routeData?.routeLabel ?? "dummy route name",
					});
				});
				await Promise.all(jobs);
				console.log({
					allCompletedServices,
				});
				console.log({ localInProgress, localPending, allCompletedServices });

				// setTotalInprogressServices(localInProgress);
				// setTotalPendingServices(localPending);
				// setCompleteServices(allCompletedServices);
				// setTotalServices(snap.docs.length);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const handleSelectedOptionsChange = (selected, type) => {
		if (type == "routes") setSelectedRoutes(selected);
		if (type == "zone") setSelectedZones(selected);
		if (type == "transporterLocation") setSelectedTransporterLocations(selected);
		if (type == "subcontractors") setSelectedSubContractors(selected);
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div className="main-container flex flex-col border overflow-scroll border-cardBorderCol bg-white rounded-cardRadii">
				<div className="px-8 pt-8 w-full flex flex-col 2xl:flex-row gap-4">
					<div className="flex justify-between gap-4 w-full">
						<div className="w-full">
							<MultiselectDropdown
								width="w-full"
								buttonText="Routes"
								options={[]}
								selectedOptions={[]}
								onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "routes")}
							/>
						</div>
						<div className="w-full">
							<MultiselectDropdown
								width="w-full"
								buttonText="Zone"
								options={[]}
								selectedOptions={[]}
								onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "zone")}
							/>
						</div>
						<div className="w-full">
							<MultiselectDropdown
								width="w-full"
								buttonText="Transporter Location"
								options={[]}
								selectedOptions={[]}
								onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "transporterLocation")}
							/>
						</div>
						<div className="w-full">
							<MultiselectDropdown
								width="w-full"
								buttonText="Subcontractors"
								options={[]}
								selectedOptions={[]}
								onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "subcontractors")}
							/>
						</div>
						<div className="w-60 text-nowrap px-4">
							<button className="text-primary hover:text-blue-900 py-2">Clear Filters</button>
						</div>
					</div>
					<div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-1 2xl:w-[20%] gap-x-4 2xl:flex-col gap-1 text-nowrap">
						{["Total Scheduled Services", "Completed Services", "Incomplete Services", "Out Of Network Services"].map(
							(item) => (
								<div className="flex items-center gap-2">
									<p>0</p>
									<p>{item}</p>
								</div>
							)
						)}
					</div>
				</div>

				<div className="flex justify-between mx-8 pb-2 border-b-2 border-l-borderCol">
					<div className="text-lg font-medium text-black">Live View</div>
				</div>

				<div className="flex flex-col justify-center items-center mt-12 pb-2 px-8">
					<TodaysReportsProgressBar
						pending={totalPendingServices}
						inProgress={totalInprogressServices}
						complete={totalServices - totalPendingServices - totalInprogressServices}
					/>
				</div>

				<div className="w-full h-[500px] p-8 pt-12">
					<MapWithMarkers
						geocodedMarkers={[]}
						initialCenter={{ lat: 32.7157, lng: -117.1611 }}
						isAddressesLoaded={true}
						roundedVal="rounded-[15px]"
					/>
				</div>

				<div className="w-full">
					<div className="w-full">
						<div className="overflow-x-auto overflow-y-visible">
							<div className="min-w-[4000px]">
								<div className="flex w-full border-b border-black border-opacity-30">
									<div className="w-32 md:w-[360px] py-4 px-2 md:px-4 items-start flex-shrink-0">
										<p className="text-sm md:text-lg pl-4 text-black font-medium">Live View</p>
									</div>

									<div className="flex-1 flex items-end relative">
										{hourMarkers.map((time, index) => (
											<div key={time} className="flex-1 pb-8 relative">
												<div
													className="absolute bottom-0 left-0 h-12 md:h-20 w-px bg-black origin-bottom"
													style={{ transform: "rotate(45deg)" }}
												/>
												<div className="absolute bottom-0 left-0 transform -rotate-45 origin-bottom-left whitespace-nowrap mb-1.5">
													<span className="text-xs md:text-sm">{time}</span>
												</div>
											</div>
										))}
									</div>
								</div>
								{routeData.length == 0 && (
									<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
										<p>No result found</p>
									</div>
								)}
								{routeData.map(({ stops, routeName, driver, vehicle, routeColor }, index) => (
									<div className="px-2 md:px-8 pb-4 pt-4 border-b" key={index}>
										<div className="flex w-full">
											<div className="w-32 md:w-[320px] space-y-2 flex-shrink-0">
												<p className="text-base font-medium">
													Route Name: <span className="font-normal">{routeName}</span>
												</p>
												<p className="text-base font-medium">
													Driver Name: <span className="font-normal">{driver.name}</span>
												</p>
											</div>

											<div className="flex-1 pr-[0.8%]">
												<div className="relative h-16">
													{/* <div className="absolute top-1/2 left-0 right-4 h-1 rounded-full bg-red-700"></div> */}
													<div
														className="absolute top-1/2 left-0 right-4 h-1 rounded-full"
														style={{
															background: `linear-gradient(to right, ${routeColor}22 0%, ${routeColor}FF 100%)`,
														}}
													></div>
													{stops.map((stop, stopIndex) => (
														<StopComp key={stopIndex} stop={stop} index={stopIndex} routeCol={routeColor} />
													))}
												</div>
											</div>
										</div>
										<div>
											<p className="text-base font-medium">
												Service Vehicle: <span className="font-normal">{vehicle.type}</span>
											</p>
											<button
												className="flex items-center text-primary gap-4 py-2 hover:text-blue-950"
												onClick={() => toggleRouteDetails(index)}
											>
												<p>View Details</p>
												<IoIosArrowDown
													size={20}
													className={`font-bold min-w-fit transform transition-transform duration-200 
              										${openRoutes[index] ? "rotate-180" : "rotate-0"}`}
												/>
											</button>
										</div>

										{openRoutes[index] && (
											<div className="grid gap-1">
												<div className="flex gap-8 py-1 font-medium">
													<p className="w-52">Status</p>
													<p className="w-52">Generator Name</p>
													<p className="w-32 text-center">Service Record</p>
												</div>
												{routeData[index].stops.map((stop) => (
													<div className="flex gap-8">
														<p className="w-52">
															{stop.isServiced ? "Completed" : "Incomplete"}{" "}
															{stop.isServiced ? `[${stop.actualServiceTime ?? ""}]` : ""}
														</p>
														<p className="w-52">{stop.generatorName}</p>
														<p className="w-32 hover:text-primary cursor-pointer text-center">View</p>
													</div>
												))}
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePerviousbtn}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</>
	);
};

export default Reports;

const MapPin = ({ number, variant = "square", size = "8", routeCol = "red-600" }) => {
	const borderStyle = {
		border: `4px solid ${routeCol}`, // Apply dynamic color directly
	};

	const containerClasses = `
	  relative inline-flex items-center justify-center
	  bg-white
	  ${size === "8" ? (variant === "square" ? "w-8 h-8" : "w-9 h-9") : ""}
	  ${variant === "square" ? "rounded" : "rounded-full"}
	  z-10
	`;

	const shapeClasses = `
	  flex items-center justify-center  
	  font-semibold
	  ${size === "8" ? "text-md" : size === "12" ? "text-xl" : "text-2xl"}
	`;

	const pointerStyles = {
		position: "absolute",
		left: "50%",
		bottom: "-14px",
		transform: "translateX(-50%)",
		width: "0",
		height: "0",
		borderLeft: "10px solid transparent",
		borderRight: "10px solid transparent",
		borderTop: `14px solid ${routeCol}`, // matches border-red-600
	};

	return (
		<div style={borderStyle} className={containerClasses}>
			<div className={shapeClasses}>{number}</div>
			<div style={pointerStyles} />
		</div>
	);
};

const timeToMinutes = (timeStr) => {
	console.log({ timeStr });
	if (timeStr) {
		const [time, period] = timeStr.split(" ");
		let [hours, minutes] = time.split(":").map(Number);

		// Convert to 24-hour format
		if (period === "PM" && hours !== 12) hours += 12;
		if (period === "AM" && hours === 12) hours = 0;

		return hours * 60 + minutes;
	}
};

const getTimePosition = (stopTime) => {
	const startMinutes = timeToMinutes(hourMarkers[0]);
	const endMinutes = timeToMinutes(hourMarkers[hourMarkers.length - 1]);
	const currentMinutes = timeToMinutes(stopTime);

	const totalDuration = endMinutes - startMinutes;
	const currentDuration = currentMinutes - startMinutes;

	return `${(currentDuration / totalDuration) * 100}%`;
};

const StopComp = ({ stop, index, routeCol }) => {
	console.log({ stop });

	return (
		<div
			key={index}
			className="absolute top-1/2 p-3"
			style={{
				left: getTimePosition(stop.scheduledTime),
				transform: "translate(-50%, -50%)",
			}}
		>
			{!stop.isServiced ? (
				stop.serviceType == "medicalWaste" ? (
					<MapPin number={index + 1} variant="square" routeCol={routeCol} />
				) : (
					<MapPin number={index + 1} variant="circle" routeCol={routeCol} />
				)
			) : (
				<div
					className={`h-8 w-8 flex items-center justify-center font-bold ${
						stop.isServiced
							? "bg-primaryGreen text-white"
							: stop.serviceType == "medicalWaste"
							? `border-4 ${stop.color} bg-white rounded`
							: `border-4 ${stop.color} bg-white rounded-full`
					} mr-${4 + (index % 2 == 0 ? index : index + 1)} text-black`}
				>
					<p>{index + 1}</p>
				</div>
			)}
		</div>
	);
};

import React, { useState } from "react";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import Button from "../../../components/UI/Button";
import { USStates } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import SearchBox from "../../../components/UI/searchBox/Search";
import { FiSearch } from "react-icons/fi";

const WasteFinder = () => {
	const [generator, setGenerator] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [address, setAddress] = useState("");
	const [query, setQuery] = useState("");
	const navigate = useNavigate();

	const octoOptions = [];
	const generatorOptions = [];

	const stateOptions = [];

	const cityOptions = [];

	const zipCodeOptions = [];

	const handleClear = () => {
		setGenerator("");
		setState("");
		setCity("");
		setZipCode("");
		setOctonumber("");
	};

	const handleSearch = () => console.log("handle search");
	const handlePreviousBtn = () => navigate("/admin/wastes/manage");
	const handleNextClick = () => navigate("/admin/wastes/boxlocations");

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg font-medium mx-8 pt-6 pb-2">Search</div>
				<div className="flex items-center py-2 z-50 gap-4 w-full max-w-2xl mx-8 px-6 text-sm bg-white rounded-full border border-gray-200 h-12 relative">
					<FiSearch className="text-cardTextGray text-opacity-40" size={20} />
					<input
						type="text"
						placeholder="Search by OCTO Number"
						value={query}
						onChange={setQuery}
						className="bg-transparent focus:outline-none w-full text-cardTextGray h-full"
					/>
				</div>
				<div className="text-lg font-medium mx-8 pt-6 pb-2 border-b border-cardTextGray border-opacity-40">
					Gate Waste Finder
				</div>
				<div className="grid gap-x-10 gap-y-2 grid-cols-2 py-4 px-8 items-center justify-center">
					{/* <div className="flex items-center w-full justify-between"> */}
					<Dropdown options={[]} label={"Address"} margin="1" value={address} onChange={setAddress} />
					<Dropdown options={USStates} label={"State"} margin="1" value={state} onChange={setState} />
					<Dropdown options={cityOptions} label={"City"} margin="1" value={city} onChange={setCity} />
					<Dropdown options={zipCodeOptions} label={"ZIP Code"} margin="1" value={zipCode} onChange={setZipCode} />
				</div>

				<div className="flex justify-end px-8 py-4 gap-2 text-sm">
					<button
						onClick={handleClear}
						style={{ width: "130px", height: "28px" }}
						className="bg-whiteBlue px-8 rounded-full hover:bg-lightBlue hover:text-white transition-all duration-300"
					>
						Clear All
					</button>
					<button
						onClick={handleSearch}
						style={{ width: "130px", height: "28px" }}
						className="bg-lightBlue text-white px-4 rounded-full hover:bg-primaryBlue transition-all duration-300"
					>
						Search
					</button>
				</div>

				<div className="flex justify-end p-8">
					<button
						onClick={handlePreviousBtn}
						style={{ width: "184px", height: "44px" }}
						className="bg-primaryBlue text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-primaryBlue text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default WasteFinder;

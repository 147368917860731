import React, { useState, useEffect } from "react";
import { BsInfoCircle } from "react-icons/bs";

const InputTreatment = ({
	id,
	label,
	placeholder,
	type = "text",
	value,
	onChange,
	margin = "",
	style = null,
	isLoading = false,
	isDisabled = false,
	isInfo = "",
	isRequired = false,
	extra = "",
	inputClassName = "",
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const handleInputChange = (e) => {
		let input = e.target.value;

		if (type === "text") {
			// Remove all non-numeric characters except for the decimal point
			input = input.replace(/[^0-9.]/g, "");

			// Always add the $ symbol to the beginning of the input value
			input = input ? `$${input}` : "";
		}

		onChange({ target: { value: input } });
	};

	const cleanValueForSave = (input) => {
		// Remove the $ symbol and any non-numeric characters for saving
		return input.replace(/[^0-9]/g, "");
	};

	return (
		<div className={`relative flex ${style ?? "items-center justify-between"} ${margin}`}>
			{label && (
				<label
					htmlFor={id}
					className={`${
						style ? "w-full" : label ? "w-1/3" : "w-full"
					} text-inputLabel flex items-center gap-1 font-normal`}
				>
					{`${label}`} {isRequired ? "*" : ""}
					{isInfo?.length > 0 && (
						<div
							className="relative"
							onMouseEnter={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
						>
							<BsInfoCircle size={16} color="#666666" />
							{showTooltip && (
								<div className="absolute top-full left-0 min-w-20 mt-1 bg-gray-800 text-white text-sm rounded px-2 py-1 z-10">
									{isInfo}
								</div>
							)}
						</div>
					)}
					{isLoading ? (
						<svg className="w-4 h-4 text-cardTextGray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path
								className="fill-current"
								d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
								opacity=".25"
							/>
							<path
								className="fill-current animate-spin origin-center"
								d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
							/>
						</svg>
					) : (
						""
					)}
				</label>
			)}
			<div className={`relative ${style ? "" : label ? "w-2/3" : ""}`}>
				<input
					id={id}
					type={type}
					value={value}
					onChange={handleInputChange}
					placeholder={placeholder}
					disabled={isDisabled}
					className={`
            ${extra?.length > 0 ? "" : "disabled:bg-white"}
            w-24
            text-cardTextGray
            bg-inputBg
            border-none
            rounded-full
            py-2
            h-7
            px-2
            focus:outline-none
            focus:ring-1
            leading-tight
            focus:ring-dashInActiveBtnText
            [&:-webkit-autofill]:bg-inputBg!
            [&:-webkit-autofill:hover]:bg-inputBg!
            [&:-webkit-autofill:focus]:bg-inputBg!
            [&:-webkit-autofill:active]:bg-inputBg!
            [&.auto-filled]:bg-inputBg!
            [-webkit-text-fill-color:inherit]!
            ${inputClassName}
          `}
				/>
			</div>
		</div>
	);
};

export default InputTreatment;

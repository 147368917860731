import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import CustomDatePicker from "../../../../../../../../components/UI/CustomDatePicker";
import Textarea from "../../../../../../../../components/UI/Textarea";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import {
	frequencyPrimaryOptions,
	frequencySecondaryOptions,
	itemsMap,
	itemsOptions,
	SERVICE_STATUS,
	serviceTypes,
	weekdayOptions,
} from "../../../../../../../../utils/constants";
import {
	getAllGeneratorsLocationSnapshot,
	getAllRoutes,
	getAllRoutesPathData,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
	getServiceSchedules,
} from "../../../../../../../../utils/firebaseOperations";
import {
	daysOfWeek,
	formattedDate,
	getUpcomingDates,
	getUpcomingWeekdays,
	showErrorToastMessage,
	showInfoToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../../../utils/helpers";
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import {
	addOrUpdateGeneratorScheduleReq,
	deleteGeneratorScheduleReq,
	getGeneratorSchedules,
} from "../../../../../../../../utils/apiOps";
import GenRouteFooter from "./components/GenRouteFooter";
import FooterActionBtns from "./components/FooterActionBtns";
import RouteAssignment from "./components/RouteAssignment";
// import MapWithRoutes from "../../../../../../../../components/maps/MapWithRoutes";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { auth, COLLECTIONS, db } from "../../../../../../../../config/firebase";
import { Input } from "postcss";
import Loader from "../../../../../../../../components/UI/loaders/Loader";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../../../../components/UI/Button";
import { User } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
const defaultOption = {
	serviceType: "",
	routeId: "",
	serviceFrequency: {
		type: "",
		days: [],
	},
	anchorDate: null,
	expectedItemOrService: [],
	notes: "",
	deliveryNotes: "",
	isWillCall: false,
	isSetUpService: false,
	isUpdating: true,
};

const GeneratorRoutes = ({ onClickBack, generatorData = {} }) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		getValues,
		reset,
		trigger,
	} = useForm({
		defaultValues: {
			serviceSchedules: [defaultOption],
		},
	});
	const {
		control: instructionControl,
		handleSubmit: instructionHandleSubmit,
		setValue: setInstructionsValue,
	} = useForm({
		defaultValues: {
			deliveryNote: "",
			parkingNote: "",
			locationOfWaste: "",
			lockBoxCode: "",
			serviceInstructions: "",
			octoConnectNote: "",
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "serviceSchedules",
	});
	const navigate = useNavigate();

	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [allRoutesOptions, setAllRoutesOptions] = useState([]);
	const [isLoadingServices, setIsLoadingServices] = useState(true);
	const [generatorScheduledServices, setGeneratorScheduledServices] = useState([]);
	const [selectedRouteIds, setSelectedRouteIds] = useState([]);
	const [routePaths, setRoutePaths] = useState([]);
	const map = useMap();
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	const [prevServiceSchedules, setPrevServiceSchedules] = useState([]);
	const [updatedRoutesId, setUpdatedRoutesId] = useState([]);
	const [allGeneratorsData, setAllGeneratorsData] = useState([]);
	const [allTreatmentData, setAllTreatmentData] = useState([]);
	const [allVendorData, setAllVendorData] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [pendingServices, setPendingServices] = useState([]);
	const [completedServices, setCompletedServices] = useState([]);
	const [copied, setCopied] = useState(false);
	const [affectedServices, setAffectedServices] = useState(0);
	const formValues = watch();
	const [user, authLoading, authError] = useAuthState(auth);

	useEffect(() => {
		if (!generatorData) return;
		setInstructionsValue("deliveryNote", generatorData?.deliveryNote ?? "");
		setInstructionsValue("locationOfWaste", generatorData?.locationOfWaste ?? "");
		setInstructionsValue("lockBoxCode", generatorData?.lockBoxCode ?? "");
		setInstructionsValue("parkingNote", generatorData?.parkingNote ?? "");
		setInstructionsValue("serviceInstructions", generatorData?.serviceInstructions ?? "");
		setInstructionsValue("octoConnectNote", generatorData?.octoConnectNote ?? "");
	}, [generatorData]);

	const fetchServiceSchedules = async () => {
		const snap = await getDocs(
			query(
				collection(db, COLLECTIONS.serviceSchedules),
				where("generatorId", "==", generatorData.id)
				// where("isDeleted", "!=", true)
			)
		);
		const tempSchedules = [];
		snap.docs.forEach(async (el) => {
			if (el.exists()) {
				const data = { ...el.data(), id: el.id };
				if (typeof data.serviceType !== "string") {
					data.serviceType = data.serviceType[0];
				}
				if (!data.hasOwnProperty("isDeleted") || data.isDeleted === false) {
					tempSchedules.push(data);
				}
			}
		});
		console.log({ tempSchedules });
		tempSchedules.sort((a, b) => {
			let dateA = null;
			let dateB = null;
			if (typeof a.createdAt.seconds === "number" || typeof a.createdAt.nanoseconds === "number") {
				dateA = new Timestamp(a.createdAt.seconds, a.createdAt.nanoseconds).toDate();
			} else {
				dateA = a.createdAt.toDate();
			}
			if (typeof b.createdAt.seconds === "number" || typeof b.createdAt.nanoseconds === "number") {
				dateB = new Timestamp(b.createdAt.seconds, b.createdAt.nanoseconds).toDate();
			} else {
				dateB = b.createdAt.toDate();
			}
			return dateA - dateB;
		});
		setValue("serviceSchedules", tempSchedules);
		setPrevServiceSchedules(tempSchedules);
	};
	useEffect(() => {
		if (!generatorData) return;
		fetchServiceSchedules();
	}, []);

	useEffect(() => {
		if (!generatorData) return;
		let unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.scheduledServices),
				where("generatorId", "==", generatorData.id),
				orderBy("date", "asc")
			),
			async (snap) => {
				console.log("fetching services");

				let tempServices = [];
				const jobs = snap.docs.map(async (el) => {
					if (el.exists()) {
						const data = { ...el.data(), id: el.id };
						if (data?.routeId?.length > 0) {
							const routeRes = await getDoc(doc(db, COLLECTIONS.routes, data.routeId));
							if (routeRes.exists()) {
								data.routeData = { ...routeRes.data(), id: routeRes.id };
							}
							const serviceDate = new Date(data.date.toDate());
							serviceDate.setUTCHours(0, 0, 0, 0);
							const serviceDateString = serviceDate.toISOString();
							const dailyRouteRes = await getDoc(
								doc(db, COLLECTIONS.routes, data.routeId, COLLECTIONS.dailyRoutes, serviceDateString)
							);
							if (dailyRouteRes.exists()) {
								data.noOfStops = dailyRouteRes?.data()?.waypoints?.length ?? 0;
							}
						}
						if (data?.serviceScheduleId?.length > 0) {
							const serviceScheduleRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, data.serviceScheduleId));
							if (serviceScheduleRes.exists()) {
								data.serviceScheduleData = { ...serviceScheduleRes.data(), id: serviceScheduleRes.id };
							}
						}
						tempServices.push(data);
					}
				});
				await Promise.all(jobs);
				tempServices = tempServices.filter((el) => el.status !== SERVICE_STATUS.DELETED);
				setPendingServices(
					tempServices
						.filter((el) => el.status === SERVICE_STATUS.PENDING || el.status === SERVICE_STATUS.CANCELLED)
						.sort((a, b) => a.date.toDate() - b.date.toDate())
				);
				setCompletedServices(
					tempServices
						.filter((el) => el.status === SERVICE_STATUS.COMPLETE)
						.sort((a, b) => a.date.toDate() - b.date.toDate())
				);
				setGeneratorScheduledServices(tempServices);
				setIsLoadingServices(false);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (!routesLibrary || !map) return;
		setDirectionsService(new routesLibrary.DirectionsService());
	}, [routesLibrary, map]);
	useEffect(() => {
		getAllOtherRoutes();
	}, []);
	const getAllOtherRoutes = async () => {
		const allRoutePathData = await getAllRoutesPathData();
		setRoutePaths(allRoutePathData);
	};

	useEffect(() => {
		let unsubscribe = getAllGeneratorsLocationSnapshot(setAllGeneratorsData, user?.uid);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		let unsubscribe = getAllTreatmentsLocationSnapshot(setAllTreatmentData, user?.uid);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		let unsubscribe = getAllVendorsLocationSnapshot(setAllVendorData, user?.uid);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const watchServiceSchedules = watch("serviceSchedules");

	useEffect(() => {
		const fetchAllRoutesOptions = async () => {
			try {
				let resp = await getAllRoutes(user?.uid);
				setAllRoutesOptions(resp);
				let options = resp.map((item) => ({
					label: item.routeLabel,
					value: item.id,
				}));
				setRouteOptions(options);
			} catch (error) {
				console.log("Error fetching routes", error);
			}
		};
		fetchAllRoutesOptions();
	}, []);

	useEffect(() => {
		if (generatorData?.serviceType) {
			setServiceTypeOptions(serviceTypes.filter((item) => generatorData.serviceType.includes(item.value)));
		}
	}, [generatorData]);

	useEffect(() => {
		if (watchServiceSchedules) {
			watchServiceSchedules.forEach((schedule, index) => {
				const calculatedDates = calculateUpcomingDates(schedule);
				setValue(`serviceSchedules.${index}.upcomingDates`, calculatedDates, { shouldValidate: true });
			});
		}
	}, [watchServiceSchedules, setValue]);
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (
				name &&
				name.startsWith("serviceSchedules") &&
				(name.endsWith("anchorDate") || name.includes("serviceFrequency"))
			) {
				const index = parseInt(name.split(".")[1]);
				const schedule = value.serviceSchedules[index];

				if (schedule && schedule.anchorDate && schedule.serviceFrequency) {
					let calculatedDates = [];
					if (schedule.serviceFrequency.type === "WC") {
						calculatedDates = [];
					} else if (schedule.serviceFrequency.type === "MTWM") {
						if (schedule.serviceFrequency.days.length > 0) {
							calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
						}
					} else {
						calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
					}
					let dates = calculatedDates;
					setValue(`serviceSchedules.${index}.upcomingDates`, dates);
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, setValue]);
	function formatUtcDateString(utcDateString) {
		const date = new Date(utcDateString);

		const formatter = new Intl.DateTimeFormat("en-US", {
			weekday: "short",
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			timeZone: "UTC",
		});

		return formatter.format(date);
	}

	const addSetUpService = () => {
		append({
			...defaultOption,
			isSetUpService: true,
		});
	};
	const serviceFrequencyOptions = [...frequencyPrimaryOptions, ...frequencySecondaryOptions];
	const handleSave = async (index) => {
		try {
			setIsSubmitting(true);
			const data = [
				{
					...formValues.serviceSchedules[index],
					generatorId: generatorData.id,
					priceBook: "",
				},
			];
			toast.dismiss();
			toast.loading("Saving...");
			const response = await addOrUpdateGeneratorScheduleReq(generatorData.id, data);

			if (!response?.data?.data?.length) {
				showErrorToastMessage("Updating Schedules failed. Internal Server Error.");
				throw new Error("Updating Schedules failed. Internal Server Error.", { cause: "customError" });
			}

			console.log("Schedule updated successfully:", response.data);

			toast.dismiss();
			toast.success("Service created successfully!");
			fetchServiceSchedules();
		} catch (error) {
			if (error.cause === "customError") {
				showErrorToastMessage(error.message);
			} else {
				toast.dismiss();
				console.error("Error saving schedules:", error);
				toast.error("Error saving schedules. Please try again.");
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const deleteSchedule = async (field, index) => {
		console.log({ field });
		showLoadingToastMessage("Deleting");
		let allSchedules = getValues("serviceSchedules");

		let dataToDelete = allSchedules.find((_, i) => index == i);
		console.log({ dataToDelete });

		let theData = {
			...field,
			id: dataToDelete.id,
		};

		if (theData.id) {
			try {
				const serviceRes = await getDocs(
					query(
						collection(db, COLLECTIONS.scheduledServices),
						where("generatorId", "==", generatorData.id),
						where("serviceScheduleId", "==", formValues.serviceSchedules[index].id),
						where("status", "==", SERVICE_STATUS.PENDING)
					)
				);
				serviceRes.docs.forEach((el) => {
					updateDoc(el.ref, { status: SERVICE_STATUS.DELETED });
				});
				await updateDoc(doc(db, COLLECTIONS.serviceSchedules, theData.id), { isDeleted: true });
				showSuccessToastMessage("Deleted successfully!");
				fetchServiceSchedules();
			} catch (error) {
				console.error("Failed to delete schedule:", error);
			}
		} else {
			showSuccessToastMessage("Deleted successfully!");
			remove(index);
		}
	};

	const instructionSubmitHandler = async (data) => {
		if (!generatorData) return;

		try {
			showLoadingToastMessage("Saving Service Instructions.");
			await updateDoc(doc(db, COLLECTIONS.generators, generatorData.id), data);
			showSuccessToastMessage("Service Instructions saved successfully.");
		} catch (error) {
			console.log(error);
			showInternalServerErrorToastMessage();
		}
	};
	const handleCopyToClipboard = async () => {
		try {
			let dates = pendingServices.map((scheduledService) => formattedDate(scheduledService.date));
			await navigator.clipboard.writeText(dates?.join("; "));
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	};

	const renderAddMoreServiceButtons = () => {
		return (
			<div className="text-sm flex gap-5 items-center">
				<button
					type="button"
					className={`text-cardTextGray border-borderCol flex items-center justify-between gap-1 min-w-40 px-8 py-3 rounded-full bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
					onClick={addSetUpService}
				>
					<span className="pr-5">Add a Service</span>
					<BiPlus size={16} />
				</button>
			</div>
		);
	};
	const renderOperatingHours = (date = new Date()) => {
		const dayNo = date.getDay();
		const dayName = daysOfWeek[dayNo];
		if (!generatorData) return "N/A";
		const operatingHours = generatorData.workingHours[dayName];

		if (operatingHours?.closed) {
			return "Closed";
		}
		if (operatingHours?.open && operatingHours?.close) {
			return `${operatingHours.open} - ${operatingHours.close}`;
		} else {
			return "N/A";
		}
	};

	const renderQuantity = (service) => {
		if (!service) return "N/A";
		if (service.serviceType === "CONSOLIDATED") {
			if (service?.consolidated?.length > 0) {
				let temp = 0;
				service.consolidated.forEach((el) => {
					temp += el.quantity;
				});
				return `${temp}`;
			} else {
				return "0";
			}
		}
	};

	const isUpdating = (prev, form) => {
		console.log({ prev, form });

		let isUpdating = false;
		if (!prev) {
			isUpdating = false;
		}
		if (!form) {
			isUpdating = false;
		}
		const prevData = prev ? { ...prev } : {};
		if (prevData.expectedItemOrService?.length > 0) {
			prevData.expectedItemOrService = prevData.expectedItemOrService.map((el) => ({
				item: el.item,
				quantity: el.quantity,
			}));
		}

		delete prev?.upcomingDates;
		const formData = form ? { ...form } : {};
		delete formData?.upcomingDates;
		if (formData.expectedItemOrService?.length > 0) {
			formData.expectedItemOrService = formData.expectedItemOrService.map((el) => ({
				item: el.item,
				quantity: el.quantity,
			}));
		}
		if (JSON.stringify(prevData) !== JSON.stringify(formData)) {
			console.log(JSON.stringify(prevData));
			console.log(JSON.stringify(formData));

			isUpdating = true;
		} else {
			isUpdating = false;
		}
		console.log({ isUpdating });

		return isUpdating;
	};

	return (
		<div className="grid bg-white p-8 py-6 mb-6 rounded-cardRadii gap-2 w-full">
			<GeneratorInfoHeader generatorData={generatorData} />
			<div className="rounded-xl overflow-clip">
				<RouteAssignment
					allRoutes={routePaths}
					selectedRoutes={selectedRouteIds}
					generatorData={generatorData}
					getValues={getValues}
					allGeneratorsData={allGeneratorsData}
					allTreatmentData={allTreatmentData}
					allVendorData={allVendorData}
				/>
			</div>
			<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Generator Service Requirements</h6>
			<form className="flex flex-col gap-2">
				{fields.map((field, index) => (
					<div key={field.id || Date.now() + Math.random()} className="border-b border-gray-100">
						<div className="flex gap-8 w-full ">
							<div className="w-1/2">
								<Controller
									name={`serviceSchedules.${index}.routeId`}
									control={control}
									rules={{ required: "Route is required" }}
									render={({ field: { onChange, value } }) => (
										<Dropdown
											label="Route"
											options={routeOptions}
											value={value}
											onChange={(e) => {
												onChange(e);
											}}
											isRequired={true}
										/>
									)}
								/>
								{errors.serviceSchedules?.[index]?.routeId && (
									<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].routeId.message}</p>
								)}
								<Controller
									name={`serviceSchedules.${index}.serviceFrequency.type`}
									control={control}
									rules={{ required: "Service Frequency is required" }}
									render={({ field: { onChange, value } }) => (
										<Dropdown
											label="Service Frequency"
											options={serviceFrequencyOptions}
											value={value}
											onChange={onChange}
											isRequired={true}
											noCursor={field?.isWillCall}
										/>
									)}
								/>
								{errors.serviceSchedules?.[index]?.serviceFrequency?.type && (
									<p className="text-red-500 text-sm mt-1">
										{errors.serviceSchedules[index].serviceFrequency.type.message}
									</p>
								)}
								{watchServiceSchedules[index]?.serviceFrequency?.type === "MTWM" && (
									<Controller
										name={`serviceSchedules.${index}.serviceFrequency.days`}
										control={control}
										rules={{ required: "Weekdays are required for multiple times weekly" }}
										render={({ field: { onChange, value } }) => (
											<MultiSelectRounded
												value={value}
												onChange={onChange}
												options={weekdayOptions}
												label="Select Weekdays"
												id={`weekdays-input-${index}`}
											/>
										)}
									/>
								)}
								<div className="flex items-center justify-between my-4">
									<label htmlFor={`anchorDate-${index}`} className="truncate text-inputLabel font-normal">
										{field?.isWillCall ? "Will Call Date" : "Anchor Date"}
									</label>
									<div className="w-2/3">
										<Controller
											name={`serviceSchedules.${index}.anchorDate`}
											control={control}
											rules={{
												required: "Anchor date is required.",
											}}
											render={({ field: { value, onChange } }) => (
												<CustomDatePicker
													selectedDate={value}
													setSelectedDate={(value) => {
														console.log({ value });
														onChange(value);
													}}
													label={"Anchor Date *"}
												/>
											)}
										/>
									</div>
								</div>
								{errors.serviceSchedules?.[index]?.anchorDate && (
									<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].anchorDate.message}</p>
								)}
							</div>
							<div className="w-1/2">
								<Controller
									name={`serviceSchedules.${index}.serviceType`}
									control={control}
									rules={{ required: "Service Type is required." }}
									render={({ field: { onChange, value } }) => (
										<Dropdown
											label="Service Type"
											id={`service-input-${index}`}
											options={serviceTypes}
											value={value}
											onChange={onChange}
											isRequired={true}
										/>
									)}
								/>
								{errors.serviceSchedules?.[index]?.serviceType && (
									<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].serviceType.message}</p>
								)}

								<Controller
									name={`serviceSchedules.${index}.expectedItemOrService`}
									control={control}
									rules={{
										required: "Expected Container is required.",
										validate: (value) => {
											return (
												value.every((item) => item.quantity >= 1 && item.quantity <= 20) ||
												"Quantity must be between 1 and 20"
											);
										},
									}}
									render={({ field: { value, onChange } }) => (
										<div>
											<MultiSelectRounded
												value={value.map((v) => v.item)}
												onChange={(selectedItems) => {
													const transformedItems = selectedItems.map((item) => {
														const existingItem = value.find((v) => v.item === item);
														return {
															item,
															quantity: existingItem ? existingItem.quantity : 1,
														};
													});
													onChange(transformedItems);
												}}
												options={itemsOptions}
												isRequired={true}
												label="Expected Container(s)"
												id={`expected-items-services-${index}`}
											/>

											{value?.length > 0 && value && value?.[0]?.item?.length > 0 && (
												<div className="mb-6 flex flex-col gap-2 justify-end">
													{value.map((itemObj, itemIndex) => (
														<div key={itemObj.item} className="flex items-center ">
															<span className="text-base w-1/3 text-inputLabel">{itemsMap?.[itemObj.item]}</span>
															<input
																type="number"
																min="1"
																max="2000"
																value={itemObj.quantity}
																onChange={(e) => {
																	const newQuantity = Math.min(Math.max(1, Number(e.target.value)), 2000);
																	const updatedItems = [...value];
																	updatedItems[itemIndex] = {
																		...itemObj,
																		quantity: newQuantity,
																	};
																	onChange(updatedItems);
																}}
																className="p-2 px-4 w-2/3 text-sm bg-inputBg rounded-full outline-none focus:ring-1 focus:ring-dashInActiveBtnText"
																placeholder="Quantity (1-20)"
															/>
														</div>
													))}
												</div>
											)}
										</div>
									)}
								/>
								{errors.serviceSchedules?.[index]?.expectedItemOrService && (
									<p className="text-red-500 text-sm mt-1">
										{errors.serviceSchedules[index].expectedItemOrService?.message}
									</p>
								)}
							</div>
						</div>

						{watchServiceSchedules[index].upcomingDates?.length > 0 && (
							<div className="mb-4 w-full">
								<h6 className="font-medium pb-2">Upcoming Schedules</h6>
								<div className="flex gap-2 flex-wrap">
									{watchServiceSchedules[index].upcomingDates.map((date, dateIndex) => (
										<div
											key={dateIndex}
											className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2"
										>
											<span>{formattedDate(date)}</span>
										</div>
									))}
								</div>
							</div>
						)}

						{
							<div className="w-full flex justify-end p-2 gap-5">
								{fields.length > 0 && (
									<button
										type="button"
										className="rounded-full px-4 py-1 text-sm border border-black hover:bg-cardTextGray hover:bg-opacity-10 "
										onClick={async () => {
											console.log("Opening modal");
											if (formValues?.serviceSchedules[index]?.id) {
												console.log(formValues?.serviceSchedules[index]?.id);
												const serviceRes = await getDocs(
													query(
														collection(db, COLLECTIONS.scheduledServices),
														where("generatorId", "==", generatorData.id),
														where("serviceScheduleId", "==", formValues.serviceSchedules[index].id),
														where("status", "==", SERVICE_STATUS.PENDING)
													)
												);
												console.log(serviceRes.docs);
												serviceRes.docs.forEach((el) => console.log(el.id));
												setAffectedServices(serviceRes?.docs?.length ?? 0);
											} else {
												setAffectedServices(0);
											}
											document.getElementById(`delete-schedule-services-${index}`).showModal();
										}}
									>
										Remove service schedule
									</button>
								)}
								<button
									type="button"
									disabled={!isUpdating(prevServiceSchedules[index], formValues.serviceSchedules[index])}
									className={`p-2 px-4 min-w-40 bg-primary-500 hover:bg-primary-500/90 disabled:bg-cardTextGray text-white rounded-full text-center `}
									onClick={async () => {
										const res = await trigger(`serviceSchedules.${index}`, { shouldFocus: true });

										if (res) {
											handleSave(index);
										}
									}}
								>
									{formValues?.serviceSchedules[index]?.id ? "Update" : "Save"}
								</button>
							</div>
						}
						<dialog id={`delete-schedule-services-${index}`} className="modal">
							<div className="modal-box">
								<div>
									{/* if there is a button in form, it will close the modal */}
									<button
										className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
										type="button"
										onClick={() => {
											document.getElementById(`delete-schedule-services-${index}`).close();
										}}
									>
										✕
									</button>
								</div>
								<h3 className="font-bold text-lg">Are you sure?</h3>
								<div className="flex py-5 gap-5 flex-col">
									<p className="">Proceeding with this operation will affect {affectedServices} pending service(s).</p>
									<p className="text-sm">
										On confirmation the pending services will be deleted and this schedule will be removed. However this
										operation will not delete any complete, inprogress or cancelled services.
									</p>
								</div>
								<div className="flex w-full justify-between">
									<button
										className="btn btn-error btn-sm"
										type="button"
										onClick={() => {
											deleteSchedule(field, index);
										}}
									>
										Confirm
									</button>
									<button
										type="button"
										className="btn btn-primary btn-sm"
										onClick={() => {
											document.getElementById(`delete-schedule-services-${index}`).close();
										}}
									>
										Cancel
									</button>
								</div>
							</div>
						</dialog>
					</div>
				))}
				<div className="ml-auto">{renderAddMoreServiceButtons()}</div>
			</form>
			<div className="py-5">
				<div className="flex flex-col gap-2">
					<div className="w-full grid gap-3">
						<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Reminders/Notifications</h6>
						<div className="flex flex-col sm:flex-row pt-1 items-center gap-6">
							<label className="text-cardTextGray">Service Day Notifications</label>
						</div>
						<div className="flex flex-col gap-6 sm:flex-row items-center">
							<label htmlFor="24-hour-notice" className="flex items-center text-gray-500 gap-5">
								<input
									type="checkbox"
									name=""
									id="24-hour-notice"
									className="w-4 h-4 bg-white"
									defaultChecked={generatorData.notifiPref24Hours}
									onChange={(e) => {
										if (e.currentTarget?.checked) {
											try {
												updateDoc(doc(db, COLLECTIONS.generators, generatorData.id), { notifiPref24Hours: true });
											} catch (error) {
												console.log(error);
												showInternalServerErrorToastMessage();
											}
										} else {
											try {
												updateDoc(doc(db, COLLECTIONS.generators, generatorData.id), { notifiPref24Hours: false });
											} catch (error) {
												console.log(error);
												showInternalServerErrorToastMessage();
											}
										}
									}}
								/>
								<p>24 Hour Notice</p>
							</label>
							<label htmlFor="same-day-notice" className="flex items-center text-gray-500 gap-5">
								<input
									type="checkbox"
									name=""
									id="same-day-notice"
									className="w-4 h-4 bg-white"
									defaultChecked={generatorData.notifiPrefServiceDay}
									onChange={(e) => {
										if (e.currentTarget.checked) {
											try {
												updateDoc(doc(db, COLLECTIONS.generators, generatorData.id), { notifiPrefServiceDay: true });
											} catch (error) {
												console.log(error);
												showInternalServerErrorToastMessage();
											}
										} else {
											try {
												updateDoc(doc(db, COLLECTIONS.generators, generatorData.id), { notifiPrefServiceDay: false });
											} catch (error) {
												console.log(error);
												showInternalServerErrorToastMessage();
											}
										}
									}}
								/>
								<p>Same-Day Notice</p>
							</label>
						</div>
					</div>
				</div>
			</div>
			<form onSubmit={instructionHandleSubmit(instructionSubmitHandler)} className="grid gap-2">
				<div>
					<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC] mb-2">
						Generator Service Instructions{" "}
						<span className="text-sm text-cardTextGray">(Instructions appear in OCTO Field App)</span>
					</h6>
					<div className="w-full flex flex-col md:flex-row gap-8">
						<div className="w-1/2">
							<Controller
								name="deliveryNote"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Delivery Note (DEL)" />
								)}
							/>
							{errors.deliveryNote && <p className="text-red-500 text-sm mt-1">{errors.deliveryNote.message}</p>}
							<Controller
								name="parkingNote"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Parking Note (PRK)" />
								)}
							/>
							{errors.parkingNote && <p className="text-red-500 text-sm mt-1">{errors.parkingNote.message}</p>}
							<Controller
								name="locationOfWaste"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Location Of Waste (LOC)" />
								)}
							/>
							{errors.locationOfWaste && <p className="text-red-500 text-sm mt-1">{errors.locationOfWaste.message}</p>}
						</div>
						<div className="w-1/2">
							<Controller
								name="lockBoxCode"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Lock Box Code" />
								)}
							/>
							{errors.lockBoxCode && <p className="text-red-500 text-sm mt-1">{errors.lockBoxCode.message}</p>}
							<Controller
								name="serviceInstructions"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Service Instructions" />
								)}
							/>
							{errors.serviceInstruction && (
								<p className="text-red-500 text-sm mt-1">{errors.serviceInstruction.message}</p>
							)}
							<Controller
								name="octoConnectNote"
								control={instructionControl}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="OCTO Connect" placeholder={"Contractor's Note"} />
								)}
							/>
							{errors.octoConnectNote && <p className="text-red-500 text-sm mt-1">{errors.octoConnectNote.message}</p>}
						</div>
					</div>
				</div>
				<div className="flex">
					<button type="submit" className="btn btn-primary btn-md text-base ml-auto">
						Save
					</button>
				</div>
			</form>

			{/* <GenRouteFooter
				isLoadingServices={isLoadingServices}
				pendingScheduledService={pendingScheduledService}
				completedScheduledService={completedScheduledService}
			/> */}
			<h6 className="font-medium py-2 text-lg">Scheduled Services</h6>
			<div className="overflow-x-auto w-full">
				<div className="flex min-w-fit bg-[#E5F2FF] px-8 font-medium py-4 gap-2 rounded-t-xl">
					<div className="w-60 shrink-0  flex items-center gap-5">
						<div className={`tooltip tooltip-right flex items-center`} data-tip={"Mark all as high priority."}>
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								// checked={
								// 	pendingServices.length ===
								// 	pendingServices.reduce((acc, curr) => {
								// 		if (curr.isPriority) {
								// 			return acc++;
								// 		}
								// 		return acc;
								// 	}, 0)
								// }
								defaultChecked={false}
								onChange={async (e) => {
									if (typeof e?.currentTarget?.checked === "undefined") return;
									setPendingServices((prev) =>
										prev.map((el) => {
											if (el.status === SERVICE_STATUS.PENDING) {
												el.isPriority = e.currentTarget?.checked;
											}
											return el;
										})
									);
									try {
										const jobs = pendingServices.map((el) => {
											updateDoc(doc(db, COLLECTIONS.scheduledServices, el.id), {
												isPriority: e?.currentTarget?.checked,
											});
										});
										await Promise.all(jobs);
									} catch (error) {
										console.log(error);
										showInternalServerErrorToastMessage();
									}
								}}
							/>
						</div>
						<p>Priority</p>
					</div>
					<div className="w-60 shrink-0">Date</div>
					<div className="w-60 shrink-0">Operating Hours</div>
					<div className="w-60 shrink-0">Route Info</div>
					<div className="w-80 shrink-0 px-2">Temporary Service Instructions</div>
					<div className="w-10 shrink-0"></div>
					<div className="w-60 shrink-0">Service Type</div>
					<div className="w-60 shrink-0"></div>
				</div>
				<div className="max-h-[65vh] min-w-fit">
					{isLoadingServices ? (
						<Loader height="h-12 pt-4" />
					) : pendingServices?.length > 0 ? (
						pendingServices.map((service, index) => (
							<div
								key={service?.id}
								className={`flex items-center gap-2 border-b border-[#CCCCCC] px-8 font-base text-cardTextGray py-4 ${
									service.status === SERVICE_STATUS.CANCELLED ? "bg-gray-200" : ""
								}`}
							>
								<div className="flex items-center gap-5 w-60 shrink-0">
									<div
										className={`${
											service.status === SERVICE_STATUS.CANCELLED ? "" : "tooltip tooltip-right"
										} flex items-center`}
										data-tip={service.isPriority ? "Cancel priority" : "Mark as priority"}
									>
										<input
											type="checkbox"
											className="w-4 h-4 bg-white"
											disabled={service.status === SERVICE_STATUS.CANCELLED}
											checked={service?.isPriority ?? false}
											onChange={async (e) => {
												if (service.status === SERVICE_STATUS.PENDING) {
													setPendingServices((prev) =>
														prev.map((el) => {
															if (el.id === service.id) {
																el.isPriority = e.currentTarget?.checked;
															}
															return el;
														})
													);
												}
												try {
													await updateDoc(doc(db, COLLECTIONS.scheduledServices, service.id), {
														isPriority: e.currentTarget?.checked,
													});
												} catch (error) {
													console.log(error);
													showInternalServerErrorToastMessage();
												}
											}}
										/>
									</div>
									{service?.isPriority ? <p className="text-red-500">{"High Priority"}</p> : <p>{"Normal Priority"}</p>}
								</div>
								<div className="w-60 shrink-0">
									{service?.date ? formatUtcDateString(service.date.toDate().toUTCString()) : "N/A"}
								</div>
								<div className="w-60 shrink-0">{renderOperatingHours(service.date.toDate())}</div>
								<div className="w-60 shrink-0">
									{service.routeData?.routeLabel.length > 0 ? service.routeData?.routeLabel + " " : "N/A"}
									{service?.noOfStops ? `[${service.noOfStops}]` : "[0]"}
								</div>
								<div className="w-80 shrink-0">
									<input
										type="text"
										defaultValue={service?.temporaryServiceInstruction ?? ""}
										disabled={service.status === SERVICE_STATUS.CANCELLED}
										className=" w-full text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-9 px-2 leading-tight focus:outline-none  focus:ring-1 focus:ring-dashInActiveBtnText"
										onBlur={async (e) => {
											console.log(service?.temporaryServiceInstruction);

											const temporaryServiceInstruction = e?.currentTarget?.value?.trim() ?? "";
											if (temporaryServiceInstruction.length) {
												try {
													await updateDoc(doc(db, COLLECTIONS.scheduledServices, service.id), {
														temporaryServiceInstruction,
													});
												} catch (error) {
													console.log(error);
													showInternalServerErrorToastMessage();
												}
											}
										}}
									/>
								</div>
								<div className="w-10 shrink-0"></div>
								<div className="w-60 shrink-0">
									{typeof service.serviceScheduleData?.serviceType === "string" &&
									service.serviceScheduleData?.serviceType?.length > 0
										? serviceTypes[
												serviceTypes.findIndex((el) => el.value === service.serviceScheduleData?.serviceType)
										  ]?.label
										: ""}
									{typeof service.serviceScheduleData?.serviceType !== "string" &&
									service.serviceScheduleData?.serviceType?.length > 0
										? service.serviceScheduleData?.serviceType.map((type, i) => {
												// console.log({ type, i });

												// console.log(serviceTypes[serviceTypes.findIndex((el) => el.value === type)]?.label);
												return (
													<p key={i}>{serviceTypes[serviceTypes.findIndex((el) => el.value === type)]?.label ?? ""} </p>
												);
										  })
										: ""}
								</div>
								<div className="w-60 shrink-0">
									<button
										type="button"
										className="btn btn-primary btn-sm text-base ml-auto w-full"
										onClick={async () => {
											if (service.status === SERVICE_STATUS.PENDING) {
												setPendingServices((prev) =>
													prev.map((el) => {
														if (el.id === service.id) {
															el.status = SERVICE_STATUS.CANCELLED;
														}
														return el;
													})
												);
												try {
													await updateDoc(doc(db, COLLECTIONS.scheduledServices, service.id), {
														status: SERVICE_STATUS.CANCELLED,
													});
												} catch (error) {
													console.log(error);
													showInternalServerErrorToastMessage();
												}
											} else if (service.status === SERVICE_STATUS.CANCELLED) {
												setPendingServices((prev) =>
													prev.map((el) => {
														if (el.id === service.id) {
															el.status = SERVICE_STATUS.PENDING;
														}
														return el;
													})
												);
												try {
													await updateDoc(doc(db, COLLECTIONS.scheduledServices, service.id), {
														status: SERVICE_STATUS.PENDING,
													});
												} catch (error) {
													console.log(error);
													showInternalServerErrorToastMessage();
												}
											}
										}}
									>
										{service.status === SERVICE_STATUS.PENDING ? "Cancel Service" : ""}
										{service.status === SERVICE_STATUS.CANCELLED ? "Reinstate Service" : ""}
									</button>
								</div>
							</div>
						))
					) : (
						<div className="w-full text-center py-4 text-cardTextGray">No upcoming services found.</div>
					)}
				</div>
			</div>
			<div className="ml-auto">
				<button
					type="button"
					className="group bg-[#E5F2FF] px-8 py-2.5 w-96 justify-center hover:bg-blue-300 transition-colors duration-200 rounded-full flex gap-2"
					onClick={() => !copied && handleCopyToClipboard()}
				>
					{copied ? (
						<>
							<MdCheck size={24} className="group-hover:fill-cardTextBlue hover:cursor-pointer" />
							<h6 className="group-hover-">Copied to Clipboard!</h6>
						</>
					) : (
						<>
							<MdContentCopy size={24} className="group-hover:fill-cardTextBlue hover:cursor-pointer" />
							<h6 className="group-hover-">Copy Service Dates to Clipboard</h6>
						</>
					)}
				</button>
			</div>

			<h6 className="font-medium py-2 text-lg">Completed Services</h6>
			<div className="overflow-x-auto w-full">
				<div className="flex min-w-fit bg-[#E5F2FF] px-8 font-medium py-4 gap-2 rounded-t-xl">
					<div className="w-60 shrink-0">Date</div>
					<div className="w-60 shrink-0">Route</div>
					<div className="w-60 shrink-0">Driver</div>
					<div className="w-60 shrink-0">QTY</div>
					<div className="w-60 shrink-0">Service Type</div>
					<div className="w-60 shrink-0">Manifest</div>
				</div>
				<div className="max-h-[65vh] min-w-fit">
					{isLoadingServices ? (
						<Loader height="h-12 pt-4" />
					) : completedServices?.length > 0 ? (
						completedServices.map((service, index) => (
							<div
								key={service?.id ?? index}
								className={`flex items-center gap-2 border-b border-[#CCCCCC] px-8 font-base text-cardTextGray py-4`}
							>
								<div className="w-60 shrink-0">
									{formattedDate(service?.date ? new Date(service.date.toDate()) : "N/A")}
								</div>

								<div className="w-60 shrink-0">
									{service.routeData?.routeLabel.length > 0 ? service.routeData?.routeLabel + " " : "N/A"}
								</div>
								<div className="w-60 shrink-0">
									{service?.assignedDriverName?.length > 0 ? service.assignedDriverName + " " : "N/A"}
								</div>
								<div className="w-60 shrink-0">{renderQuantity(service)}</div>
								<div className="w-60 shrink-0">
									{typeof service.serviceScheduleData?.serviceType === "string" &&
									service.serviceScheduleData?.serviceType?.length > 0
										? serviceTypes[
												serviceTypes.findIndex((el) => el.value === service.serviceScheduleData?.serviceType)
										  ]?.label
										: ""}
									{typeof service.serviceScheduleData?.serviceType !== "string" &&
									service.serviceScheduleData?.serviceType?.length > 0
										? service.serviceScheduleData?.serviceType.map((type, i) => {
												// console.log({ type, i });

												// console.log(serviceTypes[serviceTypes.findIndex((el) => el.value === type)]?.label);
												return (
													<p key={i}>{serviceTypes[serviceTypes.findIndex((el) => el.value === type)]?.label ?? ""} </p>
												);
										  })
										: ""}
								</div>
								<div className="w-60 shrink-0">{"--"}</div>
							</div>
						))
					) : (
						<div className="w-full text-center py-4 text-cardTextGray">No complete services found.</div>
					)}
				</div>
			</div>
			<div>
				<h6 className="font-medium py-2 text-lg">Stats For Nerds</h6>
				<h6 className="font-medium py-2 text-lg">The Last 25 Services:</h6>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
			</div>
			<div className="flex">
				<div className="ml-auto">
					<Button
						btnStyle="form_nav_secondary"
						text="Back"
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

GeneratorRoutes.propTypes = {
	generatorData: PropTypes.object,
	onClickBack: PropTypes.func,
	onNextClick: PropTypes.func,
};

export default GeneratorRoutes;

function calculateUpcomingDates(schedule) {
	if (schedule.anchorDate && schedule.serviceFrequency) {
		let calculatedDates = [];
		if (schedule.serviceFrequency.type === "WC") {
			calculatedDates = [];
		} else if (schedule.serviceFrequency.type === "MTWM") {
			if (schedule.serviceFrequency.days.length > 0) {
				calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
				console.log({ calculatedDates });
			}
		} else {
			calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
		}
		return calculatedDates;
	}
	return [];
}

// flatpickr;
// const f = flatpickr(dobRef.current, {
// 	altInput: true,
// 	altFormat: "F j, Y",
// 	dateFormat: "Y-m-d",
// 	maxDate,
// 	defaultDate: BASE_DATE,
// });

// dobFiledFlatpickr.current = f;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers";
import { Map, useMap } from "@vis.gl/react-google-maps";
import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../../../../../config/firebase";
import RouteColumn from "./RouteColumn";
import MultiselectDropdown from "../../../../../../components/UI/dropdowns/MultiselectDropdown";
import CustomDatePicker from "../../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../../components/UI/loaders/Loader";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	pointerWithin,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { ITEM_TYPE_MAP, SERVICE_TYPES, serviceTypes } from "../../../../../../utils/constants";
import { createPortal } from "react-dom";
import ServiceCard from "./ServiceCard";
import {
	getAllSatelliteLocationSnapshot,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
} from "../../../../../../utils/firebaseOperations";
import WaypointMarkers from "./WaypointMarkers";
import RoundedPin from "./RoundedPin";
import SquarePin from "./SquarePin";
import Input from "../../../../../../components/UI/Input";

import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import { DateObject } from "react-multi-date-picker";
import CustomMultiDateRangePicker from "../../../../../../components/UI/CustomMultiDateRangePicker";
import { useAuthState } from "react-firebase-hooks/auth";
const RoutesOptimization = () => {
	const [routes, setRoutes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	// const [dates, setDates] = useState([]);
	const [dates, setDates] = useState([[new DateObject(), new DateObject()]]);
	const [selected, setSelected] = useState([]);
	const [routeColumns, setRouteColumns] = useState([]);
	const [prevRouteColumns, setPrevRouteColumns] = useState([]);
	const [routeUpdatingIds, setRouteUpdatingIds] = useState([]);
	const [totalSelectedServices, setTotalSelectedServices] = useState(0);
	const [activeRouteColumn, setActiveRouteColumn] = useState(null);
	const [activeServiceCard, setActiveServiceCard] = useState(null);
	const [locations, setLocations] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [vendorMarkers, setVendorMarkers] = useState([]);
	const [treatmentFacilities, setTreatmentFacilities] = useState([]);
	const [satelliteLocations, setSatelliteLocations] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const mapRef = useMap("routeOptimization");
	const [currentRouteSplitId, setCurrentRouteSplitId] = useState(null);
	const [routeSplits, setRouteSplits] = useState([]);
	const [noOfSplit, setNoOfSplit] = useState(0);
	const [user, authLoading, authError] = useAuthState(auth);
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 20,
			},
		}),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);
	useEffect(() => {
		const unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.drivers),
				where("driverStatus", "==", "active"),
				where("transporterId", "==", user?.uid)
			),
			(snap) => {
				const tempDrivers = [];
				snap.docs.forEach((el) => {
					if (!el.exists()) return;
					const driverData = el.data();
					driverData.id = el.id;
					tempDrivers.push(driverData);
				});
				setDrivers(tempDrivers);
			}
		);

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);
	useEffect(() => {
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.vendors), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempVendorData = [];
				snap.docs.forEach((vendor) => {
					if (vendor.exists() && vendor.data().vendorCoordinates) {
						tempVendorData.push({
							...vendor.data(),
							id: vendor.id,
						});
					}
				});
				setVendorMarkers(tempVendorData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	useEffect(() => {
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.treatments), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempTreatmentData = [];
				snap.docs.forEach((treatment) => {
					if (treatment.exists() && treatment.data().treatmentCoordinates) {
						tempTreatmentData.push({
							...treatment.data(),
							id: treatment.id,
						});
					}
				});

				setTreatmentFacilities(tempTreatmentData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.satelliteLocation), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempSatelliteData = [];
				snap.docs.forEach((statellite) => {
					if (statellite.exists() && statellite.data().satelliteServiceCoordinates) {
						tempSatelliteData.push({
							...statellite.data(),
							id: statellite.id,
						});
					}
				});
				setSatelliteLocations(tempSatelliteData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const options = [];
		if (satelliteLocations.length > 0) {
			options.push({ label: "Satellite Locations", value: null, isDisabled: true });
			satelliteLocations.forEach((satellite) => options.push({ label: satellite.name, value: satellite.id }));
		}
		if (vendorMarkers.length > 0) {
			options.push({ label: "Vendors Locations", value: null, isDisabled: true });
			vendorMarkers.forEach((vendor) => options.push({ label: vendor.name, value: vendor.id }));
		}
		if (treatmentFacilities.length > 0) {
			options.push({ label: "TS/TF Facilities", value: null, isDisabled: true });
			treatmentFacilities.forEach((treatment) => options.push({ label: treatment.name, value: treatment.id }));
		}
		setLocations(options);
		setSelectedLocations(["all", ...options.map((el) => el.value)]);
	}, [satelliteLocations, vendorMarkers, treatmentFacilities]);

	useEffect(() => {
		const dailyRouteIds = [];
		dates.forEach((range) => {
			const [start, end] = range;
			const utcStartDate = new Date(Date.UTC(start.year, start.month.index, start.day, 0, 0, 0, 0));
			if (!end) {
				dailyRouteIds.push(utcStartDate.toISOString());
				return;
			}
			const utcEndDate = new Date(Date.UTC(end.year, end.month.index, end.day, 0, 0, 0, 0));
			for (let i = utcStartDate; i <= utcEndDate; i.setDate(i.getDate() + 1)) {
				dailyRouteIds.push(i.toISOString());
			}
		});
		console.log({ dailyRouteIds });

		if (!selectedRoutes.length) {
			setRouteColumns([]);
			return;
		}
		if (!dailyRouteIds.length) {
			setRouteColumns([]);
			return;
		}
		updateRouteColumns(dailyRouteIds, selectedRoutes);
	}, [selectedRoutes, dates, routes]);

	const updateRouteColumns = async (dailyRouteIds, selectedRoutes) => {
		const temp = [];
		try {
			console.log("Fetching Route Columns");

			setLoading(true);
			const promises = selectedRoutes.map((selectedRouteId) => {
				return dailyRouteIds.map(async (dailyId) => {
					const alreadyExist = routeColumns.find((col) => col.id === selectedRouteId && col.dailyRouteId === dailyId);
					if (!alreadyExist) {
						const routeData = routes.find((route) => route.id === selectedRouteId);
						const dailyRouteRes = await getDoc(
							doc(db, COLLECTIONS.routes, selectedRouteId, COLLECTIONS.dailyRoutes, dailyId)
						);

						if (dailyRouteRes.exists() && routeData) {
							const dailyRouteData = dailyRouteRes.data();
							dailyRouteData.id = dailyRouteRes.id;
							const waypoints = dailyRouteData.waypoints;

							if (waypoints?.length) {
								const waypointPromises = waypoints.map(async (wp) => {
									const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
									if (generatorRes.exists()) {
										wp.generatorData = { ...generatorRes.data(), id: generatorRes.id };
									}
									if (wp.serviceId) {
										const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId));
										if (serviceRes.exists()) {
											wp.serviceData = { ...serviceRes.data(), id: serviceRes.id };
										}
									}

									if (wp?.serviceData?.serviceScheduleId) {
										const scheduleRes = await getDoc(
											doc(db, COLLECTIONS.serviceSchedules, wp.serviceData.serviceScheduleId)
										);
										if (scheduleRes.exists()) {
											wp.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
										}
									}
								});
								await Promise.all(waypointPromises);
							}

							temp.push({
								routeId: selectedRouteId,
								dailyRouteId: dailyId,
								...routeData,
								...dailyRouteData,
								assignedDriverName: dailyRouteData?.assignedDriverName ?? "N/A",
								assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "N/A",
								waypoints,
								id: selectedRouteId + dailyId,
								isRouteReversed: dailyRouteData?.isRouteReversed ?? false,
							});
						}
					}
				});
			});
			await Promise.all(promises.flat());
			temp.sort((a, b) => {
				const dateA = new Date(a.dailyRouteId);
				const dateB = new Date(b.dailyRouteId);

				if (dateA < dateB) return -1;
				if (dateA > dateB) return 1;

				// If dates are equal, sort by routeLabel alphabetically
				if (a.routeLabel < b.routeLabel) return -1;
				if (a.routeLabel > b.routeLabel) return 1;

				return 0;
			});
			setRouteColumns(temp);
			setPrevRouteColumns(JSON.parse(JSON.stringify([...temp])));
		} catch (error) {
			console.log(error);
			setRouteColumns([]);
			setPrevRouteColumns([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let unsubscribe = null;
		setLoading(true);
		try {
			unsubscribe = onSnapshot(
				query(
					collection(db, COLLECTIONS.routes),
					where("status", "==", "ACTIVE"),
					where("transporterId", "==", user?.uid),
					orderBy("routeLabel", "asc")
				),
				async (snap) => {
					console.log("snap.docs.length");
					console.log(snap.docs.length);
					if (!snap.docs.length) return;
					const tempRoute = [];
					snap.docs.forEach((el) => {
						if (!el.exists()) return;
						const routeData = el.data();
						routeData.id = el.id;
						tempRoute.push(routeData);
					});
					setRoutes(tempRoute);
					setSelectedRoutes(["all", ...tempRoute.map((el) => el.id)]);
				}
			);
		} catch (err) {
			console.log(err);
			showErrorToastMessage("Unable to get routes.");
		} finally {
			setLoading(false);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	const dragStartHandler = (event) => {
		const { active } = event;
		const { id, data } = active;
		const type = data.current.type;
		if (type === ITEM_TYPE_MAP.ROUTES) {
			setActiveRouteColumn(findRouteColumnById(id));
		}
		if (type === ITEM_TYPE_MAP.SERVICE) {
			const service = findServiceCardByServiceId(id);
			console.log({ service });
			setActiveServiceCard({ ...service, dailyRouteId: data.current.dailyRouteId });
		}
	};
	const dragOverHandler = (event) => {
		console.log("Drag Over event");
		const { active, over } = event;

		if (!over) return;

		const activeType = active.data.current.type;
		const overType = over.data.current?.type;
		console.log({ activeType, overType });
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.SERVICE) {
			const activeServiceId = active.id;
			const overServiceId = over.id;
			const activeRouteColumnId = active.data?.current?.columnId;
			const overRouteColumnId = over.data?.current?.columnId;
			if (!activeRouteColumnId || !overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);
			const overServiceIndex = overContainer.waypoints.findIndex((el) => el.serviceId === overServiceId);

			setRouteColumns((newItems) => {
				console.log("state updated");

				const [removeditem] = newItems[activeContainerIndex].waypoints.splice(activeServiceIndex, 1);
				newItems[overContainerIndex].waypoints.splice(overServiceIndex, 0, removeditem);
				return newItems;
			});
		}
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.ROUTES) {
			const activeServiceId = active.id;
			const overRouteColumnId = over.id;
			const activeRouteColumnId = active.data?.current?.columnId;

			if (!activeRouteColumnId || !overRouteColumnId) return;
			if (activeRouteColumnId === overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);

			setRouteColumns((newItems) => {
				console.log("state updated");

				const [removeditem] = newItems[activeContainerIndex].waypoints.splice(activeServiceIndex, 1);
				newItems[overContainerIndex].waypoints.splice(
					newItems[overContainerIndex]?.waypoints?.length ?? 0,
					0,
					removeditem
				);
				return newItems;
			});
		}

		checkForChanges();
	};
	const checkForChanges = () => {
		setTimeout(() => {
			routeColumns.forEach((route) => {
				const prevRouteData = prevRouteColumns.find((el) => el.id === route.id);
				if (!prevRouteData) return;

				const routeString = JSON.stringify({ ...route });
				const prevRouteString = JSON.stringify({ ...prevRouteData });
				if (routeString !== prevRouteString) {
					setRouteUpdatingIds((prev) => Array.from(new Set([...prev, route.id])));
				} else {
					setRouteUpdatingIds((prev) => prev.filter((el) => el !== route.id));
				}
			});
		}, 0);
	};

	const dragEndHandler = (event) => {
		// Container Move handle
		const { active, over } = event;
		if (!over) return;
		const activeType = active.data.current.type;
		const overType = over.data.current.type;
		if (activeType === ITEM_TYPE_MAP.ROUTES && overType === ITEM_TYPE_MAP.ROUTES) {
			const activeRouteColumnId = active.id;
			const overRouteColumnId = over.id;
			if (activeRouteColumnId !== overRouteColumnId) {
				// Find the index of the active and over container
				const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeRouteColumnId);
				const overContainerIndex = routeColumns.findIndex((route) => route.id === overRouteColumnId);

				// Swap the active and over container
				let newItems = [...routeColumns];
				newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
				setRouteColumns(newItems);
			}
		}
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.SERVICE) {
			const activeServiceId = active.id;
			const overServiceId = over.id;
			const activeRouteColumnId = active.data.current.columnId;
			const overRouteColumnId = over.data.current.columnId;
			if (!activeRouteColumnId || !overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);
			const overServiceIndex = overContainer.waypoints.findIndex((el) => el.serviceId === overServiceId);
			// Same Container
			if (activeContainerIndex === overContainerIndex) {
				setRouteColumns((newItems) => {
					console.log("State updated");
					newItems[activeContainerIndex].waypoints = arrayMove(
						newItems[activeContainerIndex].waypoints,
						activeServiceIndex,
						overServiceIndex
					);
					return newItems;
				});
			}
		}

		setActiveRouteColumn(null);
		setActiveServiceCard(null);
		console.log("Drag End event");
	};
	const findRouteColumnById = (id) => {
		return routeColumns.find((el) => el.id === id);
	};
	const findServiceCardByServiceId = (serviceId) => {
		for (const route of routeColumns) {
			const found = route.waypoints.find((el) => el.serviceId === serviceId);
			if (found) {
				return found;
			}
		}
	};

	const renderSwimLanes = () => {
		return (
			<div className="h-full w-full flex gap-2 p-2 overflow-x-scroll">
				{loading && (
					<div className="grid items-center justify-center h-full w-full">
						<Loader height="h-20 w-20" />
					</div>
				)}
				{!loading && (
					<DndContext
						collisionDetection={pointerWithin}
						sensors={sensors}
						onDragStart={dragStartHandler}
						onDragOver={dragOverHandler}
						onDragEnd={dragEndHandler}
					>
						<SortableContext items={routeColumns.map((col) => col.id)}>
							{routeColumns.map((column) => (
								<RouteColumn
									{...column}
									key={column.id}
									id={column.id}
									waypoints={column.waypoints}
									setSelected={setSelected}
									selected={selected}
									setColumns={setRouteColumns}
									locations={locations}
									defaultEndLocation={column.defaultEndLocation}
									defaultStartLocation={column.defaultStartLocation}
									satelliteLocations={satelliteLocations}
									vendorMarkers={vendorMarkers}
									treatmentFacilities={treatmentFacilities}
									setRouteColumns={setRouteColumns}
									isRouteReversed={column?.isRouteReversed ?? false}
									totalTimeInSeconds={column?.totalTimeInSeconds ?? 0}
									totalDistanceInMeters={column?.totalDistanceInMeters ?? 0}
									assignedDriverName={column?.assignedDriverName ?? "N/A"}
									assignedDriverId={activeRouteColumn?.assignedDriverId ?? null}
									assignedServiceVehicleName={column?.assignedServiceVehicleName ?? "N/A"}
									drivers={drivers}
									routes={routes}
									setCurrentRouteSplitId={setCurrentRouteSplitId}
									routeUpdatingIds={routeUpdatingIds}
								/>
							))}
						</SortableContext>
						{createPortal(
							<DragOverlay>
								{activeRouteColumn && (
									<RouteColumn
										{...activeRouteColumn}
										id={activeRouteColumn.id}
										waypoints={activeRouteColumn.waypoints}
										setSelected={setSelected}
										selected={selected}
										setColumns={setRouteColumns}
										locations={locations}
										defaultEndLocation={activeRouteColumn.defaultEndLocation}
										defaultStartLocation={activeRouteColumn.defaultStartLocation}
										satelliteLocations={satelliteLocations}
										vendorMarkers={vendorMarkers}
										treatmentFacilities={treatmentFacilities}
										setRouteColumns={setRouteColumns}
										isRouteReversed={activeRouteColumn?.isRouteReversed ?? false}
										totalTimeInSeconds={activeRouteColumn?.totalTimeInSeconds ?? 0}
										totalDistanceInMeters={activeRouteColumn?.totalDistanceInMeters ?? 0}
										assignedDriverName={activeRouteColumn?.assignedDriverName ?? "N/A"}
										assignedDriverId={activeRouteColumn?.assignedDriverId ?? null}
										assignedServiceVehicleName={activeRouteColumn?.assignedServiceVehicleName ?? "N/A"}
										drivers={drivers}
										routes={routes}
										setCurrentRouteSplitId={setCurrentRouteSplitId}
									/>
								)}
								{activeServiceCard && (
									<ServiceCard
										dailyRouteId={activeServiceCard.dailyRouteId}
										key={activeServiceCard.serviceId}
										id={activeServiceCard.serviceId}
										text={activeServiceCard.type + activeServiceCard.id}
										generatorData={activeServiceCard.generatorData}
										serviceData={activeServiceCard.serviceData}
										scheduleData={activeServiceCard.scheduleData}
										routeColor={activeServiceCard.routeColor ?? "#FFA500"}
									/>
								)}
							</DragOverlay>,
							document.body
						)}
					</DndContext>
				)}
			</div>
		);
	};

	return (
		<div className="p-5">
			<div className="main-container flex gap-2 flex-col rounded-cardRadii">
				<div className="w-full flex gap-5 items-end flex-wrap p-2">
					<div className="flex gap-5 items-end">
						<div className="min-w-32">
							<button
								className="p-2 px-10 border border-gray-200 bg-white rounded-full"
								onClick={() => {
									setDates([[new DateObject(), new DateObject()]]);
								}}
							>
								Today
							</button>
						</div>

						<div className="flex flex-col min-w-60">
							<p className="text-sm text-gray-500 px-2">Select Date Range</p>
							<CustomMultiDateRangePicker value={dates} onChange={setDates} />
						</div>
					</div>

					<div className="min-w-60 ml-auto flex gap-5">
						<MultiselectDropdown
							width="min-w-60"
							buttonText="Transporter Locations"
							options={[{ label: "All", value: "all" }, ...locations.map((el) => el)]}
							selectedOptions={selectedLocations}
							onSelectedOptionsChange={(e) => {
								setSelectedLocations(e);
							}}
						/>
						<MultiselectDropdown
							width="min-w-60"
							buttonText="Select Route"
							options={[
								{ label: "All", value: "all" },
								...routes.map((el) => ({ label: el.routeLabel, value: el.id })),
							]}
							selectedOptions={selectedRoutes}
							onSelectedOptionsChange={(e) => {
								console.log(e);
								setSelectedRoutes(e);
							}}
						/>
					</div>
				</div>
				<div className="grid grid-cols-6 gap-5 bg-white rounded-3xl p-2 min-h-[75vh] max-h-[75vh] h-[75vh] overflow-y-auto">
					<div className="col-span-3">{renderSwimLanes()}</div>
					<div className="max-h-[calc(75vh - 20px)] w-full rounded-xl col-span-3 overflow-clip">
						<Map
							reuseMaps
							mapId={`routeOptimization`}
							defaultZoom={12}
							defaultCenter={{ lat: 32.7364432, lng: -117.1460877 }}
							gestureHandling={"cooperative"}
							zoomControl
						>
							{routeColumns.length > 0 &&
								routeColumns.map((route, i) => {
									return route.waypoints.map((waypoint, index) => (
										<WaypointMarkers
											mapRef={mapRef}
											key={waypoint.scheduleId}
											position={{ lat: waypoint.lat, lng: waypoint.lng }}
											headerContent={
												<p className="text-base font-semibold absolute top-3 left-3">
													{waypoint.generatorData.generatorName.substring(0, 100)}
												</p>
											}
											pin={
												<div>
													{typeof waypoint.scheduleData?.serviceType === "string" &&
													waypoint.scheduleData.serviceType === SERVICE_TYPES.MEDICAL_WASTE ? (
														<RoundedPin
															className="w-20 h-20 flex items-center justify-center relative"
															color={route.color ?? "#FFA500"}
															text={typeof index !== "undefined" ? index + 1 : ""}
														/>
													) : null}
													{typeof waypoint.scheduleData?.serviceType === "string" &&
													waypoint.scheduleData.serviceType === SERVICE_TYPES.PAPER_SHREDDING ? (
														<SquarePin
															className="w-15 h-15 flex items-center justify-center relative"
															color={route.color ?? "#FFA500"}
															text={typeof index !== "undefined" ? index + 1 : ""}
														/>
													) : null}
													{typeof waypoint.scheduleData?.serviceType !== "string" &&
													waypoint.scheduleData?.serviceType?.length &&
													waypoint.scheduleData.serviceType[0] === SERVICE_TYPES.MEDICAL_WASTE ? (
														<RoundedPin
															className="w-20 h-20 flex items-center justify-center relative"
															color={route.color ?? "#FFA500"}
															text={typeof index !== "undefined" ? index + 1 : ""}
														/>
													) : null}
													{typeof waypoint.scheduleData?.serviceType === "string" &&
													waypoint.scheduleData?.serviceType?.length &&
													waypoint.scheduleData.serviceType[0] === SERVICE_TYPES.PAPER_SHREDDING ? (
														<SquarePin
															className="w-15 h-15 flex items-center justify-center relative"
															color={route.color ?? "#FFA500"}
															text={typeof index !== "undefined" ? index + 1 : ""}
														/>
													) : null}
													{typeof waypoint.scheduleData?.serviceType === "undefined" ? (
														<RoundedPin
															className="w-20 h-20 flex items-center justify-center relative"
															color={route.color ?? "#FFA500"}
															text={typeof index !== "undefined" ? index + 1 : ""}
														/>
													) : null}
												</div>
											}
										>
											<div className="grid gap-2">
												<div className="grid grid-cols-3 gap-1">
													<p className="">Generator Name:</p>
													<p className="col-span-2">{waypoint.generatorData.generatorName}</p>
													<p>Generator Address: </p>
													<p className="col-span-2">
														{waypoint.generatorData?.serviceAddress?.street ?? ""}
														{waypoint?.generatorData?.serviceAddress?.suite
															? `, ${waypoint.generatorData.serviceAddress.suite}`
															: ""}
														, {waypoint?.generatorData?.serviceAddress?.city ?? ""},
														{waypoint?.generatorData?.serviceAddress?.state ?? ""}
														{waypoint?.generatorData?.serviceAddress?.zipCode ?? ""}
													</p>
													<p>Service Frequency: </p>
													<p className="col-span-2">{waypoint?.scheduleData?.serviceFrequency?.type}</p>
													<p>Service Type: </p>
													<p className="col-span-2">
														{waypoint?.scheduleData?.serviceType?.length &&
															typeof waypoint?.scheduleData?.serviceType !== "string" &&
															waypoint?.scheduleData?.serviceType?.map(
																(type) => serviceTypes.find((el) => el.value === type).label
															)}
														{waypoint?.scheduleData?.serviceType?.length &&
															typeof waypoint?.scheduleData?.serviceType === "string" &&
															serviceTypes.find((el) => el.value === waypoint?.scheduleData?.serviceType).label}
													</p>
													<p>Assigned Route: </p>
													<p className="col-span-2">{route.routeLabel}</p>
												</div>
												<div className="py-2 w-full">
													<form
														id={`${route.id + waypoint.scheduleId}`}
														onSubmit={(e) => {
															e.preventDefault();
															const form = document.getElementById(`${route.id + waypoint.scheduleId}`);
															const formData = new FormData(form);
															const currentRoute = formData.get("currentRouteId");
															console.log({ currentRoute });
															try {
																showLoadingToastMessage("Please wait we are updating the routes.");
																updateDoc(doc(db, COLLECTIONS.serviceSchedules, waypoint.scheduleId), {
																	routeId: currentRoute,
																});
																showSuccessToastMessage(
																	"Route updated successfully. It will be reflected in the map in a while."
																);
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}}
														className="grid grid-cols-2"
													>
														<select
															name="currentRouteId"
															defaultValue={route.id}
															className="select select-primary select-xs"
														>
															{routes.map((el) => (
																<option value={el.routeId} key={el.routeId}>
																	{el.routeLabel}
																</option>
															))}
														</select>
														<button type="submit" className="btn btn-primary btn-xs">
															Change Route
														</button>
													</form>
												</div>
											</div>
										</WaypointMarkers>
									));
								})}
						</Map>
					</div>
					<dialog id={`split_route`} className="modal">
						<div className="modal-box h-full min-w-[60vw] overflow-y-auto">
							<form method="dialog" className="h-full ">
								<button
									className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
									onClick={() => {
										document.getElementById(`split_route`).close();
									}}
								>
									✕
								</button>
								<div className="flex flex-col h-full">
									<h3 className="font-bold text-lg">Split Route</h3>

									<div className="flex items-end gap-5">
										<div className="w-1/2">
											<Input
												label="No of Splits"
												type="number"
												value={noOfSplit}
												onChange={(e) => setNoOfSplit(e.target.valueAsNumber)}
												style="flex-col"
											/>
										</div>
										<button
											type="button"
											className="btn btn-primary btn-sm ml-auto"
											onClick={() => {
												if (isNaN(parseInt(noOfSplit))) {
													showErrorToastMessage("Please enter a valid number.");
													return;
												}
												if (parseInt(noOfSplit) <= 0) {
													showErrorToastMessage("Please enter a number.");
													return;
												}
												const tempSplits = [];
												Array.from({ length: noOfSplit }).forEach((_, index) => {
													tempSplits.push({ routeId: "", date: new Date() });
												});
												setRouteSplits(tempSplits);
											}}
										>
											OK
										</button>
									</div>
									<div>
										{routeSplits.map((split, index) => {
											return (
												<div className="grid grid-cols-2 items-end gap-5" key={index}>
													<Dropdown
														styles="flex-col min-w-full text-sm"
														isRequired={true}
														onChange={(value) => {
															setRouteSplits((prev) => {
																return prev.map((el, i) => {
																	if (i === index) {
																		el.routeId = value;
																	}
																	return el;
																});
															});
														}}
														placeholder="Route"
														label={"Select Route"}
														value={split.routeId}
														options={routes.map((el) => ({ label: el.routeLabel, value: el.id }))}
													/>

													<div className="py-4">
														<CustomDatePicker
															labelClass="text-sm text-black"
															selectedDate={split.date}
															setSelectedDate={(value) => {
																setRouteSplits((prev) => {
																	return prev.map((el, i) => {
																		if (i === index) {
																			el.date = value;
																		}
																		return el;
																	});
																});
															}}
															label={"Date"}
														/>
													</div>
												</div>
											);
										})}
									</div>

									<div className="flex justify-between mt-auto pb-5">
										<button
											type="button"
											className="btn btn-primary btn-sm"
											onClick={async () => {
												if (!currentRouteSplitId) return;
												const convertedRouteSplits = routeSplits.map((split) => {
													const date = new Date(split.date);
													const utcDate = new Date(
														Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
													);
													return { ...split, date: utcDate.toISOString() };
												});
												const filterRouteSplit = convertedRouteSplits.filter((split) => {
													const alreadyExist = routeColumns.find((col) => col.id === split.routeId + split.date);
													console.log({ alreadyExist });
													if (!alreadyExist) return true;
													return false;
												});
												console.log({ filterRouteSplit });

												const temp = [];
												const jobs = filterRouteSplit.map(async (split) => {
													const dailyRouteRes = await getDoc(
														doc(db, COLLECTIONS.routes, split.routeId, COLLECTIONS.dailyRoutes, split.date)
													);
													const routeData = routes.find((route) => route.id === split.routeId);
													if (dailyRouteRes.exists() && routeData) {
														const dailyRouteData = dailyRouteRes.data();
														dailyRouteData.id = dailyRouteRes.id;
														const waypoints = dailyRouteData.waypoints;

														if (waypoints?.length) {
															const waypointPromises = waypoints.map(async (wp) => {
																const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
																if (generatorRes.exists()) {
																	wp.generatorData = { ...generatorRes.data(), id: generatorRes.id };
																}
																if (wp.serviceId) {
																	const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId));
																	if (serviceRes.exists()) {
																		wp.serviceData = { ...serviceRes.data(), id: serviceRes.id };
																	}
																}

																if (wp?.serviceData?.serviceScheduleId) {
																	const scheduleRes = await getDoc(
																		doc(db, COLLECTIONS.serviceSchedules, wp.serviceData.serviceScheduleId)
																	);
																	if (scheduleRes.exists()) {
																		wp.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
																	}
																}
															});
															await Promise.all(waypointPromises);
														}

														temp.push({
															routeId: split.routeId,
															dailyRouteId: split.date,
															...routeData,
															...dailyRouteData,
															assignedDriverName: dailyRouteData?.assignedDriverName ?? "N/A",
															assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "N/A",
															waypoints,
															id: split.routeId + split.date,
														});
													} else {
														const dailyRouteData = {
															defaultStartLocation: routeData.defaultStartLocation,
															defaultEndLocation: routeData.defaultEndLocation,
															totalDistanceInMeters: 0,
															totalTimeInSeconds: 0,
															createdAt: serverTimestamp(),
															updatedAt: serverTimestamp(),
															waypoints: [],
														};
														setDoc(
															doc(db, COLLECTIONS.routes, split.routeId, COLLECTIONS.dailyRoutes, split.date),
															dailyRouteData
														);
														temp.push({
															routeId: split.routeId,
															dailyRouteId: split.date,
															...routeData,
															assignedDriverName: dailyRouteData?.assignedDriverName ?? "N/A",
															assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "N/A",
															waypoints: [],
															id: split.routeId + split.date,
														});
													}
												});
												await Promise.all(jobs);
												console.log({ temp });
												const currentIndex = routeColumns.findIndex((el) => el.id === currentRouteSplitId);
												const newItems = [...routeColumns];
												newItems.splice(currentIndex + 1, 1, ...temp);
												console.log({ newItems });
												setRouteColumns(newItems);
												document.getElementById(`split_route`).close();
											}}
										>
											OK
										</button>
										<button
											className="btn btn-error btn-sm"
											onClick={() => {
												setCurrentRouteSplitId(null);
												document.getElementById(`split_route`).close();
											}}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					</dialog>
				</div>
			</div>
		</div>
	);
};

export default RoutesOptimization;

import React from "react";

const GeneratorOfficeHours = ({ workingHours }) => {
	const timeZone = workingHours.timeZone;
	delete workingHours.timeZone;
	return (
		<div className="  py-6">
			<h2 className="text-2xl font-bold mb-6 ml-3">Generator Office Hours</h2>

			<div className=" bg-white rounded-cardRadii shadow-md p-8">
				<table className="w-full">
					<thead className="p-2">
						<tr>
							<th className="text-left pb-4 font-bold">Date</th>
							<th className="text-left pb-4 font-bold">Open</th>
							<th className="text-left pb-4 font-bold">Lunch Start</th>
							<th className="text-left pb-4 font-bold">Lunch End</th>
							<th className="text-left pb-4 font-bold">Closes</th>
							<th className="text-left pb-4 font-bold">Closed</th>
						</tr>
					</thead>
					<tbody className="p-2">
						{Object.entries(workingHours).map((day, index) => {
							return (
								<tr key={index} className={day.closed ? "bg-white" : ""}>
									<td className="py-3 font-semibold">{day[0]}</td>
									<td className="py-3">{day[1]?.open?.length > 0 ? day[1].open : "--"}</td>
									<td className="py-3">{day[1]?.lunchStart?.length > 0 ? day[1].lunchStart : "--"}</td>
									<td className="py-3">{day[1]?.lunchEnd?.length > 0 ? day[1].lunchEnd : "--"}</td>
									<td className="py-3">{day[1]?.close?.length > 0 ? day[1].close : "--"}</td>
									<td className="py-3">
										{day[1]?.closed && (
											<svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-green-500">
												<path
													fillRule="evenodd"
													d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
													clipRule="evenodd"
												/>
											</svg>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default GeneratorOfficeHours;
// const officeHours = [
// 		{
// 			day: "Monday",
// 			open: "Lorem ipsum",
// 			lunchStart: "Lorem ipsum",
// 			lunchEnd: "Lorem ipsum",
// 			closes: "Lorem ipsum",
// 			closed: true,
// 		},
// 		{
// 			day: "Tuesday",
// 			open: "Lorem ipsum",
// 			lunchStart: "Lorem ipsum",
// 			lunchEnd: "Lorem ipsum",
// 			closes: "Lorem ipsum",
// 			closed: true,
// 		},
// 		{
// 			day: "Wednesday",
// 			open: "Lorem ipsum",
// 			lunchStart: "Lorem ipsum",
// 			lunchEnd: "Lorem ipsum",
// 			closes: "Lorem ipsum",
// 			closed: true,
// 		},
// 		{
// 			day: "Thursday",
// 			open: "Lorem ipsum",
// 			lunchStart: "Lorem ipsum",
// 			lunchEnd: "Lorem ipsum",
// 			closes: "Lorem ipsum",
// 			closed: true,
// 		},
// 		{
// 			day: "Friday",
// 			open: "Lorem ipsum",
// 			lunchStart: "Lorem ipsum",
// 		lunchEnd: "Lorem ipsum",
// 		closes: "Lorem ipsum",
// 		closed: true,
// 	},
// 	{
// 		day: "Saturday",
// 		open: "Lorem ipsum",
// 		lunchStart: "Lorem ipsum",
// 		lunchEnd: "Lorem ipsum",
// 		closes: "Lorem ipsum",
// 		closed: true,
// 	},
// 	{
// 		day: "Sunday",
// 		open: "Lorem ipsum",
// 		lunchStart: "Lorem ipsum",
// 		lunchEnd: "Lorem ipsum",
// 		closes: "Lorem ipsum",
// 		closed: true,
// 	},
// ];

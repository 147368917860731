import React from 'react';
import Handlebars from 'handlebars';

const template = Handlebars.compile(`
   <style>
    body {
      font-family: Arial, sans-serif;
    }
  </style>
  {{!-- Header Section --}}
  <div class="flex">
    <div class="flex-grow border-black p-2 text-center">
      <h1 class="text-lg font-bold">REGULATED MEDICAL WASTE SHIPPING & TRACKING DOCUMENT</h1>
      <p class="text-sm italic">UN3291, Regulated Medical Waste, n.o.s., 6.2, PGII</p>
    </div>
    <div class="pt-6">
      <p class="text-[10px] mb-1">Document ID #</p>
      <p class="text-[10px]">{{documentId}}</p>
    </div>
  </div>

  {{!-- Generator Information Section --}}
  <div>
    <div class="border-2 border-black">
      <div>
        <div class="bg-gray-100 border-b border-black p-1">
          <h2 class="font-bold text-center">GENERATOR INFORMATION</h2>
        </div>
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex-1 text-[10px] border-black">
                <div class="p-2">
                  <p><span class="font-semibold">Name:</span> {{generator.name}}</p>
                  <p><span class="font-semibold">Address:</span> {{generator.address}}</p>
                  <p><span class="font-semibold">Phone:</span> {{generator.phone}}</p>
                </div>
              </div>
            </div>
            <div>
              <div class="flex-1 p-2 text-[10px]">
                <p><span class="font-semibold">Generator OCTO ID #:</span> {{generator.octoId}}</p>
                <p><span class="font-semibold">Contractor:</span> {{generator.contractor}}</p>
                <p><span class="font-semibold">Registration Number:</span> {{generator.registrationNumber}}</p>
              </div>
            </div>
          </div>
        </div>
        {{!-- Generator's Certification --}}
        <div class="border-t border-dotted border-black p-2 text-[10px]">
          <p class="italic"><span class="font-semibold">Generator's Certification:</span> {{generator.certification}}</p>
        </div>

        {{!-- Generator Signature Section --}}
        <div class="border-black text-[10px]">
          <div>
            <div class="flex p-2 border-black">
              <p class="font-semibold">Representative Signature:</p>
            </div>

            <div class="flex justify-between p-2 border-black">
              <div class="flex mr-1">
                <p class="font-semibold">Generator Representative (Print):</p>
                <p>{{generator.representative}}</p>
              </div>
              <div class="flex">
                <p class="font-semibold">Date/Time:</p>
                <p>{{generator.dateTime}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {{!-- Primary Transporter Information Section --}}
  <div class="pt-2">
    <div class="border-2 border-black">
      <div>
        <div class="bg-gray-100 border-b border-black p-1">
          <h2 class="font-bold text-center">PRIMARY TRANSPORTER INFORMATION</h2>
        </div>
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex-1 text-[10px] border-black">
                <div class="p-2">
                  <p><span class="font-semibold">Name:</span> {{transporter.name}}</p>
                  <p><span class="font-semibold">Address:</span> {{transporter.address}}</p>
                  <p><span class="font-semibold">Phone:</span> {{transporter.phone}}</p>
                </div>
              </div>
            </div>
            <div>
              <div class="flex-1 p-2 text-[10px]">
                <p><span class="font-semibold">Transporter OCTO ID #:</span> {{transporter.octoId}}</p>
                <p><span class="font-semibold">Subcontractor:</span> {{transporter.subcontractor}}</p>
                <p><span class="font-semibold">Registration Number:</span> {{transporter.registrationNumber}}</p>
              </div>
            </div>
          </div>
        </div>
        {{!-- Transporter's Certification --}}
        <div class="border-t border-dotted border-black p-2 text-[10px]">
          <p class="italic"><span class="font-semibold">Transporter's Certification:</span> {{transporter.certification}}</p>
        </div>

        {{!-- Transporter Signature Section --}}
        <div class="border-black text-[10px]">
          <div>
            <div class="flex p-2 border-black">
              <p class="font-semibold">Representative Signature:</p>
            </div>

            <div class="flex justify-between p-2 border-black">
              <div class="flex mr-1">
                <p class="font-semibold">Transporter Representative (Print):</p>
                <p>{{transporter.representative}}</p>
              </div>
              <div class="flex">
                <p class="font-semibold">Date/Time:</p>
                <p>{{transporter.dateTime}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {{!-- Description of Waste Section --}}
  <div class="pt-2">
    <div class="border-2 border-black">
      <div class="bg-gray-100 border-b border-black p-1">
        <h2 class="font-bold text-center">DESCRIPTION OF WASTE</h2>
        <p class="italic text-center text-[10px]">(UN3291, Regulated Medical Waste, N.O.S., 6.2, PG II)</p>
      </div>

      <div class="flex w-full">
        {{#each wasteChunks}}
        <div class="w-1/2">
          <table class="table-auto border-collapse border border-black w-full text-[10px]">
            <thead>
              <tr class="bg-gray-100 border-b border-black">
                <th class="border border-black p-1">Serial Number</th>
                <th class="border border-black p-1">Subwaste Type</th>
                <th class="border border-black p-1">Container</th>
                <th class="border border-black p-1">Quantity</th>
                <th class="border border-black p-1">Weight (Lbs)</th>
              </tr>
            </thead>
            <tbody>
              {{#each this}}
              <tr>
                <td class="border border-black text-center p-1 text-[8px]">{{serialNumber}}</td>
                <td class="border border-black text-center p-1 text-[8px]">{{subwasteType}}</td>
                <td class="border border-black text-center p-1 text-[8px]">{{container}}</td>
                <td class="border border-black p-1 text-right text-[8px]">{{quantity}}</td>
                <td class="border border-black p-1 text-right text-[8px]">{{weight}}</td>
              </tr>
              {{/each}}
            </tbody>
          </table>
        </div>
        {{/each}}
      </div>

      <div class="border-t border-black p-2">
        <p class="text-center text-[10px] font-semibold">TOTAL WEIGHT: {{totalWeight}} Lbs</p>
      </div>
    </div>
  </div>

  {{!-- Transfer Station Information Section --}}
  <div class="pt-2">
    <div class="border-2 border-black">
      <div>
        <div class="bg-gray-100 border-b border-black p-1">
          <h2 class="font-bold text-center">TRANSFER STATION / TRANSPORTER #2 INFORMATION</h2>
        </div>
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex-1 text-[10px] border-black">
                <div class="p-2">
                  <p><span class="font-semibold">Name:</span> {{transfer.name}}</p>
                  <p><span class="font-semibold">Address:</span> {{transfer.address}}</p>
                  <p><span class="font-semibold">Phone:</span> {{transfer.phone}}</p>
                </div>
              </div>
            </div>
            <div>
              <div class="flex-1 p-2 text-[10px]">
                <p><span class="font-semibold">OCTO ID #:</span> {{transfer.octoId}}</p>
                <p><span class="font-semibold">Subcontractor:</span> {{transfer.subcontractor}}</p>
                <p><span class="font-semibold">Registration Number:</span> {{transfer.registrationNumber}}</p>
              </div>
            </div>
          </div>
        </div>
        {{!-- Transfer Station Certification --}}
        <div class="border-t border-dotted border-black p-2 text-[10px]">
          <p class="italic"><span class="font-semibold">Transfer Station Certification:</span> {{transfer.certification}}</p>
        </div>

        {{!-- Transfer Station Signature Section --}}
        <div class="border-black text-[10px]">
          <div>
            <div class="p-2 mr-1">
              <p class="font-semibold mr-1">Representative Signature:</p>
            </div>

            <div class="flex justify-between p-2 border-black">
              <div class="flex mr-1">
                <p class="font-semibold">Transfer Station Representative (Print):</p>
                <p>{{transfer.representative}}</p>
              </div>
              <div class="flex">
                <p class="font-semibold">Date/Time:</p>
                <p>{{transfer.dateTime}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {{!-- Treatment Facility Section --}}
  <div class="pt-2">
    <div class="border-2 border-black">
      <div>
        <div class="bg-gray-100 border-b border-black p-1">
          <h2 class="font-bold text-center">TREATMENT FACILITY</h2>
        </div>
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex-1 text-[10px] border-black">
                <div class="p-2">
                  <p><span class="font-semibold">Name:</span> {{treatment.name}}</p>
                  <p><span class="font-semibold">Address:</span> {{treatment.address}}</p>
                  <p><span class="font-semibold">Phone:</span> {{treatment.phone}}</p>
                </div>
              </div>
            </div>
            <div>
              <div class="flex-1 p-2 text-[10px]">
                <p><span class="font-semibold">OCTO ID #:</span> {{treatment.octoId}}</p>
                <p><span class="font-semibold">Subcontractor:</span> {{treatment.subcontractor}}</p>
                <p><span class="font-semibold">Registration Number:</span> {{treatment.registrationNumber}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="border-t border-dotted border-black p-2 text-[10px]">
          <p class="italic"><span class="font-semibold">Discrepancy Indication Space:</span> </p>
        </div>

        {{!-- Treatment Facility Certification --}}
        <div class="border-black p-2 text-[10px]">
          <p class="italic"><span class="font-semibold">Treatment Station Certification:</span> {{treatment.certification}}</p>
        </div>

        {{!-- Treatment Facility Signature Section --}}
        <div class="border-black text-[10px]">
          <div>
            <div class="flex p-2 border-black">
              <p class="font-semibold">Representative Signature:</p>
            </div>

            <div class="flex justify-between p-2 border-black">
              <div class="flex mr-1">
                <p class="font-semibold">Treatment Station Representative (Print):</p>
                <p>{{treatment.representative}}</p>
              </div>
              <div class="flex">
                <p class="font-semibold">Date/Time:</p>
                <p>{{treatment.dateTime}}</p>
              </div>
            </div>
            <div class="flex justify-end p-2">
              <p class="font-semibold">Total Weight:</p>
              <p>{{treatment.totalWeight}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`);

const SmartManifest = () => {
    const data = {
      documentId: '12345678923',
      generator: {
        name: "SDMW Gameday Men's Health 42279-Coeur d'Alene ID",
        address: '7866 La Mesa Blvd La Mesa, CA 91942 42279-Coeur d\'Alene ID',
        phone: '619-090-4504',
        octoId: 'XXXXXX',
        contractor: 'San Diego Medical Waste Services, LLC',
        registrationNumber: '',
        certification: 'I certify that the information provided is accurate and that the materials have been properly classified, described, packaged, labeled, and are in suitable condition for transportation in compliance with the applicable U.S. Department of Transportation regulations.',
        representative: 'Sammy Joe Spaccamonti',
        dateTime: 'Mon 10-09-2024 1:08 PM'
      },
      transporter: {
        name: 'San Diego Medical Waste Services, LLC of Southern California',
        address: '7866 La Mesa Blvd La Mesa, CA 91942 42279-Coeur d\'Alene ID',
        phone: '619-090-4504',
        octoId: 'XXXXXX',
        subcontractor: 'San Diego Medical Waste Services, LLC',
        registrationNumber: 'PR0081085-6324CA4414',
        certification: 'I certify that the information provided is true and correct, and that this load contains only untreated medical waste. I understand that any falsification of this manifest may result in the loss of my transporter\'s registration and/or the right to use State-authorized facilities.',
        representative: 'Sammy Joe Spaccamonti',
        dateTime: 'Mon 10-09-2024 1:08 PM'
      },
      transfer: {
        name: 'Secondary Transporter Services',
        address: '123 Main St, Secondary City, SC 12345',
        phone: '123-456-7890',
        octoId: 'SECOND123',
        subcontractor: 'Secondary Transporter Subcontractor',
        registrationNumber: 'PR0023456-7890SC1234',
        certification: ' I certify that the information provided is true and correct, and that this load contains only untreated medical waste. I understand that any falsification of this manifest may result in the loss of my transporter\'s registration and/or the right to use State-authorized facilities.',
        representative: 'John Doe',
        dateTime: 'Tue 10-10-2024 9:15 AM'
      },
      treatment: {
        name: 'Treatment Facility Services',
        address: '789 Treatment Ave, Treatment City, TC 54321',
        phone: '987-654-3210',
        octoId: 'TREAT456',
        subcontractor: 'Treatment Facility Subcontractor',
        registrationNumber: 'TF0098765-4321TC5678',
        certification: ' I certify that the information provided is true and accurate. I am authorized to accept untreated medical waste and have received the specified waste in accordance with the outlined requirements',
        representative: 'Jane Smith',
        dateTime: 'Tue 10-10-2024 2:30 PM',
        totalWeight: '100'
      },
      waste: [
        { serialNumber: '001', subwasteType: 'Sharps Waste', container: 'Box', quantity: 2, weight: 15 },
        { serialNumber: '002', subwasteType: 'Pathological Waste', container: 'Bin', quantity: 1, weight: 20 },
        { serialNumber: '003', subwasteType: 'Chemotherapy Waste', container: 'Bag', quantity: 5, weight: 10 },
        { serialNumber: '004', subwasteType: 'Pharmaceutical Waste', container: 'Box', quantity: 3, weight: 25 },
        { serialNumber: '005', subwasteType: 'Trace Chemo Waste', container: 'Bag', quantity: 4, weight: 30 }
      ],
      totalWeight: 100
    };
  
    // Split waste array into two chunks for the two-column layout
    const wasteChunks = [
      data.waste.slice(0, Math.ceil(data.waste.length / 2)),
      data.waste.slice(Math.ceil(data.waste.length / 2))
    ];
    
    const formData = {
      ...data,
      wasteChunks
    };
  
    const html = template(formData);
  
    return (
      <div className="max-w-4xl mx-auto my-8">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };
  
  export default SmartManifest;
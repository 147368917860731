import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBox from "../../../components/UI/searchBox/Search";
import { formattedDate } from "../../../utils/helpers";
import Breadcrumb from "../../../components/header/Breadcrumb";
//import UserAccBtn from "../../../components/userAccBtn/UserAccBtn";
import UserAccBtn from "./UserAccBtn";
import Notification from "../../../components/notifications/Notification";
import MinitransportorRegModal from "../../../components/userAccBtn/MiniTransporterRegModal";
import HamburgerMenu from "../../../components/header/HamburgerMenu";

// Function to get the breadcrumb items based on the location (URL)
const getTransporterBreadcrumbItems = (location) => {
    // If the path is exactly "/transporter", show only "Transporters"
    if (location.pathname === "/transporter") {
        return [
            { label: "Transporter", path: "/transporter" }
        ];
    }

     return [];
};

const TransportHeader = () => {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [breadCrumbOptions, setBreadCrumbOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Only call getTransporterBreadcrumbItems if path is /transporter
        if (location.pathname === "/transporter") {
            setBreadCrumbOptions(getTransporterBreadcrumbItems(location));
        }
    }, [location]);

    // Open modal to add transporter
    const openAddTransporterModal = () => {
        setIsModalOpen(true);
    };

    // Find the active breadcrumb index based on current path
    const activeIndex = breadCrumbOptions.findIndex(
        item => item.path === location.pathname
    );

    // Dynamic header classes based on the path
    const headerClass = location.pathname === "/transporter"
        ? "bg-white py-4"
        : "bg-white border border-borderCol h-26";

    const wrapperClass = location.pathname === "/transporter"
        ? "ml-72 max-w-full mx-auto"
        : "max-w-full mx-auto";

    return (
        <div className={`top-0 flex items-center px-8 text-sm xl:text-base justify-between ${headerClass} ${wrapperClass}`}>
            {/* Hamburger Menu for smaller screens */}
            <div className="md:hidden">
                <HamburgerMenu />
            </div>

            {/* If we're on the /transporter page, show the Breadcrumb */}
            {location.pathname === "/transporter" && (
                <Breadcrumb
                    items={breadCrumbOptions}
                    activeIndex={activeIndex}
                   // navigate={navigate}
                />
            )}

            <div className="flex gap-2 md:gap-4 items-center">
                {/* On /transporter, show date and notification */}
                {location.pathname === "/transporter" && (
                    <>
                        <div className="hidden xl:flex items-center bg-white border border-gray-200 rounded-full px-10 h-12">
                            <h6 className="truncate">{formattedDate(new Date())}</h6>
                        </div>
                        <Notification
                            isOpen={isNotificationOpen}
                            setIsOpen={setIsNotificationOpen}
                        />
                    </>
                )}

                {/* User Account Button */}
                <UserAccBtn />
            </div>

            {/* Modal for mini transporter registration */}
            {isModalOpen && (
                <MinitransportorRegModal 
                    onClose={() => setIsModalOpen(false)} 
                    activeSubmenu="transporter" 
                />
            )}
        </div>
    );
};

export default TransportHeader;

import { COLLECTIONS, db, storage } from "../config/firebase";
import {
	doc,
	getDoc,
	setDoc,
	updateDoc,
	collection,
	increment,
	deleteDoc,
	getDocs,
	runTransaction,
	query,
	orderBy,
	where,
	writeBatch,
	addDoc,
	Timestamp,
	limit,
	documentId,
	onSnapshot,
	serverTimestamp,
	arrayUnion,
	startAfter,
} from "firebase/firestore";
import { formattedDate, formattedDateTime } from "./helpers";
import { ref } from "firebase/storage";

const MAX_GENERATORS = 9999999; // Allows for 999-99999-999 as the maximum

let lastReservedNumber = null;
let isReserving = false;

export async function getNextGeneratorId() {
	if (isReserving) {
		return lastReservedNumber;
	}

	isReserving = true;

	const counterRef = doc(db, "generators_count", "generators_count");

	try {
		const reservedNumber = await runTransaction(db, async (transaction) => {
			const counterDoc = await transaction.get(counterRef);

			if (!counterDoc.exists()) {
				throw new Error("Counter document does not exist!");
			}

			const currentNumber = counterDoc.data().count;
			const nextNumber = Number(currentNumber) + 1;

			if (nextNumber > MAX_GENERATORS) {
				throw new Error("Generator number limit reached!");
			}

			return nextNumber;
		});

		lastReservedNumber = formatOctoNumber(reservedNumber);
		isReserving = false;
		return lastReservedNumber;
	} catch (error) {
		console.error("Error reserving next generator number:", error);
		isReserving = false;
		throw error;
	}
}

function formatOctoNumber(number) {
	const paddedNumber = number.toString().padStart(7, "0");
	const part1 = paddedNumber.substr(0, 3);
	const part2 = paddedNumber.substr(3, 4);
	return `${part1}-${part2}`;
}

export async function createOrUpdateGenerator(generatorData) {
	try {
		let generatorRef;
		let isNewGenerator = false;

		console.log("Generator data sent to the firebase fn", generatorData);

		if (generatorData.id) {
			generatorRef = doc(db, "generators", generatorData.id.toString());
			const generatorSnap = await getDoc(generatorRef);

			if (generatorSnap.exists()) {
				// Update existing document
				const updatedData = {
					...generatorData,
					updatedAt: new Date(),
				};
				await updateDoc(generatorRef, updatedData);
				console.log(`Updated generator with ID: ${generatorData.id}`);
			} else {
				isNewGenerator = true;
			}
		} else {
			isNewGenerator = true;
		}

		if (isNewGenerator) {
			// Create new document
			const reservedNumber = await getNextGeneratorId();
			generatorRef = doc(collection(db, "generators"));

			const newGeneratorData = {
				...generatorData,
				id: generatorRef.id,
				octoNumber: reservedNumber,
				createdAt: new Date(),
				updatedAt: new Date(),
			};

			await setDoc(generatorRef, newGeneratorData);

			// Confirm the increment of the count for new documents
			const countRef = doc(db, "generators_count", "generators_count");
			await runTransaction(db, async (transaction) => {
				const countDoc = await transaction.get(countRef);
				if (!countDoc.exists()) {
					throw new Error("Counter document does not exist!");
				}
				const currentCount = countDoc.data().count;
				const reservedNumberInt = parseInt(reservedNumber.replace(/-/g, ""));
				if (currentCount < reservedNumberInt) {
					transaction.update(countRef, { count: reservedNumberInt });
				} else {
					console.warn("Reserved number doesn't exceed current count. Not updating count.");
				}
			});

			console.log(`Created new generator with ID: ${generatorRef.id} and octoNumber: ${reservedNumber}`);
		}

		return generatorRef.id;
	} catch (error) {
		console.error("Error creating/updating generator:", error);
		throw error;
	}
}

export async function getGeneratorById(generatorId) {
	try {
		const generatorRef = doc(db, "generators", generatorId.toString());
		const generatorSnap = await getDoc(generatorRef);

		if (generatorSnap.exists()) {
			let response = generatorSnap.data();
			if (response.createdAt && response.createdAt.seconds) {
				response.createdAt = new Date(response.createdAt.toDate()).toISOString(); // Or use .toDate() and convert to string
			}
			if (response.updatedAt && response.updatedAt.seconds) {
				response.updatedAt = new Date(response.updatedAt.toDate()).toISOString();
			}
			return {
				id: generatorSnap.id,
				...response,
				scheduleDate: response.scheduleDate ? formattedDateTime(response.scheduleDate) : new Date(),
				contractStartDate: response.contractStartDate ? formattedDate(response.contractStartDate) : "",
				contractEndDate: response.contractEndDate ? formattedDate(response.contractEndDate) : "",
			};
		} else {
			return null; // Indicate no data found without logging (can be customized)
		}
	} catch (error) {
		console.error("Error fetching generator:", error.code);

		// Specific Error Handling:
		if (error.code === "permission-denied") {
			throw new Error("Permission denied: You do not have permission to access this generator.");
		} else if (error.code === "not-found") {
			return null; // Handle 'not-found' errors gracefully
		} else {
			// Other error handling strategies:
			// 1. Return a default value or error object:
			// return { error: 'An error occurred while fetching the generator' };
			// 2. Display an error message to the user:
			// alert('Error fetching generator: ' + error.message);
			// 3. Log the error to an external service:
			// logErrorToExternalService(error);
		}
	}
}

export async function getAllGenerators() {
	try {
		console.log("Called for api");

		const generatorsRef = collection(db, "generators");
		const q = query(generatorsRef);
		const generatorsSnap = await getDocs(q);

		const serviceSchedulesRef = collection(db, "serviceSchedules");
		const qSS = query(serviceSchedulesRef);
		const serviceSchedulesSnap = await getDocs(qSS);

		let serviceSchedulesDocs = serviceSchedulesSnap.docs?.map((doc) => {
			let resp = doc.data();
			return {
				id: doc.id,
				frequency: resp.serviceFrequency?.type,
			};
		});

		let docs = generatorsSnap.docs
			?.map((doc) => {
				let response = doc.data();
				if (response.createdAt && response.createdAt.seconds) {
					response.createdAt = new Date(response.createdAt.toDate()).toISOString(); // Or use .toDate() and convert to string
				}
				if (response.updatedAt && response.updatedAt.seconds) {
					response.updatedAt = new Date(response.updatedAt.toDate()).toISOString();
				}
				response.scheduleDate = response.scheduleDate ? formattedDateTime(response.scheduleDate) : "";
				response.contractStartDate = response.contractStartDate ? formattedDate(response.contractStartDate) : "";
				response.contractEndDate = response.contractEndDate ? formattedDate(response.contractEndDate) : "";
				if (response?.serviceSchedules && Array.isArray(response?.serviceSchedules)) {
					if (response.serviceSchedules?.length == 0) response.serviceFrequency = ["Unscheduled"];
					else {
						let resp = serviceSchedulesDocs
							.filter((item) => response?.serviceSchedules?.includes(item.id))
							.map((item) => item.frequency);
						response.serviceFrequency = resp;
					}
				} else {
					response.serviceFrequency = ["Unscheduled"];
				}
				return {
					id: doc.id,
					...response,
					name: response.generatorName ?? "--",
					email: response.generatorEmail?.length ? response.generatorEmail : "--",
					phone: response.generatorPhone?.length ? response.generatorPhone : "",
					internalAccountNumber: response.internalAccountNumber?.length ? response.internalAccountNumber : "--",
					octoNumber: response.octoNumber ?? "--",
					serviceType: response.serviceType ?? [],
					customerType: response.customerType ?? "--",

					address:
						[
							response.serviceAddress?.street,
							response.serviceAddress?.city,
							response.serviceAddress?.state,
							response.serviceAddress?.zipCode,
						]
							.filter(Boolean)
							.join(", ") || "--",
				};
			})
			.filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));

		const sortedDocs = docs.sort((a, b) => {
			if (a.createdAt && b.createdAt) return new Date(b.createdAt) - new Date(a.createdAt);
			if (a.createdAt) return -1;
			if (b.createdAt) return 1;
			return 0;
		});

		return sortedDocs;
	} catch (error) {
		console.error("Error fetching generator:", error.code);

		// Specific Error Handling:
		if (error.code === "permission-denied") {
			throw new Error("Permission denied: You do not have permission to access this generator.");
		} else if (error.code === "not-found") {
			return null; // Handle 'not-found' errors gracefully
		} else {
			// Other error handling strategies:
			// 1. Return a default value or error object:
			// return { error: 'An error occurred while fetching the generator' };
			// 2. Display an error message to the user:
			// alert('Error fetching generator: ' + error.message);
			// 3. Log the error to an external service:
			// logErrorToExternalService(error);
		}
	}
}

export async function updateServiceSchedules(schedules, generatorDocId = null) {
	const updatedScheduleIds = [];

	for (const schedule of schedules) {
		if (schedule.id) {
			// Update existing schedule
			const scheduleDocRef = doc(db, "serviceSchedules", schedule.id);
			await updateDoc(scheduleDocRef, schedule);
			updatedScheduleIds.push(schedule.id);
		} else {
			// New schedule
			const newScheduleRef = doc(collection(db, "serviceSchedules"));
			const scheduleWithRef = {
				...schedule,
				id: newScheduleRef.id,
			};
			await setDoc(newScheduleRef, scheduleWithRef);
			updatedScheduleIds.push(newScheduleRef.id);
		}
	}

	// Update the generator document with the new array of schedule ids
	if (generatorDocId) {
		const generatorDocRef = doc(db, "generators", generatorDocId);
		await updateDoc(generatorDocRef, {
			serviceSchedules: updatedScheduleIds,
		});
	}

	return updatedScheduleIds;
}

export async function getServiceSchedules(schedules) {
	if (!Array.isArray(schedules)) return [];
	let schedulesPromises = schedules.map((id) => getDoc(doc(db, "serviceSchedules", id)));
	try {
		let resp = await Promise.all(schedulesPromises);
		// Filter out null values (failed or missing documents)
		return resp.filter((doc) => doc !== null).map((doc) => ({ id: doc.id, ...doc.data() }));
	} catch (error) {
		return [];
	}
}

export async function deleteServiceSchedule(schedule, generatorId) {
	if (schedule.id) {
		try {
			const scheduleRef = doc(db, "serviceSchedules", schedule.id);
			const scheduleDoc = await getDoc(scheduleRef);
			if (scheduleDoc.exists) {
				await deleteDoc(scheduleRef);
				// After delete from generator serviceSchedules
				// Get generator document
				const generatorRef = doc(db, "generators", generatorId);
				const generatorDoc = await getDoc(generatorRef);

				if (generatorDoc.exists()) {
					const currentSchedules = generatorDoc.data().serviceSchedules || [];
					// Remove schedule.id from array
					const updatedSchedules = currentSchedules.filter((id) => id !== schedule.id);

					// Update generator document
					await updateDoc(generatorRef, {
						serviceSchedules: updatedSchedules,
					});
				}
			}
			return true;
		} catch (error) {
			return false;
		}
	}
}

export async function updatePriorityOfScheduledService(scheduledServiceId, isChecked) {
	const scheduleRef = doc(db, "scheduledServices", scheduledServiceId);
	let scheduleDoc = await getDoc(scheduleRef);
	let scheduleData = scheduleDoc.data();
	console.log(scheduleDoc, scheduledServiceId, isChecked);

	try {
		await updateDoc(scheduleRef, {
			...scheduleData,
			isPriority: isChecked,
		});
		return {
			success: true,
			message: isChecked ? "Marked as priority" : "Removed from priority",
		};
	} catch (error) {
		console.log("Error while making priority", error);
		return {
			success: false,
			message: "Unable to mark as priority due to some technical issue!",
		};
	}
}

export async function getAllSchedluedServiceOfGen(generatorId, routeId) {
	try {
		const startDate = new Date();
		const routesRef = collection(db, "scheduledServices");
		const q = query(routesRef);
		const routesSnap = await getDocs(q);

		// Caches for both service schedule and route data
		const serviceScheduleCache = new Map();
		const routeCache = new Map();

		// Get filtered docs
		const docs = await Promise.all(
			routesSnap.docs
				.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))
				.filter((item) => item.generatorId === generatorId && item.routeId === routeId)
				.map(async (item) => {
					// Fetch service schedule data if needed
					if (item.serviceScheduleId) {
						if (!serviceScheduleCache.has(item.serviceScheduleId)) {
							const scheduleDoc = await getDoc(doc(db, "serviceSchedules", item.serviceScheduleId));
							if (scheduleDoc.exists()) {
								serviceScheduleCache.set(item.serviceScheduleId, {
									id: scheduleDoc.id,
									...scheduleDoc.data(),
								});
							}
						}
						item.serviceSchedule = serviceScheduleCache.get(item.serviceScheduleId) || null;
					}

					// Fetch route data if needed
					if (item.routeId) {
						if (!routeCache.has(item.routeId)) {
							const routeDoc = await getDoc(doc(db, "routes", item.routeId));
							if (routeDoc.exists()) {
								routeCache.set(item.routeId, {
									id: routeDoc.id,
									...routeDoc.data(),
								});
							}
						}
						item.route = routeCache.get(item.routeId) || null;
					}

					return item;
				})
		);

		return (
			docs
				?.sort((a, b) => a.date?.toDate() - b.date?.toDate())
				.map((item) => ({ ...item, date: item.date?.toDate() })) || []
		);
	} catch (error) {
		console.error("Error fetching routes:", error.code);
		if (error.code === "permission-denied") {
			throw new Error("Permission denied: You do not have permission to access these routes.");
		} else if (error.code === "not-found") {
			return null;
		}
		throw error;
	}
}

export async function getAllRoutes(userId) {
	try {
		const routesRef = collection(db, "routes");
		const q = query(routesRef, where("status", "==", "ACTIVE"), where("transporterId", "==", userId));
		const routesSnap = await getDocs(q);

		let docs = routesSnap.docs?.map((doc) => {
			return {
				id: doc.id,
				...doc.data(),
			};
		});

		return docs;
	} catch (error) {
		console.error("Error fetching routes:", error.code);
		if (error.code === "permission-denied") {
			throw new Error("Permission denied: You do not have permission to access these routes.");
		} else if (error.code === "not-found") {
			return null;
		}
	}
}

export const deleteBatchItemsInFirestore = async (collectionName, ids) => {
	const collectionRef = collection(db, collectionName);
	let batch = writeBatch(db);

	const batchSize = 500;
	const batches = [];

	for (let i = 0; i < ids.length; i += batchSize) {
		const batchIds = ids.slice(i, i + batchSize);

		batchIds.forEach((id) => {
			const docRef = doc(collectionRef, id);
			batch.delete(docRef);
		});

		batches.push(batch);
	}

	try {
		await Promise.all(batches.map((batch) => batch.commit()));
		console.log(`Successfully deleted ${ids.length} items`);
		return true;
	} catch (error) {
		console.error("Error deleting batch items:", error);
		return false;
	}
};

export const addOrUpdateDataInFirestore = async (collectionName, docId, data, merge = true) => {
	try {
		const docRef = doc(db, collectionName, docId || undefined);
		if (docId) data.createdAt = new Date();
		else data.updatedAt = new Date();
		await setDoc(docRef, data, { merge });
		console.log("Document successfully written!");
		return docRef;
	} catch (error) {
		console.error("Error writing document: ", error);
		throw error;
	}
};

export async function createNewVendor(data) {
	const vendorRef = collection(db, "vendors");

	try {
		const newVendorRef = doc(vendorRef);
		await setDoc(newVendorRef, { ...data });
		console.log("Vendor added successfully:", newVendorRef.id);
		return newVendorRef.id;
	} catch (error) {
		console.error("Error creating/updating vendor:", error);
		throw error;
	}
}

export async function getAllVendor(userId) {
	const vendorsRef = collection(db, "vendors");
	const q = query(vendorsRef, where("transporterId", "==", userId));
	const vendorsSnap = await getDocs(q);
	const docs = vendorsSnap.docs.map((doc) => {
		const data = doc.data();
		console.log("Vendor Data:", data);

		return {
			id: doc.id,
			name: typeof data.name === "string" ? data.name : "--",
			email: typeof data.email === "string" ? data.email : "--",
			phoneNumber: data.phoneNumber || "--",
			notes: data.notes || "--",
			street: data.street || "--",
			apartment: data.apartment || "--",
			city: data.city || "--",
			state: data.state || "--",
			zipCode: data.zipCode || "--",
			vendorStatus: data.vendorStatus || "--",
			vendorType: Array.isArray(data.vendorType) && data.vendorType.length ? data.vendorType : ["--"],
			attachFiles: data.attachFiles || "--",
			createdAt: data.createdAt ? new Date(data.createdAt.seconds * 1000) : null,
		};
	});

	console.log("vendors:", docs);
	return docs;
}

export const GetVendorData = async (id) => {
	const vendorsRef = doc(db, "vendors", id);
	const result = await getDoc(vendorsRef);

	if (!result.exists()) {
		throw new Error("Vendor not found.");
	}

	const data = result.data();

	return {
		name: data.name || "",
		apartment: data.apartment || "",
		state: data.state || "",
		phoneNumber: data.phoneNumber || "",
		email: data.email || "",
		vendorWebsite: data.vendorWebsite || "",
		vendorType: Array.isArray(data.vendorType) ? data.vendorType : [],
		vendorStatus: data.vendorStatus || "",
		street: data.street || "",
		city: data.city || "",
		zipCode: data.zipCode || "",
		notes: data.Notes || "",
		altphone: data.altphone || "",
		file: data.file || "",
	};
};

export const EditVendorDetails = async (data, id) => {
	if (!id || typeof id !== "string") {
		throw new Error("Invalid vendor ID");
	}

	const vendorsRef = doc(db, "vendors", id);
	try {
		await updateDoc(vendorsRef, data);
		console.log("Vendor details updated:", vendorsRef.id);
		return { success: true, vendorsRef: vendorsRef.id };
	} catch (error) {
		console.error("Error updating vendor:", error);

		if (error.code === "not-found") {
			throw new Error("Vendor not found.");
		} else if (error.code === "permission-denied") {
			throw new Error("You do not have permission to update this vendor.");
		} else {
			throw new Error("An unexpected error occurred. Please try again.");
		}
	}
};

export const addUserToDb = async (collectionName, documentId, data) => {
	try {
		const docRef = doc(db, collectionName, documentId);
		let resp = await setDoc(docRef, {
			id: docRef.id,
			...data,
		});
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const deleteVendor = async (id) => {
	const vendorsRef = doc(db, "vendors", id);
	try {
		await deleteDoc(vendorsRef);
		console.log("vendor deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting vendor:", error);
		throw error;
	}
};

export async function getDrivers(userId) {
	const driversRef = query(collection(db, "drivers"), where("transporterId", "==", userId));
	const q = query(driversRef);
	const driversSnap = await getDocs(q);

	const docs = driversSnap.docs.map((doc) => {
		const data = doc.data();
		return {
			id: doc.id,
			portalAccess: data.portalAccess || "--",
			dateOfBirth: data.dateOfBirth,
			driverLicenseInfo: data.driverLicenseInfo,
			driverLocation: data.driverLocation || "--",
			email: data.email || "--",
			firstName: data.firstName || "--",
			hourlyWage: data.hourlyWage || "--",
			lastName: data.lastName || "--",
			licensePhoto: data.licensePhoto || "--",
			limitedDutyStatus: data.limitedDutyStatus || "--",
			middleInitial: data.middleInitial || "--",
			scheduledWorkDays: data.scheduledWorkDays || "--",
			scheduledWorkHours: data.scheduledWorkHours || "--",
			startDate: data.startDate || "--",
			username: data.username || "--",
			zebraPrintingSize: data.zebraPrintingSize || "--",
			phoneNumber: data.phoneNumber || "--",
			driverStatus: data.driverStatus || "",
		};
	});

	console.log(docs, "docs");
	return docs;
}
export function getRoutes(setData) {
	return onSnapshot(collection(db, COLLECTIONS.routes), (routesSnap) => {
		const docs = routesSnap.docs.map((snap) => {
			const { routeLabel, truckCostPerMile } = snap.data();
			return {
				id: snap.id,
				name: routeLabel,
				endLocation: snap.data()?.endLocation ?? snap.data()?.defaultEndLocation,
				startLocation: snap.data()?.startLocation ?? snap.data()?.defaultStartLocation,
				truckCostPerMile,
				...snap.data(),
			};
		});
		setData(docs);
	});
}

// export const PostDriverDetails = async (data) => {
//     const driversRef = collection(db, "drivers");

//     try {
//         const newId = data.OctoDriverId;

//         const existingDriverQuery = query(driversRef, where("OctoDriverId", "==", newId));
//         const existingDriverSnapshot = await getDocs(existingDriverQuery);

//         if (!existingDriverSnapshot.empty) {
//             throw new Error("Driver ID is taken in the meantime, please try again.");
//         }

//         const newdriverRef = doc(driversRef);
//         const driverData = { OctoDriverId: newId, ...data };

//         await setDoc(newdriverRef, driverData);

//         console.log("Driver added successfully with ID:", newId);
//         return { newdriverRef: newId, data: driverData };

//     } catch (error) {
//         console.error("Error creating driver:", error);
//         throw error;
//     }
// };

export const PostDriverDetails = async (data) => {
	const driversRef = collection(db, "drivers");

	try {
		console.log("data", data);
		console.log("data.OctoDriverId", data.OctoDriverId);

		// Ensure OctoDriverId is valid and a string
		const newId = String(data.OctoDriverId);

		if (typeof newId !== "string" || newId.trim() === "") {
			throw new Error("Invalid OctoDriverId provided.");
		}

		// Check if the OctoDriverId already exists in the Firestore database
		const existingDriverQuery = query(driversRef, where("OctoDriverId", "==", newId));
		const existingDriverSnapshot = await getDocs(existingDriverQuery);

		if (!existingDriverSnapshot.empty) {
			throw new Error("Driver ID is taken, please try again.");
		}

		// Create a new document with the OctoDriverId as the document key
		const newDriverRef = doc(driversRef, newId);
		const driverData = { OctoDriverId: newId, ...data }; // Merge the data, overriding OctoDriverId if necessary

		// Save the driver data to Firestore
		await setDoc(newDriverRef, driverData);

		console.log("Driver added successfully with OctoDriverId:", newId);
		return { newDriverRef: newId, data: driverData };
	} catch (error) {
		console.error("Error creating driver:", error);
		throw error;
	}
};

export const checkUsernameAvailability = async (username) => {
	const driversRef = collection(db, "drivers");

	const q = query(driversRef, where("username", "==", username));

	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return true;
	} else {
		return false;
	}
};

export const EditDriverDetails = async (data, id) => {
	console.log("data", data);
	const driverRef = doc(db, "drivers", id);
	try {
		delete data.portalAccess.fields;
		await updateDoc(driverRef, { ...data });
		return { driverRef: driverRef.id };
	} catch (error) {
		console.error("Error updating driver:", error);
		throw error;
	}
};

export const GetDriverData = async (id) => {
	const driverRef = doc(db, "drivers", id);

	const result = await getDoc(driverRef);

	return result._document.data.value.mapValue.fields;
};

export const deleteDriver = async (id) => {
	const driverRef = doc(db, "drivers", id);
	try {
		await deleteDoc(driverRef);
		console.log("Driver deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting driver:", error);
		throw error;
	}
};

// export const GetNewDriverId = async () => {

// 	const driversRef = collection(db, "drivers");

// 	try {
// 		const snapshot = await getDocs(driversRef);
// 		const existingIds = [];

// 		snapshot.forEach(doc => {
// 			const driverData = doc.data();
// 			if (driverData.OctoDriverId) {
// 				existingIds.push(driverData.OctoDriverId);
// 			}
// 		});

// 		existingIds.sort((a, b) => a - b);

// 		let newId = 100000;
// 		for (let i = 0; i < existingIds.length; i++) {
// 			if (existingIds[i] !== newId) {
// 				break;
// 			}
// 			newId++;
// 		}

// 		console.log("Generated new unique driver ID:", newId);
// 		return newId;
// 	} catch (error) {
// 		console.error("Error getting new driver ID:", error);
// 		throw error;
// 	}
// };

export const GetNewDriverId = async () => {
	const driversRef = collection(db, "drivers");

	try {
		const snapshot = await getDocs(driversRef);
		const existingIds = [];

		snapshot.forEach((doc) => {
			const driverData = doc.data();
			if (driverData.OctoDriverId) {
				existingIds.push(driverData.OctoDriverId);
			}
		});

		// Sort the IDs as numbers in ascending order
		existingIds.sort((a, b) => Number(a) - Number(b));

		// Get the last ID and increment by 1
		let newId = existingIds.length > 0 ? Number(existingIds[existingIds.length - 1]) + 1 : 100000;

		console.log("Generated new unique driver ID:", newId);
		return newId;
	} catch (error) {
		console.error("Error getting new driver ID:", error);
		throw error;
	}
};

//Treatment

export async function createNewTreatment(data) {
	const treatmentRef = collection(db, "treatments");

	try {
		const newTreatmentRef = doc(treatmentRef);

		await setDoc(newTreatmentRef, { ...data });

		console.log("Treatment added successfully:", newTreatmentRef.id);
		return newTreatmentRef.id;
	} catch (error) {
		console.error("Error creating/updating treatment:", error);
		throw error;
	}
}

export function getAllTreatment(callback, userId) {
	const treatmentsRef = collection(db, "treatments");
	const q = query(treatmentsRef, where("transporterId", "==", userId));

	// Return the unsubscribe function from onSnapshot
	return onSnapshot(q, (snapshot) => {
		const docs = snapshot.docs.map((doc) => {
			const data = doc.data();

			const medicalwasteKeys = data.medicalwaste
				? Object.keys(data.medicalwaste)
						.filter((key) => data.medicalwaste[key] === true)
						.map((key) => {
							switch (key) {
								case "transferStation":
									return "Transfer Station (TS)";
								case "treatmentFacilities":
									return "Treatment Facilities (TF)";
								case "paperShredding":
									return "Onsite Paper Shredding";
								default:
									return key;
							}
						})
				: [];

			return {
				id: doc.id,
				name: typeof data.name === "string" ? data.name : "",
				email: data.email1 || "--",
				octoNumber: data.octoNumber || "--",
				phone: data.phone || "",
				address: data.Address || "",
				street: data.street || "",
				suite: data.suite || "",
				state: data.state || "",
				treatmentStatus: data.treatmentStatus || "--",
				notificationTypeOption: data.notificationTypeOption || "--",
				city: data.city || "",
				stateRegistrationNumber: data.StateRegistrationNumber || "--",
				zipCode: data.zip || "",
				medicalwasteServices: medicalwasteKeys,
			};
		});

		callback(docs);
	});
}

export const deletetreatment = async (id) => {
	const treatmentsRef = doc(db, "treatments", id);
	try {
		await deleteDoc(treatmentsRef);
		console.log("Treatment deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting Treatment:", error);
		throw error;
	}
};

export const GetTreatmentData = async (id) => {
	const treatmentRef = doc(db, "treatments", id);
	const result = await getDoc(treatmentRef);

	if (!result.exists()) {
		throw new Error("Treatment not found.");
	}

	const data = result.data();

	return {
		name: data.name || "",
		street: data.street || "",
		octoNumber: data.octoNumber || "",
		suite: data.suite || "",
		city: data.city || "",
		state: data.state || "",
		zip: data.zip || "",
		phone: data.phone || "",
		email1: data.email1 || "",
		treatmentStatus: data.treatmentStatus || "",
		email2: data.email2 || "",
		email3: data.email3 || "",
		notificationTypeOption: data.notificationTypeOption || "",
		stateRegistrationNumber1: data.stateRegistrationNumber1 || "",
		stateRegistrationNumber2: data.stateRegistrationNumber2 || "",
		stateRegistrationNumber3: data.stateRegistrationNumber3 || "",
		Notes: data.Notes || "",
		medicalwaste: {
			treatmentFacilities: data.medicalwaste?.treatmentFacilities || false,
			transferStation: data.medicalwaste?.transferStation || false,
			paperShredding: data.medicalwaste?.paperShredding || false,
		},
		wasteProcessingFees: {
			biohazard: data.wasteProcessingFees?.biohazard || false,
			chemotherapy: data.wasteProcessingFees?.chemotherapy || false,
			containerWashing: data.wasteProcessingFees?.containerWashing || false,
			documentFee: data.wasteProcessingFees?.documentFee || false,
			energyFee: data.wasteProcessingFees?.energyFee || false,
			pharmaceutical: data.wasteProcessingFees?.pharmaceutical || false,
			gateFee: data.wasteProcessingFees?.gateFee || false,
		},
		treatmentCoordinates: {
			lat: data.treatmentCoordinates?.lat || "",
			lng: data.treatmentCoordinates?.lng || "",
		},
	};
};

export const editTreatmentDetails = async (data, id) => {
	if (!id || typeof id !== "string") {
		throw new Error("Invalid treatment ID");
	}

	const treatmentRef = doc(db, "treatments", id);
	try {
		await updateDoc(treatmentRef, data);
		console.log("Treatment details updated:", treatmentRef.id);
		return { success: true, treatmentRef: treatmentRef.id };
	} catch (error) {
		console.error("Error updating treatment:", error);

		if (error.code === "not-found") {
			throw new Error("Treatment not found.");
		} else if (error.code === "permission-denied") {
			throw new Error("You do not have permission to update this treatment.");
		} else {
			throw new Error("An unexpected error occurred. Please try again.");
		}
	}
};

//Transporter

export function GetTransporterDataByUID(uid, callback) {
	try {
		if (!uid) {
			throw new Error("UID is required");
		}

		const docRef = doc(db, "transporters", uid);

		// Using onSnapshot for real-time data
		const unsubscribe = onSnapshot(docRef, (docSnap) => {
			if (!docSnap.exists()) {
				console.log("No such document!");
				callback(null);
				return;
			}

			const data = docSnap.data();

			const transformedData = {
				name: data.name || "",
				phoneNumber: data.phoneNumber || "",
				website: data.website || "",
				dot: data.dot || "",
				octoNumber: data.octoNumber || "",
				businessHours: data.businessHours || "",
				satelliteStatus: data.satelliteStatus || "",
				reviewLink: data.reviewLink || "",
				entityId: data.entityId || "",
				sic: data.sic || "",
				cageCode: data.cageCode || "",
				companyDisplayName: data.companyDisplayName || "",
				generalEmail: data.generalEmail || "",
				registration1: data.registration1 || "",
				registration2: data.registration2 || "",
				companyLogo: data.companyLogo || "",
				startOfFiscalYear: data.startOfFiscalYear || "",
				ein: data.ein || "",
				epaId: data.epaId || "",
				sos: data.sos || "",
				naicsCode: data.naicsCode || "",
				shortDescription: data.shortDescription || "",
				companyMissionStatement: data.companyMissionStatement || "",
				bio: data.bio || "",
				aboutEmail: data.aboutEmail || "",
				existingUser: data.existingUser || "",
				serviceOffered: {
					medicalWasteTransporter: data.serviceOffered?.medicalWasteTransporter || false,
					medicalWasteTransferStation: data.serviceOffered?.medicalWasteTransferStation || false,
					medicalWasteTreatmentFacilities: data.serviceOffered?.medicalWasteTreatmentFacilities || false,
					rollOffContainer: data.serviceOffered?.rollOffContainer || false,
					offsitePaperShredding: data.serviceOffered?.offsitePaperShredding || false,
					onsitePaperShredding: data.serviceOffered?.onsitePaperShredding || false,
					solidWaste: data.serviceOffered?.solidWaste || false,
					recycling: data.serviceOffered?.recycling || false,
				},
				octoConnect: {
					optIn: data.octoConnect?.optIn || false,
					optout: data.octoConnect?.optout || false,
				},
				createLink: {
					treatmentFacility: data.createLink?.treatmentFacility || false,
					transferStation: data.createLink?.transferStation || false,
				},
				billingAddress: {
					name: data.billingAddress?.name || "",
					street: data.billingAddress?.street || "",
					suite: data.billingAddress?.suite || "",
					city: data.billingAddress?.city || "",
					state: data.billingAddress?.state || "",
					zipCode: data.billingAddress?.zipCode || "",
					email: data.billingAddress?.email || "",
					phone: data.billingAddress?.phone || "",
				},
				serviceAddress: {
					name: data.serviceAddress?.name || "",
					street: data.serviceAddress?.street || "",
					suite: data.serviceAddress?.suite || "",
					city: data.serviceAddress?.city || "",
					state: data.serviceAddress?.state || "",
					zipCode: data.serviceAddress?.zipCode || "",
					email: data.serviceAddress?.email || "",
					phone: data.serviceAddress?.phone || "",
				},

				dispatchEmail: data.dispatchEmail || "",
				dispatchPhone: data.dispatchPhone || "",
				assignedUser: Array.isArray(data.assignedUser) ? data.assignedUser : [],
			};

			// Pass the transformed data to the callback
			callback(transformedData);
		});

		// Return the unsubscribe function to stop the listener when no longer needed
		return unsubscribe;
	} catch (error) {
		console.error("Error fetching transporter data:", error);
		throw error;
	}
}

export async function createNewTransport(data) {
	// Ensure we have a userId in the data
	if (!data.userId) {
		throw new Error("User ID is required to create a transporter");
	}

	// Create a reference using a consistent collection name
	const transportRef = doc(db, "transporters", data.userId);

	try {
		// Clean the data to remove undefined values
		const cleanData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));

		// Provide default values for potentially undefined fields
		const defaultedData = {
			...cleanData,
			createdAt: serverTimestamp(),
			lastUpdated: serverTimestamp(),
		};

		await setDoc(transportRef, defaultedData, { merge: true });

		console.log("Transport added successfully:", transportRef.id);
		return transportRef.id; // This will be the same as the user's UID
	} catch (error) {
		console.error("Error creating/updating transport:", error);
		throw error;
	}
}

export async function getAllTransporters(uid) {
	const transportersRef = collection(db, "transportor");
	const q = query(transportersRef);
	const transportersSnap = await getDocs(q);

	const docs = transportersSnap.docs
		.map((doc) => {
			const data = doc.data();
			console.log("Transporter Data:", data);

			// Check if the current user is assigned to this transporter
			if (!Array.isArray(data.assignedUser) || !data.assignedUser.includes(uid)) {
				return null; // Skip this transporter if the user is not assigned
			}

			return {
				id: doc.id,
				name: typeof data.name === "string" ? data.name : "--",
				generalEmail: typeof data.generalEmail === "string" ? data.generalEmail : "--",
				phoneNumber: typeof data.phoneNumber === "string" ? data.phoneNumber : "--",
				octoNumber: typeof data.octoNumber === "string" ? data.octoNumber : "--",
				licensePlate: typeof data.licensePlate === "string" ? data.licensePlate : "--",
				capacity: typeof data.capacity === "string" ? data.capacity : "--",
				assignedDriver: typeof data.assignedDriver === "string" ? data.assignedDriver : "--",
				address: typeof data.address === "string" ? data.address : "--",
				apartment: typeof data.apartment === "string" ? data.apartment : "--",
				transportorStatus: typeof data.transportorStatus === "string" ? data.transportorStatus : "--",
				website: typeof data.website === "string" ? data.website : "--",
				cageCode: typeof data.cageCode === "string" ? data.cageCode : "--",
				epaId: typeof data.epaId === "string" ? data.epaId : "--",
				billingStreet: data.billingStreet || "",
				billingSuite: data.billingSuite || "",
				billingState: data.billingState || "",
				billingZip: data.billingZip || "",
			};
		})
		.filter((transporter) => transporter !== null); // Filter out transporters that the user is not assigned to

	return docs;
}

export const deletetransporter = async (id) => {
	const treatmentsRef = doc(db, "transportor", id);
	try {
		await deleteDoc(treatmentsRef);
		console.log("Satellite Location deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting Satellite Location:", error);
		throw error;
	}
};

export const GetTransporterData = async (id, currentUserUID) => {
	const satelliteRef = doc(db, "transportor", id);
	const result = await getDoc(satelliteRef);

	if (!result.exists()) {
		throw new Error("Transporter not found.");
	}

	const data = result.data();

	// Check if the current user is assigned to this transporter
	if (!Array.isArray(data.assignedUser) || !data.assignedUser.includes(currentUserUID)) {
		throw new Error("User is not assigned to this transporter.");
	}

	return {
		name: data.name || "",
		phoneNumber: data.phoneNumber || "",
		website: data.website || "",
		dot: data.dot || "",
		octoNumber: data.octoNumber || "",
		businessHours: data.businessHours || "",
		transportorStatus: data.transportorStatus || "",
		reviewLink: data.reviewLink || "",
		entityId: data.entityId || "",
		sic: data.sic || "",
		cageCode: data.cageCode || "",
		companyDisplayName: data.companyDisplayName || "",
		generalEmail: data.generalEmail || "",
		registration1: data.registration1 || "",
		registration2: data.registration2 || "",
		companyLogo: data.companyLogo || "",
		stateOfFiscalYear: data.stateOfFiscalYear || "",
		ein: data.ein || "",
		epaId: data.epaId || "",
		sos: data.sos || "",
		naicsCode: data.naicsCode || "",
		shortDescription: data.shortDescription || "",
		companyMissionStatement: data.companyMissionStatement || "",
		bio: data.bio || "",
		aboutEmail: data.aboutEmail || "",
		serviceOffered: {
			medicalWasteTransporter: data.serviceOffered?.medicalWasteTransporter || false,
			medicalWasteTransferStation: data.serviceOffered?.medicalWasteTransferStation || false,
			medicalWasteTreatmentFacilities: data.serviceOffered?.medicalWasteTreatmentFacilities || false,
			rollOffContainer: data.serviceOffered?.rollOffContainer || false,
			offsitePaperShredding: data.serviceOffered?.offsitePaperShredding || false,
			onsitePaperShredding: data.serviceOffered?.onsitePaperShredding || false,
			solidWaste: data.serviceOffered?.solidWaste || false,
			recycling: data.serviceOffered?.recycling || false,
		},
		octoConnect: {
			optIn: data.octoConnect?.optIn || false,
			optOut: data.octoConnect?.optOut || false,
		},
		createLink: {
			treatmentFacility: data.createLink?.treatmentFacility || false,
			transferStation: data.createLink?.transferStation || false,
		},
		billingAddress: {
			name: data.billingAddress?.name || "",
			street: data.billingAddress?.street || "",
			suite: data.billingAddress?.suite || "",
			city: data.billingAddress?.city || "",
			state: data.billingAddress?.state || "",
			zipCode: data.billingAddress?.zipCode || "",
			email: data.billingAddress?.email || "",
			phone: data.billingAddress?.phone || "",
		},

		serviceAddress: {
			name: data.serviceAddress?.name || "",
			street: data.serviceAddress?.street || "",
			suite: data.serviceAddress?.suite || "",
			city: data.serviceAddress?.city || "",
			state: data.serviceAddress?.state || "",
			zipCode: data.serviceAddress?.zipCode || "",
			email: data.serviceAddress?.email || "",
			phone: data.serviceAddress?.phone || "",
		},

		dispatchEmail: data.dispatchEmail || "",
		dispatchPhone: data.dispatchPhone || "",
		assignedUser: Array.isArray(data.assignedUser) ? data.assignedUser : [],
	};
};

export async function editTransportorDetails(data) {
	// Ensure we have a userId in the data
	if (!data.userId) {
		throw new Error("User ID is required to update a transporter");
	}

	// Create a reference using the same collection name and userId
	const transportRef = doc(db, "transporters", data.userId);

	try {
		// Clean the data to remove undefined values
		const cleanData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));

		// Add lastUpdated timestamp
		const updatedData = {
			...cleanData,
			lastUpdated: serverTimestamp(),
		};

		// Update the document with merge: true to preserve unmodified fields
		await setDoc(transportRef, updatedData, { merge: true });

		console.log("Transport updated successfully:", transportRef.id);
		return {
			success: true,
			transporterId: transportRef.id, // This will be the same as the user's UID
		};
	} catch (error) {
		console.error("Error updating transport:", error);

		// Provide more specific error messages
		if (error.code === "not-found") {
			throw new Error("Transporter document not found");
		} else if (error.code === "permission-denied") {
			throw new Error("You don't have permission to update this transporter");
		} else {
			throw error;
		}
	}
}

// Satellite Location

export async function createNewSatellite(data) {
	const transportRef = collection(db, "satelliteLocation");

	try {
		const newSatelliteRef = doc(transportRef);
		await setDoc(newSatelliteRef, { ...data });
		console.log("Satellite added successfully:", newSatelliteRef.id);
		return newSatelliteRef.id;
	} catch (error) {
		console.error("Error creating/updating transport:", error);
		throw error;
	}
}

export async function getAllSatellites(userId) {
	const satelliteRef = collection(db, "satelliteLocation");
	const q = query(satelliteRef, where("transporterId", "==", userId));
	const satelliteSnap = await getDocs(q);

	const docs = satelliteSnap.docs.map((doc) => {
		const data = doc.data();
		console.log("Satellite Location Data:", data);

		return {
			id: doc.id,
			name: typeof data.name === "string" ? data.name : "--",
			generalEmail: typeof data.generalEmail === "string" ? data.generalEmail : "--",
			phoneNumber: typeof data.phoneNumber === "string" ? data.phoneNumber : "--",
			octoNumber: typeof data.octoNumber === "string" ? data.octoNumber : "--",
			licensePlate: typeof data.licensePlate === "string" ? data.licensePlate : "--",
			capacity: typeof data.capacity === "string" ? data.capacity : "--",
			assignedDriver: typeof data.assignedDriver === "string" ? data.assignedDriver : "--",
			cageCode: typeof data.cageCode === "string" ? data.cageCode : "--",
			epaId: typeof data.epaId === "string" ? data.epaId : "--",
			satelliteStatus: typeof data.satelliteStatus === "string" ? data.satelliteStatus : "--",
			website: typeof data.website === "string" ? data.website : "--",
			serviceAddress: {
				name: data.serviceAddress?.name || "",
				street: data.serviceAddress?.street || "",
				suite: data.serviceAddress?.suite || "",
				city: data.serviceAddress?.city || "",
				state: data.serviceAddress?.state || "",
				zipCode: data.serviceAddress?.zipCode || "",
				email: data.serviceAddress?.email || "",
				phone: data.serviceAddress?.phone || "",
			},
		};
	});

	// console.log("Satellite Locations:", docs);
	return docs;
}

export const deleteSatellite = async (id) => {
	const satelliteRef = doc(db, "satelliteLocation", id);
	try {
		await deleteDoc(satelliteRef);
		console.log("Satellite Location deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting Satellite Location:", error);
		throw error;
	}
};

export const deleteSatelliteLogo = async (id) => {
	const satelliteRef = doc(db, "satelliteLocation", id);

	try {
		// First update the document to clear the companyLogo field
		await updateDoc(satelliteRef, {
			companyLogo: "",
		});

		// Then delete the document
		await deleteDoc(satelliteRef);
		console.log("Satellite Location deleted:", id);
		return { success: true };
	} catch (error) {
		console.error("Error deleting Satellite Location:", error);
		throw error;
	}
};

export const GetSatelliteData = async (id) => {
	const satelliteRef = doc(db, "satelliteLocation", id);
	const result = await getDoc(satelliteRef);

	if (!result.exists()) {
		throw new Error("Satellite Location not found.");
	}

	const data = result.data();

	return {
		name: data.name || "",
		phoneNumber: data.phoneNumber || "",
		website: data.website || "",
		dot: data.dot || "",
		octoNumber: data.octoNumber || "",
		businessHours: data.businessHours || "",
		satelliteStatus: data.satelliteStatus || "",
		reviewLink: data.reviewLink || "",
		entityId: data.entityId || "",
		sic: data.sic || "",
		cageCode: data.cageCode || "",
		companyDisplayName: data.companyDisplayName || "",
		generalEmail: data.generalEmail || "",
		registration1: data.registration1 || "",
		registration2: data.registration2 || "",
		companyLogo: data.companyLogo || "",
		startOfFiscalYear: data.startOfFiscalYear || "",
		ein: data.ein || "",
		epaId: data.epaId || "",
		sos: data.sos || "",
		naicsCode: data.naicsCode || "",
		shortDescription: data.shortDescription || "",
		companyMissionStatement: data.companyMissionStatement || "",
		bio: data.bio || "",
		aboutEmail: data.aboutEmail || "",
		existingUser: data.existingUser || "",
		serviceOffered: {
			medicalWasteTransporter: data.serviceOffered?.medicalWasteTransporter || false,
			medicalWasteTransferStation: data.serviceOffered?.medicalWasteTransferStation || false,
			medicalWasteTreatmentFacilities: data.serviceOffered?.medicalWasteTreatmentFacilities || false,
			rollOffContainer: data.serviceOffered?.rollOffContainer || false,
			offsitePaperShredding: data.serviceOffered?.offsitePaperShredding || false,
			onsitePaperShredding: data.serviceOffered?.onsitePaperShredding || false,
			solidWaste: data.serviceOffered?.solidWaste || false,
			recycling: data.serviceOffered?.recycling || false,
		},
		octoConnect: {
			optIn: data.octoConnect?.optIn || false,
			optout: data.octoConnect?.optout || false,
		},
		createLink: {
			treatmentFacility: data.createLink?.treatmentFacility || false,
			transferStation: data.createLink?.transferStation || false,
		},
		billingAddress: {
			name: data.billingAddress?.name || "",
			street: data.billingAddress?.street || "",
			suite: data.billingAddress?.suite || "",
			city: data.billingAddress?.city || "",
			state: data.billingAddress?.state || "",
			zipCode: data.billingAddress?.zipCode || "",
			email: data.billingAddress?.email || "",
			phone: data.billingAddress?.phone || "",
		},
		serviceAddress: {
			name: data.serviceAddress?.name || "",
			street: data.serviceAddress?.street || "",
			suite: data.serviceAddress?.suite || "",
			city: data.serviceAddress?.city || "",
			state: data.serviceAddress?.state || "",
			zipCode: data.serviceAddress?.zipCode || "",
			email: data.serviceAddress?.email || "",
			phone: data.serviceAddress?.phone || "",
		},

		dispatchEmail: data.dispatchEmail || "",
		dispatchPhone: data.dispatchPhone || "",
		assignedUser: Array.isArray(data.assignedUser) ? data.assignedUser : [],
	};
};

export const editSatelliteDetails = async (data, id) => {
	if (!id || typeof id !== "string") {
		throw new Error("Invalid Satellite Location ID");
	}

	const satelliteRef = doc(db, "satelliteLocation", id);
	try {
		await updateDoc(satelliteRef, data);
		console.log("Satellite Location details updated:", satelliteRef.id);
		return { success: true, satelliteRef: satelliteRef.id };
	} catch (error) {
		console.error("Error updating Satellite Location:", error);

		if (error.code === "not-found") {
			throw new Error("Satellite Location not found.");
		} else if (error.code === "permission-denied") {
			throw new Error("You do not have permission to update this transportor.");
		} else {
			throw new Error("An unexpected error occurred. Please try again.");
		}
	}
};

// OCTO Connect

export async function getSubContractor() {
	const transportersRef = collection(db, "transportor");
	// Create a query that filters for transporters where octoConnect.optIn is true
	const q = query(transportersRef, where("octoConnect.optIn", "==", true));
	const transportersSnap = await getDocs(q);

	const docs = transportersSnap.docs.map((doc) => {
		const data = doc.data();
		console.log("Satellite Location Data:", data);

		return {
			id: doc.id,
			name: typeof data.name === "string" ? data.name : "--",
			generalEmail: typeof data.generalEmail === "string" ? data.generalEmail : "--",
			phoneNumber: typeof data.phoneNumber === "string" ? data.phoneNumber : "--",
			octoNumber: typeof data.octoNumber === "string" ? data.octoNumber : "--",
			licensePlate: typeof data.licensePlate === "string" ? data.licensePlate : "--",
			capacity: typeof data.capacity === "string" ? data.capacity : "--",
			assignedDriver: typeof data.assignedDriver === "string" ? data.assignedDriver : "--",
			address: typeof data.address === "string" ? data.address : "--",
			apartment: typeof data.apartment === "string" ? data.apartment : "--",
			transportorStatus: typeof data.transportorStatus === "string" ? data.transportorStatus : "--",
			website: typeof data.website === "string" ? data.website : "--",
			// Optionally include octoConnect if needed
			octoConnect: {
				optIn: true, // Set optIn to true
				optOut: false, // Set optOut to false
			},
		};
	});

	return docs;
}

export const getAllRoutesPathData = async (exclusionId = "") => {
	const allRouteRes = await getDocs(query(collection(db, COLLECTIONS.routes)), where(documentId(), "!=", exclusionId));

	if (allRouteRes.docs.length > 0) {
		let allRoutePathData = [];
		const jobs = allRouteRes.docs.map(async (route) => {
			if (route.id !== exclusionId) {
				// const routePath = await getDocs(query(collection(route.ref, COLLECTIONS.path), orderBy("index", "asc")));
				const tempPath = [];
				// if (routePath.docs.length) {
				// 	routePath.forEach((el) => {
				// 		const { path, index } = el.data();
				// 		tempPath.push(...path);
				// 	});
				// }
				allRoutePathData.push({
					path: tempPath,
					id: route.id,
					startLocationName: route.data().defaultStartLocation.name,
					endLocationName: route.data().defaultEndLocation.name,
					routeLabel: route.data().routeLabel,
					waypoints: route?.data()?.waypoints ?? [],
				});
			}
		});
		await Promise.all(jobs);
		return allRoutePathData;
	}
	return [];
};

export const getAllGeneratorsLocationSnapshot = (setData, userId) => {
	return onSnapshot(query(collection(db, COLLECTIONS.generators), where("transporterId", "==", userId)), (snap) => {
		const tempGeneratorData = [];
		snap.docs.forEach((generator) => {
			if (
				generator.exists() &&
				generator.data().serviceAddCoordinates &&
				generator.data().serviceAddCoordinates.lat &&
				generator.data().serviceAddCoordinates.lng
			) {
				tempGeneratorData.push({
					...generator.data(),
					id: generator.id,
				});
			}
		});
		setData(tempGeneratorData);
	});
};
export const getAllVendorsLocationSnapshot = (setData, userId) => {
	return onSnapshot(query(collection(db, COLLECTIONS.vendors), where("transporterId", "==", userId)), (snap) => {
		const tempVendorData = [];
		snap.docs.forEach((vendor) => {
			if (vendor.exists() && vendor.data().vendorCoordinates) {
				tempVendorData.push({
					...vendor.data(),
					id: vendor.id,
				});
			}
		});
		setData(tempVendorData);
	});
};
export const getAllTreatmentsLocationSnapshot = (setData, userId) => {
	return onSnapshot(query(collection(db, COLLECTIONS.treatments), where("transporterId", "==", userId)), (snap) => {
		const tempTreatmentData = [];
		snap.docs.forEach((treatment) => {
			if (treatment.exists() && treatment.data().treatmentCoordinates) {
				tempTreatmentData.push({
					...treatment.data(),
					id: treatment.id,
				});
			}
		});

		setData(tempTreatmentData);
	});
};
export const getAllSatelliteLocationSnapshot = (setData, userId) => {
	console.log({ userId });

	return onSnapshot(
		query(collection(db, COLLECTIONS.satelliteLocation), where("transporterId", "==", userId)),
		(snap) => {
			const tempSatelliteData = [];
			snap.docs.forEach((statellite) => {
				if (statellite.exists() && statellite.data().satelliteServiceCoordinates) {
					tempSatelliteData.push({
						...statellite.data(),
						id: statellite.id,
					});
				}
			});

			setData(tempSatelliteData);
		}
	);
};

export const getAllServiceVehicleSnapshot = (userId, setData) => {
	return onSnapshot(
		query(collection(db, COLLECTIONS.serviceVehicles), where("transportedId", "==", userId)),
		(snap) => {
			const tempGeneratorData = [];
			snap.docs.forEach((generator) => {
				if (generator.exists()) {
					tempGeneratorData.push({
						...generator.data(),
						id: generator.id,
					});
				}
			});
			setData(tempGeneratorData);
		}
	);
};
export const deleteVehicleService = (id) => {
	const docRef = doc(db, COLLECTIONS.serviceVehicles, id);
	deleteDoc(docRef);
};
//

export function listenToWasteProcessingFees(callback) {
	const docRef = doc(db, "configurations", "defaultWasteProcessingFee");

	const unsubscribe = onSnapshot(
		docRef,
		(docSnap) => {
			if (!docSnap.exists()) {
				console.warn("No such document found in configurations collection.");
				callback([]);
				return;
			}

			const data = docSnap.data();
			console.log("Data:", data);

			const fees = {
				biohazard: {
					feeType: typeof data.biohazard?.feeType === "string" ? data.biohazard.feeType : "Biohazard/Sharps",
					active: typeof data.biohazard?.active === "boolean" ? data.biohazard.active : false,
					price: typeof data.biohazard?.price === "number" ? data.biohazard.price : 0.0,
					unit: typeof data.biohazard?.unit === "string" ? data.biohazard.unit : "Pound",
				},
				pharmaceutical: {
					feeType: typeof data.pharmaceutical?.feeType === "string" ? data.pharmaceutical.feeType : "Pharmaceutical",
					active: typeof data.pharmaceutical?.active === "boolean" ? data.pharmaceutical.active : true, // Set active to true
					price: typeof data.pharmaceutical?.price === "number" ? data.pharmaceutical.price : 0.0, // Updated price
					unit: typeof data.pharmaceutical?.unit === "string" ? data.pharmaceutical.unit : "Pound",
				},
				chemotherapy: {
					feeType: typeof data.chemotherapy?.feeType === "string" ? data.chemotherapy.feeType : "chemotherapy",
					active: typeof data.chemotherapy?.active === "boolean" ? data.chemotherapy.active : true, // Set active to true
					price: typeof data.chemotherapy?.price === "number" ? data.chemotherapy.price : 0.0, // Updated price
					unit: typeof data.chemotherapy?.unit === "string" ? data.chemotherapy.unit : "Pound",
				},
				containerWashing: {
					feeType:
						typeof data.containerWashing?.feeType === "string" ? data.containerWashing.feeType : "Container Washing",
					active: typeof data.containerWashing?.active === "boolean" ? data.containerWashing.active : true, // Set active to true
					price: typeof data.containerWashing?.price === "number" ? data.containerWashing.price : 0.0, // Updated price
					unit: typeof data.containerWashing?.unit === "string" ? data.containerWashing.unit : "Each",
				},
				documentFee: {
					feeType: typeof data.documentFee?.feeType === "string" ? data.documentFee.feeType : "Document Fee",
					active: typeof data.documentFee?.active === "boolean" ? data.documentFee.active : true, // Set active to true
					price: typeof data.documentFee?.price === "number" ? data.documentFee.price : 0.0, // Updated price
					unit: typeof data.documentFee?.unit === "string" ? data.documentFee.unit : "Per Transfer",
				},
				energyFee: {
					feeType: typeof data.energyFee?.feeType === "string" ? data.energyFee.feeType : "Energy Fee",
					active: typeof data.energyFee?.active === "boolean" ? data.energyFee.active : true, // Set active to true
					price: typeof data.energyFee?.price === "number" ? data.energyFee.price : 0.0, // Updated price
					unit: typeof data.energyFee?.unit === "string" ? data.energyFee.unit : "Per Transfer",
				},

				gateFee: {
					feeType: typeof data.gateFee?.feeType === "string" ? data.gateFee.feeType : "Gate Fee",
					active: typeof data.gateFee?.active === "boolean" ? data.gateFee.active : true, // Set active to true
					price: typeof data.gateFee?.price === "number" ? data.gateFee.price : 0.0, // Updated price
					unit: typeof data.gateFee?.unit === "string" ? data.gateFee.unit : "Pound",
				},
			};

			callback(fees);
		},
		(error) => {
			console.error("Error listening to waste processing fees:", error);
			callback([]);
		}
	);

	return unsubscribe;
}

export const getServiceVehicleStorageRef = (location) => {
	return ref(storage, location);
};

export const getAllVehicleSatelliteLocationSnapshot = (setData) => {
	return onSnapshot(collection(db, COLLECTIONS.satelliteLocation), (snap) => {
		const tempOptions = [];
		if (snap.docs.length) {
			snap.docs.forEach((el) => {
				if (el.exists()) {
					tempOptions.push({
						value: el.id,
						label: el.data().name,
						...el.data(),
					});
				}
			});
		}
		console.log({ tempOptions });

		setData(tempOptions);
	});
};

export const updateRouteWaypoint = async (routeId, generatorId) => {};

export const removeRouteWaypoint = async (routeId, serviceId, generatorId) => {};

const checkDuplicatesPaginated = async (field, value, generatorId, batchSize = 100) => {
	const normalizedValue = value.toLowerCase().trim();
	const generatorsRef = collection(db, "generators");

	let lastDoc = null;
	let hasMore = true;

	while (hasMore) {
		let q = query(generatorsRef, limit(batchSize));

		if (lastDoc) {
			q = query(q, startAfter(lastDoc));
		}

		const snapshot = await getDocs(q);
		hasMore = snapshot.docs.length === batchSize;

		if (hasMore) {
			lastDoc = snapshot.docs[snapshot.docs.length - 1];
		}

		for (const doc of snapshot.docs) {
			if (doc.id === generatorId) continue;

			const contacts = doc.data().contacts || [];
			const values = new Set(
				contacts
					.map((contact) => (field === "email" ? contact.emailAdd : contact.username)?.toLowerCase().trim())
					.filter(Boolean)
			);

			if (values.has(normalizedValue)) {
				return {
					isDuplicate: true,
					message: `${field === "email" ? "Email" : "Username"} "${value}" is already ${
						field === "email" ? "registered" : "taken"
					}.`,
				};
			}
		}
	}

	return {
		isDuplicate: false,
		message: `${field === "email" ? "Email" : "Username"} is unique.`,
	};
};

const uniquenessCache = {
	cache: new Map(),
	cacheDuration: 5 * 60 * 1000,
	maxEntries: 1000,

	set(key, value) {
		if (this.cache.size >= this.maxEntries) {
			this.cache.clear();
		}
		this.cache.set(key, { value, timestamp: Date.now() });
	},

	get(key) {
		const item = this.cache.get(key);
		if (!item) return null;

		if (Date.now() - item.timestamp > this.cacheDuration) {
			this.cache.delete(key);
			return null;
		}

		return item.value;
	},
};

export const checkDuplicateEmail = async (email, generatorId) => {
	if (!email) {
		throw new Error("Email is required.");
	}

	const cacheKey = `email:${email.toLowerCase().trim()}`;
	const cachedResult = uniquenessCache.get(cacheKey);

	if (cachedResult) {
		return cachedResult;
	}

	const result = await checkDuplicatesPaginated("email", email, generatorId);
	uniquenessCache.set(cacheKey, result);
	return result;
};

export const checkDuplicateUsername = async (username, generatorId) => {
	if (!username) {
		throw new Error("Username is required.");
	}

	const cacheKey = `username:${username.toLowerCase().trim()}`;
	const cachedResult = uniquenessCache.get(cacheKey);

	if (cachedResult) {
		return cachedResult;
	}

	const result = await checkDuplicatesPaginated("username", username, generatorId);
	let resp = { isDuplicateUsername: result.isDuplicate, usernameMessage: result.message };
	uniquenessCache.set(cacheKey, resp);
	return resp;
};

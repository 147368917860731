import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../Icon";
import { IoIosArrowForward } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import { menuItems } from "../../../utils/constants";

const SidebarItem = ({
	item,
	level = 0,
	active,
	expandedItemIds,
	setExpandedItemIds,
	parentLink = null,
	showOnlyIcons,
}) => {
	const [tooltipPosition, setTooltipPosition] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const timeoutRef = useRef(null);
	const submenuRef = useRef(null);
	const itemRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (submenuRef.current && !submenuRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
		};
	}, []);

	const handleItemClick = (clickedItem, hasChildren, shouldCollapseParent) => {
		if (hasChildren) {
			let arr = expandedItemIds;
			if (expandedItemIds.includes(clickedItem.id)) setExpandedItemIds(arr.filter((id) => id !== clickedItem.id));
			else {
				if (shouldCollapseParent) setExpandedItemIds([clickedItem.id]);
				else {
					const newIds = [...new Set([...arr, clickedItem.id])];
					setExpandedItemIds(newIds);
				}
			}
		} else if (level === 0) {
			setExpandedItemIds([]);
		} else {
			if (parentLink) {
				setExpandedItemIds([parentLink.id]);
			}
		}
	};

	const getLinkClassName = () => {
		const baseClass =
			"flex justify-between items-center text-sm xl:text-base transition-colors duration-100 cursor-pointer flex-grow";
		const activeClass = active && level === 0 ? "text-cardTextBlue" : "";
		const inactiveClass = !active && level === 0 ? "text-[#242424]" : "";
		const hoverClass = !active ? (level === 0 ? "group-hover:text-cardTextBlue" : "hover:text-cardTextBlue") : "";
		const levelClass = level > 0 ? "text-cardTextGray" : "";

		return `${baseClass} ${activeClass} ${inactiveClass} ${hoverClass} ${levelClass}`;
	};

	const getIconContainerClassName = () => {
		const baseClass = "flex items-center py-2";
		const levelClass = level > 0 ? "max-h-8 w-full text-xs xl:text-sm gap-2 pl-10" : "pl-6 gap-3";
		const activeClass = active && level > 0 ? "bg-cardBorderCol" : "";

		return `${baseClass} ${levelClass} ${activeClass}`;
	};

	const renderChildren = (children, parentLevel, parentLink) => {
		if (!children || children.length === 0) return null;

		let activeChildLink = children.find((child) => child.link === location.pathname)?.link;

		if (!activeChildLink) {
			let currentPath = location.pathname;

			while (currentPath) {
				activeChildLink = children.find((child) => child.link === currentPath)?.link;

				if (activeChildLink) break;
				const lastSlashIndex = currentPath.lastIndexOf("/");
				if (lastSlashIndex === -1) break;

				currentPath = currentPath.slice(0, lastSlashIndex);
			}
		}

		return (
			<AnimatePresence>
				{expandedItemIds.includes(item.id) && (
					<motion.ul
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.3 }}
						className="overflow-hidden"
					>
						{children.map((child) => (
							<SidebarItem
								key={child.id}
								item={child}
								active={child.link === activeChildLink}
								level={parentLevel + 1}
								expandedItemIds={expandedItemIds}
								setExpandedItemIds={setExpandedItemIds}
								parentLink={parentLink}
								showOnlyIcons={showOnlyIcons}
							/>
						))}
					</motion.ul>
				)}
			</AnimatePresence>
		);
	};

	const renderSubmenu = () => {
		if (!item.children?.length || level !== 0 || !isOpen || expandedItemIds.includes(item.id)) {
			return null;
		}

		const submenuContent = (
			<div
				ref={submenuRef}
				className="fixed ml-72 -mt-[2px] transition-all duration-100"
				style={{ top: `${tooltipPosition}px`, zIndex: 9999 }}
			>
				<Tooltip />
				<AnimatePresence>
					<motion.ul
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.25 }}
						className="bg-white shadow-lg rounded-xl w-60 min-w-fit overflow-hidden"
					>
						{item.children.map((child, index) => (
							<li
								key={child.id}
								className="px-3 hover:bg-lightBlue hover:text-white transition-colors duration-100"
								onMouseEnter={() => clearTimeout(timeoutRef.current)}
								onMouseLeave={handleMouseLeave}
							>
								<Link
									to={child.link}
									onClick={() => handleItemClick(item, child.children?.length > 0, true)}
									className="flex gap-2 items-center py-2"
								>
									<Icon name={child.name} active={false} type="submenu" level={level} />
									{!showOnlyIcons && <h6 className="text-nowrap">{child.label}</h6>}
								</Link>
							</li>
						))}
					</motion.ul>
				</AnimatePresence>
			</div>
		);

		return ReactDOM.createPortal(submenuContent, document.body);
	};

	return (
		<li className="relative group" ref={itemRef}>
			<div className="flex items-center">
				<Link
					to={item.link}
					onClick={() => handleItemClick(item, item.children?.length > 0, true)}
					className={getLinkClassName()}
				>
					<div className={getIconContainerClassName()}>
						<Icon name={item.name} active={active} level={level} />
						{!showOnlyIcons && <h6>{item.label}</h6>}
					</div>
				</Link>
				{item.children?.length > 0 && (
					<button
						className="p-2 hover:bg-cardBorderCol transition-colors duration-200"
						onClick={() => handleItemClick(item, true, false)}
					>
						<IoIosArrowForward
							size={18}
							className={`transform transition-transform duration-200 ${
								active && level === 0 ? "fill-cardTextBlue" : "fill-cardTextGray"
							} 
							${expandedItemIds.includes(item.id) ? "rotate-90" : "rotate-0"}
							`}
						/>
					</button>
				)}
			</div>

			{renderSubmenu()}
			{renderChildren(item.children, level, item)}
		</li>
	);
};

function Tooltip() {
	return (
		<div
			className="absolute left-0 top-3.5 w-0 h-0"
			style={{
				borderTop: "8px solid transparent",
				borderBottom: "8px solid transparent",
				borderRight: "8px solid #1493d7",
				transform: "translateX(-100%)",
			}}
		/>
	);
}

export default SidebarItem;

import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from "lucide-react";
import DatePicker from "react-datepicker";
import { PiCalendarDotsLight } from "react-icons/pi";
import { formattedDate } from "../../utils/helpers";

const CustomDatePicker = ({
	selectedDate,
	setSelectedDate,
	label,
	labelClass = "",
	borderClass = "",
	isDisabled = false,
	startYear = 2015,
	endYear = 2035,
}) => {
	const [isYearOpen, setIsYearOpen] = useState(false);
	const [isMonthOpen, setIsMonthOpen] = useState(false);
	const dropdownYearRef = useRef();
	const dropdownMonthRef = useRef();
	const CustomInputForCalendar = React.forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-inputBg rounded-full text-cardTextGray py-2 px-4 h-9 justify-between gap-4 flex items-center ${borderClass} cursor-pointer`}
			ref={ref}
			onClick={isDisabled ? () => {} : onClick}
		>
			<span className="text-cardTextGray">{value}</span>
			<PiCalendarDotsLight
				className={`text-cardTextGray ${isDisabled ? "" : "hover:text-blueText hover:cursor-pointer"}`}
				size={20}
			/>
		</div>
	));

	const CustomHeader = ({
		date,
		changeYear,
		changeMonth,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled,
	}) => {
		const years =
			startYear && endYear
				? Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i)
				: Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		// Ensure date is a valid Date object
		const currentDate = date instanceof Date ? date : new Date();

		return (
			<div className="custom-header flex items-center justify-between p-2">
				<button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="nav-button p-1">
					<ChevronLeft size={18} />
				</button>
				<div className="flex space-x-2">
					<details className="dropdown" ref={dropdownYearRef}>
						<summary className="flex gap-2 px-2 py-1 cursor-pointer" onClick={() => setIsYearOpen((prev) => !prev)}>
							<div>{currentDate.getFullYear()}</div>{" "}
							<div>{!isYearOpen ? <ChevronDown size={18} /> : <ChevronUp size={18} />}</div>
						</summary>

						<ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-24 p-1 shadow h-[40vh] overflow-x-scroll">
							<div className="h-[40vh] overflow-y-scroll">
								{years.map((year) => (
									<li
										className="flex items-center justify-center"
										key={year}
										onClick={() => {
											dropdownYearRef.current.removeAttribute("open");
											changeYear(year);
											setIsYearOpen(false);
										}}
									>
										<a className="text-base">{year}</a>
									</li>
								))}
							</div>
						</ul>
					</details>
					<details className="dropdown" ref={dropdownMonthRef}>
						<summary className="flex gap-2 px-2 py-1 cursor-pointer" onClick={() => setIsMonthOpen((prev) => !prev)}>
							<div>{months[currentDate.getMonth()]}</div>{" "}
							<div>{!isMonthOpen ? <ChevronDown size={18} /> : <ChevronUp size={18} />}</div>
						</summary>

						<ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-30 p-1 shadow h-[40vh] overflow-x-scroll">
							<div className="h-[40vh] overflow-y-scroll">
								{months.map((month) => (
									<li
										className="flex items-center w-full"
										key={month}
										onClick={() => {
											dropdownMonthRef.current.removeAttribute("open");
											changeMonth(months.indexOf(month));
											setIsMonthOpen(false);
										}}
									>
										<a className="text-base text-left w-full">{month}</a>
									</li>
								))}
							</div>
						</ul>
					</details>
				</div>
				<button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="nav-button p-1">
					<ChevronRight size={18} />
				</button>
			</div>
		);
	};

	return (
		<DatePicker
			selected={selectedDate}
			onChange={(date) => setSelectedDate(formattedDate(date))}
			customInput={<CustomInputForCalendar />}
			renderCustomHeader={CustomHeader}
			dateFormat="EEE, MM/dd/yyyy"
			showPopperArrow={false}
			className="custom-datepicker responsive-datepicker"
		/>
	);
};

CustomDatePicker.propTypes = {
	borderClass: PropTypes.string,
	isDisabled: PropTypes.bool,
	label: PropTypes.any,
	labelClass: PropTypes.string,
	selectedDate: PropTypes.any,
	setSelectedDate: PropTypes.func,
};

export default CustomDatePicker;

import PropTypes from "prop-types";

import React from "react";

const TodaysReportsProgressBar = ({ pending = 0, inProgress = 0, complete = 0 }) => {
	const totalServices = pending + inProgress + complete;

	const pendingPercentage = ((pending / totalServices) * 100).toFixed(2);
	const inProgressPercentage = ((inProgress / totalServices) * 100).toFixed(2);
	const completePercentage = ((complete / totalServices) * 100).toFixed(2);

	console.log({ pending, inProgress, complete });

	return (
		<div className="bg-gray-200 rounded-full h-5 w-full flex text-gray-700 text-md grid grid-cols-3">
			<div className="relative bg-blue-500 h-5 rounded-l-full" style={{ width: `${inProgressPercentage ?? 25}%` }}>
				<div className="absolute top-6 right-0">{inProgress}</div>
				<div className="absolute bottom-6 left-0">Incomplete</div>
			</div>
			<div className="relative bg-red-500 h-5" style={{ width: `${pendingPercentage ?? 50}%` }}>
				<div className="absolute top-6 right-0">{pending}</div>
				<div className="absolute bottom-6 left-0">Scheduled</div>
			</div>
			<div className="relative bg-green-500 h-5 rounded-r-full" style={{ width: `${completePercentage ?? 75}%` }}>
				<div className="absolute top-6 right-0">{complete}</div>
				<div className="absolute bottom-6 left-0">Completed</div>
			</div>
		</div>
	);
};

TodaysReportsProgressBar.propTypes = {
	complete: PropTypes.number,
	inProgress: PropTypes.number,
	pending: PropTypes.number,
};
export default TodaysReportsProgressBar;
